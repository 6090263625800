import React, { useContext, useEffect, useState } from "react";
import {
    Button,
    Form,
    Modal,
    notification,
    Popconfirm,
    Spin,
    Table
} from "antd";
import { AppContext } from "../contexts/AppContext";
import { API } from "aws-amplify";
import { listDialysis, listDialysisInterval } from "../backend/graphql/queries";
import {
    updateDialysis,
    updateDialysisInterval
} from "../backend/graphql/mutations";
import moment from "moment";

import renderInputItems from "../utils/renderFormInputItems";
import iconEye from "../media/Icon-Eye2.png";
import iconEdit from "../media/Icon-Edit.png";

const PatientRecordDialysisHistory = (props) => {
    const { appState } = useContext(AppContext);
    const [formUpdate] = Form.useForm();
    const { patientID, manageDialysis = false } = props;
    const [tableDialysisHistory, setTableDialysisHistory] = useState({
        data: -1,
        pagination: {
            current: 1,
            pageSize: 10,
            offset: 0,
            showSizeChanger: false
        },
        loading: false
    });
    const [tableDialysisInterval, setTableDialysisInterval] = useState({
        data: -1,
        pagination: {
            current: 1,
            pageSize: 100,
            offset: 0
        },
        loading: false
    });
    const [displayListDialysisHistory, setDisplayListDialysisHistory] =
        useState([]);
    const [displayListDialysisInterval, setDisplayListDialysisInterval] =
        useState([]);
    const [visibilityDialysisNoteSymptoms, setVisibilityDialysisNoteSymptoms] =
        useState(0);
    const [
        visibilityIntraDialysisNoteSymptoms,
        setVisibilityIntraDialysisNoteSymptoms
    ] = useState(0);
    const [visibilityDialysisHistory, setVisibilityDialysisHistory] =
        useState(false);
    const [dialysisHistory, setDialysisHistory] = useState({});
    const validateMessages = {
        required: "Required."
    };
    const [loadingModal, setLoadingModal] = useState(false);

    const columns = [
        {
            title: "Date / Time",
            dataIndex: "timestampDisplay",
            width: 150,
            // key: 'startTime'
            render: (text, record) => {
                if (manageDialysis === false) {
                    return text;
                } else {
                    return (
                        <div style={{ display: "flex", alignItems: "center" }}>
                            {text}
                            <div
                                className="clickable"
                                onClick={() => {
                                    handleModalVisibility(
                                        "dialysisHistory",
                                        record
                                    );
                                }}
                            >
                                <img src={iconEdit} />
                            </div>
                        </div>
                    );
                }
            }
        },
        {
            title: "Clinic",
            dataIndex: "clinicUid"
        },
        {
            title: "SE",
            dataIndex: "sessionMonth"
            // key: 'sessionMonth'
        },
        {
            title: "Duration",
            dataIndex: "duration",
            render(text, record) {
                let outOfRange =
                    Math.abs(parseInt(text)) >= 255 ||
                    Math.abs(parseInt(text)) <= 180;
                return {
                    props: {
                        style: {
                            color: outOfRange ? "red" : "",
                            fontWeight: outOfRange ? "bold" : "normal"
                        }
                    },
                    children: text
                };
            }
        },
        {
            title: "QB",
            dataIndex: "QB"
        },
        {
            title: "AP",
            dataIndex: "AP"
        },
        {
            title: "VP",
            dataIndex: "VP"
        },
        {
            title: "FLOW",
            dataIndex: "DFLOW"
        },
        {
            title: "COND",
            dataIndex: "CDT"
        },
        {
            title: "LAST WT",
            dataIndex: "lastWeight",
            render: (text, record) => {
                return text && `${text}`.length > 0
                    ? parseFloat(text)?.toFixed(1)
                    : "";
            }
            // key: 'lastWeight'
        },
        {
            title: "Pre Edema",
            dataIndex: "preEdema"
        },
        {
            title: "Post Edema",
            dataIndex: "postEdema"
        },
        {
            title: "PRE WT",
            dataIndex: "preWeight",
            render: (text, record) => {
                return text && `${text}`.length > 0
                    ? parseFloat(text)?.toFixed(1)
                    : "";
            }
            // key: 'preWeight'
        },
        {
            title: "DW",
            dataIndex: "dryWeight",
            render: (text, record) => {
                return text && `${text}`.length > 0
                    ? parseFloat(text)?.toFixed(1)
                    : "";
            }
            // key: 'dryWeight'
        },
        {
            title: "UFT",
            dataIndex: "ufTargetStart"
        },
        {
            title: "POST WT",
            dataIndex: "postWeight",
            render: (text, record) => {
                return text && `${text}`.length > 0
                    ? parseFloat(text)?.toFixed(1)
                    : "";
            }
            // key: 'postWeight'
        },
        {
            title: "OFF Target",
            dataIndex: "offTarget",
            render(text, record) {
                let outOfRange = Math.abs(parseFloat(text)) > 0.5;
                return {
                    props: {
                        style: {
                            color: outOfRange ? "red" : "",
                            fontWeight: outOfRange ? "bold" : "normal"
                        }
                    },
                    children:
                        text && `${text}`.length > 0
                            ? parseFloat(text)?.toFixed(1)
                            : ""
                };
            }
        },
        {
            title: "Post SBP / DBP",
            dataIndex: "postSBPDBP"
            // key: ['postSBP', 'postDBP']
        },
        {
            title: "SBP",
            dataIndex: "SBP"
        },
        {
            title: "DBP",
            dataIndex: "DBP"
        },
        {
            title: "HR",
            dataIndex: "HR"
        },
        {
            title: "Temp",
            dataIndex: "temp"
        },
        {
            title: "UFR (ℓ)",
            dataIndex: "UFR"
        },
        {
            title: "UFV (ℓ)",
            dataIndex: "cUF"
        },
        {
            title: "Temp Admit",
            dataIndex: "preTemp"
            // key: 'preTemp'
        },
        {
            title: "Temp Discharge",
            dataIndex: "postTemp"
            // key: 'postTemp'
        },
        {
            title: "MSA",
            dataIndex: "MSA"
        },
        {
            title: "Dialyser",
            dataIndex: "dialyser"
        },
        {
            title: "KTV Online",
            dataIndex: "KTVonline"
        },
        {
            title: "Dialysis Note",
            dataIndex: "combinedNotes",
            render: (text = "", record) => {
                return text !== "" && `${text}`.length > 0 ? (
                    <div
                        className="clickable"
                        onClick={() => {
                            handleModalVisibility(record.type, record.id);
                        }}
                    >
                        <img src={iconEye} />
                    </div>
                ) : (
                    ""
                );
            },
            fixed: "right"
        },
        {
            title: "Symptoms",
            dataIndex: "displaySymptoms",
            render: (text = "", record) => {
                // console.log(typeof text, text, record)
                return text !== "" && `${text}`.length > 0 ? (
                    <div
                        className="clickable"
                        onClick={() => {
                            handleModalVisibility(record.type, record.id);
                        }}
                    >
                        <img src={iconEye} />
                    </div>
                ) : (
                    ""
                );
            },
            fixed: "right"
        }
    ];

    useEffect(() => {
        if (Object.keys(appState.cognitoUser).length !== 0) {
            // _listDialysis(props.patientID, tableDialysisHistory.pagination);
            // console.log('PatientRecordDialysisHistory', props);
            getDialysisHistory(null, {
                ...tableDialysisHistory.pagination,
                offset:
                    tableDialysisHistory.pagination.current *
                        tableDialysisHistory.pagination.pageSize -
                    tableDialysisHistory.pagination.pageSize
            });
        }
        return () => {};
    }, [appState.cognitoUser]);

    useEffect(() => {
        if (
            tableDialysisHistory.data !== -1 &&
            appState.fixedListLoaded === true
        ) {
            // let array = [];
            // for (let r of tableDialysisHistory.data) {
            //     r.lastWeight = (parseInt(r.lastWeight) / 1000.0).toFixed(1);
            //     r.preWeight = (parseInt(r.preWeight) / 1000.0).toFixed(1);
            //     r.postWeight = (parseInt(r.postWeight) / 1000.0).toFixed(1);
            //     r.dryWeight = (parseInt(r.dryWeight) / 1000.0).toFixed(1);

            //     r.preTemp = (parseFloat(r.preTemp).toFixed(1));
            //     r.postTemp = (parseFloat(r.postTemp).toFixed(1));

            //     r.timestamp = moment.utc(r.startTime).local().format('DD MMM YYYY, HH:mm:ss');

            //     r.postSBPDBP = r.postSBP + ' / ' + r.postDBP;

            //     array.push({ ...r });
            // }
            // let dialysisIDs = array.map(s => s.id);
            let dialysisIDs = tableDialysisHistory.data.map((s) => s.id);
            // console.log('tableDialysisHistory', dialysisIDs);
            if (dialysisIDs.length !== 0) {
                _listDialysisInterval(
                    dialysisIDs,
                    tableDialysisInterval.pagination
                );
            } else {
                setTableDialysisInterval({
                    ...tableDialysisInterval,
                    loading: false
                });
            }
            // setDisplayListDialysisHistory(array);
            // setTableDialysisHistory({
            //     ...tableDialysisHistory,
            //     loading: false
            // })
        }

        return () => {};
    }, [tableDialysisHistory.data, appState.fixedListLoaded]);

    useEffect(() => {
        if (
            tableDialysisInterval.data !== -1 &&
            appState.fixedListLoaded === true
        ) {
            let _dialysisInterval = [];
            for (let r of tableDialysisInterval.data) {
                r.timestampDisplay = moment
                    .utc(r.timestamp)
                    .local()
                    .format("DD MMM YYYY, HH:mm:ss");

                for (let i = 0; i < appState.clinicList.length; i++) {
                    if (r.clinicID === appState.clinicList[i].id) {
                        r.clinicUid = appState.clinicList[i].uid;
                        break;
                    }
                }
                // console.log("r", r);

                let _di = {
                    ...r,
                    type: "intraDialysisNoteSymptoms",
                    notes: r.note ?? "",
                    combinedNotes: r.note ?? "",
                    UFR: (Number(r.UFR) / 1000.0).toFixed(2),
                    cUF: (Number(r.cUF) / 1000.0).toFixed(1),
                    displaySymptoms: r.symptoms
                        ? appState?.symptomList
                              ?.filter((s) =>
                                  JSON.parse(r.symptoms).includes(s.id)
                              )
                              .map((s) => s.name)
                              .join(", ")
                        : "",
                    symptoms: r.symptoms ?? ""
                };

                for (let [k, v] of Object.entries(_di)) {
                    if (v || v?.length > 0) _di[k] = v.toString();
                }

                _dialysisInterval.push(_di);
            }

            // let _dialysisHistory = [...displayListDialysisHistory];
            let _dialysisHistory = [];
            // tableDialysisInterval.dialysisIDs.forEach(dialysisID => {

            // console.log('displayListDialysisHistory', displayListDialysisHistory);
            // _dialysisHistory.forEach(d => {
            // for (let d of displayListDialysisHistory) {
            for (let d of tableDialysisHistory.data) {
                d.type = "dialysisNoteSymptoms";
                d.lastWeight =
                    d.lastWeight != null && d.lastWeight >= 0
                        ? (parseInt(d.lastWeight) / 1000.0).toFixed(1)
                        : "";
                d.preWeight =
                    d.preWeight != null && d.preWeight >= 0
                        ? (parseInt(d.preWeight) / 1000.0).toFixed(1)
                        : "";
                d.postWeight =
                    d.postWeight != null && d.postWeight >= 0
                        ? (parseInt(d.postWeight) / 1000.0).toFixed(1)
                        : "";
                d.dryWeight =
                    d.dryWeight != null && d.dryWeight >= 0
                        ? (parseInt(d.dryWeight) / 1000.0).toFixed(1)
                        : "";

                d.preTemp =
                    d.preTemp != null && d.preTemp >= 0
                        ? parseFloat(d.preTemp).toFixed(1)
                        : "";
                d.postTemp =
                    d.postTemp != null && d.postTemp >= 0
                        ? parseFloat(d.postTemp).toFixed(1)
                        : "";

                d.KTVonline =
                    d.KTVonline != null && d.KTVonline >= 0
                        ? parseFloat(d.KTVonline).toFixed(1)
                        : "";

                d.timestampDisplay = d.startTime
                    ? moment
                          .utc(d.startTime)
                          .local()
                          .format("DD MMM YYYY, HH:mm:ss")
                    : "";
                if (!d.endedBy) {
                    d.timestampDisplay += " (In Progress)";
                }

                d.postSBPDBP =
                    d.postSBP && d.postDBP ? d.postSBP + " / " + d.postDBP : "";
                d.offTarget =
                    d.postWeight && d.dryWeight
                        ? (d.postWeight - d.dryWeight).toFixed(1)
                        : "";

                let filteredDialysisInterval = _dialysisInterval.filter(
                    (s) => s.dialysisID == d.id
                );
                // console.log('filteredDialysisInterval', filteredDialysisInterval);

                // if (d.id == dialysisID) {
                let QBs = filteredDialysisInterval.map((s) => s.QB);
                d.QB = QBs.length
                    ? Math.min(...QBs) + " to " + Math.max(...QBs)
                    : "";

                let APs = filteredDialysisInterval.map((s) => s.AP);
                d.AP = APs.length
                    ? Math.min(...APs) + " to " + Math.max(...APs)
                    : "";

                let VPs = filteredDialysisInterval.map((s) => s.VP);
                d.VP = VPs.length
                    ? Math.min(...VPs) + " to " + Math.max(...VPs)
                    : "";

                let DFLOWs = filteredDialysisInterval.map((s) => s.DFLOW);
                d.DFLOW = DFLOWs.length
                    ? Math.min(...DFLOWs) + " to " + Math.max(...DFLOWs)
                    : "";

                let CDTs = filteredDialysisInterval.map((s) => s.CDT);
                d.CDT = CDTs.length
                    ? Math.min(...CDTs) + " to " + Math.max(...CDTs)
                    : "";

                let SBPs = filteredDialysisInterval.map((s) => s.SBP);
                d.SBP = SBPs.length
                    ? Math.min(...SBPs) + " to " + Math.max(...SBPs)
                    : "";

                let DBPs = filteredDialysisInterval.map((s) => s.DBP);
                d.DBP = DBPs.length
                    ? Math.min(...DBPs) + " to " + Math.max(...DBPs)
                    : "";

                let HRs = filteredDialysisInterval.map((s) => s.HR);
                d.HR = HRs.length
                    ? Math.min(...HRs) + " to " + Math.max(...HRs)
                    : "";

                let temps = filteredDialysisInterval.map((s) => s.temp);
                d.temp = temps.length
                    ? Math.min(...temps) + " to " + Math.max(...temps)
                    : "";

                let KTVs = filteredDialysisInterval.map((s) => s.KTV);
                d.KTV = KTVs.length
                    ? Math.min(...KTVs) + " to " + Math.max(...KTVs)
                    : "";

                let UFR = filteredDialysisInterval.map((s) => s.UFR);
                d.UFR = UFR.length
                    ? Math.min(...UFR) + " to " + Math.max(...UFR)
                    : "";

                let UFV = filteredDialysisInterval.map((s) => s.cUF);
                d.cUF = UFV.length ? Math.max(...UFV) : "";

                d.dialysisNotes = d.notes;
                d.combinedNotes = d.notes
                    ? d.notes +
                      " || " +
                      filteredDialysisInterval
                          .map((s) => s.note)
                          .filter((s) => s && s.length > 0)
                          .join(" | ")
                    : " || " +
                      filteredDialysisInterval
                          .map((s) => s.note)
                          .filter((s) => s && s.length > 0)
                          .join(" | ");

                let allSymoptoms = filteredDialysisInterval.reduce(
                    (a, b) =>
                        // {
                        // if (b?.symptoms) console.log(a, JSON.parse(b?.symptoms));
                        // return
                        a.concat(
                            b?.symptoms && b?.symptoms?.length > 0
                                ? JSON.parse(b?.symptoms)
                                : []
                        ),
                    // }
                    []
                );
                // console.log('allSymoptoms', allSymoptoms);
                d.displaySymptoms = allSymoptoms.length
                    ? appState?.symptomList
                          ?.filter((s) => allSymoptoms.includes(s.id))
                          .map((s) => s.name)
                          .join(", ")
                    : "";

                // let duration = filteredDialysisInterval.length > 0 && d.dischargeTime ? (moment(filteredDialysisInterval[0].timestamp).diff(moment(d.startTime), "minutes")) : (d.dischargeTime ? moment(d.dischargeTime).diff(moment(d.startTime), "minutes") : '');
                let duration = d.dischargeTime
                    ? moment(d.dischargeTime).diff(
                          moment(d.startTime),
                          "minutes"
                      )
                    : "";
                d.duration = duration || "";

                for (let i = 0; i < appState.clinicList.length; i++) {
                    if (d.clinicID === appState.clinicList[i].id) {
                        d.clinicUid = appState.clinicList[i].uid;
                        break;
                    }
                }

                for (let [k, v] of Object.entries(d)) {
                    if (v || v?.length > 0) d[k] = v.toString();
                }

                _dialysisHistory.push(d);
            }

            setDisplayListDialysisInterval(_dialysisInterval);
            setDisplayListDialysisHistory(_dialysisHistory);
            setTableDialysisInterval({
                ...tableDialysisInterval,
                loading: false
            });
            setTableDialysisHistory({
                ...tableDialysisHistory,
                loading: false
            });
        }
    }, [tableDialysisInterval.data, appState.fixedListLoaded]);

    const _listDialysis = async (patientID, props) => {
        setTableDialysisHistory({
            ...tableDialysisHistory,
            loading: true
        });
        setTableDialysisInterval({
            ...tableDialysisInterval,
            loading: true
        });
        try {
            const variables = {
                pagination: {
                    limit: props.pageSize,
                    offset: props.offset,
                    orderby: "startTime DESC"
                }
            };

            if (patientID > 0) {
                variables.filter = {
                    patientID: { eq: patientID },
                    deleted: { eq: 0 }
                    // endedBy: {attributeExists: false} // not working
                };
            }

            // if (appState.selectedClinicID > 0) {
            // if (appState.selectedClinicID !== "") {
            //     variables.filter = {
            //         ...variables.filter,
            //         clinicID: {
            //             eq: appState.selectedClinicID
            //         }
            //     }
            // }

            // console.log('PatientRecordDialysisHistory', variables);

            const result = await API.graphql({
                query: listDialysis,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables
            });
            // console.log(API result PatientRecordDialysisHistory _listDialysis", result);
            let data = result.data.result;
            if (manageDialysis === true) {
                data.result = data.result.filter(
                    (item) =>
                        item.endTime !== null &&
                        item.endTime !== undefined &&
                        item.endTime !== ""
                );
            }

            setTableDialysisHistory({
                ...tableDialysisHistory,
                // loading: _dialysisHistory.length !== 0 ? true : false,
                // data: _dialysisHistory,
                loading: data.result.length !== 0 ? true : false,
                data: data.result,
                pagination: {
                    ...tableDialysisHistory.pagination,
                    current: props.current,
                    offset: props.offset,
                    total:
                        props.offset >= data.count ? data.count + 1 : data.count // keeps the last pagination if it is the last record
                }
            });
        } catch (error) {
            console.log("error: ", error);
            setTableDialysisHistory({
                ...tableDialysisHistory,
                loading: false
            });
        } finally {
        }
    };

    const _listDialysisInterval = async (dialysisIDs, props) => {
        // console.log("props", props);
        try {
            const variables = {
                pagination: {
                    // limit: props.pageSize,
                    // offset: props.offset,
                    orderby: "timestamp DESC"
                }
            };

            variables.filter = {
                ...variables.filter,
                or: [],
                deleted: { eq: 0 }
            };
            dialysisIDs.forEach((id) =>
                variables.filter.or.push({ dialysisID: { eq: id } })
            );

            // console.log(listDialysisInterval', variables);

            const result = await API.graphql({
                query: listDialysisInterval,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables
            });
            // console.log(API result listDialysisInterval", result);
            const data = result.data.result;

            setTableDialysisInterval({
                ...tableDialysisInterval,
                data: data.result,
                loading: data.result.length !== 0 ? true : false,
                dialysisIDs: dialysisIDs
            });
        } catch (error) {
            console.log("error: ", error);
            setTableDialysisInterval({
                ...tableDialysisInterval,
                loading: false
            });
            setTableDialysisHistory({
                ...tableDialysisHistory,
                loading: false
            });
        }
    };

    const getDialysisHistory = async (filter, pagination) => {
        _listDialysis(patientID, pagination);
    };

    const updateDialysisRecord = async (values, id) => {
        // console.log(alues);
        setLoadingModal(true);
        try {
            const variables = {
                update: {
                    id: Number(id),
                    arriveTime: values.datetimePeriod[0]
                        .toISOString()
                        .split(".")[0],
                    startTime: values.datetimePeriod[0]
                        .toISOString()
                        .split(".")[0],
                    endTime: values.datetimePeriod[1]
                        .toISOString()
                        .split(".")[0],
                    dischargeTime: values.datetimePeriod[1]
                        .toISOString()
                        .split(".")[0]
                }
            };

            let fieldsToCheckAndUpdate = [
                { key: "sessionMonth", type: "int" },
                { key: "lastWeight", type: "int", multiple: 1000 },
                { key: "dryWeight", type: "int", multiple: 1000 },
                { key: "ufTargetStart", type: "float" },
                { key: "preWeight", type: "int", multiple: 1000 },
                { key: "postWeight", type: "int", multiple: 1000 },
                { key: "preTemp", type: "float" },
                { key: "postTemp", type: "float" },
                { key: "preEdema", type: "int" },
                { key: "postEdema", type: "int" },
                { key: "preSBP", type: "int" },
                { key: "postSBP", type: "int" },
                { key: "preDBP", type: "int" },
                { key: "postDBP", type: "int" },
                { key: "preHRT", type: "int" },
                { key: "postHRT", type: "int" },
                { key: "KTVonline", type: "float" },
                { key: "MSA", type: "float" },
                { key: "dialyser", type: "string" },
                { key: "membraneUse", type: "string" },
                { key: "notes", type: "string" }
            ];
            for (let obj of fieldsToCheckAndUpdate) {
                if (
                    values[obj.key] &&
                    dialysisHistory[obj.key] != values[obj.key]
                ) {
                    if (obj?.multiple)
                        values[obj.key] =
                            Number(values[obj.key]) * 1000 || null;

                    if (obj?.type === "int")
                        variables.update[obj.key] = Number.parseInt(
                            values[obj.key]
                        );
                    else if (obj?.type === "float")
                        variables.update[obj.key] = Number(values[obj.key]);
                    // else if (obj?.type === 'string')
                    else variables.update[obj.key] = values[obj.key];
                }
            }

            // console.log("variables", variables);
            const result = await API.graphql({
                query: updateDialysis,
                variables: variables,
                authMode: "AMAZON_COGNITO_USER_POOLS"
            });
            // console.log(result);
            // const data = result.data.result
            // console.log("data", data);
            notification.success({
                message: "Updated successfully"
            });
            handleModalVisibility("dialysisHistory");
            getDialysisHistory(null, {
                ...tableDialysisHistory.pagination,
                offset:
                    tableDialysisHistory.pagination.current *
                        tableDialysisHistory.pagination.pageSize -
                    tableDialysisHistory.pagination.pageSize
            });
        } catch (error) {
            console.log("error: ", error);
            notification.error({
                message: "Unable to update record"
            });
        } finally {
            setLoadingModal(false);
        }
    };

    const updateDialysisIntervalRecord = async (values, id) => {
        // console.log(values);
        setLoadingModal(true);
        try {
            const variables = {
                update: {
                    id: id,
                    timestamp: values.datetime.toISOString().split(".")[0]
                }
            };

            let fieldsToCheckAndUpdate = [
                { key: "AP", type: "int" },
                { key: "VP", type: "int" },
                { key: "SBP", type: "int" },
                { key: "DBP", type: "int" },
                { key: "HR", type: "int" },
                { key: "CDT", type: "float" },
                { key: "cUF", type: "float", multiple: 1000 },
                { key: "QB", type: "int" },
                { key: "DFLOW", type: "int" },
                { key: "UFR", type: "float", multiple: 1000 },
                { key: "temp", type: "float" },
                { key: "tmp", type: "int" },
                { key: "hepRate", type: "float" },
                { key: "note", type: "string" },
                { key: "symptoms", type: "stringArr" }
            ];
            for (let obj of fieldsToCheckAndUpdate) {
                if (
                    values[obj.key] &&
                    dialysisHistory[obj.key] != values[obj.key]
                ) {
                    if (obj?.multiple)
                        values[obj.key] =
                            Number(values[obj.key]) * 1000 || null;

                    if (obj?.type === "int")
                        variables.update[obj.key] = Number.parseInt(
                            values[obj.key]
                        );
                    else if (obj?.type === "float")
                        variables.update[obj.key] = Number(values[obj.key]);
                    // else if (obj?.type === 'string')
                    else if (obj?.type === "stringArr")
                        variables.update[obj.key] = JSON.stringify(
                            values[obj.key]
                        );
                    else variables.update[obj.key] = values[obj.key];
                }
            }

            // console.log(variables", variables);
            const result = await API.graphql({
                query: updateDialysisInterval,
                variables: variables,
                authMode: "AMAZON_COGNITO_USER_POOLS"
            });
            // console.log(esult);
            // const data = result.data.result
            // console.log("data", data);
            notification.success({
                message: "Updated successfully"
            });
            handleModalVisibility("dialysisHistory");
            getDialysisHistory(null, {
                ...tableDialysisHistory.pagination,
                offset:
                    tableDialysisHistory.pagination.current *
                        tableDialysisHistory.pagination.pageSize -
                    tableDialysisHistory.pagination.pageSize
            });
        } catch (error) {
            console.log("error: ", error);
            notification.error({
                message: "Unable to update record"
            });
        } finally {
            setLoadingModal(false);
        }
    };

    const expandedRowRender = (dialysisInterval) => {
        return (
            <div>
                <Table
                    className="subtable"
                    rowKey={(record) => record.id}
                    rowClassName={(record, index) => {
                        // console.log("index", index);
                        return index % 2 === 0 ? "highlight" : "";
                    }}
                    columns={columns}
                    scroll={{ x: 2500, y: 300 }}
                    dataSource={dialysisInterval}
                    pagination={false}
                />
            </div>
        );
    };

    const handleTableChange = (paginate) => {
        // console.log('handleTableChange', paginate)
        getDialysisHistory(null, {
            ...tableDialysisHistory.pagination,
            current: paginate.current,
            offset:
                paginate.current * tableDialysisHistory.pagination.pageSize -
                tableDialysisHistory.pagination.pageSize
        });
    };

    const handleModalVisibility = (modal, id = 0) => {
        // console.log("modal", modal, id);
        switch (modal) {
            case "dialysisNoteSymptoms":
                setVisibilityDialysisNoteSymptoms(id);
                break;
            case "intraDialysisNoteSymptoms":
                setVisibilityIntraDialysisNoteSymptoms(id);
                break;
            case "dialysisHistory":
                if (visibilityDialysisHistory === false) {
                    const record = id;
                    const type = record.startTime ?? "";
                    setDialysisHistory({
                        ...record,
                        type: type === "" ? "intra" : "session"
                    });
                    if (type !== "") {
                        // its is session
                        let datetimePeriod = [];
                        datetimePeriod.push(
                            moment.utc(record.startTime).local()
                        );
                        const endTime = record.endTime ?? "";
                        if (endTime !== "") {
                            datetimePeriod.push(moment.utc(endTime).local());
                        }
                        formUpdate.setFieldsValue({
                            datetimePeriod: datetimePeriod,
                            ...record
                        });
                    } else {
                        // its is interval
                        formUpdate.setFieldsValue({
                            ...record,
                            symptoms: record.symptoms
                                ? JSON.parse(record.symptoms)
                                : [],
                            datetime: moment.utc(record.timestamp).local()
                        });
                    }
                } else {
                    setDialysisHistory({});
                    formUpdate.resetFields();
                }
                setVisibilityDialysisHistory(!visibilityDialysisHistory);
                break;
            default:
        }
    };

    const onFinishUpdate = async (values, dialysisHistory) => {
        console.log("onFinishUpdate values", values);
        console.log("onFinishUpdate dialysisHistory", dialysisHistory);
        await formUpdate.validateFields();
        if (dialysisHistory.type === "session") {
            // update dialysis,
            updateDialysisRecord(values, dialysisHistory.id);
        } else {
            // type = intra
            // update dialysis interval
            updateDialysisIntervalRecord(values, dialysisHistory.id);
        }
    };

    const onFieldsChangeUpdate = async (changedValue, allValues) => {
        // console.log(changedValue, allValues);

        if (
            changedValue?.[0]?.name?.[0] === "postWeight" ||
            changedValue?.[0]?.name?.[0] === "dryWeight"
        ) {
            let postWeight = Number(
                allValues.find((s) => s?.name?.[0] === "postWeight")?.value
            );
            let dryWeight = Number(
                allValues.find((s) => s?.name?.[0] === "dryWeight")?.value
            );
            let offTarget = (postWeight - dryWeight).toFixed(1);
            // console.log(postWeight, dryWeight, offTarget)
            formUpdate.setFieldsValue({
                offTarget: offTarget
            });
        } else if (changedValue?.[0]?.name?.[0] === "datetimePeriod") {
            let dischargeTime = changedValue?.[0]?.value[1];
            let startTime = changedValue?.[0]?.value[0];
            let duration = dischargeTime.diff(startTime, "minutes");
            // console.log(duration)
            formUpdate.setFieldsValue({
                duration: duration
            });
        }
    };

    return (
        <div className="patientrecord-dialysishistory">
            <div className="title">Dialysis History</div>
            <div className="row">
                <div className="column1">
                    <Table
                        // loading={tableDialysisInterval.loading}
                        columns={columns}
                        scroll={{ x: 3000, y: 500 }}
                        rowClassName={(record, index) => {
                            // console.log("index", index);
                            return index % 2 === 0 ? "highlight" : "";
                        }}
                        expandable={{
                            expandedRowRender: (record) =>
                                expandedRowRender(
                                    displayListDialysisInterval.filter(
                                        (s) => s.dialysisID == record.id
                                    )
                                )
                        }}
                        loading={tableDialysisHistory.loading}
                        dataSource={displayListDialysisHistory}
                        rowKey={(record) => record.id}
                        pagination={tableDialysisHistory.pagination}
                        onChange={handleTableChange}
                    />
                </div>
            </div>
            <Modal
                title={
                    <div className="modal-title">
                        Dialysis Note and Symptoms
                    </div>
                }
                visible={visibilityDialysisNoteSymptoms}
                onCancel={() =>
                    handleModalVisibility("dialysisNoteSymptoms", 0)
                }
                footer={[
                    <Button
                        key="back"
                        onClick={() =>
                            handleModalVisibility("dialysisNoteSymptoms")
                        }
                    >
                        Cancel
                    </Button>
                ]}
                className="modal-patientrecord-medlabmilestone"
                width={800}
            >
                <div className="modal-content">
                    <div className="medicalmilestone">
                        <div className="row2">
                            <div className="label">Dialysis Note:</div>
                            <div className="value">
                                {
                                    displayListDialysisHistory.find(
                                        (s) =>
                                            s.id ==
                                            visibilityDialysisNoteSymptoms
                                    )?.combinedNotes
                                }
                            </div>
                        </div>
                        <div className="row2">
                            <div className="label">Symptoms:</div>
                            <div className="value">
                                {
                                    displayListDialysisHistory.find(
                                        (s) =>
                                            s.id ==
                                            visibilityDialysisNoteSymptoms
                                    )?.displaySymptoms
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                title={
                    <div className="modal-title">
                        Intra Dialysis Note and Symptoms
                    </div>
                }
                visible={visibilityIntraDialysisNoteSymptoms}
                onCancel={() =>
                    handleModalVisibility("intraDialysisNoteSymptoms", 0)
                }
                footer={[
                    <Button
                        key="back"
                        onClick={() =>
                            handleModalVisibility("intraDialysisNoteSymptoms")
                        }
                    >
                        Cancel
                    </Button>
                ]}
                className="modal-patientrecord-medlabmilestone"
                width={800}
            >
                <div className="modal-content">
                    <div className="medicalmilestone">
                        <div className="row2">
                            <div className="label">Intra Dialysis Note:</div>
                            <div className="value">
                                {
                                    displayListDialysisInterval.find(
                                        (s) =>
                                            s.id ==
                                            visibilityIntraDialysisNoteSymptoms
                                    )?.combinedNotes
                                }
                            </div>
                        </div>
                        <div className="row2">
                            <div className="label">Symptoms:</div>
                            <div className="value">
                                {
                                    displayListDialysisInterval.find(
                                        (s) =>
                                            s.id ==
                                            visibilityIntraDialysisNoteSymptoms
                                    )?.displaySymptoms
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                title={
                    <div className="modal-title">
                        Edit Dialysis{" "}
                        {dialysisHistory.type === "intra"
                            ? "Interval"
                            : "Session"}
                    </div>
                }
                visible={visibilityDialysisHistory}
                onCancel={() => handleModalVisibility("dialysisHistory")}
                footer={[
                    <Button
                        key="back"
                        onClick={() => handleModalVisibility("dialysisHistory")}
                    >
                        Cancel
                    </Button>,
                    <Popconfirm
                        title="Are you sure?"
                        onConfirm={() =>
                            onFinishUpdate(
                                formUpdate.getFieldsValue(),
                                dialysisHistory
                            )
                        }
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button
                            key="submit"
                            type="primary"
                            loading={loadingModal}
                        >
                            Update
                        </Button>
                    </Popconfirm>
                ]}
                className="modal-manage"
                width={800}
            >
                <div className="modal-content">
                    <Spin spinning={loadingModal}>
                        <Form
                            form={formUpdate}
                            layout="horizontal"
                            onFinish={onFinishUpdate}
                            validateMessages={validateMessages}
                            onFieldsChange={onFieldsChangeUpdate}
                            requiredMark={false}
                        >
                            {dialysisHistory.type === "intra"
                                ? renderInputItems([
                                      {
                                          label: "Date / Time",
                                          name: "datetime",
                                          type: "datepicker",
                                          showTime: true,
                                          required: true
                                      }
                                  ])
                                : renderInputItems([
                                      {
                                          label: "Date / Time Period",
                                          name: "datetimePeriod",
                                          type: "rangepicker",
                                          showTime: true,
                                          required: true
                                      }
                                  ])}
                            {appState?.loginType === "MedicalDirector" ? (
                                <>
                                    <div className="modal-edit-dialysis">
                                        {dialysisHistory.type === "intra"
                                            ? renderInputItems([
                                                  {
                                                      label: "SBP",
                                                      name: "SBP",
                                                      type: "input",
                                                      regexMessage:
                                                          "Digits only",
                                                      regex: new RegExp(
                                                          "^[0-9]*$"
                                                      )
                                                  },
                                                  {
                                                      label: "QB",
                                                      name: "QB",
                                                      type: "input",
                                                      regexMessage:
                                                          "Digits only",
                                                      regex: new RegExp(
                                                          "^[0-9]*$"
                                                      )
                                                  },
                                                  {
                                                      label: "DBP",
                                                      name: "DBP",
                                                      type: "input",
                                                      regexMessage:
                                                          "Digits only",
                                                      regex: new RegExp(
                                                          "^[0-9]*$"
                                                      )
                                                  },
                                                  {
                                                      label: "AP",
                                                      name: "AP",
                                                      type: "input",
                                                      regexMessage:
                                                          "Digits only",
                                                      regex: new RegExp(
                                                          "^[-0-9]*$"
                                                      )
                                                  },
                                                  {
                                                      label: "HR",
                                                      name: "HR",
                                                      type: "input",
                                                      regexMessage:
                                                          "Digits only",
                                                      regex: new RegExp(
                                                          "^[0-9]*$"
                                                      )
                                                  },
                                                  {
                                                      label: "VP",
                                                      name: "VP",
                                                      type: "input",
                                                      regexMessage:
                                                          "Digits only",
                                                      regex: new RegExp(
                                                          "^[0-9]*$"
                                                      )
                                                  },
                                                  {
                                                      label: "tmp",
                                                      name: "tmp",
                                                      type: "input",
                                                      regexMessage:
                                                          "Digits only",
                                                      regex: new RegExp(
                                                          "^[-0-9]*$"
                                                      )
                                                  },
                                                  {
                                                      label: "FLOW",
                                                      name: "DFLOW",
                                                      type: "input",
                                                      regexMessage:
                                                          "Digits only",
                                                      regex: new RegExp(
                                                          "^[0-9]*$"
                                                      )
                                                  },
                                                  {
                                                      label: "UFR (ℓ)",
                                                      name: "UFR",
                                                      type: "input",
                                                      regexMessage:
                                                          "Decimals only",
                                                      regex: new RegExp(
                                                          "^[+-]?([0-9]*[.])?[0-9]+$"
                                                      )
                                                  },
                                                  {
                                                      label: "COND",
                                                      name: "CDT",
                                                      type: "input",
                                                      regexMessage:
                                                          "Decimals only",
                                                      regex: new RegExp(
                                                          "^[+-]?([0-9]*[.])?[0-9]+$"
                                                      )
                                                  },
                                                  {
                                                      label: "UFV (ℓ)",
                                                      name: "cUF",
                                                      type: "input",
                                                      regexMessage:
                                                          "Decimals only",
                                                      regex: new RegExp(
                                                          "^[+-]?([0-9]*[.])?[0-9]+$"
                                                      )
                                                  },
                                                  {
                                                      label: "Temp",
                                                      name: "temp",
                                                      type: "input",
                                                      regexMessage:
                                                          "Decimals only",
                                                      regex: new RegExp(
                                                          "^[+-]?([0-9]*[.])?[0-9]+$"
                                                      )
                                                  },
                                                  {
                                                      label: "HepRate",
                                                      name: "hepRate",
                                                      type: "input",
                                                      regexMessage:
                                                          "Decimals only",
                                                      regex: new RegExp(
                                                          "^[+-]?([0-9]*[.])?[0-9]+$"
                                                      )
                                                  }
                                                  // {
                                                  //     label: "Clinic",
                                                  //     name: "clinicUid",
                                                  //     type: "input",
                                                  // },
                                                  // {
                                                  //     label: "SE",
                                                  //     name: "sessionMonth",
                                                  //     type: "input",
                                                  // },
                                                  // {
                                                  //     label: "Duration",
                                                  //     name: "duration",
                                                  //     type: "input",
                                                  //     disabled: true,
                                                  // },
                                                  // {
                                                  //     label: "Last Weight",
                                                  //     name: "lastWeight",
                                                  //     type: "input",
                                                  // },
                                                  // {
                                                  //     label: "Pre Edema",
                                                  //     name: "preEdema",
                                                  //     type: "input",
                                                  // },
                                                  // {
                                                  //     label: "Post Edema",
                                                  //     name: "postEdema",
                                                  //     type: "input",
                                                  // },
                                                  // {
                                                  //     label: "Pre Weight",
                                                  //     name: "preWeight",
                                                  //     type: "input",
                                                  // },
                                                  // {
                                                  //     label: "DW",
                                                  //     name: "dryWeight",
                                                  //     type: "input",
                                                  // },
                                                  // {
                                                  //     label: "UFT",
                                                  //     name: "ufTargetStart",
                                                  //     type: "input",
                                                  // },
                                                  // {
                                                  //     label: "POST WT",
                                                  //     name: "postWeight",
                                                  //     type: "input",
                                                  // },
                                                  // {
                                                  //     label: "OFF Target",
                                                  //     name: "offTarget",
                                                  //     type: "input",
                                                  // },
                                                  // {
                                                  //     label: "Post SBP / DBP",
                                                  //     name: "postSBPDBP",
                                                  //     type: "input",
                                                  // },
                                                  // {
                                                  //     label: "Temp Admit",
                                                  //     name: "preTemp",
                                                  //     type: "input",
                                                  // },
                                                  // {
                                                  //     label: "Temp Discharge",
                                                  //     name: "postTemp",
                                                  //     type: "input",
                                                  // },
                                                  // {
                                                  //     label: "MSA",
                                                  //     name: "MSA",
                                                  //     type: "input",
                                                  // },
                                                  // {
                                                  //     label: "Dialyser",
                                                  //     name: "dialyser",
                                                  //     type: "input",
                                                  // },
                                                  // {
                                                  //     label: "KTV Online",
                                                  //     name: "KTVonline",
                                                  //     type: "input",
                                                  // },
                                              ])
                                            : renderInputItems([
                                                  {
                                                      label: "Clinic",
                                                      name: "clinicUid",
                                                      type: "input",
                                                      disabled: true,
                                                      required: false
                                                  },
                                                  {
                                                      label: "Last Weight",
                                                      name: "lastWeight",
                                                      type: "input",
                                                      required: false,
                                                      regexMessage:
                                                          "Decimals only",
                                                      regex: new RegExp(
                                                          "^[+-]?([0-9]*[.])?[0-9]+$"
                                                      )
                                                  },
                                                  {
                                                      label: "SE",
                                                      name: "sessionMonth",
                                                      type: "input",
                                                      required: false,
                                                      regexMessage:
                                                          "Digits only",
                                                      regex: new RegExp(
                                                          "^[0-9]*$"
                                                      )
                                                  },
                                                  {
                                                      label: "Dry Weight",
                                                      name: "dryWeight",
                                                      type: "input",
                                                      required: false,
                                                      regexMessage:
                                                          "Decimals only",
                                                      regex: new RegExp(
                                                          "^[+-]?([0-9]*[.])?[0-9]+$"
                                                      )
                                                  },
                                                  {
                                                      label: "Duration",
                                                      name: "duration",
                                                      type: "input",
                                                      disabled: true
                                                  },
                                                  {
                                                      label: "UF Target (ℓ)",
                                                      name: "ufTargetStart",
                                                      type: "input",
                                                      required: false,
                                                      regexMessage:
                                                          "Decimals only",
                                                      regex: new RegExp(
                                                          "^[+-]?([0-9]*[.])?[0-9]+$"
                                                      )
                                                  },
                                                  // {
                                                  //     label: "QB",
                                                  //     name: "QB",
                                                  //     type: "input",
                                                  // },
                                                  // {
                                                  //     label: "AP",
                                                  //     name: "AP",
                                                  //     type: "input",
                                                  // },
                                                  // {
                                                  //     label: "VP",
                                                  //     name: "VP",
                                                  //     type: "input",
                                                  // },
                                                  // {
                                                  //     label: "FLOW",
                                                  //     name: "DFLOW",
                                                  //     type: "input",
                                                  // },
                                                  // {
                                                  //     label: "COND",
                                                  //     name: "CDT",
                                                  //     type: "input",
                                                  // },
                                                  {
                                                      label: "Pre Weight",
                                                      name: "preWeight",
                                                      type: "input",
                                                      regexMessage:
                                                          "Decimals only",
                                                      regex: new RegExp(
                                                          "^[+-]?([0-9]*[.])?[0-9]+$"
                                                      )
                                                  },
                                                  {
                                                      label: "Post Weight",
                                                      name: "postWeight",
                                                      type: "input",
                                                      regexMessage:
                                                          "Decimals only",
                                                      regex: new RegExp(
                                                          "^[+-]?([0-9]*[.])?[0-9]+$"
                                                      )
                                                  },
                                                  {
                                                      label: "Pre Temp",
                                                      name: "preTemp",
                                                      type: "input",
                                                      regexMessage:
                                                          "Decimals only",
                                                      regex: new RegExp(
                                                          "^[+-]?([0-9]*[.])?[0-9]+$"
                                                      )
                                                  },
                                                  {
                                                      label: "Post Temp",
                                                      name: "postTemp",
                                                      type: "input",
                                                      regexMessage:
                                                          "Decimals only",
                                                      regex: new RegExp(
                                                          "^[+-]?([0-9]*[.])?[0-9]+$"
                                                      )
                                                  },
                                                  {
                                                      label: "Pre Edema",
                                                      name: "preEdema",
                                                      type: "input",
                                                      regexMessage:
                                                          "Digits only",
                                                      regex: new RegExp(
                                                          "^[0-9]*$"
                                                      )
                                                  },
                                                  {
                                                      label: "Post Edema",
                                                      name: "postEdema",
                                                      type: "input",
                                                      regexMessage:
                                                          "Digits only",
                                                      regex: new RegExp(
                                                          "^[0-9]*$"
                                                      )
                                                  },
                                                  {
                                                      label: "Pre SBP",
                                                      name: "preSBP",
                                                      type: "input",
                                                      regexMessage:
                                                          "Digits only",
                                                      regex: new RegExp(
                                                          "^[0-9]*$"
                                                      )
                                                  },
                                                  {
                                                      label: "Post SBP",
                                                      name: "postSBP",
                                                      type: "input",
                                                      regexMessage:
                                                          "Digits only",
                                                      regex: new RegExp(
                                                          "^[0-9]*$"
                                                      )
                                                  },
                                                  {
                                                      label: "Pre DBP",
                                                      name: "preDBP",
                                                      type: "input",
                                                      regexMessage:
                                                          "Digits only",
                                                      regex: new RegExp(
                                                          "^[0-9]*$"
                                                      )
                                                  },
                                                  {
                                                      label: "Post DBP",
                                                      name: "postDBP",
                                                      type: "input",
                                                      regexMessage:
                                                          "Digits only",
                                                      regex: new RegExp(
                                                          "^[0-9]*$"
                                                      )
                                                  },
                                                  {
                                                      label: "Pre HRT",
                                                      name: "preHRT",
                                                      type: "input",
                                                      regexMessage:
                                                          "Digits only",
                                                      regex: new RegExp(
                                                          "^[0-9]*$"
                                                      )
                                                  },
                                                  {
                                                      label: "Post HRT",
                                                      name: "postHRT",
                                                      type: "input",
                                                      regexMessage:
                                                          "Digits only",
                                                      regex: new RegExp(
                                                          "^[0-9]*$"
                                                      )
                                                  },
                                                  {
                                                      label: "OFF Target",
                                                      name: "offTarget",
                                                      type: "input",
                                                      disabled: true
                                                  },
                                                  // {
                                                  //     label: "SBP",
                                                  //     name: "SBP",
                                                  //     type: "input",
                                                  // },
                                                  // {
                                                  //     label: "DBP",
                                                  //     name: "DBP",
                                                  //     type: "input",
                                                  // },
                                                  // {
                                                  //     label: "HR",
                                                  //     name: "HR",
                                                  //     type: "input",
                                                  // },
                                                  // {
                                                  //     label: "Temp",
                                                  //     name: "temp",
                                                  //     type: "input",
                                                  // },
                                                  // {
                                                  //     label: "UFR (ℓ)",
                                                  //     name: "UFR",
                                                  //     type: "input",
                                                  // },
                                                  {
                                                      label: "MSA",
                                                      name: "MSA",
                                                      type: "input",
                                                      regexMessage:
                                                          "Decimals only",
                                                      regex: new RegExp(
                                                          "^[+-]?([0-9]*[.])?[0-9]+$"
                                                      )
                                                  },
                                                  {
                                                      label: "Dialyser",
                                                      name: "dialyser",
                                                      type: "input"
                                                  },
                                                  {
                                                      label: "Membrane Use",
                                                      name: "membraneUse",
                                                      type: "input"
                                                  },
                                                  {
                                                      label: "KTV Online",
                                                      name: "KTVonline",
                                                      type: "input",
                                                      regexMessage:
                                                          "Decimals only",
                                                      regex: new RegExp(
                                                          "^[+-]?([0-9]*[.])?[0-9]+$"
                                                      )
                                                  }
                                              ])}
                                    </div>
                                    {dialysisHistory.type === "intra"
                                        ? renderInputItems([
                                              {
                                                  label: "Dialysis Note",
                                                  name: "note",
                                                  type: "text"
                                              },
                                              {
                                                  label: "Symptoms",
                                                  name: "symptoms",
                                                  type: "treeSelect",
                                                  optionList:
                                                      appState.symptomList
                                              }
                                          ])
                                        : renderInputItems([
                                              {
                                                  label: "Dialysis Note",
                                                  name: "notes",
                                                  type: "text"
                                              }
                                              // {
                                              //     label: "Symptoms",
                                              //     name: "displaySymptoms",
                                              //     type: "input",
                                              // },
                                          ])}
                                </>
                            ) : (
                                <></>
                            )}
                        </Form>
                    </Spin>
                </div>
            </Modal>
        </div>
    );
};

export default PatientRecordDialysisHistory;
