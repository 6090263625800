import React, { useEffect, useState } from "react"
import { Button, DatePicker, Divider, Empty, Form, Input, notification, Popover, Radio, Select, Space, Spin, Table } from "antd"
import { SearchOutlined, UserOutlined } from "@ant-design/icons"

import PatientDetailsSummary from "./Component-PatientDetailsSummary"
import PatientRecordLabResults from "./Component-PatientRecord-LabResults"
import PatientRecordLastNonNullLab from "./Component-PatientRecord-LastNonNullLab"
import path from "../utils/pathSettings"
import { useHistory } from "react-router-dom"

const ImportLabResultManualInputPatient = (props) => {
    const patientID = props?.match?.params?.id;
    const [form] = Form.useForm()
    const [filter, setFilter] = useState({
        search: "",
    })
    const history = useHistory();

    useEffect(() => {
        return () => { }
    }, [])

    const onFinish = () => {
        // console.log("submit form");
        // console.log(form.getFieldsValue());
        let value = form.getFieldsValue();
        history.push({ pathname: path('importLabResultManualInput'), state: { search: value.search } });
    }

    const handleSearchChange = (e) => {
        // console.log("handleSearchChange");
        // console.log(e.target.value);
        setFilter({
            ...filter,
            search: e.target.value
        })
    }

    return (
        <div className="content-content">
            <div className="container-row">
                <div className="view-desktop view-desktop-md">
                    <div className="importlabresult-header">
                        <div className="row1 title">
                            Import Lab Result - Manual Input
                        </div>
                        <Form
                            form={form}
                            layout="horizontal"
                            onFinish={onFinish}
                            requiredMark={false}
                        >
                            <div className="container-row">
                                <div className="row">
                                    <Form.Item
                                        className="search"
                                        name="search"
                                    >
                                        <Input autoComplete="off" prefix={<SearchOutlined />} placeholder="Search patient / NRIC" onChange={handleSearchChange} value={filter.search} allowClear />
                                    </Form.Item>
                                    <Button type="primary" htmlType="submit">
                                        Search
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                    <div className="importlabresult-content">
                        <div className="row">
                            <PatientDetailsSummary patientID={patientID} />
                        </div>
                        <br />
                        <PatientRecordLabResults patientID={patientID} pageSize={5} />
                        <br />
                        <PatientRecordLastNonNullLab patientID={patientID} />
                        <br />
                    </div>
                </div>
            </div>

        </div>
    )
}

export default ImportLabResultManualInputPatient