export const dialyserList = [
    { id: 1, value: 'FX60' },
    { id: 2, value: 'FX80' },
    { id: 3, value: 'POLYFLUX 1.4' },
    { id: 4, value: 'POLYFLUX 2.1' },
    { id: 5, value: 'Polyflux 170H' },
    { id: 6, value: 'Polyflux 210' },
    { id: 7, value: 'Revaclear 300' },
    { id: 8, value: 'Revaclear 400' },
    { id: 9, value: 'Theranova 400' },
    { id: 10, value: 'FB150U' },
    { id: 11, value: 'FB190U' },
    { id: 12, value: 'FB210U' },
    { id: 13, value: 'Elisio-17H' },
    { id: 14, value: 'OXIRIS' },
    { id: 15, value: 'TPE' },
    { id: 16, value: 'M100' },
];

export const routeList = [
    { id: 'iv', value: 'iv' },
    { id: 'sc', value: 'sc (Subcutaneous)' },
    { id: 'po', value: 'po (Oral)' },
    { id: 'sl', value: 'sl (Sublingual)' },
    { id: 'supp', value: 'supp (Suppository)' },
    { id: 'ap', value: 'ap (Apply)' },
    { id: 'gutt', value: 'gutt (Eyedrop)' },
    { id: 'im', value: 'im (Intramuscular)' },
]

export const freqList = [
    { id: '1x/wk', value: '1x/wk' },
    { id: '2x/wk', value: '2x/wk' },
    { id: '3x/wk', value: '3x/wk' },
    { id: '4x/wk', value: '4x/wk' },
    { id: '1x/5 days', value: '1x/5 days' },
    { id: '1x/10 days', value: '1x/10 days' },
    { id: '1x/14 days', value: '1x/14 days' },
    { id: '1x/21 days', value: '1x/21 days' },
    { id: '1x/30 days', value: '1x/30 days' },
    { id: '1x/60 days', value: '1x/60 days' },
    { id: 'Once', value: 'Once' },
    { id: 'OM', value: 'OM' },
    { id: 'ON', value: 'ON' },
    { id: 'BD', value: 'BD' },
    { id: 'TDS', value: 'TDS' },
    { id: 'QDS', value: 'QDS' },
    { id: 'PRN', value: 'PRN (as needed)' },
    { id: '1x/6 weeks', value: '1x/6 weeks' },
]

export const referringSource = [
    { id: "AH", name: "AH" },
    { id: "CGH", name: "CGH" },
    { id: "KTPH", name: "KTPH" },
    { id: "NTFGH", name: "NTFGH" },
    { id: "NUH", name: "NUH" },
    { id: "SGH", name: "SGH" },
    { id: "SKGH", name: "SKGH" },
    { id: "TTSH", name: "TTSH" },
    { id: "PRIVATE", name: "PRIVATE" },
]