import React, { useContext, useEffect, useState } from "react"

// import PatientRecordHeader from "./Component-PatientRecord-Header"
// import PatientRecordPreweights from "./Component-PatientRecord-Preweights"
// import PatientRecordDialysisOrder from "./Component-PatientRecord-DialysisOrder"
import PatientRecordAdministeredMedication from "./Component-PatientRecord-AdministeredMedication"
import PatientRecordCalculatedLabResults from "./Component-PatientRecord-CalculatedLabResults"
import PatientRecordClinicNHospitalE from "./Component-PatientRecord-ClinicNHospitalE"
import PatientRecordDialysisHistory from "./Component-PatientRecord-DialysisHistory"
import PatientRecordLabResults from "./Component-PatientRecord-LabResults"
import PatientRecordMedLabMilestone from "./Component-PatientRecord-MedLabMilestone"
import PatientRecordPreweightsDialysisOrder from "./Component-PatientRecord-PreweightsDialysisOrder"
import PatientRecordLastNonNullLab from "./Component-PatientRecord-LastNonNullLab"

const PatientDialysisCheckInEdit = (props) => {
    const patientID = props?.match?.params?.id;
    const dialysisID = props?.match?.params?.id2;
    const [lastDialysis, setLastDialysis] = useState({});

    useEffect(() => {
        return () => { }
    }, [])

    // useEffect(() => {
    //     form.setFieldsValue(values)
    // }, [values])

    // const onFinish = () => {
    //     console.log("Handle check in clicked");
    // }

    return (
        <div className="content-content">
            <div className="container-row">
                <div className="view-desktop view-desktop-md">
                    <PatientRecordPreweightsDialysisOrder page={"checkinEdit"} lastDialysis={lastDialysis} setLastDialysis={setLastDialysis} patientID={patientID} dialysisID={dialysisID} />
                    <PatientRecordDialysisHistory patientID={patientID} />
                    <div className="importlabresult-content">
                        <PatientRecordLastNonNullLab patientID={props?.match?.params?.id}  />
                        <br />
                    </div>
                    <PatientRecordMedLabMilestone patientID={patientID} />
                    <PatientRecordAdministeredMedication patientID={patientID} />
                    <PatientRecordClinicNHospitalE patientID={patientID} />
                    <PatientRecordCalculatedLabResults patientID={patientID} />
                    <PatientRecordLabResults patientID={patientID} />
                </div>
            </div>
        </div>
    )
}

export default PatientDialysisCheckInEdit