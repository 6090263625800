import React from "react"
import { Route, Switch } from "react-router-dom"
import { PrivateRoute } from "./PrivateRoute"

import path from "./pathSettings"
import Availability from "../components/Page-Availability"
import Dashboard from "../components/Page-Dashboard"
import Error404 from "../components/Page-Error-404"
import ForgotPassword from "../components/Page-ForgotPassword"
import ImportLabResultFileUpload from "../components/Page-ImportLabResult-FileUpload"
import ImportLabResultFileUploadReview from "../components/Page-ImportLabResult-FileUpload-Review"
import ImportLabResultManualInput from "../components/Page-ImportLabResult-ManualInput"
import ImportLabResultManualInputPatient from "../components/Page-ImportLabResult-ManualInput-Patient"
import IncidentReport from "../components/Page-IncidentReport"
import IncidentReportCentre from "../components/Page-IncidentReport-Centre"
import IncidentReportCentreCreate from "../components/Page-IncidentReport-Centre-Create"
import LatestClinicalNoteAuthor from "../components/Page-LatestClinicalNote-Author"
import LatestClinicalNoteCentre from "../components/Page-LatestClinicalNote-Centre"
import Login from "../components/Page-Login"
import Manage from "../components/Page-Manage"
import ManageCentre from "../components/Page-Manage-Centre"
import ManageCertification from "../components/Page-Manage-Certification"
import ManageMachine from "../components/Page-Manage-Machine"
import ManageMachineStatus from "../components/Page-Manage-MachineStatus"
import ManageDrug from "../components/Page-Manage-Drug"
import ManageSeat from "../components/Page-Manage-Seat"
import ManageStaff from "../components/Page-Manage-Staff"
import ManageSymptom from "../components/Page-Manage-Symptom"
import ManageDialysisSession from "../components/Page-Manage-DialysisSession"
import ManageDialysisSessionPatientRecord from "../components/Page-Manage-DialysisSession-Patient-Record"
import ManageFunderReport from "../components/Page-Manage-FunderReport"
import ManageUnlinkedInvoice from "../components/Page-Manage-UnlinkedInvoice"
import PatientAppointment from "../components/Page-Patient-Appointment"
import PatientCreate from "../components/Page-Patient-Create"
import PatientInformation from "../components/Page-Patient-Information"
import PatientInformationEdit from "../components/Page-Patient-Information-Edit"
import PatientDialysisCheckIn from "../components/Page-Patient-Dialysis-CheckIn"
import PatientDialysisCheckInEdit from "../components/Page-Patient-Dialysis-CheckIn-Edit"
import PatientDialysisCheckOut from "../components/Page-Patient-Dialysis-CheckOut"
import PatientDialysisCheckOutEdit from "../components/Page-Patient-Dialysis-CheckOut-Edit"
import PatientDialysisIntraDialysis from "../components/Page-Patient-Dialysis-IntraDialysis"
import PatientDocument from "../components/Page-Patient-Document"
import PatientIncidentReport from "../components/Page-Patient-IncidentReport"
import PatientRecord from "../components/Page-Patient-Record"
import PatientRecordExport from "../components/Page-Patient-Record-Export"
import SettingsCentreSelection from "../components/Page-Settings-CentreSelection"
import SettingsChangePassword from "../components/Page-Settings-ChangePassword"
import SettingsManageAccount from "../components/Page-Settings-ManageAccount"
import SettingsManageAccountEdit from "../components/Page-Settings-ManageAccount-Edit"
import redirectQuickbooks from "../components/Page-Redirect-Quickbooks"

const RoutePath = () => {
    return (
        <Switch>
            <PrivateRoute path={path("availability")} component={Availability} />
            <Route path={path("forgotPassword")} component={ForgotPassword} />
            <PrivateRoute path={path("latestClinicalNoteByAuthor")} component={LatestClinicalNoteAuthor} />
            <PrivateRoute path={path("latestClinicalNoteByCentre")} component={LatestClinicalNoteCentre} />
            <PrivateRoute path={path("importLabResultFileUploadReview")} component={ImportLabResultFileUploadReview} />
            <PrivateRoute path={path("importLabResultFileUpload")} component={ImportLabResultFileUpload} />
            <PrivateRoute path={path("importLabResultManualInputPatient", [":id"])} component={ImportLabResultManualInputPatient} />
            <PrivateRoute path={path("importLabResultManualInput")} component={ImportLabResultManualInput} />
            <PrivateRoute path={path("incidentReportCentreCreate", [":id"])} component={IncidentReportCentreCreate} />
            <PrivateRoute path={path("incidentReportCentre")} component={IncidentReportCentre} />
            <PrivateRoute path={path("incidentReport")} component={IncidentReport} />
            <Route path={path("login")} component={Login} />
            <PrivateRoute path={path("manageCentre")} component={ManageCentre} />
            <PrivateRoute path={path("manageCertification")} component={ManageCertification} />
            <PrivateRoute path={path("manageMachine")} component={ManageMachine} />
            <PrivateRoute path={path("manageMachineStatus")} component={ManageMachineStatus} />
            <PrivateRoute path={path("manageDrug")} component={ManageDrug} />
            <PrivateRoute path={path("manageFunderReport")} component={ManageFunderReport} />
            <PrivateRoute path={path("manageSeat")} component={ManageSeat} />
            <PrivateRoute path={path("manageStaff")} component={ManageStaff} />
            <PrivateRoute path={path("manageSymptom")} component={ManageSymptom} />
            <PrivateRoute path={path("manageDialysisSessionPatientRecord", [":id"])} component={ManageDialysisSessionPatientRecord} />
            <PrivateRoute path={path("manageDialysisSession")} component={ManageDialysisSession} />
            <PrivateRoute path={path("manageUnlinkedInvoice")} component={ManageUnlinkedInvoice} />
            <PrivateRoute path={path("manage")} component={Manage} />
            <PrivateRoute path={path("patientCreate")} component={PatientCreate} />
            <PrivateRoute path={path("patientAppointment", [":id"])} component={PatientAppointment} />
            <PrivateRoute path={path("patientDialysisCheckInEdit", [":id", ":id2"])} component={PatientDialysisCheckInEdit} />
            <PrivateRoute path={path("patientDialysisCheckIn", [":id", ":id2"])} component={PatientDialysisCheckIn} />
            <PrivateRoute path={path("patientDialysisCheckOutEdit", [":id", ":id2"])} component={PatientDialysisCheckOutEdit} />
            <PrivateRoute path={path("patientDialysisCheckOut", [":id", ":id2"])} component={PatientDialysisCheckOut} />
            <PrivateRoute path={path("patientDialysis", [":id", ":id2"])} component={PatientDialysisIntraDialysis} />
            <PrivateRoute path={path("patientDocument", [":id"])} component={PatientDocument} />
            <PrivateRoute path={path("patientIncidentReport", [":id"])} component={PatientIncidentReport} />
            <PrivateRoute path={path("patientRecordExport", [":id"])} component={PatientRecordExport} />
            <PrivateRoute path={path("patientRecord", [":id"])} component={PatientRecord} />
            <PrivateRoute path={path("patientInformationEdit", [":id"])} component={PatientInformationEdit} />
            <PrivateRoute path={path("patientInformation", [":id"])} component={PatientInformation} />
            <Route path={path("settingsCentreSelection")} component={SettingsCentreSelection} />
            <PrivateRoute path={path("settingsChangePassword")} component={SettingsChangePassword} />
            <PrivateRoute path={path("settingsManageAccountEdit")} component={SettingsManageAccountEdit} />
            <PrivateRoute path={path("settingsManageAccount")} component={SettingsManageAccount} />
            <PrivateRoute path={path("redirectQuickbooks")} component={redirectQuickbooks}/>
            <PrivateRoute exact path={path("dashboard")} component={Dashboard} />
            <Route component={Error404} />
        </Switch>
    )
}

export default RoutePath