import React, { useEffect, useState, useContext } from "react";
import { Calendar, Select, Spin, Tag, TreeSelect } from "antd";
import moment from "moment";
import { AppContext } from "../contexts/AppContext";

import iconMorning from "../media/Icon-Morning.png";
import iconAfternoon from "../media/Icon-Afternoon.png";
import iconEvening from "../media/Icon-Evening.png";
import { API } from "aws-amplify";
import { listAppointmentAvailability } from "../backend/graphql/queries";

const Availability = () => {
    const { appState } = useContext(AppContext);
    const { Option } = Select;
    const { TreeNode, SHOW_PARENT } = TreeSelect;
    const [monthYear, setMonthYear] = useState({
        dayOfWeek: "",
        dayOfMonth: "",
        month: "",
        year: ""
    });
    const disabledDate = (current) => {
        // console.log(moment().endOf('day'));
        // return current < moment().startOf('day');
    };
    const [filter, setFilter] = useState({
        // to be used for API query, onChange would update values here
        session: 0
        // clinics: [0]
    });
    const [loading, setLoading] = useState(false);
    const firstDayOfWeek = 7;
    const days = [1, 2, 3, 4, 5, 6, 7];
    days.splice(days.indexOf(firstDayOfWeek), 1);
    const lastDayOfWeek = days[days.length - 1];
    moment.updateLocale("en", {
        week: {
            dow: firstDayOfWeek
        }
    });
    const [appointmentAvailability, setAppointmentAvailability] = useState([]);
    const [appointmentFiltered, setAppointmentFiltered] = useState([]);
    const [availabilityDetails, setAvailabilityDetails] = useState([
        // {
        //     id: 1,
        //     clinic: "AMC Centre (AMC)",
        //     morning: 2,
        //     noon: 5,
        //     evening: 3,
        //     total: 9,
        // },
        // {
        //     id: 2,
        //     clinic: "Thomson Clinic (TMC)",
        //     morning: 2,
        //     noon: 5,
        //     evening: 3,
        //     total: 9,
        // },
        // {
        //     id: 3,
        //     clinic: "Yishun Clinic (YSC)",
        //     morning: 2,
        //     noon: 5,
        //     evening: 3,
        //     total: 9,
        // },
        // {
        //     id: 4,
        //     clinic: "Hougang Clinic (HGC)",
        //     morning: 2,
        //     noon: 5,
        //     evening: 3,
        //     total: 9,
        // },
        // {
        //     id: 5,
        //     clinic: "ABC Clinic (ABC)",
        //     morning: 2,
        //     noon: 5,
        //     evening: 3,
        //     total: 9,
        // },
        // {
        //     id: 6,
        //     clinic: "EFG Clinic (EFG)",
        //     morning: 2,
        //     noon: 5,
        //     evening: 3,
        //     total: 9,
        // },
    ]);
    const [clinicList, setClinicList] = useState([]);
    const [calendarSelectedDate, setCalenderSelectedDate] = useState(moment());

    useEffect(() => {
        setMonthYear({
            dayOfWeek: moment().format("dddd"),
            dayOfMonth: moment().format("D"),
            month: moment().format("MMMM"),
            year: moment().format("YYYY")
        });

        return () => {};
    }, []);

    useEffect(() => {
        // if (Object.keys(appState.clinicList).length !== 0) {
        if (appState.clinicList !== -1 && appState.loginType !== null) {
            // console.log("appState", appState);
            setFilter({
                ...filter,
                // clinics: appState.loginType === "Nurse" ? [parseInt(appState.selectedClinicID)] : [0],
                clinics: [0]
            });

            _listAppointmentAvailability(
                firstDayOfWeek,
                lastDayOfWeek,
                moment().local().format("yyyy-MM-01"),
                moment(moment().local().format("yyyy-MM-01"))
                    .add(1, "months")
                    .add(-1, "days")
                    .format("yyyy-MM-DD")
            );
        }
        return () => {};
    }, [appState.clinicList, appState.loginType]);

    useEffect(() => {
        // console.log('filter', filter);
        if (appointmentAvailability.length == 0) return;

        let filtered = [];
        if (filter.clinics) {
            if (filter.clinics.length == 1 && filter.clinics[0] == 0) {
                setClinicList(appState.clinicList);
                filtered = appointmentAvailability;
            } else {
                setClinicList(
                    appState.clinicList.filter((s) =>
                        filter.clinics.includes(s.id)
                    )
                );
                filtered = appointmentAvailability.filter((s) =>
                    filter.clinics.includes(s.clinicID)
                );
            }
        }

        if (filter.session) {
            let clinicIDs = filtered.map((s) => s.clinicID);
            let _filtered = filtered.filter(
                (s) => s.shift == filter.session || s.shift == null
            );

            if (_filtered.map((s) => s.clinicID).length != clinicIDs.length) {
                for (let _clinicID of clinicIDs) {
                    if (!_filtered.find((s) => s.clinicID == _clinicID)) {
                        let firstClinicIDRecord = appointmentAvailability.find(
                            (s) => s.clinicID == _clinicID
                        );
                        _filtered.push({
                            clinicID: _clinicID,
                            noOfActiveSeat: firstClinicIDRecord.noOfActiveSeat,
                            date: null,
                            noOfAppointment: null,
                            shift: null
                        });
                    }
                }
            }
            // console.log(_filtered);

            setAppointmentFiltered(
                _filtered.length > 0 ? _filtered : appointmentAvailability
            );
        } else {
            setAppointmentFiltered(appointmentAvailability);
        }

        // updateAppointmentFilteredList();
    }, [filter, appointmentAvailability]);

    // useEffect(() => {
    //     // console.log(filter, appointmentFiltered)
    //     if (appointmentFiltered.length == 0) {
    //         updateAppointmentFilteredList();
    //     }
    // }, [appointmentFiltered])

    useEffect(() => {
        handleCalendarDateChange(calendarSelectedDate);
    }, [clinicList]);

    // useEffect(() => {
    //     if (Object.keys(appState.clinicList).length !== 0) {
    //         if ((filter.clinics.length == 1 && filter.clinics[0] == 0 || filter.clinics.length == 0)) {
    //             setClinicList(appState.clinicList);
    //         } else {
    //             setClinicList(filter.clinics);
    //         }
    //     }
    // }, [filter.clinics, appState.clinicList])

    const _listAppointmentAvailability = async (
        calendarStartDay,
        calenderEndDay,
        start,
        end,
        patientID = 0,
        clinicIDs = []
    ) => {
        setLoading(true);
        try {
            const variables = {
                calendarStartDay,
                calenderEndDay,
                start,
                end
            };

            if (patientID > 0) {
                variables.patientID = patientID;
            }

            if (clinicIDs.length > 0) {
                variables.clinicIDs = clinicIDs;
            } else {
                variables.clinicIDs = appState.clinicList?.map((s) => s.id);
            }

            // console.log('Availability _listAppointmentAvailability', variables);

            const result = await API.graphql({
                query: listAppointmentAvailability,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables
            });
            // console.log("API result Availability listAppointmentAvailability", result);
            const data = result.data.result;
            const array = result.data.result.result;

            // process data
            let parseData = JSON.parse(data);
            // console.log(parseData);
            setAppointmentAvailability(parseData.data.appointmentAvailability);

            setLoading(false);
        } catch (error) {
            console.log("error: ", error);
        } finally {
        }
    };

    // const updateAppointmentFilteredList = () => {
    //     if (filter.session == 0 && (filter.clinics.length == 1 && filter.clinics[0] == 0 || filter.clinics.length == 0)) {
    //         setAppointmentFiltered(appointmentAvailability);
    //     } else if (filter.session != 0 && (filter.clinics.length == 1 && filter.clinics[0] == 0 || filter.clinics.length == 0)) {
    //         setAppointmentFiltered(appointmentAvailability.filter(s => s.shift == filter.session || s.shift == null));
    //     } else if (filter.session == 0 && filter.clinics.length > 0 && filter.clinics[0] != 0) {
    //         setAppointmentFiltered(appointmentAvailability.filter(s => filter.clinics.includes(s.clinicID)));
    //     } else {
    //         setAppointmentFiltered(appointmentAvailability.filter(s => (s.shift == filter.session || s.shift == null) && filter.clinics.includes(s.clinicID)));
    //     }
    // }

    const handleCalendarDateChange = (date) => {
        // console.log("date", date, appointment);
        setMonthYear({
            dayOfWeek: date.format("dddd"),
            dayOfMonth: date.format("D"),
            month: date.format("MMMM"),
            year: date.format("YYYY")
        });

        let selectedDate = date.format("yyyy-MM-DDT00:00:00.000") + "Z";

        if (clinicList.length == 0) return;

        let avail = [];
        for (let clinic of clinicList) {
            let clinicAvailability = appointmentAvailability.filter(
                (s) => s.clinicID == clinic.id
            );
            // console.log(selectedDate, clinic.id, clinicAvailability);

            if (clinicAvailability.length == 0) {
                avail.push({
                    id: clinic.id,
                    uid: clinic.uid,
                    clinic: clinic.name,
                    morning: 0,
                    noon: 0,
                    evening: 0,
                    total: 0
                });
            } else if (
                clinicAvailability.length == 1 ||
                (clinicAvailability.length > 1 &&
                    clinicAvailability.filter((s) => s.date == selectedDate)
                        .length == 0)
            ) {
                avail.push({
                    id: clinic.id,
                    uid: clinic.uid,
                    clinic: clinic.name,
                    morning: clinicAvailability[0]?.noOfActiveSeat,
                    noon: clinicAvailability[0]?.noOfActiveSeat,
                    evening: clinicAvailability[0]?.noOfActiveSeat,
                    total: clinicAvailability[0]?.noOfActiveSeat * 3
                });
            } else {
                // let morningAvail = clinicAvailability.filter(s => s.shift == 'M' && s.date == selectedDate)?.map(s => s.noOfActiveSeat - s.noOfAppointment);
                // let noonAvail = clinicAvailability.filter(s => s.shift == 'N' && s.date == selectedDate)?.map(s => s.noOfActiveSeat - s.noOfAppointment);
                // let eveningAvail = clinicAvailability.filter(s => s.shift == 'E' && s.date == selectedDate)?.map(s => s.noOfActiveSeat - s.noOfAppointment);

                let clinicAvailabilityMorning = clinicAvailability.filter(
                    (s) => s.shift == "M" && s.date == selectedDate
                );
                // console.log(clinicAvailabilityMorning, filter.session && filter.session == 'M' && clinicAvailabilityMorning.length > 0);
                let morningAvail =
                    clinicAvailabilityMorning.length > 0
                        ? clinicAvailabilityMorning?.map(
                              (s) => s.noOfActiveSeat - s.noOfAppointment
                          )[0]
                        : clinicAvailability.filter(
                              (s) => s.date == selectedDate
                          )[0].noOfActiveSeat;

                let clinicAvailabilityNoon = clinicAvailability.filter(
                    (s) => s.shift == "N" && s.date == selectedDate
                );
                // console.log(clinicAvailabilityNoon);
                let noonAvail =
                    clinicAvailabilityNoon.length > 0
                        ? clinicAvailabilityNoon?.map(
                              (s) => s.noOfActiveSeat - s.noOfAppointment
                          )[0]
                        : clinicAvailability.filter(
                              (s) => s.date == selectedDate
                          )[0].noOfActiveSeat;
                // let noonAvail = filter.session && filter.session != 'N' ? true : clinicAvailability.filter(s => s.shift == 'N' && s.date == selectedDate)?.map(s => s.noOfActiveSeat - s.noOfAppointment)[0];

                let clinicAvailabilityEvening = clinicAvailability.filter(
                    (s) => s.shift == "E" && s.date == selectedDate
                );
                let eveningAvail =
                    clinicAvailabilityEvening.length > 0
                        ? clinicAvailabilityEvening?.map(
                              (s) => s.noOfActiveSeat - s.noOfAppointment
                          )[0]
                        : clinicAvailability.filter(
                              (s) => s.date == selectedDate
                          )[0].noOfActiveSeat;

                // console.log(selectedDate, clinic.id, morningAvail, noonAvail, eveningAvail);

                let _avail = {
                    id: clinic.id,
                    uid: clinic.uid,
                    clinic: clinic.name,
                    morning: morningAvail,
                    noon: noonAvail,
                    evening: eveningAvail
                    // total: clinicAvailability[0]?.noOfActiveSeat * 3,
                };
                _avail.total = _avail.morning + _avail.noon + _avail.evening;
                avail.push(_avail);
            }
        }

        setAvailabilityDetails(avail);
    };

    const handleCalendarPanelChange = (date, mode) => {
        // console.log("date", date);
        // console.log("mode", mode);
        setMonthYear({
            dayOfWeek: date.format("dddd"),
            dayOfMonth: date.format("D"),
            month: date.format("MMMM"),
            year: date.format("YYYY")
        });

        if (mode === "month") {
            _listAppointmentAvailability(
                firstDayOfWeek,
                lastDayOfWeek,
                moment(date).local().format("yyyy-MM-01"),
                moment(moment(date).local().format("yyyy-MM-01"))
                    .add(1, "months")
                    .add(-1, "days")
                    .format("yyyy-MM-DD")
            );
        }
    };

    const handleTreeSelectChange = (value, label, extra) => {
        // console.log("value", value);
        // console.log("label", label);
        // console.log("extra", extra);

        setFilter({
            ...filter,
            // clinics: !value || value.length == 0 ? [0] : value
            clinics: value
        });
    };

    // const handleSessionChange = value => {
    //     console.log('handleSessionChange', value);
    //     if (value != 0) {
    //         setAppointmentFiltered(appointment.filter(s => s.shift == value || s.shift == null));
    //     } else {
    //         setAppointmentFiltered(appointment);
    //     }
    // }

    const getListData = (value) => {
        let listData = [];
        if (clinicList.length == 0) return [];
        // console.log(value.format('yyyy-MM-DDT00:00:00.000') + 'Z');

        // switch (value.date()) {
        //     case 26:
        //         listData = [
        //             { color: "green", text: "AMC" },
        //             { color: "green", text: "TMC" },
        //             { color: "green", text: "YMC" },
        //             { color: "green", text: "HGC" },
        //             { color: "green", text: "MFC" },
        //         ]
        //         break;
        //     case 27:
        //         listData = [
        //             { color: "green", text: "AMC" },
        //             { color: "green", text: "TMC" },
        //         ]
        //         break;
        //     case 28:
        //         listData = [
        //             { color: "green", text: "AMC" },
        //         ]
        //         break;
        //     case 29:
        //         listData = [
        //             { color: "green", text: "YMC" },
        //             { color: "green", text: "HGC" },
        //             { color: "green", text: "MFC" },
        //         ]
        //         break;
        //     case 30:
        //         listData = [
        //             { color: "green", text: "MFC" },
        //         ]
        //         break;
        //     default:
        // }

        // let appointmentDate = appointmentFiltered.filter(s => s.date == value.format('yyyy-MM-DDT00:00:00.000') + 'Z');
        // let appointmentDefault = appointment.filter(s => s.clinicID == )

        let selectedDate = value.format("yyyy-MM-DDT00:00:00.000") + "Z";

        // Iterate all clinic
        for (let clinic of clinicList) {
            let clinicAvailability = appointmentFiltered.filter(
                (s) => s.clinicID == clinic.id
            );
            // console.log(clinic.id, clinic.uid, selectedDate, clinicAvailability, appointmentFiltered);

            if (
                (clinicAvailability.length == 1 &&
                    clinicAvailability[0].date !== selectedDate) ||
                (clinicAvailability.length > 1 &&
                    clinicAvailability.filter((s) => s.date == selectedDate)
                        .length == 0)
            ) {
                // console.log('nothing')
                listData.push({
                    color: "green",
                    text: clinic.uid
                });
            } else if (clinicAvailability.length > 0) {
                let morningAvail = 0;
                let noonAvail = 0;
                let eveningAvail = 0;

                if (filter.session == 0 || filter.session === "M") {
                    // console.log(filter.session)
                    let clinicAvailabilityMorning = clinicAvailability.filter(
                        (s) => s.shift == "M" && s.date == selectedDate
                    );
                    // console.log(clinicAvailability, clinicAvailabilityMorning, filter.session && filter.session == 'M' && s.date == selectedDate);
                    morningAvail =
                        clinicAvailabilityMorning.length > 0
                            ? clinicAvailabilityMorning?.map(
                                  (s) => s.noOfActiveSeat - s.noOfAppointment
                              )[0]
                            : clinicAvailability.filter(
                                  (s) => s.date == selectedDate
                              )[0]?.noOfActiveSeat;
                }

                if (filter.session == 0 || filter.session === "N") {
                    // console.log(filter.session)
                    let clinicAvailabilityNoon = clinicAvailability.filter(
                        (s) => s.shift == "N" && s.date == selectedDate
                    );
                    // console.log(clinicAvailabilityNoon);
                    noonAvail =
                        clinicAvailabilityNoon.length > 0
                            ? clinicAvailabilityNoon?.map(
                                  (s) => s.noOfActiveSeat - s.noOfAppointment
                              )[0]
                            : clinicAvailability.filter(
                                  (s) => s.date == selectedDate
                              )[0]?.noOfActiveSeat;
                    // let noonAvail = filter.session && filter.session != 'N' ? true : clinicAvailability.filter(s => s.shift == 'N' && s.date == selectedDate)?.map(s => s.noOfActiveSeat - s.noOfAppointment)[0];
                }

                if (filter.session == 0 || filter.session === "E") {
                    // console.log(filter.session)
                    let clinicAvailabilityEvening = clinicAvailability.filter(
                        (s) => s.shift == "E" && s.date == selectedDate
                    );
                    // console.log('clinicAvailabilityEvening', clinicAvailabilityEvening);
                    eveningAvail =
                        clinicAvailabilityEvening.length > 0
                            ? clinicAvailabilityEvening?.map(
                                  (s) => s.noOfActiveSeat - s.noOfAppointment
                              )[0]
                            : clinicAvailability.filter(
                                  (s) => s.date == selectedDate
                              )[0]?.noOfActiveSeat;
                    // let eveningAvail = filter.session && filter.session != 'E' ? true : clinicAvailability.filter(s => s.shift == 'E' && s.date == selectedDate)?.map(s => s.noOfActiveSeat - s.noOfAppointment)[0];
                }

                // console.log(selectedDate, clinic.id, appointmentFiltered, filter.session, clinicAvailability, morningAvail, noonAvail, eveningAvail);

                if (
                    ((filter.session === 0 &&
                        (morningAvail || noonAvail || eveningAvail)) ||
                        (filter.session === "M" && morningAvail) ||
                        (filter.session === "N" && noonAvail) ||
                        (filter.session === "E" && eveningAvail)) > 0
                ) {
                    // all time slot got at least 1
                    listData.push({
                        color: "green",
                        text: clinic.uid
                    });
                    // } else if ((morningAvail || morningAvail == 0) + (noonAvail || noonAvail == 0) + (eveningAvail || eveningAvail == 0) == 3) {
                    // } else if ([morningAvail, noonAvail, eveningAvail].includes(0)) {
                    //     listData.push({
                    //         color: 'green',
                    //         text: clinic.uid
                    //     })
                }
            }
        }

        return listData || [];
    };

    const dateCellRender = (value) => {
        // console.log("dateCellRender", value);
        const listData = getListData(value);
        // console.log("listData", listData);
        return listData?.map((item) => (
            <Tag
                color={item.color}
                style={{ minWidth: "50px", textAlign: "center" }}
            >
                {item.text}
            </Tag>
        ));
    };

    const renderListOptions = (
        array,
        key = "id",
        value = "id",
        name = "name"
    ) => {
        if (typeof array == "object" && array.length !== 0) {
            const listOptions = array?.map((item, index) => {
                return (
                    <Option key={item[key]} value={item[value]}>
                        {item[name]}
                    </Option>
                );
            });

            return listOptions;
        } else {
            return <div />;
        }
    };

    const renderTreeSelectOptions = (array) => {
        if (typeof array == "object" && array.length !== 0) {
            const listOptions = array?.map((item, index) => {
                return (
                    <TreeNode title={item.name} value={item.id} key={item.id} />
                );
            });

            return listOptions;
        } else {
            return <div />;
        }
    };

    const renderAvailabilityDetails = (array) => {
        if (array.length !== 0) {
            const availabilityDetails = array?.map((item, index) => {
                return (
                    <div className="clinic">
                        <div className="clinic-header">
                            {item.clinic} ({item.uid})
                        </div>
                        <div className="clinic-content">
                            <div className="column1">
                                {item.total}
                                <br />
                                Seat(s)
                            </div>
                            <div className="column2">
                                <div className="row">
                                    <div className="label">
                                        <img src={iconMorning} /> Morning
                                    </div>
                                    <div className="value">
                                        {item.morning} Seat(s)
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="label">
                                        <img src={iconAfternoon} /> Noon
                                    </div>
                                    <div className="value">
                                        {item.noon} Seat(s)
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="label">
                                        <img src={iconEvening} /> Evening
                                    </div>
                                    <div className="value">
                                        {item.evening} Seat(s)
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            });

            return availabilityDetails;
        } else {
            return "";
        }
    };

    return (
        <div className="content-content">
            <div className="container-row">
                <div className="availability-header">
                    <div className="column1 title">Availability</div>
                </div>
                <div className="availability-content">
                    <div className="filter-clinic">
                        <TreeSelect
                            showSearch
                            allowClear
                            multiple
                            treeDefaultExpandAll
                            treeCheckable
                            placeholder="Select clinics"
                            showCheckedStrategy={SHOW_PARENT}
                            filterTreeNode={(input, treenode) =>
                                treenode.title
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                            // defaultValue={appState.clinicList != -1 ? 0 : null}
                            // defaultValue={treeSelectOptions}
                            value={filter.clinics}
                            onChange={handleTreeSelectChange}
                        >
                            <TreeNode value={0} title="All">
                                {/* {renderTreeSelectOptions([
                                    {
                                        id: 1,
                                        name: "AMC Centre (AMC)"
                                    },
                                    {
                                        id: 2,
                                        name: "Thomson Clinic (TMC)"
                                    },
                                    {
                                        id: 3,
                                        name: "Yishun Clinic (YSC)"
                                    },
                                    {
                                        id: 4,
                                        name: "Hougang Clinic (HGC)"
                                    },
                                    {
                                        id: 5,
                                        name: "Mayflower Clinic (MFC)"
                                    },
                                ])} */}
                                {appState.fixedClinicList !== -1
                                    ? renderTreeSelectOptions(
                                          appState.fixedClinicList
                                      )
                                    : ""}
                            </TreeNode>
                        </TreeSelect>
                    </div>
                    <Spin spinning={loading}>
                        <div className="filter">
                            {/* <Select
                                showSearch
                                placeholder="Select"
                                filterOption={(input, option) =>
                                    option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
                                }
                                defaultValue={1} // get from appState
                            >
                                {renderListOptions([
                                    { id: 1, name: "Clinic A" },
                                    { id: 2, name: "Clinic B" },
                                ])}
                            </Select> */}
                            <Select
                                showSearch
                                placeholder="Select"
                                filterOption={(input, option) =>
                                    option?.children
                                        ?.toLowerCase()
                                        .indexOf(input?.toLowerCase()) >= 0
                                }
                                defaultValue={0}
                                onSelect={(value) => {
                                    // console.log(value);
                                    setFilter({
                                        ...filter,
                                        session: value
                                    });
                                }}
                                // onChange={handleSessionChange}
                            >
                                {renderListOptions([
                                    { id: 0, name: "All Sessions" },
                                    { id: "M", name: "Morning Session" },
                                    { id: "N", name: "Noon Session" },
                                    { id: "E", name: "Evening Session" }
                                ])}
                            </Select>
                        </div>
                        <div className="calendar-header">
                            <div className="month-year">
                                {monthYear.month} {monthYear.year}
                            </div>
                            <div className="availabilitydetails-title">
                                Availability Details
                            </div>
                        </div>
                        <div className="row1">
                            <Calendar
                                onChange={handleCalendarDateChange}
                                onPanelChange={handleCalendarPanelChange}
                                dateCellRender={dateCellRender}
                                disabledDate={disabledDate}
                                value={calendarSelectedDate}
                                onSelect={(value) => {
                                    setCalenderSelectedDate(value);
                                }}
                            />
                            <div className="availabilitydetails">
                                <div className="availabilitydetails-header">
                                    {monthYear.dayOfWeek},{" "}
                                    {monthYear.dayOfMonth} {monthYear.month}
                                </div>
                                <div className="availabilitydetails-content">
                                    {renderAvailabilityDetails(
                                        availabilityDetails
                                    )}
                                </div>
                            </div>
                        </div>
                    </Spin>
                </div>
            </div>
        </div>
    );
};

export default Availability;
