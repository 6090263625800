import React, { useEffect, useState } from "react"

import PatientRecordDialysisHistory from "./Component-PatientRecord-DialysisHistory"
import PatientRecordPreweightsDialysisOrder from "./Component-PatientRecord-PreweightsDialysisOrder"

const ManageDialysisSessionPatientRecord = (props) => {
    useEffect(() => {
        return () => {}
    }, [])

    return (
        <div className="content-content">
            <div className="container-row">
                <div className="view-desktop view-desktop-md">
                    <PatientRecordPreweightsDialysisOrder page={"record"} patientID={props?.match?.params?.id} manageDialysis={true} />
                    <PatientRecordDialysisHistory patientID={props?.match?.params?.id} manageDialysis={true} />
                </div>
            </div>
        </div>
    )
}

export default ManageDialysisSessionPatientRecord