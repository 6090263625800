import { Checkbox, DatePicker, Form, Input, Radio, Select, TreeSelect } from "antd"
import moment from "moment"

const renderInputItems = (array) => {
    const { Option } = Select
    const { TextArea } = Input
    const { TreeNode } = TreeSelect
    const { RangePicker } = DatePicker
    const dateFormatList = ["DD MMM YYYY"]
    // const dateFormatList = ["DD MMM YYYY HH:mm"]
    const dateFormatListShowTime = ["DD MMM YYYY HH:mm:ss"]
    const disabledDate = (current) => {
        // Cannot select days next day onwards
        return current > moment().endOf("day");
    }

    const handleDatePickerChange = (date, dateString) => {
        // console.log(date, dateString);
    }

    const handleFocus = (event) => {
        event.target.select()
    }

    const renderListOptions = (array, key = "id", value = "id", name = "name") => {
        if (typeof (array) == 'object' && array.length !== 0) {
            const listOptions = array.map((item, index) => {
                return (
                    <Option key={item[key]} value={item[value]}>{item[name]}</Option>
                )
            })

            return listOptions
        }
        else {
            return (
                <div />
            )
        }
    }

    const renderTreeSelectOptions = (array) => {
        if (typeof (array) == 'object' && array.length !== 0) {
            const listOptions = array.map((item, index) => {
                return (
                    <TreeNode title={item.name} value={item.id} key={item.id} />
                )
            })

            return listOptions
        }
        else {
            return (
                ""
            )
        }
    }

    const items = array.map((item, index) => {
        // console.log("item", item);
        switch (item.type) {
            case "displayText":
                return (
                    <Form.Item
                        className={item.className}
                        key={index}
                        label={item.label}
                        name={item.name}
                        rules={[
                            {
                                required: item.required === true ? true : false
                            }
                        ]}
                        style={item.width !== undefined ? { width: item.width } : {}}
                    >
                        {item.value}
                    </Form.Item>
                )
            case "select":
                return (
                    <Form.Item
                        className={item.className}
                        key={index}
                        label={item.label}
                        name={item.name}
                        rules={[
                            {
                                required: item.required === true ? true : false
                            }
                        ]}
                        style={item.width !== undefined ? { width: item.width } : {}}
                    >
                        <Select
                            showSearch
                            placeholder={item.placeholder}
                            filterOption={(input, option) =>
                                option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
                            }
                            disabled={item.disabled === true ? true : false}
                            onChange={item.handleSelect !== undefined ? item.handleSelect : null}
                        // defaultValue={0}
                        >
                            {renderListOptions(item.optionList, item.optionListKey, item.optionListValue, item.optionListName)}
                        </Select>
                    </Form.Item>
                )
            case "treeSelect":
                return (
                    <Form.Item
                        className={item.className}
                        key={index}
                        label={item.label}
                        name={item.name}
                        rules={[
                            {
                                required: item.required === true ? true : false
                            }
                        ]}
                        style={item.width !== undefined ? { width: item.width } : {}}
                    >
                        <TreeSelect
                            showSearch
                            allowClear
                            multiple
                            treeDefaultExpandAll
                            treeCheckable
                            placeholder={item.placeholder}
                            filterTreeNode={(input, treenode) =>
                                treenode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {renderTreeSelectOptions(item.optionList)}
                        </TreeSelect>
                    </Form.Item>
                )
            case "input":
                return (
                    <Form.Item
                        className={item.className}
                        key={index}
                        label={item.label}
                        name={item.name}
                        rules={[
                            {
                                required: item.required === true ? true : false
                            },
                            {
                                message: item.regexMessage,
                                pattern: item.regex
                            }
                        ]}
                        style={item.width !== undefined ? { width: item.width } : {}}
                        hidden={item.hidden ? true : false}
                    >
                        <Input placeholder={item.placeholder} disabled={item.disabled === true ? true : false} autoComplete="off" prefix={item.prefix !== undefined ? item.prefix : false} allowClear={item.allowClear === true ? true : false} onFocus={item.onFocusSelect === true ? handleFocus : null} />
                    </Form.Item>
                )
            case "email":
                return (
                    <Form.Item
                        className={item.className}
                        key={index}
                        label={item.label}
                        name={item.name}
                        rules={[
                            {
                                type: "email",
                                required: item.required === true ? true : false
                            }
                        ]}
                        style={item.width !== undefined ? { width: item.width } : {}}
                    >
                        <Input placeholder={item.placeholder} disabled={item.disabled === true ? true : false} autoComplete="off" />
                    </Form.Item>
                )
            case "text":
                return (
                    <Form.Item
                        className={item.className}
                        key={index}
                        label={item.label}
                        name={item.name}
                        rules={[
                            {
                                required: item.required === true ? true : false
                            }
                        ]}
                        style={{ width: "100%" }}
                    >
                        <TextArea rows={item.rows} placeholder={item.placeholder} disabled={item.disabled === true ? true : false} />
                    </Form.Item>
                )
            case "datepicker":
                return (
                    <Form.Item
                        className={item.className}
                        key={index}
                        label={item.label}
                        name={item.name}
                        rules={[
                            {
                                required: item.required === true ? true : false
                            }
                        ]}
                    >
                        <DatePicker onChange={handleDatePickerChange} format={item.showTime ? dateFormatListShowTime : dateFormatList} disabledDate={item.disabledDate === false ? null : disabledDate} disabled={item.disabled === true ? true : false} showTime={item.showTime === true ? true : false} />
                    </Form.Item>
                )
            case "rangepicker":
                return (
                    <Form.Item
                        className={item.className}
                        key={index}
                        label={item.label}
                        name={item.name}
                        rules={[
                            {
                                required: item.required === true ? true : false
                            }
                        ]}
                    >
                        <RangePicker onChange={item.onChange !== undefined ? item.onChange : null} format={item.showTime ? dateFormatListShowTime : dateFormatList} disabledDate={item.disabledDate === undefined ? null : item.disabledDate} disabled={item.disabled === true ? true : false} showTime={item.showTime === true ? true : false} />
                    </Form.Item>
                )
            case "checkbox":
                return (
                    <Form.Item
                        className={item.className}
                        key={index}
                        label={item.label}
                    >
                        <Form.Item
                            name={item.name}
                            valuePropName="checked"
                            noStyle
                        >
                            <Checkbox disabled={item.disabled === true ? true : false} />
                        </Form.Item>
                    </Form.Item>
                )
            case "radio":
                return (
                    <Form.Item
                        className={item.className}
                        key={index}
                        label={item.label}
                        name={item.name}
                        rules={[
                            {
                                required: item.required === true ? true : false
                            }
                        ]}
                    >
                        <Radio.Group
                            options={item.optionList}
                            defaultValue={item.defaultValue}
                            disabled={item.disabled === true ? true : false}
                        />
                    </Form.Item>
                )
            case "space":
                return (
                    <Form.Item key={index}>&nbsp;</Form.Item>
                )
            case "none":
                return (
                    <Form.Item key={index} style={{ display: "none" }}></Form.Item>
                )
            default:
        }
    })
    return items
}

export default renderInputItems