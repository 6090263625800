import React, { useEffect, useState } from "react"
import moment from "moment"
import { CalendarOutlined, ClockCircleOutlined } from "@ant-design/icons"

// import DialysisIntervalAlarm from "./Component-DialysisIntervalAlarm";

const CurrentDatetime = () => {
    const [datetime, setDatetime] = useState(new Date())

    useEffect(() => {
        const timer = setTimeout(() => {
            setDatetime(new Date())
            clearTimeout(timer)
        }, 1000)

        return () => {
            clearTimeout(timer)
        }
    }, [datetime])

    return (
        <div className="item datetime above-sm">
            <div>
                <CalendarOutlined /> {moment(datetime).format("DD MMM YYYY")}
            </div>
            <div>
                <ClockCircleOutlined /> {moment(datetime).format("HH:mm")}
                {/* <ClockCircleOutlined /> {moment(datetime).format("HH:mm:ss")} */}
            </div>
            {/* <DialysisIntervalAlarm clockDisplay={moment(datetime).format("HH:mm")} clock={moment(datetime)} /> */}
        </div>
    )
}

export default CurrentDatetime