// import React, { createContext, useState } from "react"
import path from "./pathSettings"

const navigationMenuSettings = (loginType, location) => {
    // console.log("location", location);
    // console.log("location", location?.split("/")[1]);

    const menuDashboardNurse = [
        {
            name: "Dashboard",
            to: "/",
        },
        {
            name: "Latest Clinical Note",
            to: "submenu-latestclinicalnote",
            submenu: [
                {
                    name: "By Author",
                    to: path("latestClinicalNoteByAuthor"),
                },
                {
                    name: "By Centre",
                    to: path("latestClinicalNoteByCentre"),
                },
            ]
        },
        {
            name: "Import Lab Result",
            to: "submenu-importlabresult",
            submenu: [
                {
                    name: "Manual Input",
                    to: path("importLabResultManualInput"),
                },
                {
                    name: "File Upload",
                    to: path("importLabResultFileUpload"),
                },
            ]
        },
        {
            name: "Availability",
            to: path("availability"),
        },
        {
            name: "Incident Report Summary",
            to: path("incidentReport"),
        },
        {
            name: "Manage",
            to: "submenu-manage",
            submenu: [
                {
                    name: "Machine",
                    to: path("manageMachine"),
                },
                {
                    name: "Staff",
                    to: path("manageStaff"),
                },
            ]
        },
    ]

    const menuDashboardDoctor = [
        {
            name: "Dashboard",
            to: "/",
        },
        {
            name: "Latest Clinical Note",
            to: "submenu-latestclinicalnote",
            submenu: [
                {
                    name: "By Author",
                    to: path("latestClinicalNoteByAuthor"),
                },
                {
                    name: "By Centre",
                    to: path("latestClinicalNoteByCentre"),
                },
            ]
        },
        {
            name: "Import Lab Result",
            to: "submenu-importlabresult",
            submenu: [
                {
                    name: "Manual Input",
                    to: path("importLabResultManualInput"),
                },
                {
                    name: "File Upload",
                    to: path("importLabResultFileUpload"),
                },
            ]
        },
        {
            name: "Availability",
            to: path("availability"),
        },
        {
            name: "Incident Report Summary",
            to: path("incidentReport"),
        },
        {
            name: "Manage",
            to: "submenu-manage",
            submenu: [
                {
                    name: "Machine",
                    to: path("manageMachine"),
                },
                {
                    name: "Machine Status",
                    to: path("manageMachineStatus"),
                },
                {
                    name: "Staff",
                    to: path("manageStaff"),
                },
                {
                    name: "Certification",
                    to: path("manageCertification"),
                },
            ]
        },
    ]

    const menuDashboardManagingNurseMedicalDirector = [
        {
            name: "Dashboard",
            to: "/",
        },
        {
            name: "Latest Clinical Note",
            to: "submenu-latestclinicalnote",
            submenu: [
                {
                    name: "By Author",
                    to: path("latestClinicalNoteByAuthor"),
                },
                {
                    name: "By Centre",
                    to: path("latestClinicalNoteByCentre"),
                },
            ]
        },
        {
            name: "Import Lab Result",
            to: "submenu-importlabresult",
            submenu: [
                {
                    name: "Manual Input",
                    to: path("importLabResultManualInput"),
                },
                {
                    name: "File Upload",
                    to: path("importLabResultFileUpload"),
                },
            ]
        },
        {
            name: "Availability",
            to: path("availability"),
        },
        {
            name: "Incident Report Summary",
            to: path("incidentReport"),
        },
        {
            name: "Manage",
            to: path("manage"),
        },
    ]

    const menuInPatient = (id, inPatientOrigin) => {
        let _menuInPatient = [
            {
                // name: "< Back",
                name: "Dashboard",
                to: "/",
            },
            {
                name: "Patient Info",
                to: path("patientInformation", [id]),
            },
            // {
            //     name: inPatientOrigin !== undefined && inPatientOrigin === "dialysis" ? "Dialysis Session" : "Patient Record",
            //     to: inPatientOrigin !== undefined && inPatientOrigin === "dialysis" ? path("patientDialysis", [id]) : path("patientRecord", [id]),
            // },
            {
                name: "Patient Record",
                to: path("patientRecord", [id]),
            },
            {
                name: "Dialysis Session",
                to: path("patientDialysis", [id]),
            },
            {
                name: "Patient Incident Report",
                to: path("patientIncidentReport", [id]),
            },
            {
                name: "Lab Report",
                to: path("patientLabReport", [id]),
            },
            {
                name: "Document",
                to: path("patientDocument", [id]),
            },
            {
                name: "Appointment",
                to: path("patientAppointment", [id]),
            },
        ];

        if (loginType == 'Doctor') {
            _menuInPatient = _menuInPatient.filter(s => !['Dialysis Session', 'Appointment'].includes(s.name))
        }
        return (_menuInPatient);
    }

    let menu = [
        {
            name: "Dashboard",
            to: "/",
        },
    ]
    if (location?.split("/")[1] === "patient" && location?.split("/")[2] !== "create") {
        // in-patient
        menu = menuInPatient(location?.split("/")[2])
    }
    else {
        switch (loginType) {
            case "Nurse":
                menu = menuDashboardNurse
                break
            case "ManagingNurse":
                menu = menuDashboardManagingNurseMedicalDirector
                break
            case "Doctor":
                menu = menuDashboardDoctor
                break
            case "MedicalDirector":
                menu = menuDashboardManagingNurseMedicalDirector
                break
            default:
        }
    }

    return menu
}

export default navigationMenuSettings