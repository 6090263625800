import React, { useContext, useEffect, useState } from "react";
import {
    AutoComplete,
    Button,
    Divider,
    Form,
    Input,
    Modal,
    Select
} from "antd";

import PatientRecordDialysisOrder from "./Component-PatientRecord-DialysisOrder";
import { formatCountdown } from "antd/lib/statistic/utils";
import { API } from "aws-amplify";
import { AppContext } from "../contexts/AppContext";

import SummaryPrePostDialysis from "./Component-Summary-PrePostDialysis";
import { dialyserList } from "../utils/constants";

const PatientRecordPostweights = (props) => {
    const { appState } = useContext(AppContext);
    const {
        values,
        setValues,
        onFinish,
        page,
        checkOutException,
        form,
        patientDisplay
    } = props;
    const { Option } = Select;
    // console.log("page", page);
    // const [patient, setPatient] = useState({
    //     nameCode: "DMLGH;7585",
    //     sessionMonth: 4,
    //     lastWt: "63 KG",
    //     wtGain: "5.1",
    //     nextOfKin: "John Mclaaughlin",
    //     nextOfKinNum: "+6598765432",
    //     expectedDWDifference: "0.0 KG",
    //     expectedLWDifference: "0.1 KG"
    // })
    const [visibilityDialysisOrder, setVisibilityDialysisOrder] =
        useState(false);
    const [
        visibilityMobilePostDialysisSummary,
        setVisibilityMobilePostDialysisSummary
    ] = useState(false);

    const handleChange = (key) => {
        // console.log("key", key);
    };

    const handleFocus = (event) => {
        event.target.select();
    };

    useEffect(() => {
        form.setFieldsValue({
            postWt: "",
            postTemp: "",
            postEdema: "",
            postSBP: "",
            postDBP: "",
            postHRT: "",
            KTVonline: "",
            renalinPostcheck: ""
        });
    }, []);

    useEffect(() => {
        // console.log('PatientRecordPostweights', values);
    }, [values]);

    const renderListOptions = (
        array,
        key = "id",
        value = "id",
        name = "name"
    ) => {
        if (typeof array == "object" && array.length !== 0) {
            const listOptions = array.map((item, index) => {
                return (
                    <Option key={item[key]} value={item[value]}>
                        {item[name]}
                    </Option>
                );
            });

            return listOptions;
        } else {
            return <div />;
        }
    };

    return (
        <div className="patientrecord-preweights post">
            <div className="container-button below-md">
                <Button
                    onClick={() => {
                        setVisibilityDialysisOrder(!visibilityDialysisOrder);
                    }}
                >
                    Dialysis Order
                </Button>
                <Button
                    className="btn-finishcheckout"
                    onClick={() => {
                        setVisibilityMobilePostDialysisSummary(true);
                    }}
                >
                    Check Out
                </Button>
            </div>
            <div className="patient below-md">
                <div className="row1">{patientDisplay?.name}</div>
                <div className="row2">{patientDisplay?.nric}</div>
                <div className="side-display-patient">
                    Patient: <b>{patientDisplay.name}</b>
                </div>
            </div>
            <div className="column1">
                {patientDisplay?.namecode ? (
                    <Form.Item label="Name Code">
                        {patientDisplay?.namecode}
                    </Form.Item>
                ) : (
                    ""
                )}
                <Form.Item label="Session Month">
                    {values.sessionMonth}
                </Form.Item>
                <Form.Item label="LAST-WT (KG)">{values.lastWeight}</Form.Item>
                <Form.Item
                    label="PRE-WT (KG)"
                    name="preWt"
                    rules={[
                        {
                            required: page === "checkoutEdit" ? false : true
                        },
                        {
                            message: "Digits only",
                            pattern: new RegExp("^[+-]?([0-9]*[.])?[0-9]+$")
                        }
                    ]}
                >
                    <Input
                        autoComplete="off"
                        placeholder=""
                        allowClear
                        disabled={page === "checkoutEdit" ? true : false}
                        onFocus={handleFocus}
                    />
                </Form.Item>
                <Form.Item
                    label="POST-WT (KG)"
                    name="postWt"
                    rules={[
                        {
                            // required: !checkOutException,
                        },
                        {
                            message: "Digits only",
                            pattern: new RegExp("^[+-]?([0-9]*[.])?[0-9]+$")
                        }
                    ]}
                >
                    <Input
                        autoComplete="off"
                        placeholder=""
                        allowClear
                        disabled={page === "intra" ? true : false}
                        onFocus={handleFocus}
                    />
                </Form.Item>
            </div>
            <div className="column2">
                <div className="column">
                    <Form.Item
                        label="Pre-Temp (&#8451;)"
                        name="preTemp"
                        rules={[
                            {
                                required: page === "checkoutEdit" ? false : true
                            },
                            {
                                message: "Digits only",
                                pattern: new RegExp("^[+-]?([0-9]*[.])?[0-9]+$")
                            }
                        ]}
                    >
                        <Input
                            autoComplete="off"
                            placeholder=""
                            allowClear
                            disabled={page === "checkoutEdit" ? true : false}
                            defaultValue={values.preTemp}
                            onFocus={handleFocus}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Pre Edema"
                        name="preEdema"
                        rules={[
                            {
                                required: page === "checkoutEdit" ? false : true
                            },
                            {
                                message: "Digits only",
                                pattern: new RegExp("^[0-9]*$")
                            }
                        ]}
                    >
                        <Input
                            autoComplete="off"
                            placeholder=""
                            allowClear
                            disabled={page === "checkoutEdit" ? true : false}
                            onFocus={handleFocus}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Pre SBP"
                        name="preSBP"
                        rules={[
                            {
                                required: page === "checkoutEdit" ? false : true
                            },
                            {
                                message: "Digits only",
                                pattern: new RegExp("^[0-9]*$")
                            }
                        ]}
                    >
                        <Input
                            autoComplete="off"
                            placeholder=""
                            allowClear
                            disabled={page === "checkoutEdit" ? true : false}
                            onFocus={handleFocus}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Pre DBP"
                        name="preDBP"
                        rules={[
                            {
                                required: page === "checkoutEdit" ? false : true
                            },
                            {
                                message: "Digits only",
                                pattern: new RegExp("^[0-9]*$")
                            }
                        ]}
                    >
                        <Input
                            autoComplete="off"
                            placeholder=""
                            allowClear
                            disabled={page === "checkoutEdit" ? true : false}
                            onFocus={handleFocus}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Pre HRT"
                        name="preHRT"
                        rules={[
                            {
                                required: page === "checkoutEdit" ? false : true
                            },
                            {
                                message: "Digits only",
                                pattern: new RegExp("^[0-9]*$")
                            }
                        ]}
                    >
                        <Input
                            autoComplete="off"
                            placeholder=""
                            allowClear
                            disabled={page === "checkoutEdit" ? true : false}
                            onFocus={handleFocus}
                        />
                    </Form.Item>
                    <Form.Item
                        label="DW (KG)"
                        name="dw"
                        rules={[
                            {
                                required: page === "checkoutEdit" ? false : true
                            },
                            {
                                message: "Digits only",
                                pattern: new RegExp("^[+-]?([0-9]*[.])?[0-9]+$")
                            }
                        ]}
                    >
                        <Input
                            autoComplete="off"
                            placeholder=""
                            allowClear
                            disabled={page === "checkoutEdit" ? true : false}
                            onFocus={handleFocus}
                        />
                    </Form.Item>
                    <Form.Item
                        label="UF Target At Start (&#8467;)"
                        name="ufTargetAtStart"
                        rules={[
                            {
                                required: page === "checkoutEdit" ? false : true
                            },
                            {
                                message: "Digits only",
                                pattern: new RegExp("^[+-]?([0-9]*[.])?[0-9]+$")
                            }
                        ]}
                    >
                        <Input
                            autoComplete="off"
                            placeholder=""
                            allowClear
                            disabled={page === "checkoutEdit" ? true : false}
                            onFocus={handleFocus}
                        />
                    </Form.Item>
                    <Form.Item label="Expected DW Difference" className="red">
                        {values.expectedDWDifference}
                    </Form.Item>
                    <Form.Item label="Expected LW Difference" className="red">
                        {values.expectedLWDifference}
                    </Form.Item>
                    {/* <Form.Item
                        label="WT Gain (KG)"
                    >
                        {values.wtGain}
                    </Form.Item> */}
                    {/* <Form.Item
                        label="Next of kin"
                    >
                        {values.nextOfKin}
                    </Form.Item>
                    <Form.Item
                        label="Next of kin number"
                    >
                        {values.nextOfKinNum}
                    </Form.Item> */}
                </div>
                <div className="column">
                    <Form.Item
                        label="Post-Temp (&#8451;)"
                        name="postTemp"
                        rules={[
                            {
                                // required: !checkOutException,
                            },
                            {
                                message: "Digits only",
                                pattern: new RegExp("^[+-]?([0-9]*[.])?[0-9]+$")
                            }
                        ]}
                    >
                        <Input
                            autoComplete="off"
                            placeholder=""
                            allowClear
                            disabled={page === "intra" ? true : false}
                            onFocus={handleFocus}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Post Edema"
                        name="postEdema"
                        rules={[
                            {
                                // required: !checkOutException,
                            },
                            {
                                message: "Digits only",
                                pattern: new RegExp("^[0-9]*$")
                            }
                        ]}
                    >
                        <Input
                            autoComplete="off"
                            placeholder=""
                            allowClear
                            disabled={page === "intra" ? true : false}
                            onFocus={handleFocus}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Post SBP"
                        name="postSBP"
                        rules={[
                            {
                                // required: !checkOutException,
                            },
                            {
                                message: "Digits only",
                                pattern: new RegExp("^[0-9]*$")
                            }
                        ]}
                    >
                        <Input
                            autoComplete="off"
                            placeholder=""
                            allowClear
                            disabled={page === "intra" ? true : false}
                            onFocus={handleFocus}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Post DBP"
                        name="postDBP"
                        rules={[
                            {
                                // required: !checkOutException,
                            },
                            {
                                message: "Digits only",
                                pattern: new RegExp("^[0-9]*$")
                            }
                        ]}
                    >
                        <Input
                            autoComplete="off"
                            placeholder=""
                            allowClear
                            disabled={page === "intra" ? true : false}
                            onFocus={handleFocus}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Post HRT"
                        name="postHRT"
                        rules={[
                            {
                                // required: !checkOutException,
                            },
                            {
                                message: "Digits only",
                                pattern: new RegExp("^[0-9]*$")
                            }
                        ]}
                    >
                        <Input
                            autoComplete="off"
                            placeholder=""
                            allowClear
                            disabled={page === "intra" ? true : false}
                            onFocus={handleFocus}
                        />
                    </Form.Item>
                    <Form.Item
                        label="KTV online"
                        name="KTVonline"
                        rules={[
                            // {
                            // required: !checkOutException,
                            // },
                            {
                                message: "Digits only",
                                pattern: new RegExp("^[+-]?([0-9]*[.])?[0-9]+$")
                            }
                        ]}
                    >
                        <Input
                            autoComplete="off"
                            placeholder=""
                            allowClear
                            disabled={page === "intra" ? true : false}
                            onFocus={handleFocus}
                        />
                    </Form.Item>
                </div>
            </div>
            <div className="column3">
                <Form.Item
                    label="Clinic"
                    name="clinicID"
                    rules={
                        [
                            // {
                            //     required: true,
                            // },
                        ]
                    }
                >
                    {/* <Select onChange={handleChange} disabled = {page === "intra" ? true : false}> */}
                    <Select onChange={handleChange} disabled={true}>
                        {renderListOptions(appState.clinicList)}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Machine"
                    name="machineID"
                    rules={[
                        {
                            required: page === "checkoutEdit" ? false : true
                        }
                    ]}
                >
                    {/* <Select onChange={handleChange} disabled={page === "checkout" ? true : false}> */}
                    <Select
                        onChange={handleChange}
                        disabled={page === "checkoutEdit" ? true : false}
                    >
                        {renderListOptions(
                            appState.machineList,
                            "id",
                            "id",
                            "serialNo"
                        )}
                    </Select>
                </Form.Item>
                {/* <Form.Item
                    label="Machine"
                    name="machineId"
                >
                    DDL
                </Form.Item> */}
                <Form.Item
                    label="Dialyser"
                    name="dialyser"
                    rules={[
                        {
                            required: page === "checkoutEdit" ? false : true
                        }
                    ]}
                >
                    {/* <Input onChange={handleChange} disabled={page === "checkout" ? true : false} /> */}
                    {/* <Input onChange={handleChange} disabled={page === "checkoutEdit" ? true : false} /> */}
                    <AutoComplete
                        onChange={handleChange}
                        disabled={page === "intra" ? true : false}
                        showSearch={true}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option?.children
                                ?.toLowerCase()
                                .indexOf(input?.toLowerCase()) >= 0
                        }
                    >
                        {renderListOptions(
                            dialyserList,
                            "id",
                            "value",
                            "value"
                        )}
                    </AutoComplete>
                </Form.Item>
                <Form.Item
                    label="MSA"
                    name="MSA"
                    rules={[
                        {
                            required: page === "checkoutEdit" ? false : true
                        },
                        {
                            message: "Digits only",
                            pattern: new RegExp("^[+-]?([0-9]*[.])?[0-9]+$")
                        }
                    ]}
                >
                    {/* <Input autoComplete="off" placeholder="" allowClear disabled={page === "checkout" ? true : false} /> */}
                    <Input
                        autoComplete="off"
                        placeholder=""
                        allowClear
                        disabled={page === "checkoutEdit" ? true : false}
                        onFocus={handleFocus}
                    />
                </Form.Item>
                <Form.Item
                    label="Membrane Use"
                    name="membraneUse"
                    rules={[
                        {
                            required: page === "checkoutEdit" ? false : true
                        }
                    ]}
                >
                    {/* <Input autoComplete="off" placeholder="" allowClear disabled={page === "checkout" ? true : false} /> */}
                    <Input
                        autoComplete="off"
                        placeholder=""
                        allowClear
                        disabled={page === "checkoutEdit" ? true : false}
                        onFocus={handleFocus}
                    />
                </Form.Item>
                {/* <Form.Item
                    label="Renalin Precheck"
                    name="renalinPrecheck"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input autoComplete="off" placeholder="" allowClear disabled={page === "checkout" ? true : false} />
                </Form.Item> */}
                {/* <Form.Item
                    label="Renalin Postcheck"
                    name="renalinPostcheck"
                    rules={[
                        {
                            required: !checkOutException,
                        },
                    ]}
                >
                    <Input autoComplete="off" placeholder="" allowClear />
                </Form.Item> */}
            </div>
            <div className="below-md">
                <Button
                    className="btn-finishcheckout"
                    onClick={() => {
                        setVisibilityMobilePostDialysisSummary(true);
                        // onFinish
                    }}
                >
                    Check Out
                </Button>
            </div>
            <Modal
                visible={visibilityDialysisOrder}
                onCancel={() => {
                    setVisibilityDialysisOrder(false);
                }}
                footer={[
                    <Button
                        key="back"
                        onClick={() => setVisibilityDialysisOrder(false)}
                    >
                        Cancel
                    </Button>
                ]}
            >
                <PatientRecordDialysisOrder
                    modal={true}
                    patientID={props?.patientID}
                />
            </Modal>
            <Modal
                title="Check Out"
                visible={visibilityMobilePostDialysisSummary}
                onCancel={() => {
                    setVisibilityMobilePostDialysisSummary(false);
                }}
                footer={[
                    <Button
                        key="back"
                        onClick={() =>
                            setVisibilityMobilePostDialysisSummary(false)
                        }
                    >
                        Cancel
                    </Button>,
                    <Button
                        key="submit"
                        type="primary"
                        onClick={() => {
                            setVisibilityMobilePostDialysisSummary(false);
                            onFinish();
                        }}
                    >
                        Submit
                    </Button>
                ]}
            >
                <SummaryPrePostDialysis
                    page="checkout"
                    values={form.getFieldValue()}
                />
            </Modal>
        </div>
    );
};

export default PatientRecordPostweights;
