import React, { useContext, useEffect, useState } from "react";
import {
    Button,
    Checkbox,
    Form,
    Input,
    notification,
    Spin,
    Tabs,
    View
} from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { Link, useHistory } from "react-router-dom";
import { AppContext } from "../contexts/AppContext";
import { Auth } from "aws-amplify";

import path from "../utils/pathSettings";
import Logo from "../media/Logo-AEGISMedicalCare.png";

const Login = (props) => {
    const { appState, actionLogin } = useContext(AppContext);
    const { from } = props.location.state || { from: "/" };
    const history = useHistory();
    const [form, form2] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const validateMessages = {
        required: "This field is required.",
        types: {
            email: "This is not a valid email."
        },
        string: {
            min: "This field must be at least ${min} characters."
        }
    };
    const [newPasswordRequired, setNewPasswordRequired] = useState(false);

    useEffect(() => {
        // console.log("appState", appState);
        // if (localStorage.getItem("ircs_remember") !== null) {
        //     form.setFieldsValue({
        //         username: localStorage.getItem("ircs_remember"),
        //         remember: true,
        //     })
        // }
        // else {
        //     form.setFieldsValue({
        //         remember: false
        //     })
        // }

        return () => {};
    }, []);

    useEffect(() => {
        if (
            Object.keys(appState.cognitoUser).length !== 0 &&
            appState.validLogin === true
        ) {
            if (appState.selectedClinicID === false) {
                history.push(path("settingsCentreSelection"));
            } else {
                history.push(from);
            }
        }
    }, [appState]);

    const loginSuccessRedirect = (user) => {
        let values = form.getFieldValue();

        const cognitoGroups =
            user.signInUserSession.idToken.payload["cognito:groups"];
        // console.log("cognitoGroups", cognitoGroups);

        let platformPermission = false;
        let loginType = "";

        for (let i = 0; i < cognitoGroups.length; i++) {
            if (
                cognitoGroups[i] === "Nurse" ||
                cognitoGroups[i] === "ManagingNurse" ||
                cognitoGroups[i] === "Doctor" ||
                cognitoGroups[i] === "MedicalDirector"
            ) {
                platformPermission = true;
                loginType = cognitoGroups[i];
                break;
            }
        }

        if (platformPermission) {
            // if (values.remember === true) {
            //     localStorage.setItem("ircs_remember", values.username)
            // }
            if (values.stayLoggedIn === true) {
                localStorage.setItem("ircs_stayloggedin", true);
            }
            actionLogin(user, loginType);

            history.push(path("settingsCentreSelection"));
        }
    };

    const onFinish = async (values) => {
        setLoading(true);
        // console.log("onFinish", values, form);
        let username = values.username;

        try {
            const user = await Auth.signIn(username, values.password);
            // console.log(user);
            if (user?.signInUserSession) {
                loginSuccessRedirect(user);
            } else if (user.challengeName == "NEW_PASSWORD_REQUIRED") {
                // form2.setFieldsValue({
                //     username: values.username,
                // });
                setNewPasswordRequired(true);
                setLoading(false);
            } else {
                try {
                    await Auth.signOut();
                } catch (error) {
                    console.log("error signing out: ", error);
                }
                notification.error({
                    message: "Not authorised account"
                });
                setLoading(false);
            }
        } catch (error) {
            console.log("error signing in: ", error);
            notification.error({
                message: error.message
            });
            setLoading(false);
        }
    };

    const onFinishNewPassword = async (values) => {
        setLoading(true);
        // console.log("onFinishNewPassword", values, form);
        let username = values.username;

        try {
            let formValues = form.getFieldValue();
            await Auth.signIn(formValues.username, formValues.password)
                .then((user) => {
                    return Auth.completeNewPassword(user, values.newPassword);
                })
                .then((user) => {
                    if (user?.signInUserSession) {
                        Auth.currentAuthenticatedUser({
                            bypassCache: true // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
                        }).then((user) => {
                            loginSuccessRedirect(user);
                        });
                    }
                    // console.log(user);
                })
                .catch((e) => {
                    // console.log(e);
                });
        } catch (error) {
            console.log("error signing in: ", error);
            notification.error({
                message: error.message
            });
            setLoading(false);
        }
    };

    const handleCheckChange = (e) => {
        // console.log("handleCheckChange");
        if (form.getFieldValue("remember") === false) {
            localStorage.removeItem("ircs_remember");
        } else if (form.getFieldValue("stayLoggedIn") === false) {
            localStorage.removeItem("ircs_stayloggedin");
        }
    };

    return (
        <div className="login">
            <div className="container-login">
                <div className="login-body">
                    <div className="login-header">
                        <img src={Logo} />
                        <div>Login</div>
                    </div>
                    <div className="login-content">
                        <Spin spinning={loading}>
                            {newPasswordRequired == false ? (
                                <Form
                                    form={form}
                                    name="normal_login"
                                    className="login-form"
                                    onFinish={onFinish}
                                    validateMessages={validateMessages}
                                >
                                    <Form.Item
                                        name="username"
                                        // rules={[
                                        //     {
                                        //         required: true,
                                        //         message: "Please input your Username.",
                                        //     },
                                        // ]}
                                        rules={[
                                            {
                                                type: "email",
                                                required: true
                                            }
                                        ]}
                                    >
                                        {/* <Input autoComplete="off" prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" /> */}
                                        <Input
                                            autoComplete="off"
                                            prefix={
                                                <UserOutlined className="site-form-item-icon" />
                                            }
                                            placeholder="Email"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="password"
                                        rules={[
                                            {
                                                required: true
                                            }
                                        ]}
                                    >
                                        <Input.Password
                                            prefix={
                                                <LockOutlined className="site-form-item-icon" />
                                            }
                                            placeholder="Password"
                                        />
                                    </Form.Item>
                                    <Form.Item>
                                        {/* <Form.Item name="remember" valuePropName="checked" noStyle>
                                            <Checkbox onChange={handleCheckChange}>Remember me</Checkbox>
                                        </Form.Item> */}
                                        <Form.Item
                                            name="stayLoggedIn"
                                            valuePropName="checked"
                                            noStyle
                                        >
                                            <Checkbox
                                                onChange={handleCheckChange}
                                            >
                                                Stay logged in
                                            </Checkbox>
                                        </Form.Item>
                                        <Link
                                            className="login-form-forgot"
                                            to={path("forgotPassword")}
                                        >
                                            Forgot password
                                        </Link>
                                    </Form.Item>

                                    <Form.Item>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            className="login-form-button"
                                        >
                                            Log In
                                        </Button>
                                    </Form.Item>
                                </Form>
                            ) : (
                                <Form
                                    form={form2}
                                    name="normal_login"
                                    className="login-form"
                                    onFinish={onFinishNewPassword}
                                    validateMessages={validateMessages}
                                >
                                    <Form.Item
                                        name="username"
                                        // rules={[
                                        //     {
                                        //         required: true,
                                        //         message: "Please input your Username.",
                                        //     },
                                        // ]}
                                        rules={[
                                            {
                                                type: "email",
                                                required: true
                                            }
                                        ]}
                                    >
                                        {/* <Input autoComplete="off" prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" /> */}
                                        <Input
                                            autoComplete="off"
                                            prefix={
                                                <UserOutlined className="site-form-item-icon" />
                                            }
                                            placeholder="Email"
                                            disabled
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="newPassword"
                                        rules={[
                                            {
                                                required: true
                                            },
                                            {
                                                min: 8
                                            }
                                        ]}
                                    >
                                        <Input.Password
                                            prefix={
                                                <LockOutlined className="site-form-item-icon" />
                                            }
                                            placeholder="New Password"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="confirmNewPassword"
                                        rules={[
                                            {
                                                required: true
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (
                                                        !value ||
                                                        getFieldValue(
                                                            "newPassword"
                                                        ) === value
                                                    ) {
                                                        return Promise.resolve();
                                                    }

                                                    return Promise.reject(
                                                        new Error(
                                                            "The two passwords that you entered do not match."
                                                        )
                                                    );
                                                }
                                            })
                                        ]}
                                    >
                                        <Input.Password
                                            prefix={
                                                <LockOutlined className="site-form-item-icon" />
                                            }
                                            placeholder="Confirm New Password"
                                        />
                                    </Form.Item>

                                    <Form.Item>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            className="login-form-button"
                                        >
                                            Confirm
                                        </Button>
                                    </Form.Item>
                                </Form>
                            )}
                        </Spin>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
