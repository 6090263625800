import React, { useContext, useEffect, useState } from "react";
import {
    Button,
    DatePicker,
    Empty,
    Form,
    Input,
    message,
    Modal,
    Popconfirm,
    Select,
    Spin,
    notification,
    Pagination,
    Table
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { API } from "aws-amplify";
import moment from "moment";

import { listMedication_MedicationHistory } from "../backend/graphql/custom_queries";
import {
    createMedication,
    updateMedication
} from "../backend/graphql/mutations";
import { AppContext } from "../contexts/AppContext";

import iconFile from "../media/Icon-File2.png";
import iconAdd from "../media/Icon-Add.png";
import iconMinus from "../media/Icon-Minus.png";
import iconEye from "../media/Icon-Eye2.png";
import { listMedication } from "../backend/graphql/queries";
import { freqList, routeList } from "../utils/constants";

const PatientRecordMedication = (props) => {
    const { appState } = useContext(AppContext);
    const [form] = Form.useForm();
    const dateFormatList = ["DD MMM YYYY"];
    const validateMessages = {
        required: "Required."
    };
    const [visibilityAllergy, setVisibilityAllergy] = useState(false);
    const [visibilityHistoricalMedication, setVisibilityHistoricalMedication] =
        useState(false);
    const [visibilityCurrentMedication, setVisibilityCurrentMedication] =
        useState(false);
    const [visibilityAddCurrentMedication, setVisibilityAddCurrentMedication] =
        useState(false);
    const [tableMedication, setTableMedication] = useState({
        data: [],
        pagination: {
            current: 1,
            pageSize: props.pageSize ?? 6,
            offset: 0
        },
        loading: false
    });
    const [tableMedicationHistory, setTableMedicationHistory] = useState({
        data: -1,
        pagination: {
            current: 1,
            pageSize: props.pageSize ?? 20,
            offset: 0
        },
        loading: false
    });
    const [medicationHistory, setMedicationHistory] = useState([]);
    const [medication, setMedication] = useState([]);
    const [currentMedication, setCurrentMedication] = useState({});
    const [allergy, setAllergy] = useState([]);
    const [loading, setLoading] = useState(false);
    const { Option } = Select;

    useEffect(() => {
        // console.log('PatientRecordMedication', props?.patientID);
        // console.log(appState.cognitoUser);
        if (Object.keys(appState.cognitoUser).length !== 0) {
            _listMedication(props?.patientID);
            _listMedicationHistory(props?.patientID);
        }
        return () => {};
    }, [appState.cognitoUser]);

    useEffect(() => {
        if (tableMedication.data !== -1) {
            let _medication = [];
            for (let v of tableMedication.data) {
                _medication.push({
                    id: v.id,
                    date: v.start,
                    end: v.end,
                    dateStart: v.start,
                    dateEnd: v.end,
                    active: v.status == "ACTIVE",
                    // medication: v.drugName,
                    medication: v.medication,
                    freq: v.freq,
                    route: v.route,
                    dose: v.dose
                });
            }

            setMedication(_medication);
            setTableMedication({
                ...tableMedication,
                loading: false
            });
        }
    }, [tableMedication.data]);

    useEffect(() => {
        if (tableMedicationHistory.data !== -1) {
            let _medicationHistory = [];
            for (let v of tableMedicationHistory.data) {
                _medicationHistory.push({
                    id: v.id,
                    date: v.start,
                    end: v.end,
                    dateStart: v.start,
                    dateEnd: v.end,
                    active: v.status == "ACTIVE",
                    // medication: v.drugName,
                    medication: v.medication,
                    freq: v.freq,
                    route: v.route,
                    dose: v.dose
                });
            }

            setMedicationHistory(_medicationHistory);
            setTableMedicationHistory({
                ...tableMedicationHistory,
                loading: false
            });
        }
    }, [tableMedicationHistory.data]);

    const _listMedication = async (
        patientID,
        pagination = tableMedication.pagination
    ) => {
        setTableMedication({
            ...tableMedication,
            loading: true
        });
        try {
            const variables = {
                pagination: {
                    limit: pagination.pageSize,
                    offset: pagination.offset,
                    orderby: `(status = 'ACTIVE') ASC, createdOn DESC`
                },
                filter: {
                    patientID: {
                        eq: patientID
                    },
                    end: {
                        attributeExists: false
                    },
                    deleted: { eq: 0 }
                }
            };

            // console.log(variables);

            const result = await API.graphql({
                query: listMedication,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables
            });
            // console.log("API result PatientRecordMedication", result);
            const data = result.data.result;
            const array = result?.data?.result?.result;

            // process data
            // let _medication = [];
            // for (let v of result?.data?.result?.result) {
            //     console.log(v);
            //     _medication.push({
            //         id: v.id,
            //         date: v.start,
            //         end: v.end,
            //         dateStart: v.start,
            //         dateEnd: v.end,
            //         active: v.status == 'ACTIVE',
            //         // medication: v.drugName,
            //         medication: v.medication,
            //         freq: v.freq,
            //         route: v.route,
            //         dose: v.dose,
            //     })
            // }

            if (array) {
                setTableMedication({
                    ...tableMedication,
                    data: array ?? [],
                    pagination: {
                        ...tableMedication.pagination,
                        current: pagination.current,
                        offset: pagination.offset,
                        total:
                            pagination.offset >= data.count
                                ? data.count + 1
                                : data.count // keeps the last pagination if it is the last record
                    }
                });
            }
        } catch (error) {
            console.log("error: ", error);
            notification.error({
                message: "Unable to retrieve Medication"
            });
        } finally {
            setLoading(false);
        }
    };

    const _listMedicationHistory = async (
        patientID,
        pagination = tableMedicationHistory.pagination
    ) => {
        setTableMedicationHistory({
            ...tableMedicationHistory,
            loading: true
        });
        try {
            const variables = {
                pagination: {
                    limit: pagination.pageSize,
                    offset: pagination.offset,
                    orderby: `(status = 'ACTIVE') ASC, end DESC`
                },
                filter: {
                    patientID: {
                        eq: patientID
                    },
                    end: {
                        attributeExists: true
                    },
                    deleted: { eq: 0 }
                }
            };

            // console.log(variables);

            const result = await API.graphql({
                query: listMedication,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables
            });
            // console.log("API result PatientRecordMedication", result);
            const data = result.data.result;
            const array = result?.data?.result?.result;

            if (array) {
                setTableMedicationHistory({
                    ...tableMedicationHistory,
                    data: array ?? [],
                    pagination: {
                        ...tableMedicationHistory.pagination,
                        current: pagination.current,
                        offset: pagination.offset,
                        total:
                            pagination.offset >= data.count
                                ? data.count + 1
                                : data.count // keeps the last pagination if it is the last record
                    }
                });
            }
        } catch (error) {
            console.log("error: ", error);
            notification.error({
                message: "Unable to retrieve Medication History"
            });
        } finally {
            setLoading(false);
        }
    };

    const _createMedication = async (values) => {
        // console.log("values", values);
        setLoading(true);
        try {
            let variables = {
                patientID: props?.patientID,
                // drugID: values.medication,
                medication: values.medication,
                start: values.date.format("YYYY-MM-DD"),
                // status: 'ACTIVE'
                freq: values.frequency,
                route: values.route,
                dose: values.dose
            };

            variables = Object.entries(variables).reduce(
                (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
                {}
            );

            // console.log(variables);

            const result = await API.graphql({
                query: createMedication,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables: { create: variables }
            });
            // console.log("API result PatientRecordMedication createMedication", result);

            if (result?.data?.result) {
                _listMedication(props?.patientID);
                notification.success({
                    message: "Created successfully"
                });
                form.resetFields();
                setVisibilityAddCurrentMedication(false);
            }
        } catch (error) {
            // console.log(error);
            notification.error({
                message: "Unable to create new record"
            });
        } finally {
            setLoading(false);
        }
    };

    const _updateMedication = async (medicationID) => {
        setLoading(true);
        try {
            let variables = {
                id: medicationID,
                end: moment().format("YYYY-MM-DD")
            };

            variables = Object.entries(variables).reduce(
                (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
                {}
            );

            // console.log(variables);

            const result = await API.graphql({
                query: updateMedication,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables: { update: variables }
            });
            // console.log("API result PatientRecordMedication createMedication", result);

            if (result?.data?.result) {
                _listMedication(props?.patientID);
                _listMedicationHistory(props?.patientID);
                notification.success({
                    message: "Updated successfully"
                });
            }
        } catch (error) {
            // console.log(error);
            notification.error({
                message: "Unable to update record"
            });
        } finally {
            setLoading(false);
            form.resetFields();
        }
    };

    const onFinish = async (values) => {
        // console.log("onFinish clicked", values);
        const v = await form.validateFields();
        _createMedication(v);
    };

    const handleDatePickerChange = (date, dateString) => {
        // console.log("handleDatePickerChange");
        // console.log(date, dateString);
    };

    const handleModalVisibility = (modal, record = {}) => {
        // console.log("modal", modal);
        switch (modal) {
            case "allergy":
                setVisibilityAllergy(!visibilityAllergy);
                break;
            case "historicalMedication":
                setVisibilityHistoricalMedication(
                    !visibilityHistoricalMedication
                );
                break;
            case "viewCurrentMedication":
                setCurrentMedication(record);
                setVisibilityCurrentMedication(!visibilityCurrentMedication);
                break;
            case "addCurrentMedication":
                if (visibilityAddCurrentMedication === false) {
                    form.setFieldsValue({
                        date: moment()
                    });
                }
                setVisibilityAddCurrentMedication(
                    !visibilityAddCurrentMedication
                );
                break;
            default:
        }
    };

    // const columns = [
    //     {
    //         title: "Date",
    //         dataIndex: "date"
    //     },
    //     {
    //         title: "Medication",
    //         dataIndex: "medication"
    //     },
    //     {
    //         title: "Action",
    //         dataIndex: "action"
    //     },
    // ]

    const renderItems = (array) => {
        const items = array.map((item, index) => {
            return (
                <div
                    key={item.id}
                    className={index % 2 === 0 ? "record highlight" : "record"}
                >
                    <div>
                        <span className="clickable">
                            <CopyToClipboard
                                text={
                                    moment(item.date).format("DD MMM YYYY") +
                                    ": " +
                                    item.medication +
                                    ", " +
                                    item.dose +
                                    ", " +
                                    item.route +
                                    ", " +
                                    item.freq
                                }
                                onCopy={() => {
                                    message.success("Copied!");
                                }}
                            >
                                <img src={iconFile} />
                            </CopyToClipboard>
                        </span>
                        &nbsp;&nbsp;&nbsp;
                        {/* {moment(item.date).format("DD MMM YYYY")} */}
                        {moment(item.date).format("DD MMM YYYY")}
                    </div>
                    <div className="value">
                        <div
                            className="medication"
                            style={{ whiteSpace: "pre-wrap" }}
                        >
                            {item.medication}
                        </div>
                    </div>
                    <div className="value">
                        <div
                            className="freq"
                            style={{ whiteSpace: "pre-wrap" }}
                        >
                            {item.dose}
                        </div>
                    </div>
                    <div className="value">
                        <div
                            className="route"
                            style={{ whiteSpace: "pre-wrap" }}
                        >
                            {item.route}
                        </div>
                    </div>
                    <div className="value">
                        <div
                            className="freq"
                            style={{ whiteSpace: "pre-wrap" }}
                        >
                            {item.freq}
                        </div>
                    </div>
                    <div
                        className="clickable"
                        onClick={() => {
                            handleModalVisibility(
                                "viewCurrentMedication",
                                item
                            );
                        }}
                    >
                        <img src={iconEye} />
                    </div>
                    {/* <Button className="circle" htmlType="submit" shape="circle" icon={<MinusOutlined />} /> */}
                    <Popconfirm
                        title="Are you sure?"
                        onConfirm={() => {
                            // console.log("handle delete", item.id);
                            _updateMedication(item.id);
                        }}
                        okText="Yes"
                        cancelText="No"
                    >
                        <div className="clickable">
                            <img src={iconMinus} />
                        </div>
                    </Popconfirm>
                </div>
            );
        });
        return items;
    };

    const renderMedication = (array) => {
        return array.length === 0 ? (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ) : (
            renderItems(array)
        );
    };

    const renderAllergyItems = (array) => {
        const items = array.map((item, index) => {
            return (
                <div
                    key={item.id}
                    className={index % 2 === 0 ? "record highlight" : "record"}
                >
                    <div className="value">
                        <div className="date">
                            <span className="clickable">
                                <CopyToClipboard
                                    text={
                                        moment(item.date).format(
                                            "DD MMM YYYY"
                                        ) +
                                        ": " +
                                        item.allergy
                                    }
                                    onCopy={() => {
                                        message.success("Copied!");
                                    }}
                                >
                                    <img src={iconFile} />
                                </CopyToClipboard>
                            </span>
                            &nbsp;&nbsp;&nbsp;
                            {moment(item.date).format("DD MMM YYYY")}
                        </div>
                        <div className="name">{item.allergy}</div>
                    </div>
                </div>
            );
        });
        return items;
    };

    const renderAllergy = (array) => {
        return array.length === 0 ? (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ) : (
            renderAllergyItems(array)
        );
    };

    const renderHistoricalMedicationItems = (array) => {
        const items = array.map((item, index) => {
            // console.log("item", item);
            return (
                <div
                    key={item.id}
                    className={index % 2 === 0 ? "record highlight" : "record"}
                >
                    <div className="value">
                        <div className="date">
                            <span className="clickable">
                                <CopyToClipboard
                                    text={
                                        moment(item.dateStart).format(
                                            "DD MMM YYYY"
                                        ) +
                                        " - " +
                                        moment(item.dateEnd).format(
                                            "DD MMM YYYY"
                                        ) +
                                        ": " +
                                        item.medication +
                                        ", " +
                                        item.dose +
                                        ", " +
                                        item.route +
                                        ", " +
                                        item.freq
                                    }
                                    onCopy={() => {
                                        message.success("Copied!");
                                    }}
                                >
                                    <img src={iconFile} />
                                </CopyToClipboard>
                            </span>
                            &nbsp;&nbsp;&nbsp;
                            {moment(item.dateStart).format("DD MMM YYYY")}
                        </div>
                        <div className="date">
                            {moment(item.dateEnd).format("DD MMM YYYY")}
                        </div>
                        <div className="name">{item.medication}</div>
                        <div className="date">{item.dose}</div>
                        <div className="date">{item.route}</div>
                        <div className="date">{item.freq}</div>
                    </div>
                </div>
            );
        });
        return items;
    };

    const renderHistoricalMedication = (array) => {
        return array.length === 0 ? (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ) : (
            <div>
                <div className="record-header">
                    <div className="date">Start Date</div>
                    <div className="date">End Date</div>
                    <div className="medication">Medication</div>
                    <div className="date">Dose</div>
                    <div className="date">Route</div>
                    <div className="date">Frequency</div>
                </div>
                {renderHistoricalMedicationItems(array)}
            </div>
        );
    };

    const renderListOptions = (
        array,
        key = "id",
        value = "id",
        name = "name"
    ) => {
        if (typeof array == "object" && array.length !== 0) {
            const listOptions = array.map((item, index) => {
                return (
                    <Option key={item[key]} value={item[value]}>
                        {item[name]}
                    </Option>
                );
            });

            return listOptions;
        } else {
            return <div />;
        }
    };

    const handleTableChange = (paginate) => {
        // console.log(paginate);
        _listMedication(props?.patientID, {
            ...tableMedication.pagination,
            current: paginate.current,
            offset:
                paginate.current * tableMedication.pagination.pageSize -
                tableMedication.pagination.pageSize
        });
    };

    const handleTableHistoryChange = (paginate) => {
        // console.log(paginate);
        _listMedicationHistory(props?.patientID, {
            ...tableMedicationHistory.pagination,
            current: paginate,
            offset:
                paginate * tableMedicationHistory.pagination.pageSize -
                tableMedicationHistory.pagination.pageSize
        });
    };

    const columns = [
        {
            title: "Start Date",
            dataIndex: "date",
            width: 150,
            render: (text, record) => {
                return (
                    <div>
                        <span className="clickable">
                            <CopyToClipboard
                                text={
                                    moment(record.date).format("DD MMM YYYY") +
                                    ": " +
                                    record.medication +
                                    ", " +
                                    record.dose +
                                    ", " +
                                    record.route +
                                    ", " +
                                    record.freq
                                }
                                onCopy={() => {
                                    message.success("Copied!");
                                }}
                            >
                                <img src={iconFile} />
                            </CopyToClipboard>
                        </span>
                        &nbsp;&nbsp;&nbsp;
                        {moment(record.date).format("DD MMM YYYY")}
                    </div>
                );
            }
        },
        {
            title: "Medication",
            dataIndex: "medication"
            // width: 500,
            // ellipsis: true,
        },
        {
            title: "Dose",
            dataIndex: "dose"
        },
        {
            title: "Route",
            dataIndex: "route"
        },
        {
            title: "Frequency",
            dataIndex: "freq"
        },
        {
            title: "",
            dataIndex: "action",
            width: 50,
            render: (_, record) => {
                return (
                    <div
                        className="clickable"
                        onClick={() => {
                            handleModalVisibility(
                                "viewCurrentMedication",
                                record
                            );
                        }}
                    >
                        <img src={iconEye} />
                    </div>
                );
            }
        },
        {
            title: "",
            dataIndex: "action",
            width: 50,
            render: (_, record) => {
                return (
                    <Popconfirm
                        title="Are you sure?"
                        onConfirm={() => {
                            // console.log("handle delete", item.id);
                            _updateMedication(record.id);
                        }}
                        okText="Yes"
                        cancelText="No"
                    >
                        <div className="clickable">
                            <img src={iconMinus} />
                        </div>
                    </Popconfirm>
                );
            }
        }
    ];

    return (
        <div className="patientrecord-medication">
            <div className="container-title">
                <div className="title">Current Medication</div>
                <Button
                    type="primary"
                    onClick={() =>
                        handleModalVisibility("addCurrentMedication")
                    }
                >
                    <PlusOutlined /> Add Medication
                </Button>
                <Button
                    type="primary"
                    onClick={() =>
                        handleModalVisibility("historicalMedication")
                    }
                >
                    See Historical
                </Button>
            </div>
            <Spin spinning={tableMedication.loading}>
                {/* <div className="card">
                    <div className="card-header medicalmilestone">
                        <div className="date2">
                            Start Date
                        </div>
                        <div className="medication">
                            Medication
                        </div>
                        <div className="route">
                            Dose
                        </div>
                        <div className="route">
                            Route
                        </div>
                        <div className="freq">
                            Frequency
                        </div>
                    </div>
                    <div className="card-content">
                        <div className="record-content">
                            {renderMedication(medication)}
                        </div>
                    </div>
                    <Pagination
                        current={tableMedication.pagination.current}
                        pageSize={tableMedication.pagination.pageSize}
                        total={tableMedication.pagination.total}
                        onChange={handleTableChange}
                        showSizeChanger={false}
                    // onShowSizeChange={onShowSizeChange}
                    />
                </div> */}
                <Table
                    // className="card"
                    columns={columns}
                    rowKey={(record) => record.id}
                    rowClassName={(record, index) => {
                        return index % 2 === 0 ? "highlight" : "";
                    }}
                    dataSource={medication}
                    loading={tableMedication.loading}
                    pagination={
                        tableMedication.data?.length > 0
                            ? {
                                  ...tableMedication.pagination,
                                  position: ["bottomLeft"]
                              }
                            : false
                    }
                    onChange={handleTableChange}
                />
            </Spin>
            {/* <Modal
                title={
                    <div className="modal-title">
                        Drug Allergy
                    </div>
                }
                visible={visibilityAllergy}
                onCancel={() => handleModalVisibility("allergy")}
                footer={[
                    <Button key="back" onClick={() => handleModalVisibility("allergy")}>
                        Cancel
                    </Button>,
                ]}
                className="modal-patientrecord-medlabmilestone"
                width={600}
            >
                <div className="modal-content">
                    {renderAllergy(allergy)}
                </div>
            </Modal> */}
            <Modal
                title={<div className="modal-title">Historical Medication</div>}
                visible={visibilityHistoricalMedication}
                onCancel={() => handleModalVisibility("historicalMedication")}
                footer={[
                    <Button
                        key="back"
                        onClick={() =>
                            handleModalVisibility("historicalMedication")
                        }
                    >
                        Cancel
                    </Button>
                ]}
                className="modal-patientrecord-medlabmilestone"
                width={1000}
            >
                <Spin spinning={tableMedicationHistory.loading}>
                    <div className="modal-content">
                        {renderHistoricalMedication(medicationHistory)}
                    </div>
                    <Pagination
                        current={tableMedicationHistory.pagination.current}
                        pageSize={tableMedicationHistory.pagination.pageSize}
                        total={tableMedicationHistory.pagination.total}
                        onChange={handleTableHistoryChange}
                        showSizeChanger={false}
                        // onShowSizeChange={onShowSizeChange}
                    />
                </Spin>
            </Modal>
            <Modal
                title={
                    <div className="modal-title">Add Current Medication</div>
                }
                visible={visibilityAddCurrentMedication}
                onCancel={() => handleModalVisibility("addCurrentMedication")}
                footer={[
                    <Button
                        key="back"
                        onClick={() =>
                            handleModalVisibility("addCurrentMedication")
                        }
                    >
                        Cancel
                    </Button>,
                    <Popconfirm
                        title="Are you sure?"
                        onConfirm={() => {
                            form.submit();
                        }}
                    >
                        <Button key="submit" type="primary" loading={loading}>
                            Submit
                        </Button>
                    </Popconfirm>
                ]}
                className="modal-patientrecord-medlabmilestone"
                width={800}
            >
                <Spin spinning={loading}>
                    <div className="modal-content">
                        <Form
                            className="addmedicalmilestone"
                            form={form}
                            layout="horizontal"
                            onFinish={onFinish}
                            validateMessages={validateMessages}
                            requiredMark={false}
                        >
                            <Form.Item
                                label="Start Date"
                                name="date"
                                rules={[
                                    {
                                        required: true
                                    }
                                ]}
                            >
                                <DatePicker
                                    onChange={handleDatePickerChange}
                                    format={dateFormatList}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Medication"
                                name="medication"
                                rules={[
                                    {
                                        required: true
                                    }
                                ]}
                            >
                                <Select
                                    showSearch={true}
                                    placeholder="Select a medication"
                                    optionFilterProp="children"
                                    // onChange={onChange}
                                    // onSearch={onSearch}
                                    filterOption={(input, option) =>
                                        option?.children
                                            ?.toLowerCase()
                                            .indexOf(input?.toLowerCase()) >= 0
                                    }
                                >
                                    {appState.drugList !== -1
                                        ? renderListOptions(
                                              appState.drugList,
                                              "name",
                                              "name",
                                              "name"
                                          )
                                        : null}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="Dose"
                                name="dose"
                                rules={[
                                    {
                                        required: true
                                    }
                                ]}
                            >
                                <Input
                                    autoComplete="off"
                                    placeholder="Enter dose"
                                    allowClear
                                />
                            </Form.Item>
                            <Form.Item
                                label="Route"
                                name="route"
                                rules={[
                                    {
                                        required: true
                                    }
                                ]}
                            >
                                <Select
                                    showSearch={true}
                                    placeholder="Select a route"
                                    optionFilterProp="children"
                                    // onChange={onChange}
                                    // onSearch={onSearch}
                                    filterOption={(input, option) =>
                                        option?.children
                                            ?.toLowerCase()
                                            .indexOf(input?.toLowerCase()) >= 0
                                    }
                                >
                                    {renderListOptions(
                                        routeList,
                                        "id",
                                        "id",
                                        "value"
                                    )}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="Frequency"
                                name="frequency"
                                rules={[
                                    {
                                        required: true
                                    }
                                ]}
                            >
                                <Select
                                    showSearch={true}
                                    placeholder="Select a frequency"
                                    optionFilterProp="children"
                                    // onChange={onChange}
                                    // onSearch={onSearch}
                                    filterOption={(input, option) =>
                                        option?.children
                                            ?.toLowerCase()
                                            .indexOf(input?.toLowerCase()) >= 0
                                    }
                                >
                                    {renderListOptions(
                                        freqList,
                                        "id",
                                        "id",
                                        "value"
                                    )}
                                </Select>
                            </Form.Item>
                        </Form>
                    </div>
                </Spin>
            </Modal>
            <Modal
                title={
                    <div className="modal-title">View Current Medication</div>
                }
                visible={visibilityCurrentMedication}
                onCancel={() => handleModalVisibility("viewCurrentMedication")}
                footer={[
                    <Button
                        key="back"
                        onClick={() =>
                            handleModalVisibility("viewCurrentMedication")
                        }
                    >
                        Cancel
                    </Button>
                ]}
                className="modal-patientrecord-medlabmilestone"
                width={800}
            >
                <div className="modal-content">
                    <div className="medicalmilestone">
                        <div className="row1">
                            <div className="label">Start Date:</div>
                            <div className="value">
                                {moment(currentMedication.dateStart).format(
                                    "DD MMM YYYY"
                                )}
                            </div>
                        </div>
                        <div className="row1">
                            <div className="label">Medication:</div>
                            <div className="value">
                                {currentMedication.medication}
                            </div>
                        </div>
                        <div className="row1">
                            <div className="label">Dose:</div>
                            <div className="value">
                                {currentMedication.dose}
                            </div>
                        </div>
                        <div className="row1">
                            <div className="label">Route:</div>
                            <div className="value">
                                {currentMedication.route}
                            </div>
                        </div>
                        <div className="row1">
                            <div className="label">Frequency:</div>
                            <div className="value">
                                {currentMedication.freq}
                            </div>
                        </div>
                    </div>
                </div>
                {/* <Spin spinning={loading}>
                            <div className="modal-content">
                                <Form
                                    className="addmedicalmilestone"
                                    form={form}
                                    layout="horizontal"
                                    onFinish={onFinish}
                                    validateMessages={validateMessages}
                                    requiredMark={false}
                                >
                                    <Form.Item
                                        name="date"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <DatePicker onChange={handleDatePickerChange} format={dateFormatList} />
                                    </Form.Item>
                                    <Form.Item
                                        name="medication"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Select style={{ width: 180 }}
                                            showSearch={true}
                                            placeholder="Select a medication"
                                            optionFilterProp="children"
                                            // onChange={onChange}
                                            // onSearch={onSearch}
                                            filterOption={(input, option) =>
                                                option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
                                            }
                                        >
                                            {appState.drugList !== -1 ? renderListOptions(appState.drugList, 'name', 'name', 'name') : null}
                                        </Select>
                                    </Form.Item>
                                </Form>
                            </div>
                        </Spin> */}
            </Modal>
        </div>
    );
};

export default PatientRecordMedication;
