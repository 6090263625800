import React, { useEffect, useState, useContext } from "react";
import {
    Avatar,
    Button,
    Checkbox,
    DatePicker,
    Descriptions,
    Divider,
    Empty,
    Form,
    Input,
    message,
    Modal,
    notification,
    Popconfirm,
    Radio,
    Select,
    Spin,
    TreeSelect,
    Upload
} from "antd";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { UploadOutlined, UserOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { API, Storage } from "aws-amplify";
import moment from "moment";

import path from "../utils/pathSettings";
import renderInputItems from "../utils/renderFormInputItems";
import { listPatientAndAllergy } from "../backend/graphql/custom_queries";
import { createAllergy, updateAllergy } from "../backend/graphql/mutations";
import { updatePatient } from "../backend/graphql/mutations";
import { checkImageTypeValidity } from "../utils/fileTypeCheck";
import { AppContext } from "../contexts/AppContext";

import iconAdd from "../media/Icon-Add.png";
import iconMinus from "../media/Icon-Minus.png";
import iconFile from "../media/Icon-File2.png";
import { compressImage } from "../utils/compressImage";
import { referringSource } from "../utils/constants";

const PatientInformationEdit = (props) => {
    const [form] = Form.useForm();
    const [form2] = Form.useForm();
    const { appState, getS3 } = useContext(AppContext);
    const patientID = props?.match?.params?.id;
    const { Option } = Select;
    const { TextArea } = Input;
    const { TreeNode } = TreeSelect;
    const [values, setValues] = useState({});
    const dateFormatList = ["DD MMM YYYY"];
    const disabledDate = (current) => {
        // Cannot select days next day onwards
        return current > moment().endOf("day");
    };
    const validateMessages = {
        required: "This field is required.",
        types: {
            email: "This is not a valid email."
        }
    };
    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };
    const uploadButton = (
        <div>
            <Button icon={<UploadOutlined />}>Upload</Button>
        </div>
    );
    const [state, setState] = useState({
        fileList: []
    });
    const [loading, setLoading] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false);
    const history = useHistory();
    const [visibilityAllergy, setVisibilityAllergy] = useState(false);

    const uploadFile = async (file) => {
        // console.log(file);

        const fileName =
            "p_" +
            patientID +
            "_" +
            moment().utc().format("YYYY-MM-DD_HHmmss") +
            "_" +
            file.name;
        const fileType = file.type;

        try {
            const result = await Storage.put("patient/" + fileName, file, {
                contentType: fileType
            });
            // console.log(result, result.key);
            return result.key;
        } catch (error) {
            console.log("error: ", error);
            notification.error({
                message: error
            });
        }
    };

    useEffect(() => {
        if (
            Object.keys(appState.cognitoUser).length !== 0 &&
            appState.fixedListLoaded === true
        ) {
            // console.log("appState", appState);
            _listPatientAndAllergy(patientID);
        }
        return () => {};
    }, [appState.cognitoUser, appState.fixedListLoaded]);

    useEffect(() => {
        // console.log("values", values);
        form.setFieldsValue(values);
    }, [values]);

    const onFinishEditPatient = async (values) => {
        // console.log("save", values);
        let boolUpdate = false;
        let imageKey = "";

        // check for image upload
        if (state.fileList.length > 0) {
            const file = state.fileList[0].originFileObj;
            const typeType = file.type;

            if (checkImageTypeValidity(typeType) === true) {
                let _file = await compressImage(file);
                imageKey = (await uploadFile(_file)) ?? "";
                boolUpdate = true;
            } else {
                notification.error({
                    message: "Invalid image type. Please reattach image."
                });
            }
        } else {
            boolUpdate = true;
        }

        if (boolUpdate === true) updatePatiendInformation(values, imageKey);
    };

    const onFinishCreateAllergy = async (values) => {
        // console.log("values", values);
        await form2.validateFields();
        _createAllergy(values, patientID);
    };

    const handleModalVisibility = (modal) => {
        // console.log("modal", modal);
        switch (modal) {
            case "allergy":
                setVisibilityAllergy(!visibilityAllergy);
                break;
            default:
        }
    };

    const _createAllergy = async (_values, patientID) => {
        setLoadingModal(true);
        try {
            let variables = {
                name: _values.allergy,
                patientID: patientID,
                knownDate: moment().toISOString().split(".")[0]
            };

            variables = Object.entries(variables).reduce(
                (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
                {}
            );

            // console.log(variables);

            const result = await API.graphql({
                query: createAllergy,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables: { create: variables }
            });
            // console.log("API result PatientDetailsSummary createAllergy", result);

            if (result?.data?.result) {
                // _listPatientAndAllergy(patientID)
                let array =
                    values.allergies !== undefined ? [...values.allergies] : [];
                array.push({
                    allergy: result.data.result.name,
                    date: result.data.result.knownDate,
                    id: result.data.result.id,
                    patientID: result.data.result.patientID
                });
                setValues({
                    ...values,
                    allergies: array
                });
                form2.resetFields();
                notification.success({
                    message: "Created successfully"
                });
            }
        } catch (error) {
            // console.log(error);
            notification.error({
                message: "Unable to create record"
            });
        } finally {
            setLoadingModal(false);
        }
    };

    const _updateAllergy = async (allergyID) => {
        setLoadingModal(true);
        try {
            let variables = {
                id: allergyID,
                deleted: 1
            };

            variables = Object.entries(variables).reduce(
                (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
                {}
            );

            // console.log(variables);

            const result = await API.graphql({
                query: updateAllergy,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables: { update: variables }
            });
            // console.log("API result PatientDetailsSummary updateAllergy", result);
            // console.log("API result PatientDetailsSummary updateAllergy", result.data.result.id);

            if (result?.data?.result) {
                // _listPatient(props?.patientID);
                let array = [...values.allergies];
                array.filter((item) => item.id !== result.data.result.id);
                setValues({
                    ...values,
                    allergies: array.filter(
                        (item) => item.id !== result.data.result.id
                    )
                });
                notification.success({
                    message: "Deleted successfully"
                });
            }
        } catch (error) {
            // console.log(error);
            notification.error({
                message: "Unable to update record"
            });
        } finally {
            setLoadingModal(false);
        }
    };

    const _listPatientAndAllergy = async (patientID) => {
        setLoading(true);
        try {
            const variables = {
                filter: {
                    id: { eq: patientID }
                },
                filter2: {
                    patientID: { eq: patientID },
                    deleted: { eq: 0 }
                },
                pagination: {
                    limit: 1
                },
                pagination2: {},
                unmasked: 1
            };

            // console.log('PatientInformation', variables);

            const result = await API.graphql({
                query: listPatientAndAllergy,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables
            });
            // console.log("API result", result);
            const data = result.data.result;
            const array = data.result;

            // process data
            if (array && array.length > 0) {
                let v = array[0];
                let _patient = {
                    ...v,
                    // existingClinic: v.existingClinicID ? (appState.clinicList.filter(s => s.id == v.existingClinicID))[0].name : '',
                    existingClinic: v.existingClinicID,
                    // active: v.status == 'ACTIVE',
                    status: v.status,
                    amputeeYes: v.amputee,
                    // amputee: v.amputee?.length > 0 ? 1 : 0,
                    vwoSupportYes: v.vwoSupport,
                    vwoSupport: v?.vwoSupport?.length > 0 ? 1 : 0,
                    // firstDialysisDisplay: v.firstDialysis ? moment(v.firstDialysis).local() : null,
                    dobDisplay: v.dob ? moment(v.dob).local() : null,
                    dob: v.dob ? moment(v.dob).local() : null,
                    // firstDialysis: v.firstDialysis ? moment(v.firstDialysis).local() : null,
                    firstDialysisHospitalDate: v.firstDialysisHospitalDate
                        ? moment(v.firstDialysisHospitalDate)
                        : null,
                    referringSourceEnd: v.referringSourceEnd
                        ? moment.utc(v.referringSourceEnd).local()
                        : null,
                    referringSourceStart: v.referringSourceStart
                        ? moment.utc(v.referringSourceStart).local()
                        : null,
                    DESRD: v.DESRD ? moment(v.DESRD) : null,
                    height: v.height ? v.height / 100.0 : "",
                    weight: v.weight ? v.weight / 1000.0 : "",
                    wheelchairWeight: v.wheelchairWeight
                        ? v.wheelchairWeight / 1000.0
                        : "",
                    gender:
                        v.gender == "M"
                            ? "Male"
                            : v.gender == "F"
                            ? "Female"
                            : "Others"
                };
                _patient.bmi =
                    _patient.height && _patient.weight
                        ? (
                              _patient.weight /
                              (_patient.height * _patient.height)
                          ).toFixed(2)
                        : "";

                let imageKey = v.key ?? "";
                if (imageKey !== "") {
                    _patient.profilePic = await Storage.get(imageKey);
                } else {
                    _patient.profilePic = "";
                }

                if (
                    result?.data?.result2?.result &&
                    result?.data?.result2?.result?.length > 0
                ) {
                    let allergies = result?.data?.result2?.result;
                    // console.log(allergies)
                    // _patient.allergies = allergies.map(s => s.name).join(', ');

                    let _allergy = [];
                    for (let v of result?.data?.result2?.result) {
                        _allergy.push({
                            id: v.id,
                            patientID: v.patientID,
                            allergy: v.name,
                            date: v.knownDate
                        });
                    }
                    _patient.allergies = _allergy;
                }

                setValues(_patient);
            } else {
                notification.error({ message: "Invalid Patient" });
                history.push(path("dashboard"));
            }

            setLoading(false);
        } catch (error) {
            console.log("error: ", error);
        } finally {
        }
    };

    const updatePatiendInformation = async (values, imageKey) => {
        // console.log("values", values);
        setLoading(true);
        try {
            let variables = {
                id: patientID,
                ...values,
                dob:
                    values.dob !== null &&
                    values.dob !== undefined &&
                    values.dob !== ""
                        ? moment(values.dob).format("YYYY-MM-DD")
                        : null,
                // firstDialysis: values.firstDialysis !== null && values.firstDialysis !== undefined && values.firstDialysis !== "" ? moment(values.firstDialysis).format("YYYY-MM-DD") : null,
                // amputee: values.amputeeYes,
                vwoSupport: values.vwoSupportYes,
                height: values.height ? parseFloat(values.height) * 100 : null,
                weight: values.weight ? parseFloat(values.weight) * 1000 : null,
                wheelchairWeight: values.wheelchairWeight
                    ? parseFloat(values.wheelchairWeight) * 1000
                    : null,
                // status: values.active ? 'ACTIVE' : 'INACTIVE',
                status: values.status,
                gender:
                    values.gender == "Male"
                        ? "M"
                        : values.gender == "Female"
                        ? "F"
                        : "O",
                firstDialysisHospitalDate: values.firstDialysisHospitalDate
                    ? moment(values.firstDialysisHospitalDate)
                          .toISOString()
                          .split(".")[0]
                    : null,
                referringSourceEnd: values.referringSourceEnd
                    ? moment(values.referringSourceEnd)
                          .toISOString()
                          .split(".")[0]
                    : null,
                referringSourceStart: values.referringSourceStart
                    ? moment(values.referringSourceStart)
                          .toISOString()
                          .split(".")[0]
                    : null,
                DESRD: values.DESRD ? values.DESRD.format("YYYY-MM-DD") : null,
                existingClinicID: values.existingClinic
                    ? parseInt(values.existingClinic)
                    : null
            };
            if (imageKey !== "") {
                let s3 = getS3();
                variables = {
                    ...variables,
                    key: imageKey,
                    bucket: s3?.bucket,
                    region: s3?.region
                };
            }
            variables.existingClinic = null;
            // variables.active = null
            variables.amputeeYes = null;
            variables.vwoSupportYes = null;
            variables.allergies = null;
            variables.bmi = null;
            variables = Object.entries(variables).reduce(
                (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
                {}
            );
            // console.log("variables", variables);

            const result = await API.graphql({
                query: updatePatient,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables: { update: variables }
            });
            // console.log("result", result);

            if (result?.data?.result) {
                notification.success({
                    message: "Updated successfully"
                });
                history.push(path("patientInformation", [patientID]));
            }
        } catch (error) {
            // console.log(error);
            notification.error({
                message: "Unable to update record"
            });
        } finally {
            setLoading(false);
        }
    };

    const handleCancel = () => {
        setState({ ...state, previewVisible: false });
    };

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        setState({
            ...state,
            previewImage: file.url || file.preview,
            previewVisible: true,
            previewTitle:
                file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
        });
    };

    const handleChange = (file) => {
        // console.log(file);
        // console.log(optionNum);
        let { fileList } = file;
        // fileList = fileList.slice(-1)
        setState({ ...state, fileList: fileList });
    };

    const handleDatePickerChange = (date, dateString) => {
        // console.log(date, dateString);
    };

    // const renderListOptions = (array, key = "id", value = "id", name = "name") => {
    //     if (typeof (array) == 'object' && array.length !== 0) {
    //         const listOptions = array.map((item, index) => {
    //             return (
    //                 <Option key={item[key]} value={item[value]}>{item[name]}</Option>
    //             )
    //         })

    //         return listOptions
    //     }
    //     else {
    //         return (
    //             <div />
    //         )
    //     }
    // }

    // const renderTreeSelectOptions = (array) => {
    //     if (typeof (array) == 'object' && array.length !== 0) {
    //         const listOptions = array.map((item, index) => {
    //             return (
    //                 <TreeNode title={item.name} value={item.id} key={item.id} />
    //             )
    //         })

    //         return listOptions
    //     }
    //     else {
    //         return (
    //             ""
    //         )
    //     }
    // }

    // const renderInputItems = (array) => {
    //     const items = array.map((item, index) => {
    //         // console.log("item", item);
    //         switch (item.type) {
    //             case "select":
    //                 return (
    //                     <Form.Item
    //                         key={index}
    //                         label={item.label}
    //                         name={item.name}
    //                         rules={[
    //                             {
    //                                 required: item.required === true ? true : false
    //                             }
    //                         ]}
    //                         style={item.width !== undefined ? { width: item.width } : {}}
    //                     >
    //                         <Select
    //                             showSearch
    //                             placeholder={item.placeholder}
    //                             filterOption={(input, option) =>
    //                                 option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
    //                             }
    //                             disabled={item.disabled === true ? true : false}
    //                         // defaultValue={0}
    //                         >
    //                             {renderListOptions(item.optionList)}
    //                         </Select>
    //                     </Form.Item>
    //                 )
    //             case "treeSelect":
    //                 return (
    //                     <Form.Item
    //                         key={index}
    //                         label={item.label}
    //                         name={item.name}
    //                         rules={[
    //                             {
    //                                 required: item.required === true ? true : false
    //                             }
    //                         ]}
    //                         style={item.width !== undefined ? { width: item.width } : {}}
    //                     >
    //                         <TreeSelect
    //                             showSearch
    //                             allowClear
    //                             multiple
    //                             treeDefaultExpandAll
    //                             treeCheckable
    //                             placeholder="Select symptoms"
    //                             filterTreeNode={(input, treenode) =>
    //                                 treenode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
    //                             }
    //                         >
    //                             {renderTreeSelectOptions(item.optionList)}
    //                         </TreeSelect>
    //                     </Form.Item>
    //                 )
    //             case "input":
    //                 return (
    //                     <Form.Item
    //                         key={index}
    //                         label={item.label}
    //                         name={item.name}
    //                         rules={[
    //                             {
    //                                 required: item.required === true ? true : false
    //                             },
    //                             {
    //                                 message: item.regexMessage,
    //                                 pattern: item.regex
    //                             }
    //                         ]}
    //                         style={item.width !== undefined ? { width: item.width } : {}}
    //                     >
    //                         <Input placeholder={item.placeholder} disabled={item.disabled === true ? true : false} autoComplete="off" />
    //                     </Form.Item>
    //                 )
    //             case "email":
    //                 return (
    //                     <Form.Item
    //                         key={index}
    //                         label={item.label}
    //                         name={item.name}
    //                         rules={[
    //                             {
    //                                 type: "email",
    //                                 required: item.required === true ? true : false
    //                             }
    //                         ]}
    //                         style={item.width !== undefined ? { width: item.width } : {}}
    //                     >
    //                         <Input placeholder={item.placeholder} disabled={item.disabled === true ? true : false} autoComplete="off" />
    //                     </Form.Item>
    //                 )
    //             case "text":
    //                 return (
    //                     <Form.Item
    //                         key={index}
    //                         label={item.label}
    //                         name={item.name}
    //                         rules={[
    //                             {
    //                                 required: item.required === true ? true : false
    //                             }
    //                         ]}
    //                         style={{ width: "100%" }}
    //                     >
    //                         <TextArea rows={item.rows} placeholder={item.placeholder} disabled={item.disabled === true ? true : false} />
    //                     </Form.Item>
    //                 )
    //             case "datepicker":
    //                 return (
    //                     <Form.Item
    //                         key={index}
    //                         label={item.label}
    //                         name={item.name}
    //                         rules={[
    //                             {
    //                                 required: item.required === true ? true : false
    //                             }
    //                         ]}
    //                     >
    //                         <DatePicker onChange={handleDatePickerChange} format={dateFormatList} disabledDate={disabledDate} disabled={item.disabled === true ? true : false} />
    //                     </Form.Item>
    //                 )
    //             case "checkbox":
    //                 return (
    //                     <Form.Item
    //                         key={index}
    //                         label={item.label}
    //                     >
    //                         <Form.Item
    //                             name={item.name}
    //                             valuePropName="checked"
    //                             noStyle
    //                         >
    //                             <Checkbox disabled={item.disabled === true ? true : false} />
    //                         </Form.Item>
    //                     </Form.Item>
    //                 )
    //             case "radio":
    //                 return (
    //                     <Form.Item
    //                         key={index}
    //                         label={item.label}
    //                         name={item.name}
    //                         rules={[
    //                             {
    //                                 required: item.required === true ? true : false
    //                             }
    //                         ]}
    //                     >
    //                         <Radio.Group
    //                             options={item.optionList}
    //                             defaultValue={item.defaultValue}
    //                             disabled={item.disabled === true ? true : false}
    //                         />
    //                     </Form.Item>
    //                 )
    //             case "space":
    //                 return (
    //                     <Form.Item key={index}>&nbsp;</Form.Item>
    //                 )
    //             case "none":
    //                 return (
    //                     <Form.Item key={index} style={{ display: "none" }}></Form.Item>
    //                 )
    //             default:
    //         }
    //     })
    //     return items
    // }

    const renderAllergyItems = (array) => {
        // console.log("array", array);
        const items = array.map((item, index) => {
            return (
                <div
                    key={item.id}
                    className={index % 2 === 0 ? "record highlight" : "record"}
                >
                    <div className="value">
                        <div className="date">
                            <span className="clickable">
                                <CopyToClipboard
                                    text={
                                        moment(item.date).format(
                                            "DD MMM YYYY"
                                        ) +
                                        ": " +
                                        item.allergy
                                    }
                                    onCopy={() => {
                                        message.success("Copied!");
                                    }}
                                >
                                    <img src={iconFile} />
                                </CopyToClipboard>
                            </span>
                            &nbsp;&nbsp;&nbsp;
                            {item.date
                                ? moment(item.date).format("DD MMM YYYY")
                                : "-"}
                        </div>
                        <div className="name">{item.allergy}</div>
                    </div>
                    <Popconfirm
                        title="Are you sure?"
                        onConfirm={() => {
                            // console.log("handle delete", item.id);
                            _updateAllergy(item.id);
                        }}
                        okText="Yes"
                        cancelText="No"
                    >
                        <div className="clickable">
                            <img src={iconMinus} />
                        </div>
                    </Popconfirm>
                </div>
            );
        });
        return items;
    };

    const renderAllergy = (array) => {
        return array.length === 0 ? (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ) : (
            renderAllergyItems(array)
        );
    };

    const renderAllergyTextDisplay = (array) => {
        let text = "";
        array.forEach((item, index) => {
            if (index > 0) {
                text += ", " + array[index].allergy;
            } else {
                text = array[index].allergy;
            }
        });
        return text;
    };

    return (
        <div className="content-content">
            <div className="container-row">
                <Spin spinning={loading}>
                    <div className="patientinformation-header">
                        <div className="column1 title">
                            Edit Patient Information -{" "}
                            <span className="patient">
                                {values.name} ({values.nric})
                            </span>
                        </div>
                        <div className="column2">
                            <Button>
                                <Link
                                    to={path("patientInformation", [
                                        props.match.params.id
                                    ])}
                                >
                                    Cancel
                                </Link>
                            </Button>
                            {/* <Button type="primary" onClick={() => { updatePatiendInformation(form.getFieldsValue()) }}> */}
                            <Button
                                type="primary"
                                onClick={() =>
                                    onFinishEditPatient(form.getFieldsValue())
                                }
                            >
                                Save
                            </Button>
                        </div>
                    </div>
                    <Form
                        form={form}
                        layout="horizontal"
                        validateMessages={validateMessages}
                        requiredMark={true}
                    >
                        <div className="patientinformation-content edit">
                            <div className="column">
                                <div className="row1">
                                    <div className="container-profilepic">
                                        <div>
                                            <Avatar
                                                className="avatar"
                                                icon={<UserOutlined />}
                                                shape="square"
                                                src={values.profilePic}
                                            />
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                paddingTop: "16px"
                                            }}
                                        >
                                            <Form.Item>
                                                <Upload
                                                    action={getBase64}
                                                    listType="picture"
                                                    fileList={state.fileList}
                                                    onPreview={handlePreview}
                                                    onChange={(file) =>
                                                        handleChange(file)
                                                    }
                                                >
                                                    {state.fileList.length >= 1
                                                        ? null
                                                        : uploadButton}
                                                </Upload>
                                                <Modal
                                                    visible={
                                                        state.previewVisible
                                                    }
                                                    title={state.previewTitle}
                                                    footer={null}
                                                    onCancel={handleCancel}
                                                >
                                                    <img
                                                        alt="upload preview"
                                                        style={{
                                                            width: "100%"
                                                        }}
                                                        src={state.previewImage}
                                                    />
                                                </Modal>
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className="container-ddl">
                                        <Descriptions
                                            title={"Dialysis Details"}
                                        />
                                        {renderInputItems([
                                            // {
                                            //     label: "Dialysis Start Date",
                                            //     name: "firstDialysis",
                                            //     type: "datepicker",
                                            //     // required: true,
                                            // },
                                            {
                                                label: "Referring Doctor",
                                                name: "referrer",
                                                type: "input",
                                                placeholder:
                                                    "Input referring doctor"
                                            },
                                            {
                                                label: "Referring Source",
                                                name: "referringSource",
                                                type: "select",
                                                placeholder:
                                                    "Select referring source",
                                                optionList: referringSource
                                            },
                                            {
                                                label: "Referring Source Start Date",
                                                name: "referringSourceStart",
                                                type: "datepicker"
                                                // required: true,
                                            },
                                            {
                                                label: "Referring Source End Date",
                                                name: "referringSourceEnd",
                                                type: "datepicker"
                                                // required: true,
                                            },
                                            {
                                                label: "Dialysis Centre",
                                                name: "existingClinic",
                                                type: "select",
                                                placeholder:
                                                    "Select dialysis centre",
                                                optionList: appState.clinicList,
                                                required: true
                                            },
                                            // {
                                            //     label: "Current Dialysis Centre Start Date",
                                            //     name: "firstDialysis",
                                            //     type: "datepicker",
                                            //     // required: true,
                                            // },
                                            // {
                                            //     label: "Active",
                                            //     name: "active",
                                            //     type: "checkbox",
                                            // },
                                            {
                                                label: "Status",
                                                name: "status",
                                                type: "select",
                                                placeholder:
                                                    "Select status type",
                                                // required: true,
                                                optionList: [
                                                    {
                                                        id: "DIALYSIS",
                                                        name: "Dialysis"
                                                    },
                                                    {
                                                        id: "CLINIC",
                                                        name: "Clinic"
                                                    },
                                                    {
                                                        id: "INACTIVE",
                                                        name: "Inactive"
                                                    }
                                                ]
                                            },
                                            {
                                                label: "Travel Type",
                                                name: "travelType",
                                                type: "select",
                                                placeholder:
                                                    "Select travel type",
                                                // required: true,
                                                optionList: [
                                                    {
                                                        id: "Car Private",
                                                        name: "Car Private"
                                                    },
                                                    {
                                                        id: "Car Hire",
                                                        name: "Car Hire"
                                                    },
                                                    { id: "Bus", name: "Bus" },
                                                    {
                                                        id: "Walk",
                                                        name: "Walk"
                                                    },
                                                    {
                                                        id: "Ambulance",
                                                        name: "Ambulance"
                                                    }
                                                ]
                                            }
                                        ])}
                                    </div>
                                </div>
                                <Divider />
                                <div className="row2">
                                    <Descriptions
                                        title={"Personal Particulars"}
                                    />
                                    {renderInputItems([
                                        {
                                            label: "Full Name",
                                            name: "name",
                                            type: "input",
                                            placeholder: "Enter full name",
                                            required: true,
                                            width: "100%"
                                        },
                                        {
                                            type: "none"
                                        },
                                        // {
                                        //     label: "First Name",
                                        //     name: "firstName",
                                        //     type: "input",
                                        //     placeholder: "Enter first name",
                                        //     required: true,
                                        // },
                                        // {
                                        //     label: "Last Name",
                                        //     name: "lastName",
                                        //     type: "input",
                                        //     placeholder: "Enter last name",
                                        //     required: true,
                                        // },
                                        {
                                            label: "Identification Type",
                                            name: "idType",
                                            type: "select",
                                            required: true,
                                            placeholder:
                                                "Select identification type",
                                            optionList: [
                                                { id: "NRIC", name: "NRIC" },
                                                {
                                                    id: "FIN No.",
                                                    name: "FIN No."
                                                },
                                                {
                                                    id: "Passport No.",
                                                    name: "Passport No."
                                                }
                                            ]
                                        },
                                        {
                                            label: "NRIC",
                                            name: "nric",
                                            type: "input",
                                            placeholder: "Enter full NRIC",
                                            required: true
                                        },
                                        {
                                            label: "Gender",
                                            name: "gender",
                                            type: "select",
                                            placeholder: "Select gender",
                                            required: true,
                                            optionList: [
                                                { id: "Male", name: "Male" },
                                                {
                                                    id: "Female",
                                                    name: "Female"
                                                },
                                                { id: "Others", name: "Others" }
                                            ]
                                        },
                                        {
                                            label: "Date of Birth",
                                            name: "dob",
                                            type: "datepicker",
                                            required: true
                                        },
                                        {
                                            label: "Race",
                                            name: "race",
                                            type: "select",
                                            placeholder: "Select race",
                                            required: true,
                                            optionList: [
                                                {
                                                    id: "Chinese",
                                                    name: "Chinese"
                                                },
                                                {
                                                    id: "Eurasian",
                                                    name: "Eurasian"
                                                },
                                                {
                                                    id: "Indian",
                                                    name: "Indian"
                                                },
                                                { id: "Malay", name: "Malay" },
                                                { id: "Others", name: "Others" }
                                            ]
                                        },
                                        {
                                            label: "Religion",
                                            name: "religion",
                                            type: "select",
                                            placeholder: "Select religion",
                                            // required: true,
                                            optionList: [
                                                {
                                                    id: "Buddhism",
                                                    name: "Buddhism"
                                                },
                                                {
                                                    id: "Christianity",
                                                    name: "Christianity"
                                                },
                                                {
                                                    id: "Hinduism",
                                                    name: "Hinduism"
                                                },
                                                { id: "Islam", name: "Islam" },
                                                {
                                                    id: "Taoism",
                                                    name: "Taoism"
                                                },
                                                {
                                                    id: "Others",
                                                    name: "Others"
                                                },
                                                { id: "Nil", name: "Nil" }
                                            ]
                                        },
                                        {
                                            label: "Marital Status",
                                            name: "maritalStatus",
                                            type: "select",
                                            placeholder:
                                                "Select marital status",
                                            // required: true,
                                            optionList: [
                                                {
                                                    id: "Single",
                                                    name: "Single"
                                                },
                                                {
                                                    id: "Married",
                                                    name: "Married"
                                                },
                                                {
                                                    id: "Divorced",
                                                    name: "Divorced"
                                                },
                                                {
                                                    id: "Widowed",
                                                    name: "Widowed"
                                                }
                                            ]
                                        },
                                        {
                                            label: "Accommodation",
                                            name: "accommodation",
                                            type: "select",
                                            placeholder: "Select accommodation",
                                            // required: true,
                                            optionList: [
                                                { id: "HDB", name: "HDB" },
                                                {
                                                    id: "Condominium",
                                                    name: "Condominium"
                                                },
                                                {
                                                    id: "Landed",
                                                    name: "Landed"
                                                },
                                                { id: "Rented", name: "Rented" }
                                            ]
                                        }
                                    ])}
                                </div>
                                <Divider />
                                <div className="row2">
                                    <Descriptions title={"Contact Details"} />
                                    {renderInputItems([
                                        {
                                            label: "Mobile Number",
                                            name: "phoneNo",
                                            type: "input",
                                            placeholder: "Enter mobile number",
                                            required: true,
                                            regex: new RegExp("[0-9 .+-,]+$"),
                                            regexMessage: "Invalid number"
                                        },
                                        {
                                            label: "Home Number",
                                            name: "homeNo",
                                            type: "input",
                                            placeholder: "Enter home number",
                                            required: false,
                                            regex: new RegExp("[0-9 .+-,]+$"),
                                            regexMessage: "Invalid number"
                                        },
                                        {
                                            label: "Email",
                                            name: "email",
                                            type: "email",
                                            placeholder: "Enter email"
                                        },
                                        {
                                            type: "space"
                                        },
                                        {
                                            label: "Address",
                                            name: "address",
                                            type: "text",
                                            placeholder: "Enter address",
                                            required: true,
                                            row: 3
                                        },
                                        {
                                            type: "none"
                                        },
                                        {
                                            label: "Name of Next of Kin",
                                            name: "nokName",
                                            type: "input",
                                            placeholder:
                                                "Enter name of next of kin",
                                            required: true
                                        },
                                        {
                                            label: "NOK's relationshp with Patient",
                                            name: "nokRelation",
                                            type: "input",
                                            placeholder:
                                                "Enter relationship with patient"
                                            // required: true,
                                        },
                                        {
                                            label: "NOK's Mobile Number",
                                            name: "nokPhoneNo",
                                            type: "input",
                                            placeholder: "Enter contact number",
                                            required: true,
                                            regex: new RegExp("[0-9 .+-,]+$"),
                                            regexMessage: "Invalid number"
                                        },
                                        {
                                            type: "space"
                                        },
                                        {
                                            label: "NOK's Address",
                                            name: "nokAddress",
                                            type: "text",
                                            placeholder: "Enter address",
                                            row: 3
                                            // required: true,
                                        }
                                    ])}
                                </div>
                            </div>
                            <div className="column">
                                <div className="row2">
                                    <Descriptions title={"Medical Details"} />
                                    {renderInputItems([
                                        {
                                            label: "Height (m)",
                                            name: "height",
                                            type: "input",
                                            placeholder: "Enter height",
                                            // required: true,
                                            // regex: new RegExp('^[+-]?([0-9]*[.])?[0-9]+$'),
                                            regex: new RegExp(
                                                "^([0-9]*[.])?[0-9]+$"
                                            ),
                                            regexMessage: "Invalid input"
                                        },
                                        {
                                            label: "Weight (kg)",
                                            name: "weight",
                                            type: "input",
                                            placeholder: "Enter weight",
                                            // required: true,
                                            // regex: new RegExp('^[+-]?([0-9]*[.])?[0-9]+$'),
                                            regex: new RegExp(
                                                "^([0-9]*[.])?[0-9]+$"
                                            ),
                                            regexMessage: "Invalid input"
                                        },
                                        {
                                            label: "BMI",
                                            name: "bmi",
                                            type: "input",
                                            placeholder: "",
                                            disabled: true
                                        },
                                        {
                                            label: "Blood Group",
                                            name: "bloodGroup",
                                            type: "select",
                                            placeholder: "Select blood group",
                                            // required: true,
                                            optionList: [
                                                { id: "A POS", name: "A POS" },
                                                { id: "A NEG", name: "A NEG" },
                                                { id: "B POS", name: "B POS" },
                                                { id: "B NEG", name: "B NEG" },
                                                {
                                                    id: "AB POS",
                                                    name: "AB POS"
                                                },
                                                {
                                                    id: "AB NEG",
                                                    name: "AB NEG"
                                                },
                                                { id: "O POS", name: "O POS" },
                                                { id: "O NEG", name: "O NEG" }
                                            ]
                                        },
                                        {
                                            label: "Mobility Status",
                                            name: "mobilityStatus",
                                            type: "select",
                                            placeholder: "Select status",
                                            // required: true,
                                            optionList: [
                                                {
                                                    id: "Independent",
                                                    name: "Independent"
                                                },
                                                {
                                                    id: "Wheelchair Assisted",
                                                    name: "Wheelchair Assisted"
                                                },
                                                {
                                                    id: "Ambulant Assistant",
                                                    name: "Ambulant Assistant"
                                                }
                                            ]
                                        },
                                        {
                                            label: "Diabetic Status",
                                            name: "diabeticStatus",
                                            type: "select",
                                            placeholder: "Select status",
                                            // required: true,
                                            optionList: [
                                                { id: "Yes", name: "Yes" },
                                                { id: "No", name: "No" }
                                            ]
                                        },
                                        {
                                            label: "Smoker Status",
                                            name: "smoker",
                                            type: "select",
                                            placeholder: "Select status",
                                            // required: true,
                                            optionList: [
                                                {
                                                    id: "Current",
                                                    name: "Current"
                                                },
                                                { id: "Past", name: "Past" },
                                                { id: "Never", name: "Never" }
                                            ]
                                        },
                                        {
                                            label: "Alcohol",
                                            name: "alcohol",
                                            type: "select",
                                            placeholder: "Select status",
                                            // required: true,
                                            optionList: [
                                                {
                                                    id: "Current",
                                                    name: "Current"
                                                },
                                                { id: "Past", name: "Past" },
                                                { id: "Never", name: "Never" }
                                            ]
                                        },
                                        // {
                                        //     label: "Allergies",
                                        //     name: "allergies",
                                        //     type: "input",
                                        //     placeholder: "Input allergies",
                                        //     required: false,
                                        //     width: "100%",
                                        // },
                                        // {
                                        //     type: "none"
                                        // },
                                        {
                                            label: "Amputee",
                                            name: "amputee",
                                            // type: "radio",
                                            type: "select",
                                            required: true,
                                            // defaultValue: 0,
                                            // optionList: [
                                            //     { label: "No", value: 0 },
                                            //     { label: "Yes", value: 1 },
                                            // ]
                                            optionList: [
                                                { id: "No", name: "No" },
                                                {
                                                    id: "Left BKA",
                                                    name: "Left BKA"
                                                },
                                                {
                                                    id: "Left AKA",
                                                    name: "Left AKA"
                                                },
                                                {
                                                    id: "Right BKA",
                                                    name: "Right BKA"
                                                },
                                                {
                                                    id: "Right AKA",
                                                    name: "Right AKA"
                                                },
                                                {
                                                    id: "Bilateral AKA",
                                                    name: "Bilateral AKA"
                                                },
                                                {
                                                    id: "Bilateral BKA",
                                                    name: "Bilateral BKA"
                                                },
                                                { id: "Others", name: "Others" }
                                            ]
                                        },
                                        {
                                            type: "space"
                                        },
                                        // {
                                        //     label: "If yes (Amputee), specify",
                                        //     name: "amputeeYes",
                                        //     type: "input",
                                        //     placeholder: "Enter",
                                        // },
                                        // {
                                        //     label: "If yes (Amputee), specify",
                                        //     name: "amputeeYes",
                                        //     type: "select",
                                        //     placeholder: "Select status",
                                        //     required: true,
                                        //     optionList: [
                                        //         { id: 0, name: "None" },
                                        //         { id: 1, name: "Left BKA" },
                                        //         { id: 2, name: "Left AKA" },
                                        //         { id: 3, name: "Right BKA" },
                                        //         { id: 4, name: "Right AKA" },
                                        //         { id: 5, name: "Bilateral AKA" },
                                        //         { id: 6, name: "Bilateral BKA" },
                                        //         { id: 7, name: "Others" },
                                        //     ]
                                        // },
                                        {
                                            label: "Date ESRD",
                                            name: "DESRD",
                                            type: "datepicker",
                                            required: true
                                        },
                                        {
                                            label: "Date of First Dialysis",
                                            name: "firstDialysisHospitalDate",
                                            type: "datepicker"
                                        },
                                        {
                                            label: "Wheelchair's weight (kg)",
                                            name: "wheelchairWeight",
                                            type: "input",
                                            placeholder: "Enter weight",
                                            // regex: new RegExp('^[+-]?([0-9]*[.])?[0-9]+$'),
                                            // regexMessage: 'Digits only',
                                            regex: new RegExp(
                                                "^([0-9]*[.])?[0-9]+$"
                                            ),
                                            regexMessage: "Invalid input"
                                        },
                                        {
                                            type: "space"
                                        }
                                        // {
                                        //     label: "Patient's History",
                                        //     name: "history",
                                        //     type: "text",
                                        //     placeholder: "Enter patient's history",
                                        //     rows: 3,
                                        // },
                                        // {
                                        //     type: "none"
                                        // },
                                        // {
                                        //     label: "Cause of kidney failure",
                                        //     name: "causeOfKidneyFailure",
                                        //     type: "text",
                                        //     placeholder: "Enter cause of kidney failure",
                                        //     rows: 3,
                                        // },
                                        // {
                                        //     type: "none"
                                        // },
                                    ])}
                                </div>
                                <div className="row">
                                    Allergies:{" "}
                                    {values.allergies?.length > 0
                                        ? "Yes"
                                        : "No"}
                                    &nbsp;&nbsp;&nbsp;
                                    <div
                                        className="clickable"
                                        onClick={() => {
                                            handleModalVisibility("allergy");
                                        }}
                                    >
                                        <img src={iconAdd} />
                                    </div>
                                </div>
                                {values.allergies?.length > 0
                                    ? renderAllergyTextDisplay(values.allergies)
                                    : ""}
                                {/* <Divider />
                                <div className="row2">
                                    <Descriptions title={"Dialysis Schedule"} />
                                    {renderInputItems([
                                        // {
                                        //     label: "Pattern",
                                        //     name: "pattern",
                                        //     type: "select",
                                        //     placeholder: "Select pattern",
                                        //     optionList: [
                                        //         { id: 1, name: "Option 1" },
                                        //         { id: 2, name: "Option 2" },
                                        //         { id: 3, name: "Option 3" },
                                        //         { id: 4, name: "Option 4" },
                                        //     ]
                                        // },
                                        {
                                            label: "Session",
                                            name: "session",
                                            type: "select",
                                            placeholder: "Select session",
                                            optionList: [
                                                { id: 1, name: "Option 1" },
                                                { id: 2, name: "Option 2" },
                                                { id: 3, name: "Option 3" },
                                                { id: 4, name: "Option 4" },
                                            ]
                                        },
                                        {
                                            label: "HD Device No",
                                            name: "hdDeviceNo",
                                            type: "select",
                                            placeholder: "Select device",
                                            optionList: [
                                                { id: 1, name: "Option 1" },
                                                { id: 2, name: "Option 2" },
                                                { id: 3, name: "Option 3" },
                                                { id: 4, name: "Option 4" },
                                            ]
                                        },
                                    ])}
                                </div> */}
                                <Divider />
                                <div className="row2">
                                    <Descriptions title={"Support"} />
                                    {renderInputItems([
                                        {
                                            label: "Med Social Worker",
                                            name: "socialWorker",
                                            type: "input",
                                            placeholder:
                                                "Enter med social worker"
                                        },
                                        {
                                            type: "space"
                                        },
                                        // {
                                        //     label: "Other VWO Support",
                                        //     name: "vwoSupport",
                                        //     type: "radio",
                                        //     required: true,
                                        //     defaultValue: 0,
                                        //     optionList: [
                                        //         { label: "No", value: 0 },
                                        //         { label: "Yes", value: 1 },
                                        //     ]
                                        // },
                                        {
                                            label: "Funded",
                                            name: "vwoSupportYes",
                                            type: "input",
                                            placeholder:
                                                "If no, leave this blank",
                                            width: "100%"
                                        }
                                    ])}
                                </div>
                            </div>
                        </div>
                    </Form>
                </Spin>
            </div>
            <Modal
                title={<div className="modal-title">Allergy</div>}
                visible={visibilityAllergy}
                onCancel={() => handleModalVisibility("allergy")}
                footer={[
                    <Button
                        key="back"
                        onClick={() => handleModalVisibility("allergy")}
                    >
                        Cancel
                    </Button>
                ]}
                className="modal-patientdetailssummary-allergy"
                width={600}
            >
                <Spin spinning={loadingModal}>
                    <div className="modal-content">
                        <Form
                            form={form2}
                            layout="horizontal"
                            onFinish={onFinishCreateAllergy}
                            validateMessages={validateMessages}
                            requiredMark={false}
                        >
                            <div className="record-add">
                                {/* <Form.Item
                                    name="date"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <DatePicker onChange={handleDatePickerChange} format={dateFormatList} />
                                </Form.Item> */}
                                <Form.Item
                                    name="allergy"
                                    rules={[
                                        {
                                            required: true
                                        }
                                    ]}
                                >
                                    <Input
                                        autoComplete="off"
                                        placeholder="Enter allergy"
                                    />
                                </Form.Item>
                                <Popconfirm
                                    title="Are you sure?"
                                    onConfirm={() =>
                                        onFinishCreateAllergy(
                                            form2.getFieldsValue()
                                        )
                                    }
                                    okText="Yes"
                                    cancelText="No"
                                    onCancel={() => {
                                        form2.resetFields();
                                    }}
                                >
                                    {/* <Button className="circle" htmlType="submit" shape="circle" icon={<PlusOutlined />} /> */}
                                    <div className="clickable">
                                        <img src={iconAdd} />
                                    </div>
                                </Popconfirm>
                            </div>
                        </Form>
                        {renderAllergy(
                            values.allergies ? values.allergies : []
                        )}
                    </div>
                </Spin>
            </Modal>
        </div>
    );
};

export default PatientInformationEdit;
