
import Compressor from 'compressorjs';

export const compressImage = (file, quality = 0.8, maxHeight = 500, maxWidth = 500) => {
    return new Promise((resolve, reject) => {
        new Compressor(file, {
            quality,
            maxHeight,
            maxWidth,
            success: async (file) => {
                resolve(file);
            },
            error(err) {
                console.log("error: ", err.message);
            },
        })
    });
}