import React, { useContext, useEffect, useState } from "react";
import {
    Button,
    DatePicker,
    Empty,
    Form,
    Input,
    notification,
    Popconfirm,
    Select,
    Table,
    AutoComplete,
    Spin,
    Pagination
} from "antd";

import { DataContext } from "../contexts/DataContext";
import { AppContext } from "../contexts/AppContext";
import iconAdd from "../media/Icon-Add.png";
import { API } from "aws-amplify";
import { createMedicationAdministered } from "../backend/graphql/mutations";
import { listMedicationAdministered } from "../backend/graphql/queries";
import moment from "moment";
import { freqList, routeList } from "../utils/constants";

const { RangePicker } = DatePicker;

const PatientRecordAdministeredMedication = (props) => {
    const { appState } = useContext(AppContext);
    const [form] = Form.useForm();
    const { Option } = Select;
    // const [loading, setLoading] = useState(false);
    // const [medicationAdministered, setMedicationAdministered] = useState([])
    const dateFormatList = ["DD MMM YYYY, HH:mm"];
    const validateMessages = {
        required: "Required."
    };
    const [table, setTable] = useState({
        data: [],
        pagination: {
            current: 1,
            pageSize: 6,
            offset: 0
        },
        loading: false
    });

    useEffect(() => {
        // console.log('PatientRecordAdministeredMedication', props?.patientID);
        if (Object.keys(appState.cognitoUser).length !== 0) {
            // _listMedicationAdministered(props?.patientID);
            getMedicationAdministered(null, table.pagination);
        }
        return () => {};
    }, [appState.cognitoUser]);

    const handleDatePickerChange = (date, dateString) => {
        // console.log("handleDatePickerChange");
        // console.log(date, dateString);
    };

    const renderListOptions = (
        array,
        key = "id",
        value = "id",
        name = "name"
    ) => {
        if (typeof array == "object" && array.length !== 0) {
            const listOptions = array.map((item, index) => {
                return (
                    <Option key={item[key]} value={item[value]}>
                        {item[name]}
                    </Option>
                );
            });

            return listOptions;
        } else {
            return <div />;
        }
    };

    const columns = [
        {
            title: "Date / Time",
            dataIndex: "datetime",
            width: 180,
            renderInput: (
                <Form.Item
                    key="datetime"
                    name="datetime"
                    rules={[
                        {
                            required: true
                        }
                    ]}
                    style={{ width: 180 }}
                >
                    <DatePicker
                        onChange={handleDatePickerChange}
                        format={dateFormatList}
                        showTime={true}
                    />
                </Form.Item>
            )
        },
        {
            title: "Medication",
            dataIndex: "medication",
            renderInput: (
                <Form.Item
                    key="medication"
                    name="medication"
                    rules={[
                        {
                            required: true
                        }
                    ]}
                    style={{ width: 180 }}
                >
                    <Select
                        style={{ width: 180 }}
                        showSearch={true}
                        placeholder="Select a medication"
                        optionFilterProp="children"
                        // onChange={onChange}
                        // onSearch={onSearch}
                        filterOption={(inputValue, option) =>
                            option?.value
                                .toUpperCase()
                                .indexOf(inputValue.toUpperCase()) !== -1
                        }
                    >
                        {/* {renderListOptions(appState.drugList, 'name', 'name', 'name')} */}
                        {appState.drugList !== -1
                            ? renderListOptions(
                                  appState.drugList,
                                  "name",
                                  "name",
                                  "name"
                              )
                            : null}
                    </Select>
                </Form.Item>
            )
        },
        {
            title: "Dose",
            dataIndex: "dose",
            renderInput: (
                <Form.Item
                    key="dose"
                    name="dose"
                    rules={[
                        {
                            required: true
                        }
                    ]}
                    style={{ width: 180 }}
                >
                    <Input
                        autoComplete="off"
                        placeholder="Enter dose"
                        allowClear
                    />
                </Form.Item>
            )
        },
        {
            title: "Route",
            dataIndex: "route",
            renderInput: (
                <Form.Item
                    key="route"
                    name="route"
                    rules={[
                        {
                            required: true
                        }
                    ]}
                    style={{ width: 180 }}
                >
                    <Select
                        style={{ width: 180 }}
                        showSearch={true}
                        placeholder="Select a route"
                        optionFilterProp="children"
                        // onChange={onChange}
                        // onSearch={onSearch}
                        filterOption={(input, option) =>
                            option?.children
                                ?.toLowerCase()
                                .indexOf(input?.toLowerCase()) >= 0
                        }
                    >
                        {renderListOptions(routeList, "id", "id", "value")}
                    </Select>
                </Form.Item>
            )
        },
        {
            title: "Frequency",
            dataIndex: "frequency",
            renderInput: (
                <Form.Item
                    key="frequency"
                    name="frequency"
                    rules={[
                        {
                            required: true
                        }
                    ]}
                    style={{ width: 180 }}
                >
                    <Select
                        style={{ width: 180 }}
                        showSearch={true}
                        placeholder="Select a frequency"
                        optionFilterProp="children"
                        // onChange={onChange}
                        // onSearch={onSearch}
                        filterOption={(input, option) =>
                            option?.children
                                ?.toLowerCase()
                                .indexOf(input?.toLowerCase()) >= 0
                        }
                    >
                        {renderListOptions(freqList, "id", "id", "value")}
                    </Select>
                </Form.Item>
            )
        },
        {
            title: "Given By",
            dataIndex: "givenBy",
            renderInput: (
                <Form.Item
                    key="givenBy"
                    name="givenBy"
                    rules={[
                        {
                            required: true
                        }
                    ]}
                    style={{ width: 180 }}
                >
                    <Select
                        style={{ width: 180 }}
                        showSearch={true}
                        placeholder="Select a staff"
                        optionFilterProp="children"
                        // onChange={onChange}
                        // onSearch={onSearch}
                        filterOption={(input, option) =>
                            option?.children
                                ?.toLowerCase()
                                .indexOf(input?.toLowerCase()) >= 0
                        }
                    >
                        {appState.staffList !== -1
                            ? renderListOptions(
                                  appState.staffList,
                                  "accountID",
                                  "accountID",
                                  "name"
                              )
                            : null}
                        {/* {renderListOptions(appState.staffList, 'accountID', 'accountID', 'name')} */}
                    </Select>
                </Form.Item>
            )
        },
        {
            title: "witnessedBy",
            dataIndex: "witnessedBy",
            renderInput: (
                <Form.Item
                    key="witnessedBy"
                    name="witnessedBy"
                    rules={[
                        {
                            required: true
                        }
                    ]}
                    style={{ width: 180 }}
                >
                    <Select
                        style={{ width: 180 }}
                        showSearch={true}
                        placeholder="Select a staff"
                        optionFilterProp="children"
                        // onChange={onChange}
                        // onSearch={onSearch}
                        filterOption={(input, option) =>
                            option?.children
                                ?.toLowerCase()
                                .indexOf(input?.toLowerCase()) >= 0
                        }
                    >
                        {appState.staffList !== -1
                            ? renderListOptions(
                                  appState.staffList,
                                  "accountID",
                                  "accountID",
                                  "name"
                              )
                            : null}
                        {/* {renderListOptions(appState.staffList, 'accountID', 'accountID', 'name')} */}
                    </Select>
                </Form.Item>
            )
        },
        {
            title: "",
            dataIndex: "action"
        }
    ];

    const _listMedicationAdministered = async (
        patientID,
        pagination = table.pagination,
        filter = null
    ) => {
        // setLoading(true);
        // setTable({
        //     ...table,
        //     loading: true,
        // })
        try {
            const variables = {
                pagination: {
                    limit: pagination.pageSize,
                    offset: pagination.offset,
                    orderby: `createdOn DESC`
                },
                filter: {
                    patientID: {
                        eq: patientID
                    },
                    deleted: { eq: 0 }
                }
            };

            // console.log(variables);

            const result = await API.graphql({
                query: listMedicationAdministered,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables
            });
            // console.log("API result PatientRecordAdministeredMedication", result);

            const data = result.data.result;
            const array = result.data.result.result;

            // process data
            let _medicationAdministered = [];
            for (let v of array) {
                _medicationAdministered.push({
                    id: v.id,
                    patientID: v.patientID,
                    dialysisIntervalID: v.dialysisIntervalID,
                    medication: v.medication,
                    dose: v.dose,
                    route: v.route,
                    frequency: v.freq,
                    // givenBy: v.givenBy,
                    givenBy: v.givenByStaffName,
                    // witnessedBy: v.witnessedBy,
                    witnessedBy: v.witnessedByStaffName,
                    datetime: moment
                        .utc(v.createdOn)
                        .local()
                        .format("DD MMM YYYY, HH:mm")
                });
            }

            // setMedicationAdministered(_medicationAdministered);
            setTable({
                ...table,
                data: _medicationAdministered,
                loading: false,
                pagination: {
                    ...table.pagination,
                    current: pagination.current,
                    offset: pagination.offset,
                    total:
                        pagination.offset >= data.count
                            ? data.count + 1
                            : data.count // keeps the last pagination if it is the last record
                }
            });

            // console.log(_medicationAdministered);
        } catch (error) {
            console.log("error: ", error);
            notification.error({
                message: "Unable to retrieve Administered Medication"
            });
        } finally {
        }
    };

    const _createMedicationAdministered = async (values) => {
        // setLoading(true);
        setTable({
            ...table,
            loading: true
        });
        try {
            let variables = {
                patientID: props?.patientID,
                // dialysisID:
                // dialysisInterval:
                medication: values.medication,
                dose: values.dose,
                route: values.route,
                freq: values.frequency,
                givenBy: values.givenBy,
                witnessedBy: values.witnessedBy,
                createdOn: values.datetime.toISOString().split(".")[0]
            };

            variables = Object.entries(variables).reduce(
                (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
                {}
            );

            // console.log(variables);

            const result = await API.graphql({
                query: createMedicationAdministered,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables: { create: variables }
            });
            // console.log("API result PatientRecordAdministeredMedication createMedicationAdministered", result);

            _listMedicationAdministered(props?.patientID);
            if (result?.data?.result) {
                notification.success({
                    message: "Created successfully"
                });
                form.resetFields();
            }

            setTable({
                ...table,
                loading: false
            });
        } catch (error) {
            // console.log(error);
            notification.error({
                message: "Unable to create new record"
            });
        } finally {
        }
    };

    const getMedicationAdministered = (filter, pagination) => {
        setTable({
            ...table,
            loading: true
        });

        _listMedicationAdministered(props?.patientID, pagination, filter);
    };

    const handleTableChange = (paginate) => {
        getMedicationAdministered(null, {
            ...table.pagination,
            current: paginate,
            offset:
                paginate * table.pagination.pageSize - table.pagination.pageSize
        });
    };

    const onFinish = async (values) => {
        // console.log("onFinish clicked", values);
        const v = await form.validateFields();
        // console.log("onFinish clicked2", v);
        if (v.givenBy == v.witnessedBy) {
            // console.log(`same user can't be witness`);
            notification.error({
                message: "Same user can't be witness"
            });
        } else {
            _createMedicationAdministered(v);
        }
    };

    // render custom table header
    const renderHeaderItems = (array) => {
        const items = array.map((item, index) => {
            // console.log("item", item);
            const width = item.width !== undefined ? item.width : null;

            return (
                <div
                    key={item.dataIndex}
                    className={item.dataIndex !== "action" ? "column" : ""}
                    style={width !== null ? { width: width } : {}}
                >
                    {item.title}
                </div>
            );
        });
        return items;
    };

    const renderAdministeredMedicationHeader = (array) => {
        return array.length === 0 ? "" : renderHeaderItems(array);
    };

    // render custom table input row
    const renderInputItems = (array) => {
        const items = array.map((item, index) => {
            // console.log("item width", item.width);
            const width = item.width !== undefined ? item.width : null;
            if (item.renderInput !== undefined) {
                return item.renderInput;
            } else {
                if (width !== null) {
                    return (
                        <Form.Item
                            key={item.dataIndex}
                            name={item.dataIndex}
                            style={{ width: width }}
                            rules={[
                                {
                                    required: true
                                }
                            ]}
                        >
                            <Input autoComplete="off" />
                        </Form.Item>
                    );
                } else {
                    if (item.dataIndex === "action") {
                        return (
                            <Form.Item key={item.dataIndex}>
                                <Popconfirm
                                    title="Are you sure?"
                                    onConfirm={() =>
                                        onFinish(form.getFieldsValue())
                                    }
                                    onCancel={() => {
                                        form.resetFields();
                                    }}
                                    okText="Yes"
                                    cancelText="No"
                                    placement="topRight"
                                >
                                    {/* <Button className="circle" htmlType="submit" shape="circle" icon={<PlusOutlined />} /> */}
                                    <div className="clickable">
                                        <img src={iconAdd} />
                                    </div>
                                </Popconfirm>
                            </Form.Item>
                        );
                    } else {
                        return (
                            <Form.Item
                                key={item.dataIndex}
                                name={item.dataIndex}
                                rules={[
                                    {
                                        required: true
                                    }
                                ]}
                            >
                                <Input autoComplete="off" />
                            </Form.Item>
                        );
                    }
                }
            }
        });
        return items;
    };

    const renderAdministeredMedicationInputRow = (array) => {
        return array.length === 0 ? "" : renderInputItems(array);
    };

    const renderItems = (columns, array) => {
        const items = array.map((item, index) => {
            // console.log("item", item);
            const renderItemColumns = (columns, rowItem) => {
                // console.log("columns", columns);
                // console.log("rowItem", rowItem);
                const renderRowItems = columns.map((columnItem, index) => {
                    // console.log("columns item", columnItem);
                    const width =
                        columnItem.width !== undefined
                            ? columnItem.width
                            : null;
                    let value = "";
                    for (const key in rowItem) {
                        // console.log("key", key);
                        // console.log("value", rowItem[key]);
                        if (columnItem.dataIndex === key) {
                            value = rowItem[key];
                            break;
                        }
                    }
                    return (
                        <div
                            key={columnItem.dataIndex}
                            className={
                                columnItem.dataIndex !== "action"
                                    ? "column"
                                    : ""
                            }
                            style={width !== null ? { width: width } : {}}
                        >
                            {value}
                        </div>
                    );
                });
                return renderRowItems;
            };

            return (
                <div
                    key={item.id}
                    className={index % 2 === 0 ? "record highlight" : "record"}
                >
                    {renderItemColumns(columns, item)}
                </div>
            );
        });
        return items;
    };

    const renderAdministeredMedication = (columns, array) => {
        return array.length === 0 ? (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ) : (
            renderItems(columns, array)
        );
    };

    return (
        <div className="patientrecord-administeredmedication">
            <div className="row">
                <div className="row">
                    <div className="title">Administered Medication</div>
                </div>
            </div>
            <Spin spinning={table.loading}>
                <div className="card">
                    <div className="card-header">
                        {renderAdministeredMedicationHeader(columns)}
                    </div>
                    <div className="card-content">
                        <Form
                            form={form}
                            layout="horizontal"
                            onFinish={onFinish}
                            validateMessages={validateMessages}
                            requiredMark={false}
                        >
                            <div className="record-add">
                                {renderAdministeredMedicationInputRow(columns)}
                            </div>
                        </Form>
                        <div className="record-content">
                            {renderAdministeredMedication(
                                columns,
                                // medicationAdministered
                                table.data
                                // [
                                //     {
                                //         id: 1,
                                //         datetime: "10 Apr 2021, 06:00",
                                //         medication: "Medication",
                                //         route: "Route",
                                //         frequency: "Frequency",
                                //         givenBy: "Given By",
                                //         witnessedBy: "witnessedBy",
                                //     },
                                //     {
                                //         id: 2,
                                //         datetime: "10 Apr 2021, 06:00",
                                //         medication: "Medication",
                                //         route: "Route",
                                //         frequency: "Frequency",
                                //         givenBy: "Given By",
                                //         witnessedBy: "witnessedBy",
                                //     },
                                //     {
                                //         id: 3,
                                //         datetime: "10 Apr 2021, 06:00",
                                //         medication: "Medication",
                                //         route: "Route",
                                //         frequency: "Frequency",
                                //         givenBy: "Given By",
                                //         witnessedBy: "witnessedBy",
                                //     },
                                // ]
                            )}
                        </div>
                        <Pagination
                            current={table.pagination.current}
                            pageSize={table.pagination.pageSize}
                            total={table.pagination.total}
                            onChange={handleTableChange}
                            showSizeChanger={false}
                        />
                    </div>
                </div>
            </Spin>
        </div>
    );
};

export default PatientRecordAdministeredMedication;
