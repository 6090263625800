import React from "react";
import { BrowserRouter } from "react-router-dom";
import "antd/dist/antd.less";
import "./css/App.css";

import RoutePath from "./utils/RoutePath";
import HeaderApp from "./components/Component-HeaderApp";

const App = () => {
  // useEffect(() => {
  //   const init = async () => {
  //     const credentials = await Auth.currentCredentials()
  //     console.log(credentials);
  //   }
  //   init()
  //   return () => { }
  // }, [])

  return (
    <BrowserRouter>
      <div className="default-theme">
        <HeaderApp />
        <div className="content">
          <RoutePath />
        </div>
      </div>
    </BrowserRouter>
  );
};

export default App;
