import React, { useContext, useEffect, useRef, useState } from "react";
import {
    Avatar,
    Button,
    DatePicker,
    Divider,
    Empty,
    Form,
    Input,
    notification,
    Pagination,
    Popover,
    Radio,
    Select,
    Space,
    Spin,
    Tabs
} from "antd";
import {
    DownOutlined,
    PlusOutlined,
    SearchOutlined,
    UserOutlined
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { API } from "aws-amplify";

import {
    listPatient,
    listAppointmentDetail,
    listQBAccesses,
    listClinic
} from "../backend/graphql/queries";
// import { onCreateAppointment, onUpdateAppointment, onStartDialysis, onSubmitDialysisInterval, onCheckoutDialysis } from "../backend/graphql/subscriptions"
import { onSubmitDialysisInterval } from "../backend/graphql/subscriptions";
import {
    onCreateAppointment,
    onUpdateAppointment,
    onStartDialysis,
    onCheckoutDialysis
} from "../backend/graphql/custom_subscriptions";
import path from "../utils/pathSettings";
import { DataContext } from "../contexts/DataContext";
import { AppContext } from "../contexts/AppContext";
// import menu from "../utils/navigationMenuSettingsArchive"
import CardPatient from "./Component-Card-Patient";
import IconAlert from "../media/Icon-AlertSymbol.png";
import bellnotification from "../media/bellnotification.wav";
import {
    listAppointmentDetail_Seat,
    listQBAccess
} from "../backend/graphql/custom_queries";
import {
    linkAppointmentToQuickbooks,
    linkAppointmentToQuickbooks2,
    linkAppointmentToQuickbooks3
} from "../backend/graphql/mutations";

const ManageUnlinkedInvoice = (props) => {
    const notificationSound = new Audio(bellnotification);
    const { dataState } = useContext(DataContext);
    const { appState, actionSetQuickBookRedirect } = useContext(AppContext);
    const [form] = Form.useForm();
    const { Option } = Select;
    const dateFormatList = ["DD MMM YYYY"];
    const [filter, setFilter] = useState({
        // date: null,
        date: moment().local(),
        search: "",
        searchType: "daily",
        searchTypeDaily: "all",
        searchTypeDisplay: ""
        // typing: false,
        // typingTimeout: null,
    });
    const [filterSearchTypeApplied, setFilterSearchTypeApplied] =
        useState("daily");
    const [customedDropdownVisibility, setCustomedDropdownVisibility] =
        useState(false);
    const [table, setTable] = useState({
        data: -1,
        pagination: {
            current: 1,
            pageSize: 100,
            offset: 0,
            total: 0
        },
        loading: false
    });
    const [clinic, setClinic] = useState({
        data: -1,
        pagination: {
            current: 1,
            pageSize: 20,
            offset: 0,
            total: 0
        },
        loading: false
    });
    const [errorMessageDisplay, setErrorMessageDisplay] = useState(false);
    const [displayList, setDisplayList] = useState([]);
    const [qbAccess, setQBAccess] = useState(-1);
    const [morningListDisplay, setMorningListDisplay] = useState([]);
    const [noonListDisplay, setNoonListDisplay] = useState([]);
    const [eveningListDisplay, setEveningListDisplay] = useState([]);
    const [unlinkedListDisplay, setUnlinkedListDisplay] = useState([]);
    const [loading, setLoading] = useState(false);
    // const [executeSearch, setExecuteSearch] = useState(false);
    const history = useHistory();
    const filterRef = useRef({});
    // console.log("filterRef", filterRef);
    const tableRef = useRef({});
    let subscriptionAppointmentOnCreate;
    let subscriptionAppointmentOnUpdate;
    let subscriptionDialysisSessionOnStart;
    let subscriptionDialysisIntervalOnSubmit;
    let subscriptionDialysisSessionOnCheckout;

    // const _listPatient = async (search = '', clinicID = 0, date = '', offset = 0, limit = 100) => {
    const _listPatient = async (filter, clinicID, props = table.pagination) => {
        // console.log("filter", filter);
        // console.log("clinicID", clinicID, filterSearchTypeApplied);
        try {
            const variables = {
                pagination: {
                    limit: props.pageSize,
                    offset: props.offset
                },
                filter: {
                    and: []
                }
            };

            if (appState.clinicList && appState.clinicList.length > 0) {
                let or = [];
                for (let c of appState.clinicList) {
                    or.push({
                        existingClinicID: { eq: c.id }
                    });
                }
                variables.filter.and.push({ or: or });
            }

            if (filter.search?.length > 0) {
                variables.filter.and.push({
                    or: [
                        {
                            name: { contains: filter.search }
                        },
                        {
                            nric: { contains: filter.search }
                        },
                        {
                            referringSource: { contains: filter.search }
                        },
                        {
                            referrer: { contains: filter.search }
                        }
                    ]
                });
            }

            if (clinicID !== null && filterSearchTypeApplied != "all") {
                variables.filter = {
                    ...variables.filter,
                    existingClinicID: {
                        eq: clinicID
                    }
                };
            }

            // console.log('Dashboard', variables);

            const result = await API.graphql({
                query: listPatient,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables
            });
            // console.log("API result", result);

            const data = result.data.result;
            const array = result.data.result?.result;

            if (array.length > 0) {
                setTable({
                    ...table,
                    loading: true,
                    // loading: false, // set false for simulation
                    data: array,
                    pagination: {
                        ...table.pagination,
                        pageSize: props.pageSize,
                        current: props.current,
                        offset: props.offset,
                        total:
                            props.offset >= data.count
                                ? data.count + 1
                                : data.count // keeps the last pagination if it is the last record
                    }
                });
                tableRef.current = {
                    ...table,
                    loading: true,
                    // loading: false, // set false for simulation
                    data: array,
                    pagination: {
                        ...table.pagination,
                        pageSize: props.pageSize,
                        current: props.current,
                        offset: props.offset,
                        total:
                            props.offset >= data.count
                                ? data.count + 1
                                : data.count // keeps the last pagination if it is the last record
                    }
                };
            }
            // else {
            //     // notification.error({
            //     //     message: <div style={{ fontWeight: "bold", color: "red" }}>Error</div>,
            //     //     description: "No records found. Showing all results."
            //     // });

            //     // form.setFieldsValue({ search: '' });
            //     // setFilter({
            //     //     ...filter,
            //     //     search: ""
            //     // })
            //     // filterRef.current = {
            //     //     ...filter,
            //     //     search: ""
            //     // }

            //     // getPatientSessions({
            //     //     ...form.getFieldsValue(),
            //     //     search: ''
            //     // }, {
            //     //     ...props,
            //     //     current: 1,
            //     //     offset: 0
            //     // });

            //     notification.info({
            //         message: "No records found."
            //     })
            //     setTable({
            //         ...table,
            //         loading: false,
            //         // loading: false, // set false for simulation
            //         data: array,
            //         pagination: {
            //             ...table.pagination,
            //             current: props.current,
            //             offset: props.offset,
            //             total: 0
            //         }
            //     })
            //     tableRef.current = {
            //         ...table,
            //         loading: false,
            //         // loading: false, // set false for simulation
            //         data: array,
            //         pagination: {
            //             ...table.pagination,
            //             current: props.current,
            //             offset: props.offset,
            //             total: 0
            //         }
            //     }
            // }
            else {
                notification.info({
                    message: "No records found."
                });
                setTable({
                    ...table,
                    loading: false,
                    data: [],
                    pagination: {
                        ...table.pagination,
                        current: 1,
                        offset: 0,
                        total: 0
                    }
                });
                tableRef.current = {
                    ...table,
                    loading: false,
                    data: [],
                    pagination: {
                        ...table.pagination,
                        current: 1,
                        offset: 0,
                        total: 0
                    }
                };
            }
        } catch (error) {
            console.log("error: ", error);
        } finally {
        }
    };

    const _listAppointmentDetail = async (
        filter,
        clinicID,
        props = table.pagination
    ) => {
        // console.log("list appointment detail filter", filter);
        try {
            let variables = {
                pagination: {
                    limit: props.pageSize,
                    offset: props.offset
                },
                filter: {
                    quickbookInvoiceID: {
                        attributeExists: false
                    },
                    dialysisID: {
                        attributeExists: true
                    },
                    dialysisEndedBy: {
                        attributeExists: true
                    },
                    dialysisEndTime: {
                        attributeExists: true
                    },
                    deleted: {
                        eq: 0
                    },
                    clinicID: { eq: parseInt(appState.selectedClinicID) }
                }
            };

            if (filter.search.length > 0) {
                variables.filter = {
                    ...variables.filter,
                    or: [
                        {
                            name: { contains: filter.search }
                        },
                        {
                            nric: { contains: filter.search }
                        }
                    ]
                };
            }

            // if (clinicID !== null && filterSearchTypeApplied != 'all') {
            //     variables.filter = {
            //         ...variables.filter,
            //         clinicID: { eq: clinicID }
            //     }
            // }

            // if (filter.searchType === "daily" && filter.searchTypeDaily !== "all") {
            //     const shift = filter.searchTypeDaily === "morning" ? "M" : (filter.searchTypeDaily === "noon" ? "N" : "E")
            //     variables.filter = {
            //         ...variables.filter,
            //         shift: { eq: shift }
            //     }
            // }

            // variables.filter2 = {
            //     clinicID: { eq: appState.selectedClinicID },
            //     status: { eq: 'AVAILABLE' },
            //     deleted: { eq: 0 }
            // }

            // console.log('Dashboard daily', variables);

            const result = await API.graphql({
                query: listAppointmentDetail,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables
            });
            // console.log("API result Dashboard", result, filter);

            const data = result.data.result;
            const array = result.data.result.result;
            // console.log(array.length, filter?.search?.length);

            if (array.length > 0) {
                let _appointmentDetail = [];

                array.forEach((a) => {
                    let _a = {
                        ...a
                    };

                    _a.appointmentID = a.id;
                    _a.id = a.patientID;
                    _a.dialysisInterval = a.lastDialysisIntervalID;
                    _a.startedBy = a.startedBy;
                    _a.endedBy = a.endedBy;
                    // _a.
                    // _a.uid = 'ASD';

                    _appointmentDetail.push(_a);
                });
                // console.log(_appointmentDetail);

                setTable({
                    ...table,
                    loading: true,
                    data: _appointmentDetail,
                    pagination: {
                        ...table.pagination,
                        current: props.current,
                        offset: props.offset,
                        pageSize: props.pageSize,
                        total:
                            props.offset >= data.count
                                ? data.count + 1
                                : data.count // keeps the last pagination if it is the last record
                    }
                });
                tableRef.current = {
                    ...table,
                    loading: true,
                    data: _appointmentDetail,
                    pagination: {
                        ...table.pagination,
                        current: props.current,
                        offset: props.offset,
                        total:
                            props.offset >= data.count
                                ? data.count + 1
                                : data.count // keeps the last pagination if it is the last record
                    }
                };

                // let groupByAppointmentDetail = _appointmentDetail.groupBy(({shift}) => shift);
                // let groupByAppointmentDetail = _appointmentDetail.reduce(function (rv, x) {
                //     (rv[x['shift']] = rv[x['shift']] || []).push(x);
                //     return rv;
                // }, {});
                // let groupByAppointmentDetailCount = Object.keys(groupByAppointmentDetail)?.map(s => groupByAppointmentDetail[s]?.length);
                // let maxAppointmentSessionNo = Math.max(...groupByAppointmentDetailCount);
                // console.log('groupByAppointmentDetail', groupByAppointmentDetail, groupByAppointmentDetailCount, maxAppointmentSessionNo);

                // if (maxAppointmentSessionNo > result.data?.result2?.count && moment().diff(filter.date, "days") <= 0) {
                //     setErrorMessageDisplay(`${maxAppointmentSessionNo} appointment${maxAppointmentSessionNo > 1 ? 's' : ''}, ${result.data?.result2?.count} seat${result.data?.result2?.count > 1 ? 's' : ''}`);
                // } else {
                //     setErrorMessageDisplay(false);
                // }
            }
            // else if (array.length == 0 && filter?.search?.length > 0) {
            //     notification.error({
            //         message: <div style={{ fontWeight: "bold", color: "red" }}>Error</div>,
            //         description: "No records found. Showing all results."
            //     });

            //     form.setFieldsValue({ search: '' });
            //     setFilter({
            //         ...filter,
            //         search: ""
            //     })
            //     filterRef.current = {
            //         ...filter,
            //         search: ""
            //     }

            //     getPatientSessions({
            //         ...form.getFieldsValue(),
            //         search: ''
            //     }, {
            //         ...props,
            //         current: 1,
            //         offset: 0
            //     });
            // }
            else {
                // notification.info({
                //     message: "No records found."
                // })
                notification.error({
                    message: (
                        <div style={{ fontWeight: "bold", color: "red" }}>
                            Error
                        </div>
                    ),
                    description: "No records found."
                });
                setTable({
                    ...table,
                    loading: false,
                    data: [],
                    pagination: {
                        ...table.pagination,
                        current: 1,
                        offset: 0,
                        total: 0
                    }
                });
                tableRef.current = {
                    ...table,
                    loading: false,
                    data: [],
                    pagination: {
                        ...table.pagination,
                        current: 1,
                        offset: 0,
                        total: 0
                    }
                };
            }
        } catch (error) {
            console.log("error: ", error);
            setTable({
                ...table,
                loading: false
            });
            notification.error({
                message: "Unable to retrieve Appointments"
            });
        } finally {
        }
    };

    const _dialysisLinkQuickbook = async (
        patientID,
        dialysisID,
        quickbookURL,
        centerName = ""
    ) => {
        setLoading(true);
        try {
            let locURL = window.location.href.split("//");
            let domainURL = locURL[1].split("/")[0];
            let redirect_uri =
                locURL[0] +
                "//" +
                domainURL +
                path("manageUnlinkedInvoice") +
                "/";

            const variables = {
                patientID,
                dialysisID,
                quickbookURL: quickbookURL ?? "",
                redirectURL: redirect_uri
            };

            // console.log('linkAppointmentToQuickbooks variables', variables);

            const result = await API.graphql({
                query: appState.useRefreshToken
                    ? linkAppointmentToQuickbooks3
                    : linkAppointmentToQuickbooks,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables
            });
            // console.log("API result linkAppointmentToQuickbooks", result);

            const data = result.data.result;
            // const array = result.data.result.result
            const parseData = JSON.parse(data);
            const invoiceURL = parseData.invoiceURL;

            // console.log('checkoutDialysis', data);
            // console.log(parseData, invoiceURL);

            if (invoiceURL) window.open(invoiceURL, "_blank"); //.focus();
            history.push(path("patientRecord", [patientID]));

            setLoading(false);
            // return data;

            // console.log(_labReport);
        } catch (error) {
            console.log("error: ", error);
            if (
                error &&
                error.errors.length > 0 &&
                error.errors[0].message === "Quickbooks token expired."
            ) {
                notification.error({
                    message: `QuickBooks token expired${
                        centerName ? " for " + centerName : ""
                    }. Please sign in to QuickBooks and Refresh`,
                    duration: null
                });
            } else {
                notification.error({
                    message: "Unable to link Dialysis"
                });
            }
        } finally {
            setLoading(false);
        }
    };

    const getPatientSessions = (filter, props) => {
        setTable({
            ...table,
            loading: true,
            data: -1
        });

        // console.log("getPatientSessions filter", filter);
        // console.log("getPatientSessions props", props);
        // console.log("table", table);

        if (filter.searchType === "daily") {
            // console.log("call list dialysis session API");
            // call list diaylsis session API.
            // const timer = setTimeout(() => {
            //     setTable({
            //         ...table,
            //         // loading: dataState.patientSessionRecords.length !== 0 ? true : false,
            //         loading: false, // set false for simulation
            //         data: dataState.patientSessionRecords,
            //         pagination: {
            //             ...table.pagination,
            //             total: 7,
            //         }
            //     })
            //     clearTimeout(timer)
            // }, 1000)
            _listAppointmentDetail(filter, appState.selectedClinicID, props);
        } else {
            // console.log("call list patient record API");
            if (filter.searchType === "centre") {
                // console.log("from centre ID", appState.selectedClinicID);
                // _listPatient(filter, appState.selectedClinicID, props);
            } else {
                // console.log("from all centres");
                // _listPatient(filter, null, props);
            }
        }

        // const timer = setTimeout(() => {
        //     setTable({
        //         ...table,
        //         // loading: dataState.patientSessionRecords.length !== 0 ? true : false,
        //         loading: false, // set false for simulation
        //         data: dataState.patientSessionRecords
        //     })
        //     clearTimeout(timer)
        // }, 1000)

        // if (filterSearchTypeApplied == 'all') {
        //     _listPatient(filter.search);
        // } else if (filterSearchTypeApplied == 'centre') {
        //     _listPatient(filter.search, appState.selectedClinicID);
        // } else {
        //     const timer = setTimeout(() => {
        //         setTable({
        //             ...table,
        //             // loading: dataState.patientSessionRecords.length !== 0 ? true : false,
        //             loading: false, // set false for simulation
        //             data: dataState.patientSessionRecords
        //         })
        //         clearTimeout(timer)
        //     }, 1000)
        // }
    };

    const getQuickbooksToken = async () => {
        setClinic({
            ...clinic,
            loading: true
        });
        try {
            const variables = {
                filter: {
                    id: { eq: `${appState.selectedClinicID}` }
                },
                pagination2: {
                    limit: 1
                },
                filter2: {
                    id: {
                        eq: parseInt(appState.selectedClinicID)
                    }
                }
                // limit: 1,
            };

            // console.log('getQuickbooksToken', variables);

            const result = await API.graphql({
                query: listQBAccess,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables
                // authMode: "AWS_IAM",
            });
            // console.log("listQBAccesses", result);

            if (result?.data?.result?.items?.length > 0) {
                let data = result?.data?.result?.items[0];
                setQBAccess({
                    ...(data ?? {}),
                    dateDisplay: moment(data.date)
                        .local()
                        .format("DD MMM YYYY"),
                    expiresInDay: moment(data.date)
                        .add(data.expiresIn, "seconds")
                        .diff(moment(), "days")
                });
            }

            if (result?.data?.result2?.result?.length > 0) {
                // console.log(result?.data?.result2?.result);
                let _clinic = result?.data?.result2?.result[0];
                setClinic({
                    ...clinic,
                    data: _clinic
                    // loading: _clinic.qbClientID ? false : true,
                });
            }
        } catch (error) {
            console.log("error: ", error);
        }
    };

    const subscriptionAppointmentOnCreateSetup = () => {
        subscriptionAppointmentOnCreate = API.graphql({
            query: onCreateAppointment,
            authMode: "AMAZON_COGNITO_USER_POOLS",
            variables: {
                clinicID: localStorage.getItem("ircs_clinicid")
            }
        }).subscribe({
            next: async (_appointment) => {
                // console.log("appointment create", _appointment);
                getPatientSessions(
                    filterRef.current,
                    tableRef.current.pagination
                );
            }
        });
    };

    const subscriptionAppointmentOnUpdateSetup = () => {
        subscriptionAppointmentOnUpdate = API.graphql({
            query: onUpdateAppointment,
            authMode: "AMAZON_COGNITO_USER_POOLS",
            variables: {
                clinicID: localStorage.getItem("ircs_clinicid")
            }
        }).subscribe({
            next: async (_appointment) => {
                // console.log("appointment update", _appointment);
                getPatientSessions(
                    filterRef.current,
                    tableRef.current.pagination
                );
            }
        });
    };

    const subscriptionDialysisSessionOnStartSetup = () => {
        subscriptionDialysisSessionOnStart = API.graphql({
            query: onStartDialysis,
            authMode: "AMAZON_COGNITO_USER_POOLS",
            variables: {
                clinicID: localStorage.getItem("ircs_clinicid")
            }
        }).subscribe({
            next: async (_dialysisSession) => {
                // console.log("dialysisSession start", _dialysisSession);
                getPatientSessions(
                    filterRef.current,
                    tableRef.current.pagination
                );
            }
        });
    };

    const subscriptionDialysisIntervalOnSubmitSetup = () => {
        subscriptionDialysisIntervalOnSubmit = API.graphql({
            query: onSubmitDialysisInterval,
            authMode: "AMAZON_COGNITO_USER_POOLS"
            // variables: {
            //     clinicID: localStorage.getItem("ircs_clinicid")
            // }
        }).subscribe({
            next: async (_dialysisInterval) => {
                // console.log("dialysisInterval submit", _dialysisInterval);
                const item =
                    _dialysisInterval.value.data.onSubmitDialysisInterval;
                // console.log("item.clinicID", item.clinicID);
                // console.log("localStorage", localStorage.getItem("ircs_clinicid"));
                if (
                    item.clinicID?.toString() ===
                    localStorage.getItem("ircs_clinicid")
                ) {
                    getPatientSessions(
                        filterRef.current,
                        tableRef.current.pagination
                    );
                }
                // getPatientSessions(filterRef.current, tableRef.current.pagination)
            }
        });
    };

    const subscriptionDialysisSessionOnCheckoutSetup = () => {
        subscriptionDialysisSessionOnCheckout = API.graphql({
            query: onCheckoutDialysis,
            authMode: "AMAZON_COGNITO_USER_POOLS"
            // variables: {
            //     clinicID: localStorage.getItem("ircs_clinicid")
            // }
        }).subscribe({
            next: async (_onCheckoutDialysis) => {
                // console.log("dialysisSession checkout", _onCheckoutDialysis);
                const item = JSON.parse(
                    _onCheckoutDialysis.value.data.onCheckoutDialysis
                );
                // console.log("item", item);
                // console.log("item.clinicID", item.clinicID);
                // console.log("localStorage", localStorage.getItem("ircs_clinicid"));
                if (
                    item.clinicID?.toString() ===
                    localStorage.getItem("ircs_clinicid")
                ) {
                    getPatientSessions(
                        filterRef.current,
                        tableRef.current.pagination
                    );
                }
                // getPatientSessions(filterRef.current, tableRef.current.pagination)
            }
        });
    };

    useEffect(() => {
        // const currentDatetime = moment().local()
        // setFilter({
        //     ...filter,
        //     date: currentDatetime
        // })
        // filterRef.current = {
        //     ...filter,
        //     date: currentDatetime
        // }

        // subscriptionAppointmentOnCreateSetup()
        // subscriptionAppointmentOnUpdateSetup()
        // subscriptionDialysisSessionOnStartSetup()
        // subscriptionDialysisIntervalOnSubmitSetup()
        // subscriptionDialysisSessionOnCheckoutSetup()

        return () => {
            // subscriptionAppointmentOnCreate.unsubscribe()
            // subscriptionAppointmentOnUpdate.unsubscribe()
            // subscriptionDialysisSessionOnStart.unsubscribe()
            // subscriptionDialysisIntervalOnSubmit.unsubscribe()
            // subscriptionDialysisSessionOnCheckout.unsubscribe()
        };
    }, []);

    useEffect(() => {
        // console.log("appState", appState);
        // console.log('props', props);

        if (Object.keys(appState.cognitoUser).length !== 0) {
            getQuickbooksToken();

            let checkout = JSON.parse(sessionStorage.getItem("ircs_quickbook"));
            // console.log(checkout);

            if (checkout) {
                let patientID = checkout.patientID;
                let dialysisID = checkout.dialysisID;
                let quickbook = props.location.search;

                sessionStorage.removeItem("ircs_quickbook");
                if (quickbook != undefined && quickbook.length > 0) {
                    onFinishLinkQB(quickbook, patientID, dialysisID);
                } else {
                    const currentDatetime = moment().local();
                    // console.log("currentDatetime", currentDatetime);
                    form.setFieldsValue({
                        ...filter,
                        date: currentDatetime
                    });
                }
            }
            // getPatientSessions(filter, table.pagination) // commented to resolve the double query issue on initial load
        }
        return () => {};
    }, [appState.cognitoUser]);

    // useEffect(() => {
    //     // reset back to today
    //     form.setFieldsValue({
    //         ...filter,
    //         date: filter.date !== null ? filter.date : moment()
    //     });
    // }, [filter.searchType, filter.searchTypeDaily, filter.search])

    useEffect(() => {
        // console.log("FILTER");
        let object = {};
        if (filter.searchType === "daily") {
            // setFilter({
            //     ...filter,
            //     searchTypeDisplay: filter.searchType.slice(0, 1).toUpperCase() + filter.searchType.slice(1).toLowerCase() + " - " + filter.searchTypeDaily.slice(0, 1).toUpperCase() + filter.searchTypeDaily.slice(1).toLowerCase()
            // })
            object = {
                ...filter,
                searchTypeDisplay:
                    filter.searchType.slice(0, 1).toUpperCase() +
                    filter.searchType.slice(1).toLowerCase() +
                    " - " +
                    filter.searchTypeDaily.slice(0, 1).toUpperCase() +
                    filter.searchTypeDaily.slice(1).toLowerCase()
            };
        } else if (filter.searchType === "all") {
            // setFilter({
            //     ...filter,
            //     searchTypeDisplay: "All Centres"
            // })
            object = {
                ...filter,
                searchTypeDisplay: "All Centres"
            };
        } else {
            // setFilter({
            //     ...filter,
            //     searchTypeDisplay: filter.searchType.slice(0, 1).toUpperCase() + filter.searchType.slice(1).toLowerCase()
            // })
            object = {
                ...filter,
                searchTypeDisplay:
                    filter.searchType.slice(0, 1).toUpperCase() +
                    filter.searchType.slice(1).toLowerCase()
            };
        }
        setFilter(object);
        filterRef.current = object;

        //reload with reset pagination
        // console.log('reset pagination')
        onFinish(
            {
                pagination: {
                    ...table.pagination,
                    current: 1,
                    offset: 0
                }
            },
            filter
        );
    }, [filter.searchType, filter.searchTypeDaily, filter.date]);

    useEffect(() => {
        // console.log('daily update table', table);
        if (table.data !== -1) {
            if (filter.searchType === "daily") {
                // Filter out unique date shift
                let data_dateShift = table.data.map((s) => {
                    return JSON.stringify({ date: s.date, shift: s.shift });
                });
                let filtered_dateShift = [...new Set(data_dateShift)];
                filtered_dateShift = filtered_dateShift.map((s) =>
                    JSON.parse(s)
                );

                let sorted_dateShift = filtered_dateShift.sort(
                    (a, b) =>
                        (moment(a.date) > moment(b.date) ? 1 : -1) ||
                        (a.shift === "M" && b.shift !== "M" ? 1 : -1) ||
                        (a.shift === "N" && b.shift === "E" ? 1 : -1) ||
                        (a.shift === "E" ? -1 : -1)
                );
                sorted_dateShift = sorted_dateShift.sort(
                    (a, b) =>
                        (moment(a.date) > moment(b.date) ? 1 : -1) ||
                        (a.shift === "M" && b.shift !== "M" ? 1 : -1) ||
                        (a.shift === "N" && b.shift === "E" ? 1 : -1) ||
                        (a.shift === "E" ? -1 : -1)
                );
                // console.log('sorted_dateShift', sorted_dateShift);

                let _data = [];
                for (let dateShift of sorted_dateShift) {
                    _data.push({
                        date: dateShift.date,
                        shift: dateShift.shift,
                        data: table.data.filter(
                            (s) =>
                                s.date === dateShift.date &&
                                s.shift === dateShift.shift
                        )
                    });
                }

                setUnlinkedListDisplay(_data);
            }
            // else {
            //     setDisplayList(table.data)
            // }
            setTable({
                ...table,
                loading: false
            });
            tableRef.current = {
                ...table,
                loading: false
            };
        }
    }, [table.data]);

    // useEffect(() => {
    //     if (executeSearch) {
    //         getPatientSessions()
    //         setExecuteSearch(false);
    //     }
    //     return () => { }
    // }, [executeSearch])

    const onFinish = (value = null, _filter = null) => {
        // console.log("submit form", value);
        // console.log("hello filter", _filter);
        // console.log("form get", form.getFieldsValue());
        // console.log("hello hello filter", filter);
        // getPatientSessions(form.getFieldsValue(), table.pagination)
        if (_filter !== null) {
            getPatientSessions(
                _filter,
                value?.pagination ? value.pagination : table.pagination
            );
        } else {
            getPatientSessions(
                filter,
                value?.pagination ? value.pagination : table.pagination
            );
            // getPatientSessions(form.getFieldsValue(), value?.pagination ? value.pagination : table.pagination)
        }
        // setExecuteSearch(true);
    };

    const onFinishLinkQB = async (
        url = null,
        patientID,
        dialysisID,
        centerName = ""
    ) => {
        // console.log("Handle check out clicked", url);
        // _updateDialysis(patientID, form.getFieldValue());

        if (appState.tokenExpired) {
            notification.error({
                message:
                    "QuickBooks token expired. Please sign in to QuickBooks and Refresh",
                duration: null
            });
        } else if ((url && url.length > 0) || appState.useRefreshToken) {
            // console.log('PatientRecordPostweightsDialysisOrder after quickbook', _values, url)
            _dialysisLinkQuickbook(patientID, dialysisID, url, centerName);
        }
    };

    const handleDatePickerChange = (date, dateString) => {
        // console.log("handleDatePickerChange");
        // console.log(date, dateString);
        setFilter({
            ...filter,
            date: date
        });
        filterRef.current = {
            ...filter,
            date: date
        };
    };

    const handleSearchChange = (e) => {
        // console.log("handleSearchChange");
        // console.log(e.target.value);
        setFilter({
            ...filter,
            search: e.target.value
        });
        filterRef.current = {
            ...filter,
            search: e.target.value
        };
        // if (filter.typingTimeout !== null) {
        //     clearTimeout(filter.typingTimeout)
        // }
        // setFilter({
        //     ...filter,
        //     typingTimeout: setTimeout(() => {
        //         console.log("stopped typing alr");
        //         // console.log("stop", filter);
        //         clearTimeout(filter.typingTimeout)
        //     }, 5000)
        // })
    };

    const handleRadioChangeSearchType = (e) => {
        // console.log("searchType", e.target.value);
        // console.log("filter", filter);
        setFilter({
            ...filter,
            searchType: e.target.value
        });
        filterRef.current = {
            ...filter,
            searchType: e.target.value
        };
        setFilterSearchTypeApplied(e.target.value);
        form.setFieldsValue({ searchType: e.target.value });
    };

    const handleRadioChangeSearchTypeDaily = (e) => {
        // console.log("searchTypeDaily", e.target.value);
        setFilter({
            ...filter,
            searchTypeDaily: e.target.value
        });
        filterRef.current = {
            ...filter,
            searchTypeDaily: e.target.value
        };
    };

    const handleCustomedDropdownVisibileChange = (visible) => {
        setCustomedDropdownVisibility(visible);
    };

    // const handleChange = (key) => {
    //     console.log("key", key);
    // }

    const handleTableChange = (page, pageSize) => {
        // console.log("handletablechange page", page);
        // console.log("handletablechange pageSize", pageSize);
        window.scrollTo({ top: 0 });
        getPatientSessions(filter, {
            ...table.pagination,
            pageSize: pageSize,
            current: page,
            offset: page * pageSize - pageSize
        });
        // getPatientSessions(filter, {
        //     ...table.pagination,
        //     current: paginate,
        //     offset: paginate * table.pagination.pageSize - table.pagination.pageSize,
        // })
    };

    const renderCards = (array) => {
        const cards = array.map((item) => {
            return (
                <CardPatient
                    item={item}
                    key={item.id}
                    filterSearchTypeApplied={filterSearchTypeApplied}
                    manageInvoice={true}
                    onFinishLinkQB={onFinishLinkQB}
                />
            );
        });
        return cards;
    };

    const renderCardPatientRecords = (array) => {
        return array?.length === 0 ? (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ) : (
            <div className="container-card">{renderCards(array)}</div>
        );
    };

    const renderUnlinkedRecords = (array) => {
        // console.log(array);
        return array.map((s) => (
            <div>
                <Divider orientation="left">
                    {moment(s.date).format("DD MMM YYYY (ddd)") +
                        " - " +
                        (s.shift === "M"
                            ? "Morning Session"
                            : s.shift === "N"
                            ? "Noon Session"
                            : "Evening Session")}
                    {/* 29 Apr 2022 (Fri) - Morning Session */}
                </Divider>
                {renderCardPatientRecords(s.data)}
            </div>
        ));
    };

    const customedDropdown = () => {
        return (
            <div className="column">
                <Radio.Group
                    onChange={handleRadioChangeSearchType}
                    value={filter.searchType}
                >
                    <Space direction="vertical">
                        <Radio value={"daily"}>Search Daily</Radio>
                        {/* <Radio value={"centre"}>Search Current Centre</Radio>
                        <Radio value={"all"}>Search All Centres</Radio> */}
                    </Space>
                </Radio.Group>
                {filter.searchType === "daily" ? (
                    <>
                        <Divider />
                        <Radio.Group
                            onChange={handleRadioChangeSearchTypeDaily}
                            value={filter.searchTypeDaily}
                        >
                            <Space direction="vertical">
                                <Radio value={"all"}>All Sessions</Radio>
                                <Radio value={"morning"}>
                                    Morning Sessions
                                </Radio>
                                <Radio value={"noon"}>Noon Sessions</Radio>
                                <Radio value={"evening"}>
                                    Evening Sessions
                                </Radio>
                            </Space>
                        </Radio.Group>
                    </>
                ) : (
                    ""
                )}
                {/* <br />
                <Button type="primary" onClick={() => { setCustomedDropdownVisibility(false) }} style={{ borderRadius: "5px" }}>
                    Ok
                </Button> */}
            </div>
        );
    };

    const handleRefreshQBOnClick = () => {
        let locURL = window.location.href.split("//");
        let domainURL = locURL[1].split("/")[0];
        // let redirect_uri = (window.location.hostname == 'localhost' ? `http://localhost:3000` : window.location.href.split('//')[0]) + path('redirectQuickbooks');
        let redirect_uri =
            locURL[0] + "//" + domainURL + path("redirectQuickbooks") + "/";
        // console.log(redirectURL', redirect_uri);
        // console.log(`https://appcenter.intuit.com/connect/oauth2?client_id=${clinic?.data?.qbClientID}&response_type=code&scope=com.intuit.quickbooks.accounting&redirect_uri=${redirect_uri}&state=testState`)

        window.open(
            `https://appcenter.intuit.com/connect/oauth2?client_id=${clinic?.data?.qbClientID}&response_type=code&scope=com.intuit.quickbooks.accounting&redirect_uri=${redirect_uri}&state=testState`,
            "_blank"
        );
    };

    return (
        <div className="content-content">
            <div className="manage-header">
                <div className="container-rows">
                    <div className="row1">
                        <div className="container-title">
                            <div className="title">Manage Unlinked Invoice</div>
                            <Space className="row">
                                <div
                                    className="statscard"
                                    style={{ height: "100px" }}
                                >
                                    <div className="column1">
                                        {appState.selectedClinicID &&
                                        appState?.fixedClinicList?.length
                                            ? `${
                                                  appState?.fixedClinicList?.find(
                                                      (s) =>
                                                          s.id ==
                                                          appState.selectedClinicID
                                                  )?.uid
                                              } `
                                            : ""}
                                        QuickBook
                                        <br />
                                        Last Connected <br />
                                        {qbAccess?.dateDisplay ?? "-"}
                                    </div>
                                    {/* <div className="column2">
                                </div> */}
                                </div>
                                <div
                                    className="statscard"
                                    style={{ height: "100px" }}
                                >
                                    {qbAccess?.expiresInDay > 0 ? (
                                        <div className="column1">
                                            Expiring In <br />
                                            {qbAccess.expiresInDay} days
                                        </div>
                                    ) : (
                                        <div className="column1 red">
                                            Expired <br />
                                            {qbAccess?.expiresInDay ?? 0} days
                                            ago
                                        </div>
                                    )}

                                    {/* <div className="column2">
                                </div> */}
                                </div>
                            </Space>
                        </div>
                        <Button
                            type="primary"
                            loading={clinic.loading}
                            disabled={
                                !clinic.loading && !clinic?.data?.qbClientID
                            }
                            onClick={() => handleRefreshQBOnClick()}
                        >
                            Refresh QuickBooks Authentication
                        </Button>
                    </div>
                    {/* <div className="row1 title">
                    Manage Unlinked Invoice
                </div> */}
                    <Form
                        form={form}
                        layout="horizontal"
                        onFinish={onFinish}
                        requiredMark={false}
                    >
                        <div className="container-row">
                            <div className="row2">
                                <div className="row">
                                    {/* <Form.Item
                                    className="date"
                                    name="date"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <DatePicker onChange={handleDatePickerChange} format={dateFormatList} />
                                </Form.Item>
                                <Form.Item
                                    name="searchType"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Popover
                                        content={customedDropdown()}
                                        placement="bottom"
                                        trigger="click"
                                        visible={customedDropdownVisibility}
                                        onVisibleChange={handleCustomedDropdownVisibileChange}
                                    >
                                        <Button className="ddl">
                                            {filter.searchTypeDisplay} <DownOutlined />
                                        </Button>
                                    </Popover>
                                </Form.Item>
                                <Form.Item
                                    className="display-none"
                                    name="searchTypeDaily"
                                >
                                    <Input autoComplete="off" />
                                </Form.Item> */}
                                    <Form.Item
                                        className="search"
                                        name="search"
                                        rules={[
                                            {
                                                pattern: new RegExp(
                                                    /^[\w\-' ]+$/
                                                ),
                                                message: "Alphanumeric only"
                                            }
                                        ]}
                                    >
                                        <Input
                                            autoComplete="off"
                                            prefix={<SearchOutlined />}
                                            placeholder="Search patient / NRIC"
                                            onChange={handleSearchChange}
                                            value={filter.search}
                                            allowClear
                                        />
                                    </Form.Item>
                                    <Button type="primary" htmlType="submit">
                                        Search
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Form>
                    {/* <div>
                    {table.pagination.total} record(s) found{
                        table.pagination.total !== 0 ? (
                            <span>, showing page {table.pagination.current} out of {Math.ceil(table.pagination.total / table.pagination.pageSize)}</span>
                        ) : ""
                    }
                </div> */}
                </div>
            </div>
            <Spin spinning={table.loading || loading}>
                {errorMessageDisplay !== false ? (
                    <div className="importlabresult-error">
                        Insufficient seats {`(${errorMessageDisplay})`}
                    </div>
                ) : (
                    ""
                )}
                <div>
                    {filterSearchTypeApplied === "daily" ? (
                        <div>{renderUnlinkedRecords(unlinkedListDisplay)}</div>
                    ) : (
                        <>{renderCardPatientRecords(displayList)}</>
                    )}
                </div>
                <br />
                <Pagination
                    current={table.pagination.current}
                    pageSize={table.pagination.pageSize}
                    total={table.pagination.total}
                    onChange={handleTableChange}
                    // showSizeChanger={false}
                />
            </Spin>
        </div>
    );
};

export default ManageUnlinkedInvoice;
