import React, { useEffect, useState } from "react"
// import { Form } from "antd"

// import PatientRecordHeader from "./Component-PatientRecord-Header"
// import PatientRecordPreweights from "./Component-PatientRecord-Preweights"
// import PatientRecordDialysisOrder from "./Component-PatientRecord-DialysisOrder"
import PatientRecordAdministeredMedication from "./Component-PatientRecord-AdministeredMedication"
import PatientRecordCalculatedLabResults from "./Component-PatientRecord-CalculatedLabResults"
import PatientRecordClinicNHospitalE from "./Component-PatientRecord-ClinicNHospitalE"
import PatientRecordDialysisHistory from "./Component-PatientRecord-DialysisHistory"
import PatientRecordLabResults from "./Component-PatientRecord-LabResults"
import PatientRecordMedLabMilestone from "./Component-PatientRecord-MedLabMilestone"
import PatientRecordPreweightsDialysisOrder from "./Component-PatientRecord-PreweightsDialysisOrder"
import PatientRecordLastNonNullLab from "./Component-PatientRecord-LastNonNullLab"

const PatientRecord = (props) => {
    useEffect(() => {
        return () => {}
    }, [])

    return (
        <div className="content-content">
            <div className="container-row">
                <div className="view-desktop view-desktop-md">
                    <PatientRecordPreweightsDialysisOrder page={"record"} patientID={props?.match?.params?.id} />
                    <PatientRecordDialysisHistory patientID={props?.match?.params?.id} />
                    <div className="importlabresult-content">
                        <PatientRecordLastNonNullLab patientID={props?.match?.params?.id}  />
                        <br />
                    </div>
                    <PatientRecordMedLabMilestone patientID={props?.match?.params?.id} />
                    <PatientRecordAdministeredMedication patientID={props?.match?.params?.id} />
                    <PatientRecordClinicNHospitalE patientID={props?.match?.params?.id} />
                    <PatientRecordCalculatedLabResults patientID={props?.match?.params?.id} />
                    <PatientRecordLabResults patientID={props?.match?.params?.id} />
                </div>
            </div>
        </div>
    )
}

export default PatientRecord