import React, { useEffect }  from "react"
import { Result, Button } from "antd"
import { Link } from "react-router-dom"

const Error404 = () => {
    useEffect(() => {

        return () => {}
    }, [])

    return (
        <Result
            status="404"
            title="404"
            subTitle="Sorry, the page you visited does not exist."
            extra={<Button type="primary"><Link push to="/">Back Home</Link></Button>}
        />
    )
}

export default Error404