import React, { useContext, useEffect, useState } from "react";
import {
    Button,
    Form,
    DatePicker,
    Input,
    Modal,
    notification,
    Popconfirm,
    Space,
    Spin,
    Table,
    TreeSelect
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { API } from "aws-amplify";

import { listMachine } from "../backend/graphql/queries";
import { createMachine, updateMachine } from "../backend/graphql/mutations";
import { AppContext } from "../contexts/AppContext";
import renderInputItems from "../utils/renderFormInputItems";
import iconCalendar from "../media/Icon-Calendar.png";
import iconMinus from "../media/Icon-Minus.png";
import { listMachine_AvailableMachine_UnavailableMachine } from "../backend/graphql/custom_queries";

const ManageMachine = () => {
    const { appState } = useContext(AppContext);
    const [formAdd] = Form.useForm();
    const [formUpdate] = Form.useForm();
    const [formSearch] = Form.useForm();
    const dateFormatList = ["DD MMM YYYY"];
    const { TreeNode, SHOW_PARENT } = TreeSelect;
    const [filter, setFilter] = useState({
        centre: [0],
        search: ""
    });
    const validateMessages = {
        required: "Required."
    };
    const [totalMachine, setTotalMachine] = useState("");
    const [totalAvailableMachine, setTotalAvailableMachine] = useState(0);
    const [totalUnavailableMachine, setTotalUnavailableMachine] = useState(0);
    const [table, setTable] = useState({
        data: -1,
        pagination: {
            current: 1,
            pageSize: 20,
            offset: 0
        },
        loading: false
    });
    const [display, setDisplay] = useState([]);
    const [clinicList, setClinicList] = useState(-1);
    const [visibilityAddMachine, setVisibilityAddMachine] = useState(false);
    const [visibilityUpdateMachine, setVisibilityUpdateMachine] =
        useState(false);
    const [editMachine, setEditMachine] = useState({});
    const [loadingModal, setLoadingModal] = useState(false);

    useEffect(() => {
        getMachineList(table.pagination, filter);
        formSearch.setFieldsValue(filter);
    }, []);

    useEffect(() => {
        if (appState.fixedListLoaded === true) {
            setClinicList(appState.clinicList);
        }
    }, [appState.fixedListLoaded]);

    useEffect(() => {
        if (table.data !== -1 && clinicList !== -1) {
            // console.log("clinicList", clinicList);
            let array = [];
            for (let i = 0; i < table.data.length; i++) {
                let object = {
                    ...table.data[i]
                };
                for (let j = 0; j < clinicList.length; j++) {
                    if (table.data[i].clinicID === clinicList[j].id) {
                        object = {
                            ...object,
                            centreName: clinicList[j].name
                        };
                        break;
                    }
                }
                array.push(object);
            }
            setDisplay(array);
            setTable({
                ...table,
                loading: false
            });
        }
    }, [table.data, clinicList]);

    useEffect(() => {
        if (Object.keys(editMachine).length !== 0) {
            // console.log("editMachine", editMachine);
            formUpdate.setFieldsValue({
                clinicID: editMachine.clinicID ? editMachine.clinicID : "",
                name: editMachine.name ? editMachine.name : "",
                serialNo: editMachine.serialNo ? editMachine.serialNo : "",
                manufacturerName: editMachine.manufacturer
                    ? editMachine.manufacturer
                    : "",
                status: editMachine.status ? editMachine.status : ""
                // expiryDate: moment(new Date(editMachine.expiryDate.replace(/-/g, "/") + " UTC")),
            });
        }
    }, [editMachine]);

    const getMachineList = async (props, values) => {
        // console.log("values", values);
        setTable({
            ...table,
            loading: true
        });
        try {
            let filter = {
                deleted: { eq: 0 }
            };

            if (values.search != "") {
                if (!filter.and) filter.and = [];

                filter.and.push({
                    or: [
                        { name: { contains: values.search } },
                        { serialNo: { contains: values.search } }
                    ]
                });
            }

            // console.log("values.centre[0]", values.centre[0]);
            if (
                values.centre &&
                values.centre.length >= 1 &&
                values.centre[0] !== 0
            ) {
                if (!filter.and) filter.and = [];

                let array = [];
                for (let i = 0; i < values.centre.length; i++) {
                    array.push({
                        clinicID: { eq: values.centre[i] }
                    });
                }
                filter.and.push({
                    or: array
                });
            }

            const machineDetails = {
                pagination: {
                    limit: props.pageSize,
                    offset: props.offset,
                    orderby: "status ASC, name ASC"
                },
                filter: filter,
                filter2: { ...filter, status: { eq: "AVAILABLE" } },
                filter3: { ...filter, status: { eq: "NOTAVAILABLE" } }
            };
            // console.log("machineDetails", machineDetails);
            const result = await API.graphql({
                query: listMachine_AvailableMachine_UnavailableMachine,
                variables: machineDetails,
                authMode: "AMAZON_COGNITO_USER_POOLS"
            });
            // console.log(result);
            const data = result.data.result;
            const data2 = result.data.result2;
            const data3 = result.data.result3;

            // console.log("data", data);
            setTotalMachine(data.count);
            setTotalAvailableMachine(data2.count);
            setTotalUnavailableMachine(data3.count);
            setTable({
                ...table,
                data: data.result,
                loading: data.result.length === 0 ? false : true,
                pagination: {
                    ...table.pagination,
                    current: props.current,
                    offset: props.offset,
                    pageSize: props.pageSize,
                    total:
                        props.offset >= data.count ? data.count + 1 : data.count // keeps the last pagination if it is the last record
                }
            });
        } catch (error) {
            notification.error({
                message: "Unable to retrieve records"
            });
            setTable({
                ...table,
                loading: false
            });
        }
    };

    const createMachineRecord = async (values) => {
        setLoadingModal(true);
        try {
            const machineDetails = {
                create: {
                    clinicID: values.clinicID,
                    name: values.name,
                    serialNo: values.serialNo,
                    manufacturer: values.manufacturerName,
                    status: values.status
                }
            };
            // console.log("machineDetails", machineDetails);
            const result = await API.graphql({
                query: createMachine,
                variables: machineDetails,
                authMode: "AMAZON_COGNITO_USER_POOLS"
            });
            // console.log(result);
            // const data = result.data.result
            // console.log("data", data);
            notification.success({
                message: "Created successfully"
            });
            getMachineList(table.pagination, filter);
            formAdd.resetFields();
            handleModalVisibility("addMachine");
        } catch (error) {
            console.log("error", error);
            notification.error({
                message: "Unable to create record"
            });
        } finally {
            setLoadingModal(false);
        }
    };

    const updateMachineRecord = async (values, id) => {
        setLoadingModal(true);
        try {
            const machineDetails = {
                update: {
                    id: id,
                    clinicID: values.clinicID,
                    name: values.name,
                    serialNo: values.serialNo,
                    manufacturer: values.manufacturerName,
                    status: values.status
                }
            };
            // console.log("machineDetails", machineDetails);
            const result = await API.graphql({
                query: updateMachine,
                variables: machineDetails,
                authMode: "AMAZON_COGNITO_USER_POOLS"
            });
            // console.log(result);
            // const data = result.data.result
            // console.log("data", data);
            notification.success({
                message: "Updated successfully"
            });
            getMachineList(table.pagination, filter);
            handleModalVisibility("updateMachine");
        } catch (error) {
            console.log("error", error);
            if (error.errors[0].message == "MachineStatus expired.") {
                notification.error({
                    message: "MachineStatus expired."
                });
            } else {
                notification.error({
                    message: "Unable to update record"
                });
            }
        } finally {
            setLoadingModal(false);
        }
    };

    const deleteMachineRecord = async (id) => {
        setLoadingModal(true);
        try {
            const machineDetails = {
                update: {
                    id: id,
                    deleted: 1
                }
            };
            // console.log("machineDetails", machineDetails);
            const result = await API.graphql({
                query: updateMachine,
                variables: machineDetails,
                authMode: "AMAZON_COGNITO_USER_POOLS"
            });
            // console.log(result);
            // const data = result.data.result
            // console.log("data", data);
            notification.success({
                message: "Deleted successfully"
            });
            getMachineList(table.pagination, filter);
        } catch (error) {
            console.log("error", error);
            notification.error({
                message: "Unable to update record"
            });
        } finally {
            setLoadingModal(false);
        }
    };

    const onFinishSearch = (values) => {
        // console.log("submit formSearch", values);
        setFilter(values);
        getMachineList(table.pagination, values);
    };

    const onFinishAdd = async (values) => {
        // console.log("onFinishAdd", values);
        await formAdd.validateFields();
        createMachineRecord(values);
        // setLoadingModal(true)
        // const timer = setTimeout(() => {
        //     setLoadingModal(false)
        //     clearTimeout(timer)
        //     handleModalVisibility("addMachine")
        // }, 1000)
    };

    const onFinishUpdate = async (values, id) => {
        // console.log("onFinishUpdate", values);
        await formUpdate.validateFields();
        updateMachineRecord(values, id);
        // setLoadingModal(true)
        // const timer = setTimeout(() => {
        //     setLoadingModal(false)
        //     clearTimeout(timer)
        //     handleModalVisibility("updateMachine")
        // }, 1000)
    };

    const handleTableChange = (paginate) => {
        getMachineList(
            {
                ...table.pagination,
                pageSize: paginate.pageSize,
                current: paginate.current,
                offset: paginate.current * paginate.pageSize - paginate.pageSize
            },
            filter
        );
    };

    const handleModalVisibility = (modal, record) => {
        // console.log("modal", modal);
        switch (modal) {
            case "addMachine":
                setVisibilityAddMachine(!visibilityAddMachine);
                break;
            case "updateMachine":
                setEditMachine(record ? record : {});
                setVisibilityUpdateMachine(!visibilityUpdateMachine);
                break;
            default:
        }
    };

    const handleDatePickerChange = (date, dateString) => {
        // console.log("handleDatePickerChange");
        // console.log(date, dateString);
    };

    const handleTreeSelectChange = (value, label, extra) => {
        // console.log("value", value);
        // console.log("label", label);
        // console.log("extra", extra);
    };

    const handleDelete = async (id) => {
        // console.log("handle delete", id);
        deleteMachineRecord(id);
    };

    const renderTreeSelectOptions = (array) => {
        if (typeof array == "object" && array.length !== 0) {
            const listOptions = array.map((item, index) => {
                return (
                    <TreeNode title={item.name} value={item.id} key={item.id} />
                );
            });

            return listOptions;
        } else {
            return <div />;
        }
    };

    const columns = [
        {
            title: "Machine Name",
            dataIndex: "name"
        },
        {
            title: "Machine Serial No",
            dataIndex: "serialNo"
        },
        {
            title: "Centre",
            dataIndex: "centreName"
        },
        {
            title: "Manufacturer",
            dataIndex: "manufacturer"
        },
        {
            title: "Status",
            dataIndex: "status"
        },
        // {
        //     title: "Expiry Date",
        //     dataIndex: "expiryDate"
        // },
        {
            title: "Actions",
            dataIndex: "actions",
            render: (_, record) => {
                return (
                    <Space size="large">
                        <div
                            className="table-row-action clickable"
                            onClick={() =>
                                handleModalVisibility("updateMachine", record)
                            }
                        >
                            <div className="icon">
                                <img src={iconCalendar} />
                            </div>
                            <div className="text">Edit</div>
                        </div>
                        <Popconfirm
                            title="Are you sure?"
                            onConfirm={() => handleDelete(record.id)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <div className="table-row-action clickable red">
                                <div className="icon">
                                    <img src={iconMinus} />
                                </div>
                                <div className="text">Delete</div>
                            </div>
                        </Popconfirm>
                    </Space>
                );
            }
        }
    ];

    return (
        <div className="content-content">
            <div className="manage-header">
                <div className="container-rows">
                    <div className="row1">
                        <div className="container-title">
                            <div className="title">Manage Machines</div>
                            <Space className="row">
                                <div className="statscard">
                                    <div className="column1">
                                        Total
                                        <br />
                                        Machines
                                    </div>
                                    <div className="column2">
                                        {totalMachine}
                                    </div>
                                </div>
                                {totalAvailableMachine !== 0 ? (
                                    <div className="statscard">
                                        <div className="column1">
                                            Available
                                            <br />
                                            Machines
                                        </div>
                                        <div className="column2">
                                            {totalAvailableMachine}
                                        </div>
                                    </div>
                                ) : (
                                    ""
                                )}
                                {totalUnavailableMachine !== 0 ? (
                                    <div className="statscard">
                                        <div className="column1">
                                            Unavailable
                                            <br />
                                            Machines
                                        </div>
                                        <div className="column2">
                                            {totalUnavailableMachine}
                                        </div>
                                    </div>
                                ) : (
                                    ""
                                )}
                            </Space>
                        </div>
                        <Button
                            type="primary"
                            onClick={() => handleModalVisibility("addMachine")}
                        >
                            Add Machine
                        </Button>
                    </div>
                    <Form
                        form={formSearch}
                        layout="horizontal"
                        onFinish={onFinishSearch}
                        validateMessages={validateMessages}
                        requiredMark={false}
                    >
                        <div className="container-row">
                            <div className="row2">
                                <div className="row">
                                    <Form.Item
                                        key="centre"
                                        name="centre"
                                        rules={[
                                            {
                                                required: true
                                            }
                                        ]}
                                        style={{ minWidth: 430, width: "50%" }}
                                    >
                                        <TreeSelect
                                            showSearch
                                            allowClear
                                            multiple
                                            treeDefaultExpandAll
                                            treeCheckable
                                            placeholder="Select clinics"
                                            showCheckedStrategy={SHOW_PARENT}
                                            filterTreeNode={(input, treenode) =>
                                                treenode.title
                                                    .toLowerCase()
                                                    .indexOf(
                                                        input.toLowerCase()
                                                    ) >= 0
                                            }
                                            // defaultValue={0}
                                            onChange={handleTreeSelectChange}
                                        >
                                            <TreeNode value={0} title="All">
                                                {/* {renderTreeSelectOptions(clinicList !== -1 ? clinicList: [])} */}
                                                {clinicList !== -1
                                                    ? renderTreeSelectOptions(
                                                          clinicList
                                                      )
                                                    : ""}
                                            </TreeNode>
                                        </TreeSelect>
                                    </Form.Item>
                                </div>
                                <div className="row">
                                    <Form.Item className="search" name="search">
                                        <Input
                                            autoComplete="off"
                                            prefix={<SearchOutlined />}
                                            placeholder="Search machine, machine serial no or manufacturer"
                                            value={filter.search}
                                            allowClear
                                        />
                                    </Form.Item>
                                    <Button type="primary" htmlType="submit">
                                        Search
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
            <div className="manage-content">
                <Table
                    columns={columns}
                    rowKey={(record) => record.id}
                    rowClassName={(record, index) => {
                        // console.log("index", index);
                        return index % 2 === 0 ? "highlight" : "";
                    }}
                    loading={table.loading}
                    dataSource={display}
                    pagination={table.pagination}
                    onChange={handleTableChange}
                    // dataSource={[
                    //     {
                    //         id: "1",
                    //         name: "Machine 123",
                    //         machineID: "rts123123123",
                    //         centreID: 1,
                    //         centreName: "Centre ABC",
                    //         manufacturerName: "Manufacturer Ptd Ltd",
                    //         expiryDate: "2021-04-10 06:00:00",
                    //     },
                    //     {
                    //         id: "2",
                    //         name: "Machine 123",
                    //         machineID: "rts123123123",
                    //         centreID: 2,
                    //         centreName: "Centre DEF",
                    //         manufacturerName: "Manufacturer Ptd Ltd",
                    //         expiryDate: "2021-04-10 06:00:00",
                    //     },
                    //     {
                    //         id: "3",
                    //         name: "Machine 123",
                    //         machineID: "rts123123123",
                    //         centreID: 3,
                    //         centreName: "Centre HIJ",
                    //         manufacturerName: "Manufacturer Ptd Ltd",
                    //         expiryDate: "2021-04-10 06:00:00",
                    //     },
                    // ]}
                />
            </div>
            <Modal
                title={<div className="modal-title">Edit Machine</div>}
                visible={visibilityUpdateMachine}
                onCancel={() => handleModalVisibility("updateMachine")}
                footer={[
                    <Button
                        key="back"
                        onClick={() => handleModalVisibility("updateMachine")}
                    >
                        Cancel
                    </Button>,
                    <Popconfirm
                        title="Are you sure?"
                        onConfirm={() =>
                            onFinishUpdate(
                                formUpdate.getFieldsValue(),
                                editMachine.id
                            )
                        }
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button
                            key="submit"
                            type="primary"
                            loading={loadingModal}
                        >
                            Update
                        </Button>
                    </Popconfirm>
                ]}
                className="modal-manage"
                width={800}
            >
                <div className="modal-content">
                    <Spin spinning={loadingModal}>
                        <Form
                            form={formUpdate}
                            layout="horizontal"
                            onFinish={onFinishUpdate}
                            validateMessages={validateMessages}
                            requiredMark={false}
                        >
                            {renderInputItems([
                                {
                                    label: "Centre",
                                    name: "clinicID",
                                    type: "select",
                                    placeholder: "Select centre",
                                    required: true,
                                    optionList:
                                        clinicList !== -1 ? clinicList : []
                                    // optionList: [
                                    //     { id: 1, name: "Centre ABC" },
                                    //     { id: 2, name: "Centre CDE" },
                                    //     { id: 3, name: "Centre HIJ" },
                                    //     { id: 4, name: "Centre KLM" },
                                    // ]
                                },
                                {
                                    label: "Machine Name",
                                    name: "name",
                                    type: "input",
                                    placeholder: "Enter machine name",
                                    required: true
                                },
                                {
                                    label: "Machine Serial No",
                                    name: "serialNo",
                                    type: "input",
                                    placeholder: "Enter machine serial no",
                                    required: true
                                },
                                {
                                    label: "Manufacturer",
                                    name: "manufacturerName",
                                    type: "input",
                                    placeholder: "Enter manufacturer name"
                                },
                                {
                                    label: "Status",
                                    name: "status",
                                    type: "select",
                                    placeholder: "Select status",
                                    required: true,
                                    optionList: [
                                        { id: "AVAILABLE", name: "AVAILABLE" },
                                        {
                                            id: "NOTAVAILABLE",
                                            name: "NOT AVAILABLE"
                                        }
                                    ]
                                }
                                // {
                                //     label: "Expiry Date",
                                //     name: "expiryDate",
                                //     type: "datepicker",
                                // },
                            ])}
                        </Form>
                    </Spin>
                </div>
            </Modal>
            <Modal
                title={<div className="modal-title">Add Machine</div>}
                visible={visibilityAddMachine}
                onCancel={() => handleModalVisibility("addMachine")}
                footer={[
                    <Button
                        key="back"
                        onClick={() => handleModalVisibility("addMachine")}
                    >
                        Cancel
                    </Button>,
                    <Popconfirm
                        title="Are you sure"
                        onConfirm={() => onFinishAdd(formAdd.getFieldsValue())}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button
                            key="submit"
                            type="primary"
                            loading={loadingModal}
                        >
                            Add
                        </Button>
                    </Popconfirm>
                ]}
                className="modal-manage"
                width={800}
            >
                <div className="modal-content">
                    <Spin spinning={loadingModal}>
                        <Form
                            form={formAdd}
                            layout="horizontal"
                            onFinish={onFinishAdd}
                            validateMessages={validateMessages}
                            requiredMark={false}
                        >
                            {renderInputItems([
                                {
                                    label: "Centre",
                                    name: "clinicID",
                                    type: "select",
                                    placeholder: "Select centre",
                                    required: true,
                                    optionList:
                                        clinicList !== -1 ? clinicList : []
                                },
                                {
                                    label: "Machine Name",
                                    name: "name",
                                    type: "input",
                                    placeholder: "Enter machine name",
                                    required: true
                                },
                                {
                                    label: "Machine Serial No",
                                    name: "serialNo",
                                    type: "input",
                                    placeholder: "Enter machine serial no",
                                    required: true
                                },
                                {
                                    label: "Manufacturer",
                                    name: "manufacturerName",
                                    type: "input",
                                    placeholder: "Enter manufacturer name"
                                },
                                {
                                    label: "Status",
                                    name: "status",
                                    type: "select",
                                    placeholder: "Select status",
                                    required: true,
                                    optionList: [
                                        { id: "AVAILABLE", name: "AVAILABLE" },
                                        {
                                            id: "NOTAVAILABLE",
                                            name: "NOT AVAILABLE"
                                        }
                                        // { id: 2, name: "Needs Maintenance" },
                                    ]
                                }
                                // {
                                //     label: "Expiry Date",
                                //     name: "expiryDate",
                                //     type: "datepicker",
                                // },
                            ])}
                        </Form>
                    </Spin>
                </div>
            </Modal>
        </div>
    );
};

export default ManageMachine;
