import React from "react"
import { Divider, Table } from "antd"
import { useHistory } from 'react-router-dom'
import { NavHashLink } from "react-router-hash-link"

import path from "../utils/pathSettings"
import IconIncidentReport from "../media/Icon-PatientCard-IncidentReport.png"
import IconNurse from "../media/Icon-PatientCard-Nurse.png"
import IconPatientInfo from "../media/Icon-PatientCard-PatientInfo.png"
import IconEye from "../media/Icon-PatientCard-Eye.png"

const LatestClinicalNoteCard = (props) => {
    const { item } = props
    const history = useHistory()
    const columns1 = [
        {
            title: "Date",
            dataIndex: "datetime",
            width: 120
        },
        {
            title: "Author",
            dataIndex: "author",
            width: 120
        },
        {
            title: "Clinic/Event",
            dataIndex: "clinicEvent"
        },
        {
            title: "Observation",
            dataIndex: "observation"
        },
        {
            title: "Assessment",
            dataIndex: "assessment"
        },
        {
            title: "Plans",
            dataIndex: "plans"
        },
        // {
        //     title: "Intra Dialysis Notes",
        //     dataIndex: "intraDialysisNotes"
        // },
    ]

    const columns2 = [
        {
            title: "Admission Date",
            dataIndex: "admitTimestamp"
        },
        {
            title: "Discharge Date",
            dataIndex: "leaveTimestamp"
        },
        {
            title: "No. Day",
            dataIndex: "day"
        },
        {
            title: "Location",
            dataIndex: "location"
        },
        {
            title: "Primary Diagnosis",
            dataIndex: "primaryDiagnosis"
        },
        {
            title: "Main Reason",
            dataIndex: "hospitalMain"
        },
        {
            title: "Other Reasons",
            dataIndex: "hospitalReason"
        },
    ]

    return (
        <div className="latestclinicalnotes-card">
            <div className="row1">
                <div className="cardtitle">
                    <div className="title">
                        {item.name}
                    </div>
                    <div className="subtitle">
                        {item.nric}
                    </div>
                </div>
                <div className="nav">
                    <div className="icon" onClick={() => { history.push(path("patientInformation", [item.id])) }}><img src={IconPatientInfo} /></div>
                    <div className="icon" onClick={() => { history.push(path("patientRecord", [item.id])) }}><img src={IconNurse} /></div>
                    <div className="icon" onClick={() => { history.push(path("patientIncidentReport", [item.id])) }}><img src={IconIncidentReport} /></div>
                    {/* &nbsp;&nbsp;&nbsp;<div className="icon" onClick={() => { history.push(path("patientClinicNHospitalE", [item.id])) }}><img src={IconPatientInfo} /></div> */}
                    &nbsp;&nbsp;&nbsp;<NavHashLink className="icon" to={path("patientClinicNHospitalE", [item.id])}><img src={IconEye} /></NavHashLink>
                </div>

            </div>
            <Divider />
            <div className="row2">
                <div className="overflow">
                    <div className="table1">
                        <div className="title">Clinical Notes</div>
                        <Table
                            columns={columns1}
                            dataSource={item.latestClinicalNotes}
                            pagination={false}
                        />
                    </div>
                    <div className="table2">
                        <div className="title">Hospital Entries</div>
                        <Table
                            columns={columns2}
                            dataSource={item.latestHospitalEntries}
                            pagination={false}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LatestClinicalNoteCard