import React, { useContext } from "react"
import { Route, Redirect } from "react-router-dom"

import path from "./pathSettings"
import { AppContext } from '../contexts/AppContext'

export const PrivateRoute = ({ component: Component, ...rest }) => {
    const { appState } = useContext(AppContext)
    return (
        <Route
            {...rest}
            render={(props) =>
                appState.validLogin && appState.selectedClinicID !== "" ?
                    (
                        <Component {...props} />
                    ) :
                    appState.validLogin ? (
                        <Redirect to={
                            {
                                pathname: path("settingsCentreSelection"),
                                state: { from: props.location }
                            }
                        } />
                    ) :
                        (
                            <Redirect to={
                                {
                                    pathname: path("login"),
                                    state: { from: props.location }
                                }
                            } />
                        )}
        />
    )
}