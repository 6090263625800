import React, { useContext, useEffect, useState } from "react";
import {
    Button,
    DatePicker,
    Empty,
    Form,
    Input,
    Modal,
    notification,
    Popconfirm,
    Select,
    Space,
    Spin,
    Table,
    Upload
} from "antd";
import moment from "moment";
import { Auth, API, Storage } from "aws-amplify";

import { listPatientDocument } from "../backend/graphql/queries";
import { createPatientDocument } from "../backend/graphql/mutations";
import { AppContext } from "../contexts/AppContext";
import PatientDetailsSummary from "./Component-PatientDetailsSummary";
import iconEye from "../media/Icon-Eye2.png";
import iconDownload from "../media/Icon-Download.png";
import imgFileUpload from "../media/Image-FileUpload.png";

const PatientDocument = (props) => {
    const patientID = props?.match?.params?.id;
    const { appState, getS3 } = useContext(AppContext);
    const [form] = Form.useForm();
    const validateMessages = {
        required: "Required."
    };
    const [table, setTable] = useState({
        data: [],
        pagination: {
            current: 1,
            pageSize: 10,
            offset: 0,
            onChange: (page, pageSize) => {
                setTable({
                    ...table,
                    pagination: {
                        ...table.pagination,
                        current: page,
                        pageSize: pageSize,
                        offset: page * pageSize - pageSize
                    }
                });
            }
        },
        loading: false
    });
    const [loadingModal, setLoadingModal] = useState(false);
    const [visibilityUploadDocument, setVisibilityUploadDocument] =
        useState(false);
    const columns = [
        {
            title: "Date/Time",
            dataIndex: "datetime"
        },
        {
            title: "Author",
            dataIndex: "author"
        },
        {
            title: "Document Title",
            dataIndex: "title"
        },
        {
            title: "Action",
            dataIndex: "action",
            render: (_, record) => {
                return record?.key && record?.key?.length > 0 ? (
                    <Space size="large" className="table-row-action">
                        <div
                            className="clickable"
                            onClick={() => handleDownload(record.key)}
                        >
                            <img src={iconEye} /> View
                        </div>
                        <div
                            className="clickable"
                            onClick={() => handleDownload(record.key, true)}
                        >
                            <img src={iconDownload} /> Download
                        </div>
                    </Space>
                ) : (
                    <></>
                );
            }
        }
    ];

    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };
    const uploadButton = (
        <div>
            <img src={imgFileUpload} />
            <div className="upload-text">
                <div>Drag and Drop file here</div>
                <div>or</div>
                <div>Click here to browse</div>
            </div>
        </div>
    );
    const [state, setState] = useState({
        fileList: []
    });

    const handleCancel = () => {
        setState({ ...state, previewVisible: false });
    };

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        setState({
            ...state,
            previewImage: file.url || file.preview,
            previewVisible: true,
            previewTitle:
                file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
        });
    };

    const handleChange = (file) => {
        // console.log(file);
        // console.log(optionNum);
        let { fileList } = file;
        // fileList = fileList.slice(-1)
        if (fileList.length > 0) {
            form.setFieldsValue({ key: fileList });
        } else {
            form.setFieldsValue({ key: null });
        }
        setState({ ...state, fileList: fileList });
    };

    const downloadBlob = (blob, filename) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename || "download";
        const clickHandler = () => {
            setTimeout(() => {
                URL.revokeObjectURL(url);
                a.removeEventListener("click", clickHandler);
            }, 150);
        };
        a.addEventListener("click", clickHandler, false);
        a.click();
        return a;
    };

    const handleDownload = async (key, download = false) => {
        let result = await Storage.get(key, {
            download,
            progressCallback(progress) {
                // console.log(`Downloaded: ${progress.loaded}/${progress.total}`);
            }
        });

        if (download) downloadBlob(result.Body, key);
        else window.open(result);
    };

    const onFinish = async (values) => {
        // console.log("onFinish clicked", values);
        await form.validateFields();
    };

    useEffect(() => {
        return () => {};
    }, []);

    useEffect(() => {
        if (
            Object.keys(appState.cognitoUser).length !== 0 &&
            Object.keys(appState.clinicList).length !== 0
        ) {
            // console.log('ManageMachineStatus', props);
            _listPatientDocument(patientID, {
                ...table.pagination,
                offset:
                    table.pagination.current * table.pagination.pageSize -
                    table.pagination.pageSize
            });
        }
        return () => {};
    }, [appState.cognitoUser, appState.clinicList]);

    const uploadFile = async (file) => {
        // console.log(file);

        const fileName =
            "p_" +
            patientID +
            "_" +
            moment().utc().format("YYYY-MM-DD_HHmmss") +
            "_" +
            file.name;
        const fileType = file.type;

        try {
            const result = await Storage.put("patient/" + fileName, file, {
                contentType: fileType
            });
            // console.log(result, result.key);
            return result.key;
        } catch (error) {
            console.log("error: ", error);
            notification.error({
                message: error
            });
        }
    };

    const _listPatientDocument = async (
        patientID,
        props = table.pagination
    ) => {
        setTable({
            ...table,
            loading: true
        });
        try {
            const variables = {
                pagination: {
                    orderby: "createdOn DESC"
                },
                filter: {
                    patientID: {
                        eq: patientID
                    }
                }
            };
            // console.log('_listPatientDocument', variables);

            const result = await API.graphql({
                query: listPatientDocument,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables
            });
            // console.log("API result listPatientDocument", result);
            const data = result.data.result;

            console.log(data);
            if (data?.result && data.result.length > 0) {
                let _data = [];
                for (let item of data.result) {
                    _data.push({
                        ...item,
                        datetime: moment
                            .utc(item.createdOn)
                            .local()
                            .format("DD MMM YYYY, HH:mm"),
                        author: item.staffName
                    });
                }

                setTable({
                    ...table,
                    loading: data.result.length == 0 ? true : false,
                    data: _data,
                    pagination: {
                        ...table.pagination,
                        current: props.current,
                        offset: props.offset,
                        total: data.count
                    }
                });
            } else {
                setTable({
                    ...table,
                    loading: false,
                    data: [],
                    pagination: {
                        ...table.pagination,
                        current: 1,
                        offset: props.offset,
                        total: 0
                    }
                });
            }
        } catch (error) {
            console.log("error: ", error);
        } finally {
        }
    };

    const _createPatientDocument = async (values) => {
        setLoadingModal(true);
        try {
            const variables = {
                create: {
                    patientID: patientID,
                    title: values.title
                }
            };

            Auth.currentAuthenticatedUser().then((user) => {
                // console.log(user);
                variables.create.staffAccountID = user.username;
            });

            if (state?.fileList) {
                // console.log(state.fileList);
                let _f = [];
                for (let file of state.fileList) {
                    _f.push(await uploadFile(file.originFileObj));
                }
                // console.log(_f);

                if (_f && _f.length > 0) {
                    variables.create.key = _f[0];
                    let s3 = getS3();
                    variables.create.bucket = s3?.bucket;
                    variables.create.region = s3?.region;
                }
            }

            // console.log("createPatientDocument", variables);
            const result = await API.graphql({
                query: createPatientDocument,
                variables: variables,
                authMode: "AMAZON_COGNITO_USER_POOLS"
            });
            // console.log(result);

            notification.success({
                message: "Updated successfully"
            });
            _listPatientDocument(patientID);
            handleModalVisibility("uploadDocument");
        } catch (error) {
            console.log("error", error);
            notification.error({
                message: "Unable to update record"
            });
        } finally {
            setLoadingModal(false);
        }
    };

    const handleModalVisibility = (modal) => {
        // console.log("modal", modal);
        switch (modal) {
            case "uploadDocument":
                setVisibilityUploadDocument(!visibilityUploadDocument);
                break;
            default:
        }
    };

    const onFinishUpload = async (values) => {
        // console.log('onFinishUpload', values);
        await form.validateFields();
        _createPatientDocument(values);
    };

    return (
        <div className="content-content">
            <div className="patientdocument-header">
                <div className="column1 title">Document</div>
                <PatientDetailsSummary patientID={patientID} />
                <div className="column3">
                    <Button
                        type="primary"
                        onClick={() => handleModalVisibility("uploadDocument")}
                    >
                        Upload Document
                    </Button>
                </div>
            </div>
            <div className="patientdocument-content">
                <Table
                    columns={columns}
                    rowKey={(record) => record.id}
                    rowClassName={(record, index) => {
                        // console.log("index", index);
                        return index % 2 === 0 ? "highlight" : "";
                    }}
                    dataSource={
                        table.data
                        // [
                        //     {
                        //         id: 1,
                        //         datetime: "10 Apr 2021, 06:00",
                        //         author: "Author Name",
                        //         documentTitle: "Document Name",
                        //     },
                        //     {
                        //         id: 2,
                        //         datetime: "10 Apr 2021, 06:00",
                        //         author: "Author Name",
                        //         documentTitle: "Document Name",
                        //     },
                        //     {
                        //         id: 3,
                        //         datetime: "10 Apr 2021, 06:00",
                        //         author: "Author Name",
                        //         documentTitle: "Document Name",
                        //     },
                        //     {
                        //         id: 4,
                        //         datetime: "10 Apr 2021, 06:00",
                        //         author: "Author Name",
                        //         documentTitle: "Document Name",
                        //     },
                        // ]
                    }
                />
            </div>
            <Modal
                title={<div className="modal-title">Upload Document</div>}
                visible={visibilityUploadDocument}
                onCancel={() => handleModalVisibility("uploadDocument")}
                footer={[
                    <Button
                        key="back"
                        onClick={() => handleModalVisibility("uploadDocument")}
                    >
                        Cancel
                    </Button>,
                    <Button
                        key="submit"
                        type="primary"
                        onClick={() => onFinishUpload(form.getFieldsValue())}
                    >
                        Submit
                    </Button>
                ]}
                className="modal-patient-document"
                width={800}
            >
                <div className="modal-content">
                    <Spin spinning={loadingModal}>
                        <Form
                            className="uploadDocument"
                            form={form}
                            layout="horizontal"
                            onFinish={onFinish}
                            validateMessages={validateMessages}
                            requiredMark={false}
                        >
                            <Form.Item
                                label="Document Title"
                                name="title"
                                rules={[
                                    {
                                        required: true
                                    }
                                ]}
                            >
                                <Input
                                    autoComplete="off"
                                    placeholder="Document Title"
                                    allowClear
                                />
                            </Form.Item>
                            <Form.Item
                                name="key"
                                rules={[
                                    {
                                        required: true
                                    }
                                ]}
                            >
                                <Upload
                                    className="fileupload"
                                    action={getBase64}
                                    listType="picture-card"
                                    fileList={state.fileList}
                                    onPreview={handlePreview}
                                    onChange={(file) => handleChange(file)}
                                >
                                    {state.fileList.length >= 1
                                        ? null
                                        : uploadButton}
                                </Upload>
                                <Modal
                                    visible={state.previewVisible}
                                    title={state.previewTitle}
                                    footer={null}
                                    onCancel={handleCancel}
                                >
                                    <img
                                        alt="upload preview"
                                        style={{ width: "100%" }}
                                        src={state.previewImage}
                                    />
                                </Modal>
                            </Form.Item>
                        </Form>
                    </Spin>
                </div>
            </Modal>
        </div>
    );
};

export default PatientDocument;
