import React, { useEffect, useState } from "react";
import {
    Button,
    Form,
    Input,
    Modal,
    notification,
    Popconfirm,
    Space,
    Spin,
    Table
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { API } from "aws-amplify";

import { listClinic, listClinicSummary } from "../backend/graphql/queries";
import { createClinic, updateClinic } from "../backend/graphql/mutations";
import path from "../utils/pathSettings";
import renderInputItems from "../utils/renderFormInputItems";
import iconEdit from "../media/Icon-Edit.png";
import iconEye from "../media/Icon-Eye2.png";

const ManageCentre = () => {
    const [formAdd] = Form.useForm();
    const [formUpdate] = Form.useForm();
    const [formSearch] = Form.useForm();
    const [filter, setFilter] = useState({
        search: ""
    });
    const validateMessages = {
        required: "Required."
    };
    const [table, setTable] = useState({
        data: -1,
        pagination: {
            current: 1,
            pageSize: 20,
            offset: 0,
            showSizeChanger: true
        },
        loading: false
    });
    const [tableDetails, setTableDetails] = useState({
        data: -1
        // pagination: {
        //     current: 1,
        //     pageSize: 20,
        //     offset: 0,
        //     showSizeChanger: false
        // },
        // loading: false
    });
    const [display, setDisplay] = useState([]);
    const [visibilityUpdateCentre, setVisibilityUpdateCentre] = useState(false);
    const [editCentre, setEditCentre] = useState({});
    const [visibilityAddCentre, setVisibilityAddCentre] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false);
    const history = useHistory();

    const getClinicList = async (props, values) => {
        setTable({
            ...table,
            loading: true
        });
        try {
            let filter = {
                deleted: { eq: 0 }
            };

            if (values.search != "") {
                filter = {
                    ...filter,
                    name: { contains: values.search }
                };
            }

            const variables = {
                pagination: {
                    limit: props.pageSize,
                    offset: props.offset,
                    orderby: "(status = 'ACTIVE') DESC, name ASC"
                },
                filter: filter
            };
            // console.log("clinicDetails", clinicDetails);
            const result = await API.graphql({
                query: listClinicSummary,
                variables: variables,
                authMode: "AMAZON_COGNITO_USER_POOLS"
            });
            // console.log('result', result);
            const data = result.data.result;
            // console.log("data", data);
            setTable({
                ...table,
                data: data.result,
                // loading: data.result.length === 0 ? false : true,
                pagination: {
                    ...table.pagination,
                    pageSize: props.pageSize,
                    current: props.current,
                    offset: props.offset,
                    total:
                        props.offset >= data.count ? data.count + 1 : data.count // keeps the last pagination if it is the last record
                }
            });
        } catch (error) {
            notification.error({
                message: "Unable to retrieve records"
            });
            setTable({
                ...table,
                loading: false
            });
        }
    };

    const createClinicRecord = async (values) => {
        setLoadingModal(true);
        try {
            const clinicDetails = {
                create: {
                    name: values.name,
                    uid: values.uid,
                    status: values.status,
                    address: values.address,
                    phoneNo: values.phoneNo,
                    email: values.email
                }
            };

            if (values.qbClientID)
                clinicDetails.create.qbClientID = values.qbClientID;
            if (values.qbClientSecret)
                clinicDetails.create.qbClientSecret = values.qbClientSecret;
            if (values.qbRealmID)
                clinicDetails.create.qbRealmID = values.qbRealmID;
            if (values.qbItemID)
                clinicDetails.create.qbItemID = values.qbItemID;
            if (values.qbTaxCodeID)
                clinicDetails.create.qbTaxCodeID = values.qbTaxCodeID;

            // console.log("clinicDetails", clinicDetails);
            const result = await API.graphql({
                query: createClinic,
                variables: clinicDetails,
                authMode: "AMAZON_COGNITO_USER_POOLS"
            });
            // console.log(result);
            // const data = result.data.result
            // console.log("data", data);
            notification.success({
                message: "Created successfully"
            });
            getClinicList(table.pagination, filter);
            formAdd.resetFields();
            formAdd.setFieldsValue({
                status: "ACTIVE"
            });
            handleModalVisibility("addCentre");
        } catch (error) {
            console.log("error", error);
            notification.error({
                message: "Unable to update record"
            });
        } finally {
            setLoadingModal(false);
        }
    };

    const updateClinicRecord = async (values, id) => {
        setLoadingModal(true);
        try {
            const clinicDetails = {
                update: {
                    id: id,
                    name: values.name,
                    uid: values.uid,
                    status: values.status,
                    address: values.address,
                    phoneNo: values.phoneNo,
                    email: values.email
                }
            };

            if (values.qbClientID)
                clinicDetails.update.qbClientID = values.qbClientID ?? "";
            if (values.qbClientSecret)
                clinicDetails.update.qbClientSecret =
                    values.qbClientSecret ?? "";
            if (values.qbRealmID)
                clinicDetails.update.qbRealmID = values.qbRealmID ?? "";
            clinicDetails.update.qbItemID = Number(values.qbItemID) ?? 0;
            clinicDetails.update.qbTaxCodeID = Number(values.qbTaxCodeID) ?? 0;

            // console.log("clinicDetails", clinicDetails);
            const result = await API.graphql({
                query: updateClinic,
                variables: clinicDetails,
                authMode: "AMAZON_COGNITO_USER_POOLS"
            });
            // console.log(result);
            // const data = result.data.result
            // console.log("data", data);
            notification.success({
                message: "Updated successfully"
            });
            getClinicList(table.pagination, filter);
            formUpdate.resetFields();
            handleModalVisibility("updateCentre");
        } catch (error) {
            console.log("error", error);
            notification.error({
                message: "Unable to update record"
            });
        } finally {
            setLoadingModal(false);
        }
    };

    useEffect(() => {
        getClinicList(table.pagination, filter);
        formSearch.setFieldsValue(filter);
        formAdd.setFieldsValue({
            name: "",
            status: "ACTIVE",
            address: "",
            phoneNo: ""
        });
    }, []);

    useEffect(() => {
        if (table.data !== -1) {
            // any data changes for display to be done here
            let array = [];
            for (let i = 0; i < table.data.length; i++) {
                let object = {
                    ...table.data[i],
                    noOfSeat: table.data[i].noOfSeat
                };
                array.push(object);
            }
            setDisplay(table.data);
            setTable({
                ...table,
                loading: false
            });
        }
    }, [table.data]);

    useEffect(() => {
        // console.log("editCentre", editCentre);
        formUpdate.setFieldsValue({
            name: editCentre.name ? editCentre.name : "",
            uid: editCentre.uid ? editCentre.uid : "",
            status: editCentre.status ? editCentre.status : "",
            address: editCentre.address ? editCentre.address : "",
            phoneNo: editCentre.phoneNo ? editCentre.phoneNo : "",
            email: editCentre.email ? editCentre.email : "",
            qbRealmID: editCentre.qbRealmID?.toString() ?? "",
            qbItemID: editCentre.qbItemID?.toString() ?? "",
            qbTaxCodeID: editCentre.qbTaxCodeID?.toString() ?? ""
        });
    }, [editCentre]);

    const onFinishSearch = (values) => {
        // console.log("submit formSearch", values);
        setFilter(values);
        getClinicList(table.pagination, values);
    };

    const onFinishAdd = async (values) => {
        // console.log("onFinishAdd", values);
        await formAdd.validateFields();
        createClinicRecord(values);
        // setLoadingModal(true)
        // const timer = setTimeout(() => {
        //     setLoadingModal(false)
        //     clearTimeout(timer)
        //     handleModalVisibility("addCentre")
        // }, 1000)
    };

    const onFinishUpdate = async (values, id) => {
        // console.log("onFinishUpdate", values);
        await formUpdate.validateFields();
        updateClinicRecord(values, id);
        // setLoadingModal(true)
        // const timer = setTimeout(() => {
        //     setLoadingModal(false)
        //     clearTimeout(timer)
        //     handleModalVisibility("updateCentre")
        // }, 1000)
    };

    const handleModalVisibility = (modal, record) => {
        // console.log("modal", modal);
        switch (modal) {
            case "addCentre":
                setVisibilityAddCentre(!visibilityAddCentre);
                break;
            case "updateCentre":
                setEditCentre(record ? record : {});
                setVisibilityUpdateCentre(!visibilityUpdateCentre);
                break;
            default:
        }
    };

    const handleTableChange = (paginate) => {
        getClinicList(
            {
                ...table.pagination,
                pageSize: paginate.pageSize,
                current: paginate.current,
                offset: paginate.current * paginate.pageSize - paginate.pageSize
            },
            filter
        );
    };

    const handleTreeSelectChange = (value, label, extra) => {
        // console.log("value", value);
        // console.log("label", label);
        // console.log("extra", extra);
    };

    const handleDelete = async (id) => {
        // console.log("handle delete", id);
        setTable({
            ...table,
            loading: true
        });
        const timer = setTimeout(() => {
            setTable({
                ...table,
                loading: false
            });
            clearTimeout(timer);
        }, 1000);
    };

    const columns = [
        {
            title: "Centre",
            dataIndex: "name"
        },
        {
            title: "Code",
            dataIndex: "uid"
        },
        {
            title: "Status",
            dataIndex: "status",
            render: (text, record) => {
                return (
                    text.slice(0, 1).toUpperCase() + text.slice(1).toLowerCase()
                );
            }
        },
        {
            title: "Address",
            dataIndex: "address"
        },
        {
            title: "Phone Line",
            dataIndex: "phoneNo"
        },
        {
            title: "Email",
            dataIndex: "email"
        },
        {
            title: "Total Doctor",
            dataIndex: "totalDoctor"
        },
        {
            title: "Total Nurse",
            dataIndex: "totalNurse"
        },
        {
            title: "Total Seat",
            dataIndex: "totalSeat"
        },
        {
            title: "Total Machine",
            dataIndex: "totalMachine"
        },
        {
            title: "Actions",
            dataIndex: "actions",
            render: (_, record) => {
                return (
                    <Space size="large">
                        <div
                            className="table-row-action clickable"
                            onClick={() =>
                                handleModalVisibility("updateCentre", record)
                            }
                        >
                            <div className="icon">
                                <img src={iconEdit} />
                            </div>
                            <div className="text">Edit</div>
                        </div>
                        <div
                            className="table-row-action clickable"
                            onClick={() =>
                                history.push(path("manageStaff"), {
                                    clinicID: record.id
                                })
                            }
                        >
                            <div className="icon">
                                <img src={iconEye} />
                            </div>
                            <div className="text">View Staff</div>
                        </div>
                    </Space>
                );
            }
        }
    ];

    return (
        <div className="content-content">
            <div className="manage-header">
                <div className="container-rows">
                    <div className="row1">
                        <div className="title">Manage Centres</div>
                        <Button
                            type="primary"
                            onClick={() => handleModalVisibility("addCentre")}
                        >
                            Add Centre
                        </Button>
                    </div>
                    <Form
                        form={formSearch}
                        layout="horizontal"
                        onFinish={onFinishSearch}
                        validateMessages={validateMessages}
                        requiredMark={false}
                    >
                        <div className="container-row">
                            <div className="row2">
                                <div className="row">
                                    {/* <Form.Item
                                        className="display-none"
                                        name="searchTypeDaily"
                                    >
                                        <Input autoComplete="off" />
                                    </Form.Item> */}
                                    <Form.Item className="search" name="search">
                                        <Input
                                            autoComplete="off"
                                            prefix={<SearchOutlined />}
                                            placeholder="Search centre"
                                            value={filter.search}
                                            allowClear
                                        />
                                    </Form.Item>
                                    <Button type="primary" htmlType="submit">
                                        Search
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
            <div className="manage-content">
                <Table
                    columns={columns}
                    rowKey={(record) => record.id}
                    rowClassName={(record, index) => {
                        // console.log("index", index);
                        return index % 2 === 0 ? "highlight" : "";
                    }}
                    loading={table.loading}
                    dataSource={display}
                    pagination={table.pagination}
                    onChange={handleTableChange}
                    // dataSource={[
                    //     {
                    //         id: "1",
                    //         centreID: 1,
                    //         name: "Centre ABC",
                    //         address: "address 123 abc",
                    //         phoneNo: "+6598765432",
                    //         email: "abc@email.com",
                    //         countDoctor: "10",
                    //         countNurse: "50",
                    //         noOfSeat: "20",
                    //         countMachine: "20",
                    //     },
                    //     {
                    //         id: "2",
                    //         centreID: 2,
                    //         name: "Centre DEF",
                    //         address: "address 123 abc",
                    //         phoneNo: "+6598765432",
                    //         email: "abc@email.com",
                    //         countDoctor: "10",
                    //         countNurse: "50",
                    //         noOfSeat: "20",
                    //         countMachine: "20",
                    //     },
                    //     {
                    //         id: "3",
                    //         centreID: 3,
                    //         name: "Centre GHI",
                    //         address: "address 123 abc",
                    //         phoneNo: "+6598765432",
                    //         email: "abc@email.com",
                    //         countDoctor: "10",
                    //         countNurse: "50",
                    //         noOfSeat: "20",
                    //         countMachine: "20",
                    //     },
                    // ]}
                />
            </div>
            <Modal
                title={<div className="modal-title">Edit Centre</div>}
                visible={visibilityUpdateCentre}
                onCancel={() => handleModalVisibility("updateCentre")}
                footer={[
                    <Button
                        key="back"
                        onClick={() => handleModalVisibility("updateCentre")}
                    >
                        Cancel
                    </Button>,
                    <Popconfirm
                        title="Are you sure?"
                        onConfirm={() =>
                            onFinishUpdate(
                                formUpdate.getFieldsValue(),
                                editCentre.id
                            )
                        }
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button
                            key="submit"
                            type="primary"
                            loading={loadingModal}
                        >
                            Update
                        </Button>
                    </Popconfirm>
                ]}
                className="modal-manage"
                width={800}
            >
                <div className="modal-content">
                    <Spin spinning={loadingModal}>
                        <Form
                            form={formUpdate}
                            layout="horizontal"
                            onFinish={onFinishUpdate}
                            validateMessages={validateMessages}
                            requiredMark={false}
                        >
                            {renderInputItems([
                                {
                                    label: "Centre Name",
                                    name: "name",
                                    type: "input",
                                    placeholder: "Enter centre name",
                                    required: true
                                },
                                {
                                    label: "Code",
                                    name: "uid",
                                    type: "input",
                                    placeholder: "Enter center code",
                                    require: true
                                },
                                {
                                    label: "Status",
                                    name: "status",
                                    type: "select",
                                    placeholder: "Select status",
                                    required: true,
                                    optionList: [
                                        { id: "ACTIVE", name: "Active" },
                                        { id: "INACTIVE", name: "Inactive" }
                                    ]
                                },
                                {
                                    label: "Address",
                                    name: "address",
                                    type: "text",
                                    placeholder: "Enter address",
                                    rows: "3"
                                    // required: true,
                                },
                                {
                                    label: "Phone Line",
                                    name: "phoneNo",
                                    type: "input",
                                    placeholder: "Enter phone line"
                                    // required: true,
                                },
                                {
                                    label: "Email",
                                    name: "email",
                                    type: "email",
                                    placeholder: "Enter email"
                                    // required: true,
                                },
                                {
                                    label: "Quickbooks Client ID",
                                    name: "qbClientID",
                                    type: "input",
                                    placeholder: "Enter Quickbooks Client ID"
                                    // required: true,
                                },
                                {
                                    label: "Quickbooks Client Secret",
                                    name: "qbClientSecret",
                                    type: "input",
                                    placeholder:
                                        "Enter Quickbooks Client Secret"
                                    // required: true,
                                },
                                {
                                    label: "Quickbooks Realm ID",
                                    name: "qbRealmID",
                                    type: "input",
                                    placeholder: "Enter Quickbooks Realm ID"
                                    // required: true,
                                },
                                {
                                    label: "Quickbooks Item ID",
                                    name: "qbItemID",
                                    type: "input",
                                    placeholder: "Enter Quickbooks Item ID",
                                    // required: true,
                                    regex: new RegExp("^[0-9]*$"),
                                    regexMessage: "Digits only"
                                },
                                {
                                    label: "Quickbooks Tax Code ID",
                                    name: "qbTaxCodeID",
                                    type: "input",
                                    placeholder: "Enter Quickbooks Tax Code ID",
                                    // required: true,
                                    regex: new RegExp("^[0-9]*$"),
                                    regexMessage: "Digits only"
                                }
                            ])}
                        </Form>
                    </Spin>
                </div>
            </Modal>
            <Modal
                title={<div className="modal-title">Add Centre</div>}
                visible={visibilityAddCentre}
                onCancel={() => handleModalVisibility("addCentre")}
                footer={[
                    <Button
                        key="back"
                        onClick={() => handleModalVisibility("addCentre")}
                    >
                        Cancel
                    </Button>,
                    <Popconfirm
                        title="Are you sure"
                        onConfirm={() => onFinishAdd(formAdd.getFieldsValue())}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button
                            key="submit"
                            type="primary"
                            loading={loadingModal}
                        >
                            Add
                        </Button>
                    </Popconfirm>
                ]}
                className="modal-manage"
                width={800}
            >
                <div className="modal-content">
                    <Spin spinning={loadingModal}>
                        <Form
                            form={formAdd}
                            layout="horizontal"
                            onFinish={onFinishUpdate}
                            validateMessages={validateMessages}
                            requiredMark={false}
                        >
                            {renderInputItems([
                                {
                                    label: "Centre Name",
                                    name: "name",
                                    type: "input",
                                    placeholder: "Enter centre name",
                                    required: true
                                },
                                {
                                    label: "Code",
                                    name: "uid",
                                    type: "input",
                                    placeholder: "Enter center code",
                                    require: true
                                },
                                {
                                    label: "Status",
                                    name: "status",
                                    type: "select",
                                    placeholder: "Select status",
                                    required: true,
                                    optionList: [
                                        { id: "ACTIVE", name: "Active" },
                                        { id: "INACTIVE", name: "Inactive" }
                                    ]
                                },
                                {
                                    label: "Address",
                                    name: "address",
                                    type: "text",
                                    placeholder: "Enter address",
                                    rows: "3"
                                    // required: true,
                                },
                                {
                                    label: "Phone Line",
                                    name: "phoneNo",
                                    type: "input",
                                    placeholder: "Enter phone line"
                                    // required: true,
                                },
                                {
                                    label: "Email",
                                    name: "email",
                                    type: "email",
                                    placeholder: "Enter email"
                                    // required: true,
                                },
                                {
                                    label: "Quickbooks Client ID",
                                    name: "qbClientID",
                                    type: "input",
                                    placeholder: "Enter Quickbooks Client ID"
                                    // required: true,
                                },
                                {
                                    label: "Quickbooks Client Secret",
                                    name: "qbClientSecret",
                                    type: "input",
                                    placeholder:
                                        "Enter Quickbooks Client Secret"
                                    // required: true,
                                },
                                {
                                    label: "Quickbooks Realm ID",
                                    name: "qbRealmID",
                                    type: "input",
                                    placeholder: "Enter Quickbooks Realm ID"
                                    // required: true,
                                },
                                {
                                    label: "Quickbooks Item ID",
                                    name: "qbItemID",
                                    type: "input",
                                    placeholder: "Enter Quickbooks Item ID",
                                    // required: true,
                                    regex: new RegExp("^[0-9]*$"),
                                    regexMessage: "Digits only"
                                },
                                {
                                    label: "Quickbooks Tax Code ID",
                                    name: "qbTaxCodeID",
                                    type: "input",
                                    placeholder: "Enter Quickbooks Tax Code ID",
                                    // required: true,
                                    regex: new RegExp("^[0-9]*$"),
                                    regexMessage: "Digits only"
                                }
                            ])}
                        </Form>
                    </Spin>
                </div>
            </Modal>
        </div>
    );
};

export default ManageCentre;
