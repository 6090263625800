import React, { useContext, useEffect, useState } from "react"
import { Descriptions } from "antd"

import { AppContext } from "../contexts/AppContext"

const SummaryIntraDialysis = (props) => {
    const { appState } = useContext(AppContext);
    const { values } = props
    const [intraDialysis, setIntraDialysis] = useState({
        sbp: "",
        dbp: "",
        hr: "",
        qb: "",
        ap: "",
        vp: "",
        tmp: "",
        ufr: "",
        cuf: "",
        dflow: "",
        cdt: "",
        temp: "",
        hepRate: "",
        note: "",
        symptoms: [],
        administeredMedication: [],
    })

    useEffect(() => {
        // console.log(intraDialysis", intraDialysis);
        // console.log(values", values);
        setIntraDialysis({
            ...intraDialysis,
            ...values
        })
    }, [values])


    return (
        <div className="modal-patientrecord-dialysisorder">
            <div className="row2">
                <Descriptions
                    className="column"
                    column={{ xs: 1, sm: 1 }}
                >
                    <Descriptions.Item label="SBP">{intraDialysis.sbp}</Descriptions.Item>
                    <Descriptions.Item label="DBP">{intraDialysis.dbp}</Descriptions.Item>
                    <Descriptions.Item label="HR">{intraDialysis.hr}</Descriptions.Item>
                    <Descriptions.Item label="QB">{intraDialysis.qb}</Descriptions.Item>
                    <Descriptions.Item label="AP">{intraDialysis.ap}</Descriptions.Item>
                    <Descriptions.Item label="VP">{intraDialysis.vp}</Descriptions.Item>
                    <Descriptions.Item label="TMP">{intraDialysis.tmp}</Descriptions.Item>
                    <Descriptions.Item label="UFR (ℓ)">{intraDialysis.ufr}</Descriptions.Item>
                    <Descriptions.Item label="UFV (ℓ)">{intraDialysis.cuf}</Descriptions.Item>
                    <Descriptions.Item label="FLOW">{intraDialysis.dflow}</Descriptions.Item>
                    <Descriptions.Item label="COND">{intraDialysis.cdt}</Descriptions.Item>
                    <Descriptions.Item label="TEMP">{intraDialysis.temp}</Descriptions.Item>
                    <Descriptions.Item label="HepRate">{intraDialysis.hepRate}</Descriptions.Item>
                    <Descriptions.Item label="Dialysis Note">{intraDialysis.note}</Descriptions.Item>
                    <Descriptions.Item label="Number of Symptoms">{intraDialysis.symptoms.length}</Descriptions.Item>
                    <Descriptions.Item label="Number of Administered Medication">{intraDialysis.administeredMedication.length}</Descriptions.Item>
                </Descriptions>
            </div>
        </div>
    )
}

export default SummaryIntraDialysis