import React, { useContext, useEffect, useState } from "react";
import { Button, Empty, Form, notification, Pagination, Spin } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import renderInputItems from "../utils/renderFormInputItems";
import LatestClinicalNoteCard from "./Component-LatestClinicalNote-Card";
import { API } from "aws-amplify";
import {
    listHospitalSummary,
    listHospitalSummaryWithPatient
} from "../backend/graphql/queries";
import { AppContext } from "../contexts/AppContext";
import moment from "moment";

const LatestClinicalNoteCentre = () => {
    const { appState } = useContext(AppContext);
    const [form] = Form.useForm();
    const [filter, setFilter] = useState({
        search: ""
    });
    const [table, setTable] = useState({
        data: -1,
        pagination: {
            current: 1,
            pageSize: 100,
            offset: 0
        },
        loading: false
    });
    const [display, setDisplay] = useState([]);

    useEffect(() => {
        if (Object.keys(appState.cognitoUser).length !== 0) {
            getClinicalNoteRecord(filter, table.pagination);
            form.setFieldsValue(filter);
        }

        return () => {};
    }, [appState.cognitoUser]);

    useEffect(() => {
        if (
            table.data != -1 &&
            appState?.clinicList != -1 &&
            appState?.clinicList.length > 0
        ) {
            let _datas = [];
            let uniquePatientID = [
                ...new Set(table.data.map((s) => s.patientID))
            ];
            for (let pID of uniquePatientID) {
                let patientDatas = table.data.filter((s) => s.patientID == pID);
                let clinicalNotes = patientDatas
                    .filter((s) => ["CLINIC", "EVENT"].includes(s.type))
                    .sort((a, b) =>
                        a?.admitTimestamp > b?.admitTimestamp ? -1 : 1
                    );
                let clinicalNote;
                if (clinicalNotes.length > 0) clinicalNote = clinicalNotes[0];
                let hospitalEntries = patientDatas.filter(
                    (s) => s.type == "HOSPITAL"
                );
                let hospitalEntry;
                if (hospitalEntries.length > 0)
                    hospitalEntry = hospitalEntries[0];

                let _data = {
                    id: pID,
                    name: patientDatas[0].patientName,
                    nric: patientDatas[0].patientNric,
                    latestClinicalNotes: [],
                    latestHospitalEntries: []
                };

                if (clinicalNote) {
                    _data.latestClinicalNotes.push({
                        id: clinicalNote.id,
                        datetime: moment
                            .utc(clinicalNote.admitTimestamp)
                            .local()
                            .format("DD MMM YYYY"),
                        author: clinicalNote.staffName,
                        // clinicEvent: clinicalNote.type == 'CLINIC' ? 'Clinic ' + appState?.clinicList?.find(s => s.id == clinicalNote.clinicID).name : clinicalNote.type,
                        clinicEvent: clinicalNote.type,
                        observation: clinicalNote.observation,
                        assessment: clinicalNote.assessment,
                        plans: clinicalNote.plan,
                        intraDialysisNotes: clinicalNote.notes
                    });
                }

                if (hospitalEntry) {
                    _data.latestHospitalEntries.push({
                        id: hospitalEntry.id,
                        admitTimestamp: moment
                            .utc(hospitalEntry.admitTimestamp)
                            .local()
                            .format("DD MMM YYYY"),
                        leaveTimestamp: hospitalEntry.leaveTimestamp
                            ? moment
                                  .utc(hospitalEntry.leaveTimestamp)
                                  .local()
                                  .format("DD MMM YYYY")
                            : "",
                        day: hospitalEntry.noOfDays,
                        location: hospitalEntry.location,
                        reason: hospitalEntry.reason,
                        // primaryDiagnosis: hospitalEntry.diagnosis,
                        primaryDiagnosis: hospitalEntry.assessment,
                        outcome: hospitalEntry.outcome,
                        hospitalMain: hospitalEntry.hospitalMain,
                        hospitalReason: hospitalEntry.hospitalReason
                    });
                }

                _datas.push(_data);
            }
            setDisplay(_datas);
        }
    }, [table.data, appState?.clinicList]);

    const getClinicalNoteRecord = async (
        filter = filter,
        props = table.pagination
    ) => {
        setTable({
            ...table,
            loading: true
        });
        try {
            const variables = {
                pagination: {
                    limit: props.pageSize,
                    offset: props.offset,
                    orderby: `\`admitTimestamp\` DESC`
                },
                // filter: {
                // }
                groupBy: "clinicID",
                clinicID: appState.selectedClinicID
            };

            if (filter?.search?.length > 0) {
                variables.filter = {
                    ...variables.filter,
                    or: [
                        {
                            patientName: {
                                contains: filter.search
                            }
                        },
                        {
                            patientNric: {
                                contains: filter.search
                            }
                        }
                    ]
                };
            }

            console.log("variables", variables);
            const result = await API.graphql({
                query: listHospitalSummaryWithPatient,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables
            });
            // console.log("result", result);
            const data = result.data.result;
            const array = result.data.result?.result;
            // console.log("data", data);

            if (array.length >= 0) {
                array.map(
                    (s) =>
                        (s.patientNric =
                            s.patientNric
                                ?.substring(0, s.patientNric.length - 4)
                                .replace(/\d|\w/g, "*") +
                            s.patientNric?.substring(s.patientNric.length - 4))
                );

                setTable({
                    ...table,
                    loading: false,
                    data: array,
                    pagination: {
                        ...table.pagination,
                        current: props.current,
                        pageSize: props.pageSize,
                        offset: props.offset,
                        total:
                            props.offset >= data.count
                                ? data.count + 1
                                : data.count // keeps the last pagination if it is the last record
                    }
                });
            }
        } catch (error) {
            console.log("error", error);
            notification.error({
                message: "Unable to retrieve records"
            });
            setTable({
                ...table,
                loading: false
            });
        }
    };

    const onFinish = (values) => {
        // console.log("search", values);
        getClinicalNoteRecord(values, table.pagination);
    };

    const handleTableChange = (paginate) => {
        // console.log('paginate', paginate, table.pagination);
        if (table.pagination.current !== paginate)
            getClinicalNoteRecord(filter, {
                ...table.pagination,
                current: paginate,
                // pageSize: paginate.pageSize,
                offset:
                    paginate * table.pagination.pageSize -
                    table.pagination.pageSize
            });
    };

    const handleTableShowSizeChange = (current, size) => {
        // console.log('handleTableShowSizeChange', current, size);
        getClinicalNoteRecord(filter, {
            ...table.pagination,
            current: 1,
            pageSize: size,
            offset: 0
        });
    };

    const renderClinicNHopstialECards = (array) => {
        const items = array.map((item, index) => {
            // console.log("item", item);
            return <LatestClinicalNoteCard item={item} />;
        });
        return items;
    };

    const renderClinicNHopstialE = (array) => {
        return array.length === 0 ? (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ) : (
            renderClinicNHopstialECards(array)
        );
    };

    return (
        <div className="content-content">
            <div className="latestclinicalnote-header">
                <div className="row1 title">
                    Latest Clinical Note - By Centre
                </div>
                <Form
                    form={form}
                    layout="horizontal"
                    onFinish={onFinish}
                    requiredMark={false}
                >
                    <div className="container-row">
                        <div className="row2">
                            <div className="row">
                                {renderInputItems([
                                    // {
                                    //     label: "",
                                    //     name: "centreId",
                                    //     type: "select",
                                    //     placeholder: "Select centre",
                                    //     optionList: [
                                    //         { id: 0, name: "All" },
                                    //         { id: 1, name: "Centre 1" },
                                    //         { id: 2, name: "Centre 2" },
                                    //         { id: 3, name: "Centre 3" },
                                    //         { id: 4, name: "Centre 4" },
                                    //     ],
                                    //     className: "search",
                                    //     width: "120px",
                                    // },
                                    {
                                        label: "",
                                        name: "search",
                                        type: "input",
                                        placeholder: "Patient Name / NRIC",
                                        prefix: <SearchOutlined />,
                                        allowClear: true,
                                        className: "search"
                                    }
                                ])}
                                <Button type="primary" htmlType="submit">
                                    Search
                                </Button>
                            </div>
                        </div>
                    </div>
                </Form>
            </div>
            <Spin spinning={table.loading}>
                <div className="latestclinicalnote-content">
                    {renderClinicNHopstialE(
                        display
                        // [
                        //     {
                        //         id: 1,
                        //         name: "Diego McLaughlin",
                        //         nric: "*****123A",
                        //         latestClinicalNotes: [
                        //             {
                        //                 id: 5,
                        //                 datetime: "10 Apr 2021, 06:00",
                        //                 author: "Dr ABC",
                        //                 clinicEvent: "Clinic ABC",
                        //                 observation: "This is the review bla bla bla",
                        //                 assessment: "This is the assessment ah ah ah",
                        //                 plans: "This is the plan an an",
                        //                 intraDialysisNotes: "This is the notes. Ha ha ha",
                        //             }
                        //         ],
                        //         latestHospitalEntries: [
                        //             {
                        //                 id: 5,
                        //                 day: "2 days",
                        //                 location: "ABC Hospital",
                        //                 primaryDiagnosis: "This is a diagnosis report. Blah blah blah",
                        //             },
                        //         ],
                        //     },
                        //     {
                        //         id: 1,
                        //         name: "Diego NotLaughlin",
                        //         nric: "*****123B",
                        //         latestClinicalNotes: [
                        //             {
                        //                 id: 5,
                        //                 datetime: "10 Apr 2021, 06:00",
                        //                 author: "Dr ABC",
                        //                 clinicEvent: "Clinic ABC",
                        //                 observation: "This is the review bla bla bla",
                        //                 assessment: "This is the assessment ah ah ah",
                        //                 plans: "This is the plan an an",
                        //                 intraDialysisNotes: "This is the notes. Ha ha ha",
                        //             }
                        //         ],
                        //         latestHospitalEntries: [
                        //             {
                        //                 id: 5,
                        //                 day: "2 days",
                        //                 location: "ABC Hospital",
                        //                 primaryDiagnosis: "This is a diagnosis report. Blah blah blah",
                        //             },
                        //         ],
                        //     }
                        // ]
                    )}
                    <Pagination
                        pageSize={table.pagination.pageSize}
                        current={table.pagination.current}
                        total={table.pagination.total}
                        onChange={handleTableChange}
                        // showSizeChanger={false}
                        onShowSizeChange={handleTableShowSizeChange}
                    />
                </div>
            </Spin>
        </div>
    );
};

export default LatestClinicalNoteCentre;
