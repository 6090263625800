import React, { useEffect, useState, useContext, useMemo } from "react";
import {
    Avatar,
    Button,
    Checkbox,
    DatePicker,
    Descriptions,
    Divider,
    Form,
    Input,
    Modal,
    notification,
    Radio,
    Select,
    Spin,
    TreeSelect,
    Upload
} from "antd";
import {
    MinusCircleOutlined,
    PlusOutlined,
    UploadOutlined,
    UserOutlined
} from "@ant-design/icons";
import { useHistory, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import moment from "moment";
import { API, Storage } from "aws-amplify";
import Compressor from "compressorjs";

import path from "../utils/pathSettings";
import { AppContext } from "../contexts/AppContext";
import { createAllergy, createPatient } from "../backend/graphql/mutations";
import { updatePatient } from "../backend/graphql/mutations";
import { checkImageTypeValidity } from "../utils/fileTypeCheck";
import { compressImage } from "../utils/compressImage";
import PatientCreateDuplicated from "./Component-Patient-Create-Duplicated";
import { referringSource } from "../utils/constants";

const PatientCreate = (props) => {
    const [form] = Form.useForm();
    const { appState, getS3 } = useContext(AppContext);
    const location = useLocation();
    const { Option } = Select;
    const { TextArea } = Input;
    const { TreeNode } = TreeSelect;
    const [values, setValues] = useState({});
    const dateFormatList = ["DD MMM YYYY"];
    const disabledDate = (current) => {
        // Cannot select days next day onwards
        return current > moment().endOf("day");
    };
    const validateMessages = {
        required: "This field is required.",
        types: {
            email: "This is not a valid email."
        }
    };
    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };
    const uploadButton = (
        <div>
            <Button icon={<UploadOutlined />}>Upload</Button>
        </div>
    );
    const [state, setState] = useState({
        fileList: []
    });
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const [patientDuplicatedSearch, setPatientDuplicatedSearch] = useState("");

    const uploadFile = async (file, patientID) => {
        // console.log(file);
        const fileName =
            "p_" +
            patientID +
            "_" +
            moment().utc().format("YYYY-MM-DD_HHmmss") +
            "_" +
            file.name;
        const fileType = file.type;

        try {
            const result = await Storage.put("patient/" + fileName, file, {
                contentType: fileType
            });
            // console.log(result, result.key);
            return result.key;
        } catch (error) {
            console.log("error: ", error);
            notification.error({
                message: error
            });
        }
    };

    const createPatientInformation = async (values, fileList) => {
        // console.log("values", values);
        setLoading(true);
        try {
            let variables = {
                ...values,
                dob:
                    values.dob !== null &&
                    values.dob !== undefined &&
                    values.dob !== ""
                        ? moment(values.dob).format("YYYY-MM-DD")
                        : null,
                // firstDialysis: values.firstDialysis !== null && values.firstDialysis !== undefined && values.firstDialysis !== "" ? moment(values.firstDialysis).format("YYYY-MM-DD") : null,
                // amputee: values.amputeeYes,
                vwoSupport: values.vwoSupportYes,
                height: values.height ? parseFloat(values.height) * 100 : null,
                weight: values.weight ? parseFloat(values.weight) * 1000 : null,
                wheelchairWeight: values.wheelchairWeight
                    ? parseFloat(values.wheelchairWeight) * 1000
                    : null,
                // status: values.active ? 'ACTIVE' : 'INACTIVE',
                status: values.status,
                gender:
                    values.gender == "Male"
                        ? "M"
                        : values.gender == "Female"
                        ? "F"
                        : "O",
                firstDialysisHospitalDate: values.firstDialysisHospitalDate
                    ? moment(values.firstDialysisHospitalDate)
                          .toISOString()
                          .split(".")[0]
                    : null,
                referringSourceEnd: values.referringSourceEnd
                    ? moment(values.referringSourceEnd)
                          .toISOString()
                          .split(".")[0]
                    : null,
                referringSourceStart: values.referringSourceStart
                    ? moment(values.referringSourceStart)
                          .toISOString()
                          .split(".")[0]
                    : null,
                DESRD: values.DESRD ? values.DESRD.format("YYYY-MM-DD") : null,
                existingClinicID: values.existingClinic
                    ? parseInt(values.existingClinic)
                    : null
            };
            variables.existingClinic = null;
            // variables.active = null
            variables.amputeeYes = null;
            variables.vwoSupportYes = null;
            variables.allergies = null;
            variables.bmi = null;
            variables = Object.entries(variables).reduce(
                (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
                {}
            );
            // console.log("variables", variables);

            const result = await API.graphql({
                query: createPatient,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables: { create: variables }
            });
            // console.log("result", result);

            if (result?.data?.result) {
                if (fileList.length > 0) {
                    const file = fileList[0].originFileObj;

                    let _file = await compressImage(file);
                    const imageKey = (await uploadFile(_file)) ?? "";
                    updatePatiendInformation(result.data.result.id, imageKey);
                }

                if (typeof values.allergies == "string") {
                    variables = {
                        patientID: result.data.result.id,
                        name: values.allergies,
                        knownDate: moment().toISOString().split(".")[0]
                    };

                    // console.log('variables', variables)

                    const result1 = await API.graphql({
                        query: createAllergy,
                        authMode: "AMAZON_COGNITO_USER_POOLS",
                        variables: { create: variables }
                    });
                    // console.log(result1);
                } else if (typeof values.allergies == "object") {
                    let query1 = ``;
                    //     `
                    //     mutation CreateAllergy(
                    //         $create: CreateAllergyInput!
                    //     ) {
                    //         result: createAllergy(create: $create) { id }
                    //     }
                    //    `;
                    let query2 = ``;
                    let variables = {};

                    for (let i = 0; i < values.allergies.length; i++) {
                        query1 += `
                        $create${i}: CreateAllergyInput!`;
                        query2 += `
                        result${i}: createAllergy(create: $create${i}) { id }`;

                        variables["create" + i] = {
                            patientID: result.data.result.id,
                            name: values.allergies[i].allergy,
                            knownDate: moment().toISOString().split(".")[0]
                        };

                        // console.log('variables', variables)

                        const result2 = await API.graphql({
                            query: `mutation CreateAllergy( ${query1} ) { ${query2} }`,
                            authMode: "AMAZON_COGNITO_USER_POOLS",
                            variables
                        });
                        // console.log(result2);
                    }
                }

                notification.success({
                    message: "Created successfully"
                });

                // Include the returned patient id for redirect
                history.push(
                    path("patientInformation", [result.data.result.id])
                );
            }
        } catch (error) {
            console.log("error", error);
            if (
                error &&
                error?.errors?.length > 0 &&
                error.errors[0].message === "NRIC in used."
            ) {
                notification.error({
                    message: "NRIC in used."
                });
            } else {
                notification.error({
                    message: "Unable to create record"
                });
            }
        } finally {
            setLoading(false);
        }
    };

    const updatePatiendInformation = async (id, imageKey) => {
        try {
            let s3 = getS3();
            let variables = {
                id,
                key: imageKey,
                bucket: s3?.bucket,
                region: s3?.region
            };
            const result = await API.graphql({
                query: updatePatient,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables: { update: variables }
            });
            // console.log("updatePatiendInformation", result);
        } catch (error) {
            // console.log(error);
            notification.error({
                message: "Unable to update profile picture"
            });
        }
    };

    useEffect(() => {
        // console.log('location', location);
        const params = new URLSearchParams(location.search);
        const name = params.get("name") ?? ""; // bar
        const nric = params.get("nric") ?? ""; // bar
        const gender = params.get("gender") ?? ""; // bar
        // console.log(name, nric, gender);

        setValues({
            amputee: "No",
            // accommodation: "HDB",
            // address: "123",
            // alcohol: "Current",
            // allergies: "123",
            // bloodGroup: "A POS",
            // causeOfKidneyFailure: "123",
            // diabeticStatus: "Yes",
            // dob: moment("2022-02-02"),
            // email: "123@123.co",
            // firstDialysis: moment("2022-02-01"),
            // firstDialysisHospital: "12",
            // firstDialysisHospitalDate: moment("2022-01-31T17:57:15"),
            gender: gender,
            // height: 1.65,
            // history: "123",
            // homeNo: "123",
            // idType: "NRIC",
            // maritalStatus: "Single",
            // mobilityStatus: "Independent",
            name: name,
            // nokAddress: "123",
            // nokName: "123",
            // nokPhoneNo: "123",
            // nokRelation: "123",
            nric: nric
            // phoneNo: "123",
            // race: "Chinese",
            // referrer: "Dr",
            // referringSource: "SGH",
            // referringSourceEnd: moment("2022-02-01T17:56:53"),
            // referringSourceStart: moment("2022-01-31T17:56:51"),
            // religion: "Muslim",
            // smoker: "Past",
            // socialWorker: "123",
            // active: 1,
            // travelType: "Public Transport",
            // vwoSupport: "1",
            // weight: 69,
            // wheelchairWeight: 1,
            // existingClinic: 1,
        });
    }, []);

    useEffect(() => {
        // console.log("values", values);
        form.setFieldsValue(values);
    }, [values]);

    const handleCancel = () => {
        setState({ ...state, previewVisible: false });
    };

    const onFinishCreatePaitent = async (values) => {
        // console.log("create patient values", values);
        await form.validateFields();
        let boolCreate = false;

        // check for image upload
        if (state.fileList.length > 0) {
            const file = state.fileList[0].originFileObj;
            const typeType = file.type;

            if (checkImageTypeValidity(typeType) === true) {
                boolCreate = true;
            } else {
                notification.error({
                    message: "Invalid image type. Please reattach image."
                });
            }
        } else {
            boolCreate = true;
        }

        if (boolCreate === true)
            createPatientInformation(values, state.fileList);
    };

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        setState({
            ...state,
            previewImage: file.url || file.preview,
            previewVisible: true,
            previewTitle:
                file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
        });
    };

    const handleChange = (file) => {
        // console.log(file);
        // console.log(optionNum);
        let { fileList } = file;
        // fileList = fileList.slice(-1)
        setState({ ...state, fileList: fileList });
    };

    const handleOnChange = (value) => {
        // console.log('handleOnChange', value, value.target, value.target.id, value.target.value);

        if (value.target.id === "name") {
            setPatientDuplicatedSearch(value.target.value);
        }
    };

    const handleInputOnBlur = () => {
        let formValues = form.getFieldValue();
        // console.log(formValues);

        if (formValues.height && formValues.weight) {
            let height = parseFloat(formValues.height);
            let weight = parseFloat(formValues.weight);

            if (height > 0 && weight > 0)
                form.setFieldsValue({
                    bmi: (weight / (height * height)).toFixed(2)
                });
        }
    };

    const handleDatePickerChange = (date, dateString) => {
        // console.log(date, dateString);
    };

    const renderListOptions = (
        array,
        key = "id",
        value = "id",
        name = "name"
    ) => {
        if (typeof array == "object" && array.length !== 0) {
            const listOptions = array.map((item, index) => {
                return (
                    <Option key={item[key]} value={item[value]}>
                        {item[name]}
                    </Option>
                );
            });

            return listOptions;
        } else {
            return <div />;
        }
    };

    const renderTreeSelectOptions = (array) => {
        if (typeof array == "object" && array.length !== 0) {
            const listOptions = array.map((item, index) => {
                return (
                    <TreeNode title={item.name} value={item.id} key={item.id} />
                );
            });

            return listOptions;
        } else {
            return "";
        }
    };

    const renderInputItems = (array) => {
        const items = array.map((item, index) => {
            // console.log("item", item);
            switch (item.type) {
                case "select":
                    return (
                        <Form.Item
                            key={index}
                            label={item.label}
                            name={item.name}
                            rules={[
                                {
                                    required:
                                        item.required === true ? true : false
                                }
                            ]}
                            style={
                                item.width !== undefined
                                    ? { width: item.width }
                                    : {}
                            }
                        >
                            <Select
                                showSearch
                                placeholder={item.placeholder}
                                filterOption={(input, option) =>
                                    option?.children
                                        ?.toLowerCase()
                                        .indexOf(input?.toLowerCase()) >= 0
                                }
                                disabled={item.disabled === true ? true : false}
                                // defaultValue={0}
                            >
                                {renderListOptions(item.optionList)}
                            </Select>
                        </Form.Item>
                    );
                case "treeSelect":
                    return (
                        <Form.Item
                            key={index}
                            label={item.label}
                            name={item.name}
                            rules={[
                                {
                                    required:
                                        item.required === true ? true : false
                                }
                            ]}
                            style={
                                item.width !== undefined
                                    ? { width: item.width }
                                    : {}
                            }
                        >
                            <TreeSelect
                                showSearch
                                allowClear
                                multiple
                                treeDefaultExpandAll
                                treeCheckable
                                placeholder="Select symptoms"
                                filterTreeNode={(input, treenode) =>
                                    treenode.title
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {renderTreeSelectOptions(item.optionList)}
                            </TreeSelect>
                        </Form.Item>
                    );
                case "input":
                    return (
                        <Form.Item
                            key={index}
                            label={item.label}
                            name={item.name}
                            onChange={item.onChange}
                            rules={[
                                {
                                    required:
                                        item.required === true ? true : false
                                },
                                {
                                    message: item.regexMessage,
                                    pattern: item.regex
                                }
                            ]}
                            style={
                                item.width !== undefined
                                    ? { width: item.width }
                                    : {}
                            }
                        >
                            <Input
                                placeholder={item.placeholder}
                                disabled={item.disabled === true ? true : false}
                                onBlur={
                                    item.onBlur !== undefined
                                        ? item.onBlur
                                        : null
                                }
                                autoComplete="off"
                            />
                        </Form.Item>
                    );
                case "email":
                    return (
                        <Form.Item
                            key={index}
                            label={item.label}
                            name={item.name}
                            rules={[
                                {
                                    type: "email",
                                    required:
                                        item.required === true ? true : false
                                }
                            ]}
                            style={
                                item.width !== undefined
                                    ? { width: item.width }
                                    : {}
                            }
                        >
                            <Input
                                placeholder={item.placeholder}
                                disabled={item.disabled === true ? true : false}
                                autoComplete="off"
                            />
                        </Form.Item>
                    );
                case "text":
                    return (
                        <Form.Item
                            key={index}
                            label={item.label}
                            name={item.name}
                            rules={[
                                {
                                    required:
                                        item.required === true ? true : false
                                }
                            ]}
                            style={{ width: "100%" }}
                        >
                            <TextArea
                                rows={item.rows}
                                placeholder={item.placeholder}
                                disabled={item.disabled === true ? true : false}
                            />
                        </Form.Item>
                    );
                case "datepicker":
                    return (
                        <Form.Item
                            key={index}
                            label={item.label}
                            name={item.name}
                            rules={[
                                {
                                    required:
                                        item.required === true ? true : false
                                }
                            ]}
                        >
                            <DatePicker
                                onChange={handleDatePickerChange}
                                format={dateFormatList}
                                disabledDate={disabledDate}
                                disabled={item.disabled === true ? true : false}
                            />
                        </Form.Item>
                    );
                case "checkbox":
                    return (
                        <Form.Item key={index} label={item.label}>
                            <Form.Item
                                name={item.name}
                                valuePropName="checked"
                                noStyle
                            >
                                <Checkbox
                                    disabled={
                                        item.disabled === true ? true : false
                                    }
                                />
                            </Form.Item>
                        </Form.Item>
                    );
                case "radio":
                    return (
                        <Form.Item
                            key={index}
                            label={item.label}
                            name={item.name}
                            rules={[
                                {
                                    required:
                                        item.required === true ? true : false
                                }
                            ]}
                        >
                            <Radio.Group
                                options={item.optionList}
                                defaultValue={item.defaultValue}
                                disabled={item.disabled === true ? true : false}
                            />
                        </Form.Item>
                    );
                case "space":
                    return <Form.Item key={index}>&nbsp;</Form.Item>;
                case "none":
                    return (
                        <Form.Item
                            key={index}
                            style={{ display: "none" }}
                        ></Form.Item>
                    );
                default:
            }
        });
        return items;
    };

    return (
        <div className="content-content">
            <div className="container-row">
                <Spin spinning={loading}>
                    <div className="patientinformation-header">
                        <div className="column1 title">Create Patient</div>
                        <div className="column2">
                            <Button>
                                <Link to={path("dashboard")}>Cancel</Link>
                            </Button>
                            {/* <Button type="primary" onClick={async () => {
                                let v = await form.validateFields();
                                // console.log(v);
                                createPatientInformation(v)
                            }}> */}
                            <Button
                                type="primary"
                                onClick={() =>
                                    onFinishCreatePaitent(form.getFieldsValue())
                                }
                            >
                                Save
                            </Button>
                        </div>
                    </div>
                    <Form
                        form={form}
                        layout="horizontal"
                        validateMessages={validateMessages}
                        requiredMark={true}
                    >
                        <div className="patientinformation-content">
                            <div className="column">
                                <div className="row1">
                                    <div className="container-profilepic">
                                        <div>
                                            <Avatar
                                                className="avatar"
                                                icon={<UserOutlined />}
                                                shape="square"
                                                src={
                                                    values.key
                                                        ? Storage.get(
                                                              values.key
                                                          )
                                                        : ""
                                                }
                                            />
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                paddingTop: "16px"
                                            }}
                                        >
                                            <Form.Item>
                                                <Upload
                                                    action={getBase64}
                                                    listType="picture"
                                                    fileList={state.fileList}
                                                    onPreview={handlePreview}
                                                    onChange={(file) =>
                                                        handleChange(file)
                                                    }
                                                >
                                                    {state.fileList.length >= 1
                                                        ? null
                                                        : uploadButton}
                                                </Upload>
                                                <Modal
                                                    visible={
                                                        state.previewVisible
                                                    }
                                                    title={state.previewTitle}
                                                    footer={null}
                                                    onCancel={handleCancel}
                                                >
                                                    <img
                                                        alt="upload preview"
                                                        style={{
                                                            width: "100%"
                                                        }}
                                                        src={state.previewImage}
                                                    />
                                                </Modal>
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className="container-ddl">
                                        <Descriptions
                                            title={"Dialysis Details"}
                                        />
                                        {renderInputItems([
                                            // {
                                            //     label: "Dialysis Start Date",
                                            //     name: "firstDialysis",
                                            //     type: "datepicker",
                                            //     // required: true,
                                            // },
                                            {
                                                label: "Referring Doctor",
                                                name: "referrer",
                                                type: "input",
                                                placeholder:
                                                    "Input referring doctor"
                                            },
                                            {
                                                label: "Referring Source",
                                                name: "referringSource",
                                                type: "select",
                                                placeholder:
                                                    "Select referring source",
                                                optionList: referringSource
                                            },
                                            {
                                                label: "Referring Source Start Date",
                                                name: "referringSourceStart",
                                                type: "datepicker"
                                                // required: true,
                                            },
                                            {
                                                label: "Referring Source End Date",
                                                name: "referringSourceEnd",
                                                type: "datepicker"
                                                // required: true,
                                            },
                                            {
                                                label: "Dialysis Centre",
                                                name: "existingClinic",
                                                type: "select",
                                                placeholder:
                                                    "Select dialysis centre",
                                                optionList:
                                                    appState.clinicList !== -1
                                                        ? appState.clinicList
                                                        : [],
                                                // optionList: appState.clinicList
                                                required: true
                                            },
                                            // {
                                            //     label: "Current Dialysis Centre Start Date",
                                            //     name: "firstDialysis",
                                            //     type: "datepicker",
                                            //     // required: true,
                                            // },
                                            // {
                                            //     label: "Active",
                                            //     name: "active",
                                            //     type: "checkbox",
                                            // },
                                            {
                                                label: "Status",
                                                name: "status",
                                                type: "select",
                                                placeholder:
                                                    "Select status type",
                                                // required: true,
                                                optionList: [
                                                    {
                                                        id: "DIALYSIS",
                                                        name: "Dialysis"
                                                    },
                                                    {
                                                        id: "CLINIC",
                                                        name: "Clinic"
                                                    },
                                                    {
                                                        id: "INACTIVE",
                                                        name: "Inactive"
                                                    }
                                                ]
                                            },
                                            {
                                                label: "Travel Type",
                                                name: "travelType",
                                                type: "select",
                                                placeholder:
                                                    "Select travel type",
                                                // required: true,
                                                optionList: [
                                                    {
                                                        id: "Car Private",
                                                        name: "Car Private"
                                                    },
                                                    {
                                                        id: "Car Hire",
                                                        name: "Car Hire"
                                                    },
                                                    { id: "Bus", name: "Bus" },
                                                    {
                                                        id: "Walk",
                                                        name: "Walk"
                                                    },
                                                    {
                                                        id: "Ambulance",
                                                        name: "Ambulance"
                                                    }
                                                ]
                                            }
                                        ])}
                                    </div>
                                </div>
                                <Divider />
                                <div className="row2">
                                    <Descriptions
                                        title={"Personal Particulars"}
                                    />
                                    {renderInputItems([
                                        {
                                            label: "Full Name",
                                            name: "name",
                                            type: "input",
                                            placeholder: "Enter full name",
                                            required: true,
                                            width: "100%",
                                            onChange: handleOnChange
                                        }
                                    ])}
                                    <PatientCreateDuplicated
                                        search={patientDuplicatedSearch}
                                    />
                                </div>
                                <div className="row2">
                                    {renderInputItems([
                                        {
                                            type: "none"
                                        },
                                        // {
                                        //     label: "First Name",
                                        //     name: "firstName",
                                        //     type: "input",
                                        //     placeholder: "Enter first name",
                                        //     required: true,
                                        // },
                                        // {
                                        //     label: "Last Name",
                                        //     name: "lastName",
                                        //     type: "input",
                                        //     placeholder: "Enter last name",
                                        //     required: true,
                                        // },
                                        {
                                            label: "Identification Type",
                                            name: "idType",
                                            type: "select",
                                            required: true,
                                            placeholder:
                                                "Select identification type",
                                            optionList: [
                                                { id: "NRIC", name: "NRIC" },
                                                {
                                                    id: "FIN No.",
                                                    name: "FIN No."
                                                },
                                                {
                                                    id: "Passport No.",
                                                    name: "Passport No."
                                                }
                                            ]
                                        },
                                        {
                                            label: "NRIC",
                                            name: "nric",
                                            type: "input",
                                            placeholder: "Enter full NRIC",
                                            required: true
                                        },
                                        {
                                            label: "Gender",
                                            name: "gender",
                                            type: "select",
                                            placeholder: "Select gender",
                                            required: true,
                                            optionList: [
                                                { id: "Male", name: "Male" },
                                                {
                                                    id: "Female",
                                                    name: "Female"
                                                },
                                                { id: "Others", name: "Others" }
                                            ]
                                        },
                                        {
                                            label: "Date of Birth",
                                            name: "dob",
                                            type: "datepicker",
                                            required: true
                                        },
                                        {
                                            label: "Race",
                                            name: "race",
                                            type: "select",
                                            placeholder: "Select race",
                                            required: true,
                                            optionList: [
                                                {
                                                    id: "Chinese",
                                                    name: "Chinese"
                                                },
                                                {
                                                    id: "Eurasian",
                                                    name: "Eurasian"
                                                },
                                                {
                                                    id: "Indian",
                                                    name: "Indian"
                                                },
                                                { id: "Malay", name: "Malay" },
                                                { id: "Others", name: "Others" }
                                            ]
                                        },
                                        {
                                            label: "Religion",
                                            name: "religion",
                                            type: "select",
                                            placeholder: "Select religion",
                                            // required: true,
                                            optionList: [
                                                {
                                                    id: "Buddhism",
                                                    name: "Buddhism"
                                                },
                                                {
                                                    id: "Christianity",
                                                    name: "Christianity"
                                                },
                                                {
                                                    id: "Hinduism",
                                                    name: "Hinduism"
                                                },
                                                { id: "Islam", name: "Islam" },
                                                {
                                                    id: "Taoism",
                                                    name: "Taoism"
                                                },
                                                {
                                                    id: "Others",
                                                    name: "Others"
                                                },
                                                { id: "Nil", name: "Nil" }
                                            ]
                                        },
                                        {
                                            label: "Marital Status",
                                            name: "maritalStatus",
                                            type: "select",
                                            placeholder:
                                                "Select marital status",
                                            // required: true,
                                            optionList: [
                                                {
                                                    id: "Single",
                                                    name: "Single"
                                                },
                                                {
                                                    id: "Married",
                                                    name: "Married"
                                                },
                                                {
                                                    id: "Divorced",
                                                    name: "Divorced"
                                                },
                                                {
                                                    id: "Widowed",
                                                    name: "Widowed"
                                                }
                                            ]
                                        },
                                        {
                                            label: "Accommodation",
                                            name: "accommodation",
                                            type: "select",
                                            placeholder: "Select accommodation",
                                            // required: true,
                                            optionList: [
                                                { id: "HDB", name: "HDB" },
                                                {
                                                    id: "Condominium",
                                                    name: "Condominium"
                                                },
                                                {
                                                    id: "Landed",
                                                    name: "Landed"
                                                },
                                                { id: "Rented", name: "Rented" }
                                            ]
                                        }
                                    ])}
                                </div>
                                <Divider />
                                <div className="row2">
                                    <Descriptions title={"Contact Details"} />
                                    {renderInputItems([
                                        {
                                            label: "Mobile Number",
                                            name: "phoneNo",
                                            type: "input",
                                            placeholder: "Enter mobile number",
                                            required: true,
                                            regex: new RegExp("[0-9 .+-,]+$"),
                                            regexMessage: "Invalid number"
                                        },
                                        {
                                            label: "Home Number",
                                            name: "homeNo",
                                            type: "input",
                                            placeholder: "Enter home number",
                                            required: false,
                                            regex: new RegExp("[0-9 .+-,]+$"),
                                            regexMessage: "Invalid number"
                                        },
                                        {
                                            label: "Email",
                                            name: "email",
                                            type: "email",
                                            placeholder: "Enter email"
                                        },
                                        {
                                            type: "space"
                                        },
                                        {
                                            label: "Address",
                                            name: "address",
                                            type: "text",
                                            placeholder: "Enter address",
                                            required: true,
                                            row: 3
                                        },
                                        {
                                            type: "none"
                                        },
                                        {
                                            label: "Name of Next of Kin",
                                            name: "nokName",
                                            type: "input",
                                            placeholder:
                                                "Enter name of next of kin",
                                            required: true
                                        },
                                        {
                                            label: "NOK's relationshp with Patient",
                                            name: "nokRelation",
                                            type: "input",
                                            placeholder:
                                                "Enter relationship with patient"
                                            // required: true,
                                        },
                                        {
                                            label: "NOK's Mobile Number",
                                            name: "nokPhoneNo",
                                            type: "input",
                                            placeholder: "Enter contact number",
                                            required: true,
                                            regex: new RegExp("[0-9 .+-,]+$"),
                                            regexMessage: "Invalid number"
                                        },
                                        {
                                            type: "space"
                                        },
                                        {
                                            label: "NOK's Address",
                                            name: "nokAddress",
                                            type: "text",
                                            placeholder: "Enter address",
                                            row: 3
                                            // required: true,
                                        }
                                    ])}
                                </div>
                            </div>
                            <div className="column">
                                <div className="row2">
                                    <Descriptions title={"Medical Details"} />
                                    {renderInputItems([
                                        {
                                            label: "Height (m)",
                                            name: "height",
                                            type: "input",
                                            placeholder: "Enter height",
                                            // required: true,
                                            // regex: new RegExp('^[+-]?([0-9]*[.])?[0-9]+$'),
                                            // regexMessage: 'Digits only'
                                            regex: new RegExp(
                                                "^([0-9]*[.])?[0-9]+$"
                                            ),
                                            regexMessage: "Invalid input",
                                            onBlur: handleInputOnBlur
                                        },
                                        {
                                            label: "Weight (kg)",
                                            name: "weight",
                                            type: "input",
                                            placeholder: "Enter weight",
                                            // required: true,
                                            // regex: new RegExp('^[+-]?([0-9]*[.])?[0-9]+$'),
                                            // regexMessage: 'Digits only'
                                            regex: new RegExp(
                                                "^([0-9]*[.])?[0-9]+$"
                                            ),
                                            regexMessage: "Invalid input",
                                            onBlur: handleInputOnBlur
                                        },
                                        {
                                            label: "BMI",
                                            name: "bmi",
                                            type: "input",
                                            placeholder: "",
                                            disabled: true
                                        },
                                        {
                                            label: "Blood Group",
                                            name: "bloodGroup",
                                            type: "select",
                                            placeholder: "Select blood group",
                                            // required: true,
                                            optionList: [
                                                { id: "A POS", name: "A POS" },
                                                { id: "A NEG", name: "A NEG" },
                                                { id: "B POS", name: "B POS" },
                                                { id: "B NEG", name: "B NEG" },
                                                {
                                                    id: "AB POS",
                                                    name: "AB POS"
                                                },
                                                {
                                                    id: "AB NEG",
                                                    name: "AB NEG"
                                                },
                                                { id: "O POS", name: "O POS" },
                                                { id: "O NEG", name: "O NEG" }
                                            ]
                                        },
                                        {
                                            label: "Mobility Status",
                                            name: "mobilityStatus",
                                            type: "select",
                                            placeholder: "Select status",
                                            // required: true,
                                            optionList: [
                                                {
                                                    id: "Independent",
                                                    name: "Independent"
                                                },
                                                {
                                                    id: "Wheelchair Assisted",
                                                    name: "Wheelchair Assisted"
                                                },
                                                {
                                                    id: "Ambulant Assistant",
                                                    name: "Ambulant Assistant"
                                                }
                                            ]
                                        },
                                        {
                                            label: "Diabetic Status",
                                            name: "diabeticStatus",
                                            type: "select",
                                            placeholder: "Select status",
                                            // required: true,
                                            optionList: [
                                                { id: "Yes", name: "Yes" },
                                                { id: "No", name: "No" }
                                            ]
                                        },
                                        {
                                            label: "Smoker Status",
                                            name: "smoker",
                                            type: "select",
                                            placeholder: "Select status",
                                            // required: true,
                                            optionList: [
                                                {
                                                    id: "Current",
                                                    name: "Current"
                                                },
                                                { id: "Past", name: "Past" },
                                                { id: "Never", name: "Never" }
                                            ]
                                        },
                                        {
                                            label: "Alcohol",
                                            name: "alcohol",
                                            type: "select",
                                            placeholder: "Select status",
                                            // required: true,
                                            optionList: [
                                                {
                                                    id: "Current",
                                                    name: "Current"
                                                },
                                                { id: "Past", name: "Past" },
                                                { id: "Never", name: "Never" }
                                            ]
                                        },
                                        // {
                                        //     label: "Allergies",
                                        //     name: "allergies",
                                        //     type: "input",
                                        //     placeholder: "Input allergies",
                                        //     required: false,
                                        //     width: "100%",
                                        // },
                                        // {
                                        //     type: "none"
                                        // },
                                        {
                                            label: "Amputee",
                                            name: "amputee",
                                            // type: "radio",
                                            type: "select",
                                            required: true,
                                            // defaultValue: 0,
                                            // optionList: [
                                            //     { label: "No", value: 0 },
                                            //     { label: "Yes", value: 1 },
                                            // ]
                                            optionList: [
                                                { id: "No", name: "No" },
                                                {
                                                    id: "Left BKA",
                                                    name: "Left BKA"
                                                },
                                                {
                                                    id: "Left AKA",
                                                    name: "Left AKA"
                                                },
                                                {
                                                    id: "Right BKA",
                                                    name: "Right BKA"
                                                },
                                                {
                                                    id: "Right AKA",
                                                    name: "Right AKA"
                                                },
                                                {
                                                    id: "Bilateral AKA",
                                                    name: "Bilateral AKA"
                                                },
                                                {
                                                    id: "Bilateral BKA",
                                                    name: "Bilateral BKA"
                                                },
                                                { id: "Others", name: "Others" }
                                            ]
                                        },
                                        {
                                            type: "space"
                                        },
                                        // {
                                        //     label: "If yes (Amputee), specify",
                                        //     name: "amputeeYes",
                                        //     type: "input",
                                        //     placeholder: "Enter",
                                        // },
                                        // {
                                        //     label: "If yes (Amputee), specify",
                                        //     name: "amputeeYes",
                                        //     type: "select",
                                        //     placeholder: "Select status",
                                        //     required: true,
                                        //     optionList: [
                                        //         { id: 0, name: "None" },
                                        //         { id: 1, name: "Left BKA" },
                                        //         { id: 2, name: "Left AKA" },
                                        //         { id: 3, name: "Right BKA" },
                                        //         { id: 4, name: "Right AKA" },
                                        //         { id: 5, name: "Bilateral AKA" },
                                        //         { id: 6, name: "Bilateral BKA" },
                                        //         { id: 7, name: "Others" },
                                        //     ]
                                        // },
                                        {
                                            label: "Date ESRD",
                                            name: "DESRD",
                                            type: "datepicker",
                                            required: true
                                        },
                                        {
                                            label: "Date of First Dialysis",
                                            name: "firstDialysisHospitalDate",
                                            type: "datepicker"
                                        },
                                        {
                                            label: "Wheelchair's weight (kg)",
                                            name: "wheelchairWeight",
                                            type: "input",
                                            placeholder: "Enter weight",
                                            // regex: new RegExp('^[+-]?([0-9]*[.])?[0-9]+$'),
                                            // regexMessage: 'Digits only'
                                            regex: new RegExp(
                                                "^([0-9]*[.])?[0-9]+$"
                                            ),
                                            regexMessage: "Invalid input"
                                        },
                                        {
                                            type: "space"
                                        }
                                        // {
                                        //     label: "Patient's History",
                                        //     name: "history",
                                        //     type: "text",
                                        //     placeholder: "Enter patient's history",
                                        //     rows: 3,
                                        // },
                                        // {
                                        //     type: "none"
                                        // },
                                        // {
                                        //     label: "Cause of kidney failure",
                                        //     name: "causeOfKidneyFailure",
                                        //     type: "text",
                                        //     placeholder: "Enter cause of kidney failure",
                                        //     rows: 3,
                                        // },
                                        // {
                                        //     type: "none"
                                        // },
                                    ])}
                                </div>
                                <Form.List
                                    name="allergies"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Missing allergy, please provide 1"
                                        }
                                    ]}
                                    initialValue={[""]}
                                >
                                    {(fields, { add, remove }, { errors }) => (
                                        <>
                                            {fields.map((field) => (
                                                <div
                                                    key={field.key}
                                                    className="allergies-input"
                                                >
                                                    <Form.Item
                                                        {...field}
                                                        label="Allergy"
                                                        name={[
                                                            field.name,
                                                            "allergy"
                                                        ]}
                                                        fieldKey={[
                                                            field.fieldKey,
                                                            "allergy"
                                                        ]}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    "Missing allergy"
                                                            }
                                                        ]}
                                                    >
                                                        <Input
                                                            placeholder="Input allergy"
                                                            autoComplete="off"
                                                        />
                                                    </Form.Item>
                                                    <MinusCircleOutlined
                                                        className="btn-minus"
                                                        onClick={() =>
                                                            remove(field.name)
                                                        }
                                                    />
                                                </div>
                                            ))}
                                            <Form.Item>
                                                <Button
                                                    type="primary"
                                                    onClick={() => add()}
                                                    style={{ width: "60%" }}
                                                    icon={<PlusOutlined />}
                                                >
                                                    Add Allergy
                                                </Button>
                                                <Form.ErrorList
                                                    errors={errors}
                                                />
                                            </Form.Item>
                                        </>
                                    )}
                                </Form.List>
                                {/* <Divider />
                                <div className="row2">
                                    <Descriptions title={"Dialysis Schedule"} />
                                    {renderInputItems([
                                        // {
                                        //     label: "Pattern",
                                        //     name: "pattern",
                                        //     type: "select",
                                        //     placeholder: "Select pattern",
                                        //     optionList: [
                                        //         { id: 1, name: "Option 1" },
                                        //         { id: 2, name: "Option 2" },
                                        //         { id: 3, name: "Option 3" },
                                        //         { id: 4, name: "Option 4" },
                                        //     ]
                                        // },
                                        // {
                                        //     label: "Session",
                                        //     name: "session",
                                        //     type: "select",
                                        //     placeholder: "Select session",
                                        //     optionList: [
                                        //         { id: 1, name: "Option 1" },
                                        //         { id: 2, name: "Option 2" },
                                        //         { id: 3, name: "Option 3" },
                                        //         { id: 4, name: "Option 4" },
                                        //     ]
                                        // },
                                        // {
                                        //     label: "HD Device No",
                                        //     name: "hdDeviceNo",
                                        //     type: "select",
                                        //     placeholder: "Select device",
                                        //     optionList: [
                                        //         { id: 1, name: "Option 1" },
                                        //         { id: 2, name: "Option 2" },
                                        //         { id: 3, name: "Option 3" },
                                        //         { id: 4, name: "Option 4" },
                                        //     ]
                                        // },
                                    ])}
                                </div> */}
                                <Divider />
                                <div className="row2">
                                    <Descriptions title={"Support"} />
                                    {renderInputItems([
                                        {
                                            label: "Med Social Worker",
                                            name: "socialWorker",
                                            type: "input",
                                            placeholder:
                                                "Enter med social worker"
                                        },
                                        {
                                            type: "space"
                                        },
                                        // {
                                        //     label: "Funded",
                                        //     name: "vwoSupport",
                                        //     type: "radio",
                                        //     // required: true,
                                        //     defaultValue: 0,
                                        //     optionList: [
                                        //         { label: "No", value: 0 },
                                        //         { label: "Yes", value: 1 },
                                        //     ]
                                        // },
                                        // {
                                        //     label: "If yes (Funded), specify",
                                        //     name: "vwoSupportYes",
                                        //     type: "input",
                                        //     placeholder: "Enter",
                                        // },
                                        {
                                            label: "Funded",
                                            name: "vwoSupportYes",
                                            type: "input",
                                            placeholder:
                                                "If no, leave this blank",
                                            width: "100%"
                                        }
                                    ])}
                                </div>
                            </div>
                        </div>
                    </Form>
                </Spin>
            </div>
        </div>
    );
};

export default PatientCreate;
