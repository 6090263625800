import React, { useContext, useEffect, useState } from "react";
import {
    Button,
    Checkbox,
    Form,
    Input,
    notification,
    Spin,
    Tabs,
    View
} from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { Link, useHistory } from "react-router-dom";
import { AppContext } from "../contexts/AppContext";

import path from "../utils/pathSettings";
import Logo from "../media/Logo-AEGISMedicalCare.png";
import { Auth } from "aws-amplify";

const ForgotPassword = (props) => {
    const { appState, actionLogin } = useContext(AppContext);
    const { from } = props.location.state || { from: "/" };
    const history = useHistory();
    const [form, form2] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const validateMessages = {
        required: "This field is required.",
        types: {
            email: "This is not a valid email."
        },
        string: {
            min: "This field must be at least ${min} characters."
        }
    };
    const [sentVerificationCode, setSentVerificationCode] = useState(false);

    useEffect(() => {
        // console.log("appState", appState);
        // if (appState.validLogin === true) {
        //     history.push(from)
        // }
        // if (localStorage.getItem("ircs_remember") !== null) {
        //     form.setFieldsValue({
        //         username: localStorage.getItem("ircs_remember"),
        //         remember: true,
        //     })
        // }
        // else {
        //     form.setFieldsValue({
        //         remember: false
        //     })
        // }

        return () => {};
    }, []);

    useEffect(() => {
        if (
            appState.fixedListLoaded === true &&
            Object.keys(appState.cognitoUser).length !== 0 &&
            appState.validLogin === true
        ) {
            history.push(from);
        }
    }, [appState]);

    const onFinish = async (values) => {
        setLoading(true);
        // console.log("onFinish", values);
        let email = values.username;

        try {
            const result = await Auth.forgotPassword(email);
            // console.log(result);
            notification.success({
                message: "Verification code has been sent to " + email
            });
            setSentVerificationCode(true);
        } catch (error) {
            console.log("error: ", error);
            if (error.code === "UserNotFoundException") {
                notification.error({
                    message: "Email not found."
                });
            } else if (error.code === "InvalidParameterException") {
                notification.error({
                    message:
                        "Unable to reset password as the email entered is not verified by user."
                });
            } else {
                notification.error({
                    message: error.message
                });
            }
        } finally {
            setLoading(false);
        }
    };

    const onFinishResetPassword = async (values) => {
        setLoading(true);
        // console.log("onFinishResetPassword", values);

        try {
            const result = await Auth.forgotPasswordSubmit(
                values.username,
                values.verificationCode,
                values.newPassword
            );
            // console.log(result);
            notification.success({
                message: "Password has been successfully reset"
            });
            history.push(path("login"));
        } catch (error) {
            console.log("error: ", error);
            notification.error({
                message: error.message
            });
            setLoading(false);
        }
    };

    return (
        <div className="login">
            <div className="container-login">
                <div className="login-body">
                    <div className="login-header">
                        <img src={Logo} />
                        <div>Forgot Password</div>
                    </div>
                    <div className="login-content">
                        <Spin spinning={loading}>
                            {sentVerificationCode == false ? (
                                <Form
                                    form={form}
                                    name="normal_login"
                                    className="login-form"
                                    onFinish={onFinish}
                                    validateMessages={validateMessages}
                                >
                                    <Form.Item
                                        name="username"
                                        rules={[
                                            {
                                                type: "email",
                                                required: true
                                            }
                                        ]}
                                    >
                                        <Input
                                            autoComplete="off"
                                            prefix={
                                                <UserOutlined className="site-form-item-icon" />
                                            }
                                            placeholder="Email"
                                        />
                                    </Form.Item>
                                    <Form.Item>
                                        <Link
                                            className="login-form-forgot"
                                            to={path("login")}
                                        >
                                            Back to Login
                                        </Link>
                                    </Form.Item>
                                    <Form.Item>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            className="login-form-button"
                                        >
                                            Send Verifcation Code
                                        </Button>
                                    </Form.Item>
                                </Form>
                            ) : (
                                <Form
                                    form={form2}
                                    name="normal_login"
                                    className="login-form"
                                    onFinish={onFinishResetPassword}
                                    validateMessages={validateMessages}
                                >
                                    <Form.Item
                                        name="username"
                                        rules={[
                                            {
                                                type: "email",
                                                required: true
                                            }
                                        ]}
                                    >
                                        <Input
                                            autoComplete="off"
                                            prefix={
                                                <UserOutlined className="site-form-item-icon" />
                                            }
                                            placeholder="Email"
                                            disabled
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="verificationCode"
                                        rules={[
                                            {
                                                required: true
                                            }
                                        ]}
                                    >
                                        <Input placeholder="Enter Verification Code" />
                                    </Form.Item>
                                    <Form.Item
                                        name="newPassword"
                                        rules={[
                                            {
                                                required: true
                                            }
                                        ]}
                                    >
                                        <Input.Password
                                            prefix={
                                                <LockOutlined className="site-form-item-icon" />
                                            }
                                            placeholder="New Password"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="confirmNewPassword"
                                        rules={[
                                            {
                                                required: true
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (
                                                        !value ||
                                                        getFieldValue(
                                                            "newPassword"
                                                        ) === value
                                                    ) {
                                                        return Promise.resolve();
                                                    }

                                                    return Promise.reject(
                                                        new Error(
                                                            "The two passwords that you entered do not match."
                                                        )
                                                    );
                                                }
                                            })
                                        ]}
                                    >
                                        <Input.Password
                                            prefix={
                                                <LockOutlined className="site-form-item-icon" />
                                            }
                                            placeholder="Confirm New Password"
                                        />
                                    </Form.Item>
                                    <Form.Item>
                                        <Link
                                            className="login-form-forgot"
                                            to={path("login")}
                                        >
                                            Back to Login
                                        </Link>
                                    </Form.Item>
                                    <Form.Item>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            className="login-form-button"
                                        >
                                            Reset Password
                                        </Button>
                                    </Form.Item>
                                </Form>
                            )}
                        </Spin>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;
