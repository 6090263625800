import React from "react"

import PatientRecordClinicNotes from "./Component-PatientRecord-ClinicNotes"
import PatientRecordHospitalEntries from "./Component-PatientRecord-HospitalEntries"

const PatientRecordClinicNHospitalE = (props) => {

    return (
        <div className="patientrecord-clinicnhospitale" id="clinicalnotes">
            <PatientRecordClinicNotes patientID={props?.patientID} />
            <PatientRecordHospitalEntries patientID={props?.patientID} />
        </div>
    )
}

export default PatientRecordClinicNHospitalE