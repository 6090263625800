import React, { useContext, useEffect, useState } from "react";
import {
    Avatar,
    Button,
    DatePicker,
    Divider,
    Empty,
    Form,
    Input,
    Modal,
    notification,
    Popover,
    Radio,
    Select,
    Space,
    Spin,
    Table,
    Tabs,
    TreeSelect
} from "antd";
import { TreeNode } from "antd/lib/tree-select";
import { SHOW_PARENT } from "rc-tree-select";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { API } from "aws-amplify";
import moment from "moment";

import path from "../utils/pathSettings";
import { AppContext } from "../contexts/AppContext";
import { listIncident, listIncidentCentre } from "../backend/graphql/queries";
import IconIncidentReport from "../media/Icon-PatientCard-IncidentReport.png";
import IconNurse from "../media/Icon-PatientCard-Nurse.png";
import IconPatientInfo from "../media/Icon-PatientCard-PatientInfo.png";
import iconEye from "../media/Icon-Eye2.png";
import renderInputItems from "../utils/renderFormInputItems";
import useSWR from "swr";
import { fetcher } from "../utils/useSWR";

const IncidentReport = () => {
    const { appState } = useContext(AppContext);
    const [form] = Form.useForm();
    const { Option } = Select;
    const history = useHistory();
    const [filter, setFilter] = useState({
        search: "",
        searchType: "patient",
        clinicIDs: appState.selectedClinicID
            ? [parseInt(appState.selectedClinicID)]
            : []
    });
    const [appliedFilter, setAppliedFilter] = useState({
        search: "",
        searchType: "patient",
        clinicIDs: appState.selectedClinicID
            ? [parseInt(appState.selectedClinicID)]
            : []
    });
    const [table, setTable] = useState({
        data: -1,
        pagination: {
            current: 1,
            pageSize: 20,
            offset: 0
        },
        loading: false
    });
    const [display, setDisplay] = useState([]);
    const [visibilityViewIncident, setVisibilityViewIncident] = useState(false);
    const [incident, setIncident] = useState({});
    const patientColumns = [
        {
            // title: "Timestamp",
            title: "Submitted",
            dataIndex: "timestamp"
        },
        {
            title: "Centre/Group Reporting",
            dataIndex: "reportingCentre"
        },
        // {
        //     title: "No",
        //     dataIndex: "no"
        // },
        {
            // title: "Location of Event",
            title: "Location",
            dataIndex: "location"
        },
        {
            // title: "Date / Time of Event",
            title: "Date / Time",
            dataIndex: "datetime"
        },
        {
            title: "Reported By",
            dataIndex: "reportedBy"
        },
        // {
        //     title: "Centre/Patient Event",
        //     dataIndex: "centrePatientEvent",
        // },
        {
            // title: "Event Type",
            title: "Type",
            dataIndex: "eventType"
        },
        // {
        //     title: "Name Code",
        //     dataIndex: "nameCode"
        // },
        {
            // title: "Description Event",
            title: "Description",
            dataIndex: "description",
            ellipsis: true
        },
        {
            // title: "Probable Event Cause",
            title: "Probable Cause",
            dataIndex: "probableCause",
            ellipsis: true
        },
        {
            title: "Action Taken [dated]",
            dataIndex: "actionTaken",
            ellipsis: true
        },
        {
            // title: "Event Outcome",
            title: "Outcome",
            dataIndex: "eventOutcome",
            ellipsis: true
        },
        {
            title: "Further Action Planned",
            dataIndex: "furtherActionPlanned",
            ellipsis: true
        },
        {
            title: "Additional Orders (Medical Director)",
            dataIndex: "addedOrders",
            ellipsis: true
        },
        {
            title: "Action",
            dataIndex: "action",
            render: (text, record) => {
                return (
                    <div
                        className="clickable"
                        onClick={() => {
                            handleModalVisibility("viewIncident", record);
                        }}
                    >
                        <img src={iconEye} />
                    </div>
                );
            }
        }
    ];
    const centreColumns = [
        // {
        //     title: "Patient Name",
        //     dataIndex: "name",
        // },
        // {
        //     title: "Patient Nric",
        //     dataIndex: "nric",
        // },
        {
            title: "Submitted",
            dataIndex: "timestamp"
        },
        {
            title: "Centre/Group Reporting",
            dataIndex: "reportingCentre"
        },
        {
            title: "No",
            dataIndex: "no"
        },
        {
            title: "Location of Event",
            dataIndex: "location"
        },
        {
            title: "Date / Time of Event",
            dataIndex: "datetime"
        },
        {
            title: "Reported By",
            dataIndex: "reportedBy"
        },
        {
            title: "Centre/Patient Event",
            dataIndex: "centrePatientEvent"
        },
        {
            title: "Event Type",
            dataIndex: "eventType"
        },
        // {
        //     title: "Name Code",
        //     dataIndex: "nameCode"
        // },
        {
            title: "Description Event",
            dataIndex: "description",
            ellipsis: true
        },
        {
            title: "Probable Event Cause",
            dataIndex: "probableCause",
            ellipsis: true
        },
        {
            title: "Action Taken [dated]",
            dataIndex: "actionTaken",
            ellipsis: true
        },
        {
            title: "Outcome",
            dataIndex: "eventOutcome",
            ellipsis: true
        },
        {
            title: "Further Action Planned",
            dataIndex: "furtherActionPlanned",
            ellipsis: true
        },
        {
            title: "Additional Orders (Medical Director)",
            dataIndex: "addedOrders",
            ellipsis: true
        },
        {
            title: "Action",
            dataIndex: "action",
            render: (text, record) => {
                return (
                    <div
                        className="clickable"
                        onClick={() => {
                            handleModalVisibility("viewIncident", record);
                        }}
                    >
                        <img src={iconEye} />
                    </div>
                );
            }
        }
    ];
    const [columns, setColumns] = useState([]);

    const [queryOption, setQueryOption] = useState(null);
    const { data, error, mutate, isValidating } = useSWR(queryOption, fetcher, {
        dedupingInterval: 60000,
        focusThrottleInterval: 60000
        // revalidateIfStale: false,
        // revalidateOnFocus: false,
        // revalidateOnReconnect: false
    });

    useEffect(() => {
        if (data && queryOption && isValidating == false) {
            // console.log(data, queryOption);
            const result = data;

            // console.log("result", result);
            const _data = result?.data?.result;
            const array = result?.data?.result?.result;
            // console.log("data", _data);

            if (array) {
                setTable({
                    ...table,
                    loading: false,
                    data: array ?? [],
                    pagination: {
                        ...table.pagination,
                        current:
                            queryOption?.variables?.pagination?.offset /
                            queryOption?.variables?.pagination?.limit,
                        offset: queryOption?.variables?.pagination?.offset,
                        total:
                            queryOption?.variables?.pagination?.offset >=
                            _data.count
                                ? _data.count + 1
                                : _data.count // keeps the last pagination if it is the last record
                    }
                });
                setQueryOption(null);
            }
        }
    }, [data, isValidating]);

    const handleModalVisibility = (modal, record) => {
        // console.log("modal", modal);
        switch (modal) {
            case "viewIncident":
                setIncident(record ? record : {});
                setVisibilityViewIncident(!visibilityViewIncident);
                break;
            default:
        }
    };

    useEffect(() => {
        getIncidentRecords(filter);
        form.setFieldsValue({
            ...filter
        });
    }, []);

    useEffect(() => {
        if (
            table.data != -1 &&
            appState?.staffList?.length > 0 &&
            appState?.clinicList?.length > 0
        ) {
            let _datas = [];

            if (filter.searchType == "patient") {
                setColumns(patientColumns);
                for (let d of table.data) {
                    let _data = {
                        id: d.patientID,
                        name: d.patientName,
                        nric: d.patientNric,
                        latestIncident: [
                            {
                                // name: d.patientName,
                                // nric: d.patientNric,
                                id: d.id,
                                timestamp:
                                    moment
                                        .utc(d.createdOn)
                                        .local()
                                        .format("DD MMM YYYY, HH:mm") ?? "",
                                reportingCentre:
                                    appState.clinicList?.find(
                                        (s) => s.id == d.clinicID
                                    )?.name ?? "",
                                no: d.id,
                                location: d.location,
                                datetime:
                                    moment
                                        .utc(d.datetimeOccurred)
                                        .local()
                                        .format("DD MMM YYYY, HH:mm") ?? "",
                                reportedBy:
                                    appState.staffList?.find(
                                        (s) => s.accountID == d.reportedBy
                                    )?.name ?? d.reportedBy,
                                centrePatientEvent:
                                    d.eventCategory == "PatientEvent"
                                        ? "Patient Event"
                                        : "Centre Event",
                                eventType: d.eventType,
                                eventOutcome: d.eventOutcome,
                                description: d.description,
                                probableCause: d.cause,
                                actionTaken: d.action,
                                furtherActionPlanned: d.furtherAction,
                                addedOrders: d.order
                            }
                        ]
                    };
                    _datas.push(_data);
                }
            } else if (filter.searchType == "centre") {
                setColumns(centreColumns);

                let clinicIDs = [
                    ...new Set([
                        ...table.data.map((s) => s.clinicID),
                        ...filter.clinicIDs
                    ])
                ];
                for (let cID of clinicIDs) {
                    let filteredData = table.data.filter(
                        (s) => s.clinicID == cID
                    );

                    let _data = {
                        id: cID,
                        name:
                            appState.clinicList?.find((s) => s.id == cID)
                                ?.uid ?? "",
                        nric:
                            appState.clinicList?.find((s) => s.id == cID)
                                ?.name ?? "",
                        latestIncident: []
                    };

                    for (let d of filteredData) {
                        _data.latestIncident.push({
                            // name: d.patientName,
                            // nric: d.patientNric,
                            id: d.id,
                            timestamp:
                                moment
                                    .utc(d.createdOn)
                                    .local()
                                    .format("DD MMM YYYY, HH:mm") ?? "",
                            reportingCentre:
                                appState.clinicList?.find(
                                    (s) => s.id == d.clinicID
                                )?.name ?? "",
                            no: d.id,
                            location: d.location,
                            datetime:
                                moment
                                    .utc(d.datetimeOccurred)
                                    .local()
                                    .format("DD MMM YYYY, HH:mm") ?? "",
                            reportedBy:
                                appState.staffList?.find(
                                    (s) => s.accountID == d.reportedBy
                                )?.name ?? d.reportedBy,
                            centrePatientEvent:
                                d.eventCategory == "PatientEvent"
                                    ? "Patient Event"
                                    : "Centre Event",
                            eventType: d.eventType,
                            eventOutcome: d.eventOutcome,
                            description: d.description,
                            probableCause: d.cause,
                            actionTaken: d.action,
                            furtherActionPlanned: d.furtherAction,
                            addedOrders: d.order
                        });
                    }

                    _datas.push(_data);
                }
            }
            // console.log('display', _datas);
            setDisplay(_datas);
            setAppliedFilter(filter);
        }
    }, [table.data, appState.clinicList, appState.staffList]);

    const getIncidentRecords = async (
        filter = filter,
        props = table.pagination
    ) => {
        setTable({
            ...table,
            loading: true
        });
        try {
            const variables = {
                pagination: {
                    limit: props.pageSize,
                    offset: props.offset,
                    // orderby: 'id desc',
                    orderby: "`datetimeOccurred` desc"
                },
                filter: {
                    patientID: {
                        attributeExists: true
                    }
                }
            };

            let result;

            if (filter.searchType == "patient") {
                variables.groupByPatient = 1;

                if (filter?.search && filter?.search?.length > 0) {
                    variables.filter.or = [
                        {
                            patientName: {
                                contains: filter.search
                            }
                        },
                        {
                            patientNric: {
                                contains: filter.search
                            }
                        }
                    ];
                }

                // console.log('variables', filter, variables);
                let _queryOption = {
                    query: listIncident,
                    authMode: "AMAZON_COGNITO_USER_POOLS",
                    variables
                };
                // if (JSON.stringify(_queryOption) !== JSON.stringify(queryOption)) {
                setQueryOption(_queryOption);
                // }
                // result = await API.graphql({
                //     query: listIncident,
                //     authMode: 'AMAZON_COGNITO_USER_POOLS',
                //     variables,
                // })
            } else if (filter.searchType == "centre") {
                // variables.groupByClinic = 1;
                variables.filter = {
                    ...variables.filter,
                    patientID: {
                        attributeExists: false
                    }
                };
                variables.pagination = {
                    limit: 10
                };

                if (
                    filter?.clinicIDs &&
                    filter?.clinicIDs?.length >= 1 &&
                    filter.clinicIDs[0] !== 0
                ) {
                    variables.filter.or = [];

                    for (let cID of filter.clinicIDs) {
                        variables.filter.or.push({
                            clinicID: {
                                eq: cID
                            }
                        });
                    }
                }

                // console.log('variables', filter, variables);
                let _queryOption = {
                    query: listIncidentCentre,
                    authMode: "AMAZON_COGNITO_USER_POOLS",
                    variables
                };
                // if (JSON.stringify(_queryOption) !== JSON.stringify(queryOption)) {
                setQueryOption(_queryOption);
                // }
                // result = await API.graphql({
                //     query: listIncidentCentre,
                //     authMode: 'AMAZON_COGNITO_USER_POOLS',
                //     variables,
                // })
            }

            // // console.log("result", result);
            // const data = result.data.result
            // const array = result.data.result?.result
            // // console.log("data", data);

            // if (array.length >= 0) {
            //     setTable({
            //         ...table,
            //         loading: false,
            //         data: array,
            //         pagination: {
            //             ...table.pagination,
            //             current: props.current,
            //             offset: props.offset,
            //             total: props.offset >= data.count ? data.count + 1 : data.count // keeps the last pagination if it is the last record
            //         }
            //     })
            // }
        } catch (error) {
            console.log("error:", error);
            notification.error({
                message: "Unable to retrieve records"
            });
            setTable({
                ...table,
                loading: false
            });
        }
    };

    const onFinish = (values) => {
        // console.log("submit form");
        // console.log(form.getFieldsValue());
        setFilter(form.getFieldsValue());

        getIncidentRecords(values);
    };

    const handleSelectOptionChanged = (value) => {
        // console.log('handleSelectOptionChanged', value);

        setFilter({
            ...filter,
            searchType: value
        });
    };

    const renderIncidentReportCards = (array) => {
        const items = array.map((item, index) => {
            // console.log("item", item);
            return (
                <div className="incidentreportsummary-card">
                    <div className="row1">
                        <div className="cardtitle">
                            <div className="title">{item.name}</div>
                            <div className="subtitle">
                                {appliedFilter.searchType == "centre"
                                    ? item.nric
                                    : item.nric
                                          ?.substring(0, item.nric.length - 4)
                                          .replace(/\d|\w/g, "*") +
                                      item.nric?.substring(
                                          item.nric.length - 4
                                      )}
                            </div>
                        </div>
                        {appliedFilter.searchType === "patient" ? (
                            <div className="nav">
                                <div
                                    className="icon"
                                    onClick={() => {
                                        history.push(
                                            path("patientInformation", [
                                                item.id
                                            ])
                                        );
                                    }}
                                >
                                    <img src={IconPatientInfo} />
                                </div>
                                <div
                                    className="icon"
                                    onClick={() => {
                                        history.push(
                                            path("patientRecord", [item.id])
                                        );
                                    }}
                                >
                                    <img src={IconNurse} />
                                </div>
                                <div
                                    className="icon"
                                    onClick={() => {
                                        history.push(
                                            path("patientIncidentReport", [
                                                item.id
                                            ])
                                        );
                                    }}
                                >
                                    <img src={IconIncidentReport} />
                                </div>
                            </div>
                        ) : (
                            <div className="nav">
                                <div
                                    className="icon"
                                    onClick={() => {
                                        history.push(
                                            path("incidentReportCentreCreate", [
                                                item.id
                                            ])
                                        );
                                    }}
                                >
                                    <img src={IconIncidentReport} />
                                </div>
                            </div>
                        )}
                    </div>
                    <Divider />
                    <div className="row2">
                        <Table
                            columns={columns}
                            scroll={{ x: 3000, y: 500 }}
                            dataSource={item.latestIncident}
                            pagination={false}
                            rowKey={(record) => record.id}
                            rowClassName={(record, index) => {
                                // console.log("index", index);
                                return index % 2 === 0 ? "highlight" : "";
                            }}
                        />
                    </div>
                </div>
            );
        });
        return items;
    };

    const renderIncidentReport = (array) => {
        return array.length === 0 ? (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ) : (
            renderIncidentReportCards(array)
        );
    };

    const renderTreeSelectOptions = (array) => {
        if (typeof array == "object" && array.length !== 0) {
            const listOptions = array.map((item, index) => {
                return (
                    <TreeNode title={item.name} value={item.id} key={item.id} />
                );
            });

            return listOptions;
        } else {
            return <div />;
        }
    };

    return (
        <div className="content-content">
            <div className="incidentreport-header">
                <div className="row1 title">Incident Report Summary</div>
                <Form
                    form={form}
                    layout="horizontal"
                    onFinish={onFinish}
                    requiredMark={false}
                >
                    <div className="container-row">
                        <div className="row2">
                            <div className="row">
                                <Form.Item
                                    key="searchType"
                                    name="searchType"
                                    rules={[
                                        {
                                            required: true
                                        }
                                    ]}
                                    style={{ width: 180 }}
                                >
                                    <Select
                                        style={{ width: 180 }}
                                        onChange={handleSelectOptionChanged}
                                    >
                                        <Option key="patient" value="patient">
                                            Search Patient
                                        </Option>
                                        <Option key="centre" value="centre">
                                            Search Centre
                                        </Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    className="display-none"
                                    name="searchTypeDaily"
                                >
                                    <Input autoComplete="off" />
                                </Form.Item>
                                {filter.searchType == "patient" ? (
                                    <Form.Item className="search" name="search">
                                        <Input
                                            autoComplete="off"
                                            prefix={<SearchOutlined />}
                                            placeholder="Search patient / NRIC"
                                            value={filter.search}
                                            allowClear
                                        />
                                    </Form.Item>
                                ) : (
                                    // <Form.Item
                                    //     className="search"
                                    //     name="clinicID"
                                    // >
                                    //     <Select></Select>
                                    // </Form.Item>
                                    // renderInputItems([
                                    //     {
                                    //         className: "search",
                                    //         name: "clinicID",
                                    //         index: "clinicID",
                                    //         type: "treeSelect",
                                    //         placeholder: "Select Clinic",
                                    //         required: true,
                                    //         optionList: appState.clinicList,
                                    //     }
                                    // ])

                                    <Form.Item
                                        name="clinicIDs"
                                        key="clinicIDs"
                                        rules={[
                                            {
                                                required: true
                                            }
                                        ]}
                                        style={{ minWidth: 430, width: "50%" }}
                                    >
                                        <TreeSelect
                                            showSearch
                                            allowClear
                                            multiple
                                            treeDefaultExpandAll
                                            treeCheckable
                                            placeholder="Select clinics"
                                            showCheckedStrategy={SHOW_PARENT}
                                            filterTreeNode={(input, treenode) =>
                                                treenode.title
                                                    .toLowerCase()
                                                    .indexOf(
                                                        input.toLowerCase()
                                                    ) >= 0
                                            }
                                            // defaultValue={0}
                                            // onChange={handleTreeSelectChange}
                                        >
                                            {/* <TreeNode value={0} title="All"> */}
                                            {renderTreeSelectOptions(
                                                appState.clinicList !== -1
                                                    ? appState.clinicList
                                                    : []
                                            )}
                                            {/* </TreeNode> */}
                                        </TreeSelect>
                                    </Form.Item>
                                )}
                                <Button type="primary" htmlType="submit">
                                    Search
                                </Button>
                            </div>
                        </div>
                    </div>
                </Form>
            </div>
            <div className="incidentreport-content">
                <Spin spinning={table.loading}>
                    {renderIncidentReport(
                        display
                        // [
                        //     {
                        //         id: 1,
                        //         name: "Diego McLaughlin",
                        //         nric: "*****123A",
                        //         latestIncident: [
                        //             {
                        //                 timestamp: "10 Apr 2021, 06:00",
                        //                 reportingCentre: "Centre A",
                        //                 no: 1,
                        //                 location: "Location A",
                        //                 datetime: "10 Apr 2021, 08:00",
                        //                 reportedBy: "Nurse ABC",
                        //                 centrePatientEvent: "Patient Event",
                        //                 eventType: "Event Type",
                        //                 nameCode: "Name Code",
                        //                 eventOutcome: "Event Outcome",
                        //                 description: "Description",
                        //                 probableCause: "Probable Cause",
                        //                 actionTaken: "Action Taken",
                        //                 furtherActionPlanned: "Further Action Planned",
                        //                 addedOrders: "Added Orders",
                        //             },
                        //         ]
                        //     },
                        //     {
                        //         id: 2,
                        //         name: "Diego NotLaughlin",
                        //         nric: "*****123B",
                        //         latestIncident: [
                        //             {
                        //                 timestamp: "10 Apr 2021, 06:00",
                        //                 reportingCentre: "Centre A",
                        //                 no: 1,
                        //                 location: "Location A",
                        //                 datetime: "10 Apr 2021, 08:00",
                        //                 reportedBy: "Nurse ABC",
                        //                 centrePatientEvent: "Patient Event",
                        //                 eventType: "Event Type",
                        //                 nameCode: "Name Code",
                        //                 eventOutcome: "Event Outcome",
                        //                 description: "Description",
                        //                 probableCause: "Probable Cause",
                        //                 actionTaken: "Action Taken",
                        //                 furtherActionPlanned: "Further Action Planned",
                        //                 addedOrders: "Added Orders",
                        //             },
                        //         ]
                        //     },
                        // ]
                    )}
                </Spin>
            </div>
            <Modal
                title={
                    <div className="modal-title">Incident Report - View</div>
                }
                visible={visibilityViewIncident}
                onCancel={() => handleModalVisibility("viewIncident")}
                footer={[
                    <Button
                        key="back"
                        onClick={() => handleModalVisibility("viewIncident")}
                    >
                        Cancel
                    </Button>
                ]}
                className="modal-patient-incidentreport"
                // width={800}
                width={1000}
            >
                <div className="modal-content">
                    <div className="viewincident">
                        <div className="row1">
                            <div className="label">Submitted:</div>
                            <div className="value">{incident.timestamp}</div>
                        </div>
                        <div className="row1">
                            <div className="label">Centre/Group Reporting:</div>
                            <div className="value">
                                {incident.reportingCentre}
                            </div>
                        </div>
                        <div className="row1">
                            <div className="label">No:</div>
                            <div className="value">{incident.id}</div>
                        </div>
                        <div className="row1">
                            <div className="label">Location:</div>
                            <div className="value">{incident.location}</div>
                        </div>
                        <div className="row1">
                            <div className="label">Date / Time:</div>
                            <div className="value">{incident.datetime}</div>
                        </div>
                        <div className="row1">
                            <div className="label">Type:</div>
                            <div className="value">{incident.eventType}</div>
                        </div>
                        <div className="row1">
                            <div className="label">Reported By:</div>
                            <div className="value">{incident.reportedBy}</div>
                        </div>
                        <div className="row2">
                            <div className="label">Description:</div>
                            <div className="value">{incident.description}</div>
                        </div>
                        <div className="row2">
                            <div className="label">Probably Cause:</div>
                            <div className="value">
                                {incident.probableCause}
                            </div>
                        </div>
                        <div className="row2">
                            <div className="label">Action Taken [dated]:</div>
                            <div className="value">{incident.actionTaken}</div>
                        </div>
                        <div className="row2">
                            <div className="label">Outcome:</div>
                            <div className="value">{incident.eventOutcome}</div>
                        </div>
                        <div className="row2">
                            <div className="label">Further Action Planned:</div>
                            <div className="value">
                                {incident.furtherActionPlanned}
                            </div>
                        </div>
                        <div className="row2">
                            <div className="label">
                                Additional Orders (Medical Director):
                            </div>
                            <div className="value">{incident.addedOrders}</div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default IncidentReport;
