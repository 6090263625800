// rename c, u, s, d, l
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createAppointment = /* GraphQL */ `
  mutation CreateAppointment($create: CreateAppointmentInput!) {
    result: createAppointment(create: $create) {
      id
      patientID
      clinicID
      seatID
      date
      shift
      quickbookInvoiceID
      time
      dialysisID
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const updateAppointment = /* GraphQL */ `
  mutation UpdateAppointment($update: UpdateAppointmentInput!) {
    result: updateAppointment(update: $update) {
      id
      patientID
      clinicID
      seatID
      date
      shift
      quickbookInvoiceID
      time
      dialysisID
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const createCalculatedLabResult = /* GraphQL */ `
  mutation CreateCalculatedLabResult($create: CreateCalculatedLabResultInput!) {
    result: createCalculatedLabResult(create: $create) {
      id
      patientID
      clinicID
      timestamp
      dialysisID
      labReportID
      latest
      URRD
      KTVonline
      KTVe
      ureaPre
      ureaPost
      dryWeight
      UF
      MSA
      dialyser
      URR
      QB
      QD
      minQA
      maxQA
      dialysisArriveTime
      dialysisStartTime
      dialysisEndTime
      dialysisDischargeTime
      dialysisIntervalLastTime
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const updateCalculatedLabResult = /* GraphQL */ `
  mutation UpdateCalculatedLabResult($update: UpdateCalculatedLabResultInput!) {
    result: updateCalculatedLabResult(update: $update) {
      id
      patientID
      clinicID
      timestamp
      dialysisID
      labReportID
      latest
      URRD
      KTVonline
      KTVe
      ureaPre
      ureaPost
      dryWeight
      UF
      MSA
      dialyser
      URR
      QB
      QD
      minQA
      maxQA
      dialysisArriveTime
      dialysisStartTime
      dialysisEndTime
      dialysisDischargeTime
      dialysisIntervalLastTime
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const updateCalculatedLabResultSubscription = /* GraphQL */ `
  mutation UpdateCalculatedLabResultSubscription($id: Int!) {
    result: updateCalculatedLabResultSubscription(id: $id) {
      id
      patientID
      clinicID
      timestamp
      dialysisID
      labReportID
      latest
      URRD
      KTVonline
      KTVe
      ureaPre
      ureaPost
      dryWeight
      UF
      MSA
      dialyser
      URR
      QB
      QD
      minQA
      maxQA
      dialysisArriveTime
      dialysisStartTime
      dialysisEndTime
      dialysisDischargeTime
      dialysisIntervalLastTime
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const createClinic = /* GraphQL */ `
  mutation CreateClinic($create: CreateClinicInput!) {
    result: createClinic(create: $create) {
      id
      name
      uid
      phoneNo
      email
      address
      status
      noOfSeat
      qbClientID
      qbRealmID
      qbItemID
      qbTaxCodeID
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const updateClinic = /* GraphQL */ `
  mutation UpdateClinic($update: UpdateClinicInput!) {
    result: updateClinic(update: $update) {
      id
      name
      uid
      phoneNo
      email
      address
      status
      noOfSeat
      qbClientID
      qbRealmID
      qbItemID
      qbTaxCodeID
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const createClinicStatus = /* GraphQL */ `
  mutation CreateClinicStatus($create: CreateClinicStatusInput!) {
    result: createClinicStatus(create: $create) {
      id
      name
      expiryDate
      nextAudit
      status
      remarks
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const updateClinicStatus = /* GraphQL */ `
  mutation UpdateClinicStatus($update: UpdateClinicStatusInput!) {
    result: updateClinicStatus(update: $update) {
      id
      name
      expiryDate
      nextAudit
      status
      remarks
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const createDialysis = /* GraphQL */ `
  mutation CreateDialysis($create: CreateDialysisInput!) {
    result: createDialysis(create: $create) {
      id
      patientID
      sessionMonth
      arriveTime
      startTime
      endTime
      dischargeTime
      accessDIALYSISREF
      startedBy
      endedBy
      clinicID
      preWeight
      lastWeight
      postWeight
      dryWeight
      height
      preTemp
      preEdema
      preSBP
      preDBP
      preHRT
      preRenalin
      ufTargetStart
      postTemp
      postEdema
      postSBP
      postDBP
      postHRT
      postRenalin
      KTVonline
      machineID
      dialyser
      MSA
      membraneUse
      dialysisOrderID
      notes
      lastUpdatedBy
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const createDialysisReturnNum = /* GraphQL */ `
  mutation CreateDialysisReturnNum($create: CreateDialysisInput!) {
    result: createDialysisReturnNum(create: $create) {
      id
      patientID
      sessionMonth
      arriveTime
      startTime
      endTime
      dischargeTime
      accessDIALYSISREF
      startedBy
      endedBy
      clinicID
      preWeight
      lastWeight
      postWeight
      dryWeight
      height
      preTemp
      preEdema
      preSBP
      preDBP
      preHRT
      preRenalin
      ufTargetStart
      postTemp
      postEdema
      postSBP
      postDBP
      postHRT
      postRenalin
      KTVonline
      machineID
      dialyser
      MSA
      membraneUse
      dialysisOrderID
      notes
      lastUpdatedBy
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const updateDialysis = /* GraphQL */ `
  mutation UpdateDialysis($update: UpdateDialysisInput!) {
    result: updateDialysis(update: $update) {
      id
      patientID
      sessionMonth
      arriveTime
      startTime
      endTime
      dischargeTime
      accessDIALYSISREF
      startedBy
      endedBy
      clinicID
      preWeight
      lastWeight
      postWeight
      dryWeight
      height
      preTemp
      preEdema
      preSBP
      preDBP
      preHRT
      preRenalin
      ufTargetStart
      postTemp
      postEdema
      postSBP
      postDBP
      postHRT
      postRenalin
      KTVonline
      machineID
      dialyser
      MSA
      membraneUse
      dialysisOrderID
      notes
      lastUpdatedBy
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const createDialysisInterval = /* GraphQL */ `
  mutation CreateDialysisInterval($create: CreateDialysisIntervalInput!) {
    result: createDialysisInterval(create: $create) {
      id
      dialysisID
      clinicID
      timestamp
      staffAccountID
      accessNum
      AP
      VP
      SBP
      DBP
      HR
      CDT
      KTV
      cUF
      QB
      DFLOW
      UFR
      temp
      tmp
      hepRate
      note
      symptoms
      lastUpdatedBy
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const createDialysisIntervalReturnNum = /* GraphQL */ `
  mutation CreateDialysisIntervalReturnNum(
    $create: CreateDialysisIntervalInput!
  ) {
    result: createDialysisIntervalReturnNum(create: $create) {
      id
      dialysisID
      clinicID
      timestamp
      staffAccountID
      accessNum
      AP
      VP
      SBP
      DBP
      HR
      CDT
      KTV
      cUF
      QB
      DFLOW
      UFR
      temp
      tmp
      hepRate
      note
      symptoms
      lastUpdatedBy
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const updateDialysisInterval = /* GraphQL */ `
  mutation UpdateDialysisInterval($update: UpdateDialysisIntervalInput!) {
    result: updateDialysisInterval(update: $update) {
      id
      dialysisID
      clinicID
      timestamp
      staffAccountID
      accessNum
      AP
      VP
      SBP
      DBP
      HR
      CDT
      KTV
      cUF
      QB
      DFLOW
      UFR
      temp
      tmp
      hepRate
      note
      symptoms
      lastUpdatedBy
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const updateDialysisIntervalByNum = /* GraphQL */ `
  mutation UpdateDialysisIntervalByNum($update: UpdateDialysisIntervalInput!) {
    result: updateDialysisIntervalByNum(update: $update) {
      id
      dialysisID
      clinicID
      timestamp
      staffAccountID
      accessNum
      AP
      VP
      SBP
      DBP
      HR
      CDT
      KTV
      cUF
      QB
      DFLOW
      UFR
      temp
      tmp
      hepRate
      note
      symptoms
      lastUpdatedBy
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const createDialysisOrder = /* GraphQL */ `
  mutation CreateDialysisOrder($create: CreateDialysisOrderInput!) {
    result: createDialysisOrder(create: $create) {
      id
      uid
      staffAccountID
      patientID
      accessID
      MSA
      dOrder
      freqMth
      duration
      hepLoad
      hepCont
      antio
      dialysate
      accessPrim
      aNeedle
      vNeedle
      bicarbonate
      temp
      QD
      QB
      dryWeight
      notes
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const updateDialysisOrder = /* GraphQL */ `
  mutation UpdateDialysisOrder($update: UpdateDialysisOrderInput!) {
    result: updateDialysisOrder(update: $update) {
      id
      uid
      staffAccountID
      patientID
      accessID
      MSA
      dOrder
      freqMth
      duration
      hepLoad
      hepCont
      antio
      dialysate
      accessPrim
      aNeedle
      vNeedle
      bicarbonate
      temp
      QD
      QB
      dryWeight
      notes
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const createDrug = /* GraphQL */ `
  mutation CreateDrug($create: CreateDrugInput!) {
    result: createDrug(create: $create) {
      id
      name
      purpose
      manufacturer
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const updateDrug = /* GraphQL */ `
  mutation UpdateDrug($update: UpdateDrugInput!) {
    result: updateDrug(update: $update) {
      id
      name
      purpose
      manufacturer
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const createAllergy = /* GraphQL */ `
  mutation CreateAllergy($create: CreateAllergyInput!) {
    result: createAllergy(create: $create) {
      id
      patientID
      name
      knownDate
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const updateAllergy = /* GraphQL */ `
  mutation UpdateAllergy($update: UpdateAllergyInput!) {
    result: updateAllergy(update: $update) {
      id
      patientID
      name
      knownDate
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const createHospitalReason = /* GraphQL */ `
  mutation CreateHospitalReason($create: CreateHospitalReasonInput!) {
    result: createHospitalReason(create: $create) {
      id
      hospCode
      hospDetail
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const updateHospitalReason = /* GraphQL */ `
  mutation UpdateHospitalReason($update: UpdateHospitalReasonInput!) {
    result: updateHospitalReason(update: $update) {
      id
      hospCode
      hospDetail
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const createHospitalSummary = /* GraphQL */ `
  mutation CreateHospitalSummary($create: CreateHospitalSummaryInput!) {
    result: createHospitalSummary(create: $create) {
      id
      accessIDHosp
      patientID
      clinicID
      staffAccountID
      staffName
      admitTimestamp
      type
      observation
      assessment
      plan
      notes
      leaveTimestamp
      noOfDays
      location
      reason
      hospitalMain
      hospitalReason
      diagnosis
      outcome
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const createHospitalSummaryReturnNum = /* GraphQL */ `
  mutation CreateHospitalSummaryReturnNum(
    $create: CreateHospitalSummaryInput!
  ) {
    result: createHospitalSummaryReturnNum(create: $create) {
      id
      accessIDHosp
      patientID
      clinicID
      staffAccountID
      staffName
      admitTimestamp
      type
      observation
      assessment
      plan
      notes
      leaveTimestamp
      noOfDays
      location
      reason
      hospitalMain
      hospitalReason
      diagnosis
      outcome
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const updateHospitalSummary = /* GraphQL */ `
  mutation UpdateHospitalSummary($update: UpdateHospitalSummaryInput!) {
    result: updateHospitalSummary(update: $update) {
      id
      accessIDHosp
      patientID
      clinicID
      staffAccountID
      staffName
      admitTimestamp
      type
      observation
      assessment
      plan
      notes
      leaveTimestamp
      noOfDays
      location
      reason
      hospitalMain
      hospitalReason
      diagnosis
      outcome
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const updateLabReport = /* GraphQL */ `
  mutation UpdateLabReport($update: UpdateLabReportInput!) {
    result: updateLabReport(update: $update) {
      id
      patientID
      clinicID
      timestamp
      source
      accessIDlab
      name
      glucose
      TW
      Plt
      Hb
      MCV
      K
      Na
      ureaPre
      ureaPost
      creatinine
      HCO3
      chlorine
      Ca
      Po4
      IPTH
      HepBsAg
      HepBsAb
      _two
      Fe
      TIBC
      ferritin
      protein
      Alb
      Ublood
      Urbc
      Uprt
      Uwbc
      TUP24
      TUP12
      Uvol
      CCT
      uric
      HCV
      HIV
      ggt
      images
      others
      othersDetails
      TC
      HDL
      LDL
      TG
      Ratio
      Timing
      Biltotal
      SAP
      SGPT_ALT
      SGOT_AST
      Hba1c
      labsNoted
      SBP
      DBP
      Mg
      Tac
      urineMA
      MACr
      uCreatinine
      ESR
      CRP
      CNI
      Myco
      Pred
      noted
      t4
      FT4
      TSH
      gis
      hte
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const createLabElectrolytes = /* GraphQL */ `
  mutation CreateLabElectrolytes($create: CreateLabElectrolytesInput!) {
    result: createLabElectrolytes(create: $create) {
      id
      patientID
      clinicID
      timestamp
      K
      K_timestamp
      HCO3
      HCO3_timestamp
      creatinine
      creatinine_timestamp
      ureaPre
      ureaPre_timestamp
      ureaPost
      ureaPost_timestamp
      URR
      URR_timestamp
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const updateLabElectrolytes = /* GraphQL */ `
  mutation UpdateLabElectrolytes($update: UpdateLabElectrolytesInput!) {
    result: updateLabElectrolytes(update: $update) {
      id
      patientID
      clinicID
      timestamp
      K
      K_timestamp
      HCO3
      HCO3_timestamp
      creatinine
      creatinine_timestamp
      ureaPre
      ureaPre_timestamp
      ureaPost
      ureaPost_timestamp
      URR
      URR_timestamp
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const createLabLFTData = /* GraphQL */ `
  mutation CreateLabLFTData($create: CreateLabLFTDataInput!) {
    result: createLabLFTData(create: $create) {
      id
      patientID
      clinicID
      timestamp
      protein
      protein_timestamp
      Alb
      Alb_timestamp
      Biltotal
      Biltotal_timestamp
      SAP
      SAP_timestamp
      SGPT_ALT
      SGPT_ALT_timestamp
      SGOT_AST
      SGOT_AST_timestamp
      ggt
      ggt_timestamp
      glucose
      glucose_timestamp
      Hba1c
      Hba1c_timestamp
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const updateLabLFTData = /* GraphQL */ `
  mutation UpdateLabLFTData($update: UpdateLabLFTDataInput!) {
    result: updateLabLFTData(update: $update) {
      id
      patientID
      clinicID
      timestamp
      protein
      protein_timestamp
      Alb
      Alb_timestamp
      Biltotal
      Biltotal_timestamp
      SAP
      SAP_timestamp
      SGPT_ALT
      SGPT_ALT_timestamp
      SGOT_AST
      SGOT_AST_timestamp
      ggt
      ggt_timestamp
      glucose
      glucose_timestamp
      Hba1c
      Hba1c_timestamp
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const createLabHbCaData = /* GraphQL */ `
  mutation CreateLabHbCaData($create: CreateLabHbCaDataInput!) {
    result: createLabHbCaData(create: $create) {
      id
      patientID
      clinicID
      timestamp
      Hb
      Hb_timestamp
      MCV
      MCV_timestamp
      TW
      TW_timestamp
      Plt
      Plt_timestamp
      Fe
      Fe_timestamp
      TIBC
      TIBC_timestamp
      LDL
      LDL_timestamp
      Po4
      Po4_timestamp
      Ca
      Ca_timestamp
      IPTH
      IPTH_timestamp
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const updateLabHbCaData = /* GraphQL */ `
  mutation UpdateLabHbCaData($update: UpdateLabHbCaDataInput!) {
    result: updateLabHbCaData(update: $update) {
      id
      patientID
      clinicID
      timestamp
      Hb
      Hb_timestamp
      MCV
      MCV_timestamp
      TW
      TW_timestamp
      Plt
      Plt_timestamp
      Fe
      Fe_timestamp
      TIBC
      TIBC_timestamp
      LDL
      LDL_timestamp
      Po4
      Po4_timestamp
      Ca
      Ca_timestamp
      IPTH
      IPTH_timestamp
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const createLabVirologyLIPDS = /* GraphQL */ `
  mutation CreateLabVirologyLIPDS($create: CreateLabVirologyLIPDSInput!) {
    result: createLabVirologyLIPDS(create: $create) {
      id
      patientID
      clinicID
      timestamp
      HepBsAg
      HepBsAg_timestamp
      HepBsAb
      HepBsAb_timestamp
      HCV
      HCV_timestamp
      HIV
      HIV_timestamp
      TC
      TC_timestamp
      HDL
      HDL_timestamp
      LDL
      LDL_timestamp
      TG
      TG_timestamp
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const updateLabVirologyLIPDS = /* GraphQL */ `
  mutation UpdateLabVirologyLIPDS($update: UpdateLabVirologyLIPDSInput!) {
    result: updateLabVirologyLIPDS(update: $update) {
      id
      patientID
      clinicID
      timestamp
      HepBsAg
      HepBsAg_timestamp
      HepBsAb
      HepBsAb_timestamp
      HCV
      HCV_timestamp
      HIV
      HIV_timestamp
      TC
      TC_timestamp
      HDL
      HDL_timestamp
      LDL
      LDL_timestamp
      TG
      TG_timestamp
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const createIncident = /* GraphQL */ `
  mutation CreateIncident($create: CreateIncidentInput!) {
    result: createIncident(create: $create) {
      id
      reportedBy
      clinicID
      patientID
      location
      datetimeOccurred
      eventCategory
      eventType
      eventOutcome
      description
      cause
      action
      furtherAction
      order
      responseBy
      responseTimestamp
      response
      patientName
      patientNric
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const updateIncident = /* GraphQL */ `
  mutation UpdateIncident($update: UpdateIncidentInput!) {
    result: updateIncident(update: $update) {
      id
      reportedBy
      clinicID
      patientID
      location
      datetimeOccurred
      eventCategory
      eventType
      eventOutcome
      description
      cause
      action
      furtherAction
      order
      responseBy
      responseTimestamp
      response
      patientName
      patientNric
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const createMachine = /* GraphQL */ `
  mutation CreateMachine($create: CreateMachineInput!) {
    result: createMachine(create: $create) {
      id
      serialNo
      clinicID
      name
      manufacturer
      status
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const createMachineStatus = /* GraphQL */ `
  mutation CreateMachineStatus($create: CreateMachineStatusInput!) {
    result: createMachineStatus(create: $create) {
      id
      machineID
      prevMachineStatusID
      name
      expiryDate
      nextAudit
      status
      remarks
      approvedBy
      approvedTimestamp
      mandatary
      machineSerialNo
      machineName
      machineClinicID
      machineClinicName
      key
      bucket
      region
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const createMedicalMilestone = /* GraphQL */ `
  mutation CreateMedicalMilestone($create: CreateMedicalMilestoneInput!) {
    result: createMedicalMilestone(create: $create) {
      id
      patientID
      timestamp
      milestone
      note
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const updateMedicalMilestone = /* GraphQL */ `
  mutation UpdateMedicalMilestone($update: UpdateMedicalMilestoneInput!) {
    result: updateMedicalMilestone(update: $update) {
      id
      patientID
      timestamp
      milestone
      note
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const createMedication = /* GraphQL */ `
  mutation CreateMedication($create: CreateMedicationInput!) {
    result: createMedication(create: $create) {
      id
      accessID
      patientID
      medication
      status
      dose
      route
      freq
      start
      end
      note
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const createMedicationReturnNum = /* GraphQL */ `
  mutation CreateMedicationReturnNum($create: CreateMedicationInput!) {
    result: createMedicationReturnNum(create: $create) {
      id
      accessID
      patientID
      medication
      status
      dose
      route
      freq
      start
      end
      note
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const updateMedication = /* GraphQL */ `
  mutation UpdateMedication($update: UpdateMedicationInput!) {
    result: updateMedication(update: $update) {
      id
      accessID
      patientID
      medication
      status
      dose
      route
      freq
      start
      end
      note
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const createMedicationAdministered = /* GraphQL */ `
  mutation CreateMedicationAdministered(
    $create: CreateMedicationAdministeredInput!
  ) {
    result: createMedicationAdministered(create: $create) {
      id
      accessID
      patientID
      dialysisID
      dialysisIntervalID
      medication
      dose
      route
      freq
      givenBy
      givenByStaffName
      witnessedBy
      witnessedByStaffName
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const createMedicationAdministeredByRef = /* GraphQL */ `
  mutation CreateMedicationAdministeredByRef(
    $create: CreateMedicationAdministeredByRefInput!
    $accessDIALYSISREF: String
  ) {
    result: createMedicationAdministeredByRef(
      create: $create
      accessDIALYSISREF: $accessDIALYSISREF
    ) {
      id
      accessID
      patientID
      dialysisID
      dialysisIntervalID
      medication
      dose
      route
      freq
      givenBy
      givenByStaffName
      witnessedBy
      witnessedByStaffName
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const updateMedicationAdministered = /* GraphQL */ `
  mutation UpdateMedicationAdministered(
    $update: UpdateMedicationAdministeredInput!
  ) {
    result: updateMedicationAdministered(update: $update) {
      id
      accessID
      patientID
      dialysisID
      dialysisIntervalID
      medication
      dose
      route
      freq
      givenBy
      givenByStaffName
      witnessedBy
      witnessedByStaffName
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const updatePatient = /* GraphQL */ `
  mutation UpdatePatient($update: UpdatePatientInput!) {
    result: updatePatient(update: $update) {
      id
      accessIDPt
      namecode
      registeredDate
      quickbookCustomerID
      key
      bucket
      region
      firstDialysisHospital
      firstDialysisHospitalDate
      referringSource
      referrer
      referringSourceStart
      referringSourceEnd
      existingClinicID
      status
      travelType
      schedulePattern
      scheduleSession
      name
      idType
      nric
      gender
      dob
      race
      religion
      maritalStatus
      accommodation
      address
      phoneNo
      homeNo
      email
      nokName
      nokPhoneNo
      nokAddress
      nokRelation
      height
      weight
      bloodGroup
      mobilityStatus
      diabeticStatus
      smoker
      alcohol
      amputee
      stage
      wheelchairWeight
      medicalAlert
      socialWorker
      vwoSupport
      firstConsultDate
      DESRD
      DExit
      RExit
      hepB
      FHepB
      FHCV
      FHIV
      FAccess
      comanaging
      procedure
      medication
      vaccination
      history
      causeOfKidneyFailure
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const createPatientDocument = /* GraphQL */ `
  mutation CreatePatientDocument($create: CreatePatientDocumentInput!) {
    result: createPatientDocument(create: $create) {
      id
      staffAccountID
      staffName
      patientID
      title
      status
      key
      bucket
      region
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const updatePatientDocument = /* GraphQL */ `
  mutation UpdatePatientDocument($update: UpdatePatientDocumentInput!) {
    result: updatePatientDocument(update: $update) {
      id
      staffAccountID
      staffName
      patientID
      title
      status
      key
      bucket
      region
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const createSeat = /* GraphQL */ `
  mutation CreateSeat($create: CreateSeatInput!) {
    result: createSeat(create: $create) {
      id
      clinicID
      machineID
      status
      name
      machineName
      machineSerialNo
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const createStaffStatus = /* GraphQL */ `
  mutation CreateStaffStatus($create: CreateStaffStatusInput!) {
    result: createStaffStatus(create: $create) {
      id
      accountID
      name
      status
      issued
      expiry
      approvedBy
      approvedTimestamp
      staffName
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const updateStaffStatus = /* GraphQL */ `
  mutation UpdateStaffStatus($update: UpdateStaffStatusInput!) {
    result: updateStaffStatus(update: $update) {
      id
      accountID
      name
      status
      issued
      expiry
      approvedBy
      approvedTimestamp
      staffName
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const createSymptom = /* GraphQL */ `
  mutation CreateSymptom($create: CreateSymptomInput!) {
    result: createSymptom(create: $create) {
      id
      name
      class
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const updateSymptom = /* GraphQL */ `
  mutation UpdateSymptom($update: UpdateSymptomInput!) {
    result: updateSymptom(update: $update) {
      id
      name
      class
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const createDialysisByNum = /* GraphQL */ `
  mutation CreateDialysisByNum($create: CreateDialysisInput!) {
    result: createDialysisByNum(create: $create) {
      id
      patientID
      sessionMonth
      arriveTime
      startTime
      endTime
      dischargeTime
      accessDIALYSISREF
      startedBy
      endedBy
      clinicID
      preWeight
      lastWeight
      postWeight
      dryWeight
      height
      preTemp
      preEdema
      preSBP
      preDBP
      preHRT
      preRenalin
      ufTargetStart
      postTemp
      postEdema
      postSBP
      postDBP
      postHRT
      postRenalin
      KTVonline
      machineID
      dialyser
      MSA
      membraneUse
      dialysisOrderID
      notes
      lastUpdatedBy
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const createDialysisIntervalByNum = /* GraphQL */ `
  mutation CreateDialysisIntervalByNum($create: CreateDialysisIntervalInput!) {
    result: createDialysisIntervalByNum(create: $create) {
      id
      dialysisID
      clinicID
      timestamp
      staffAccountID
      accessNum
      AP
      VP
      SBP
      DBP
      HR
      CDT
      KTV
      cUF
      QB
      DFLOW
      UFR
      temp
      tmp
      hepRate
      note
      symptoms
      lastUpdatedBy
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const createDialysisOrderByNum = /* GraphQL */ `
  mutation CreateDialysisOrderByNum($create: CreateDialysisOrderInput!) {
    result: createDialysisOrderByNum(create: $create) {
      id
      uid
      staffAccountID
      patientID
      accessID
      MSA
      dOrder
      freqMth
      duration
      hepLoad
      hepCont
      antio
      dialysate
      accessPrim
      aNeedle
      vNeedle
      bicarbonate
      temp
      QD
      QB
      dryWeight
      notes
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const createAllergyByNum = /* GraphQL */ `
  mutation CreateAllergyByNum($create: CreateAllergyInput!, $accessIDPt: Int) {
    result: createAllergyByNum(create: $create, accessIDPt: $accessIDPt) {
      id
      patientID
      name
      knownDate
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const createHospitalSummaryByNum = /* GraphQL */ `
  mutation CreateHospitalSummaryByNum(
    $create: CreateHospitalSummaryInput!
    $accessIDPt: Int
  ) {
    result: createHospitalSummaryByNum(create: $create, accessIDPt: $accessIDPt) {
      id
      accessIDHosp
      patientID
      clinicID
      staffAccountID
      staffName
      admitTimestamp
      type
      observation
      assessment
      plan
      notes
      leaveTimestamp
      noOfDays
      location
      reason
      hospitalMain
      hospitalReason
      diagnosis
      outcome
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const createLabReport = /* GraphQL */ `
  mutation CreateLabReport($create: CreateLabReportInput!) {
    result: createLabReport(create: $create) {
      id
      patientID
      clinicID
      timestamp
      source
      accessIDlab
      name
      glucose
      TW
      Plt
      Hb
      MCV
      K
      Na
      ureaPre
      ureaPost
      creatinine
      HCO3
      chlorine
      Ca
      Po4
      IPTH
      HepBsAg
      HepBsAb
      _two
      Fe
      TIBC
      ferritin
      protein
      Alb
      Ublood
      Urbc
      Uprt
      Uwbc
      TUP24
      TUP12
      Uvol
      CCT
      uric
      HCV
      HIV
      ggt
      images
      others
      othersDetails
      TC
      HDL
      LDL
      TG
      Ratio
      Timing
      Biltotal
      SAP
      SGPT_ALT
      SGOT_AST
      Hba1c
      labsNoted
      SBP
      DBP
      Mg
      Tac
      urineMA
      MACr
      uCreatinine
      ESR
      CRP
      CNI
      Myco
      Pred
      noted
      t4
      FT4
      TSH
      gis
      hte
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const createMedicationByNum = /* GraphQL */ `
  mutation CreateMedicationByNum(
    $create: CreateMedicationInput!
    $accessIDPt: Int
  ) {
    result: createMedicationByNum(create: $create, accessIDPt: $accessIDPt) {
      id
      accessID
      patientID
      medication
      status
      dose
      route
      freq
      start
      end
      note
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const createPatient = /* GraphQL */ `
  mutation CreatePatient($create: CreatePatientInput!) {
    result: createPatient(create: $create) {
      id
      accessIDPt
      namecode
      registeredDate
      quickbookCustomerID
      key
      bucket
      region
      firstDialysisHospital
      firstDialysisHospitalDate
      referringSource
      referrer
      referringSourceStart
      referringSourceEnd
      existingClinicID
      status
      travelType
      schedulePattern
      scheduleSession
      name
      idType
      nric
      gender
      dob
      race
      religion
      maritalStatus
      accommodation
      address
      phoneNo
      homeNo
      email
      nokName
      nokPhoneNo
      nokAddress
      nokRelation
      height
      weight
      bloodGroup
      mobilityStatus
      diabeticStatus
      smoker
      alcohol
      amputee
      stage
      wheelchairWeight
      medicalAlert
      socialWorker
      vwoSupport
      firstConsultDate
      DESRD
      DExit
      RExit
      hepB
      FHepB
      FHCV
      FHIV
      FAccess
      comanaging
      procedure
      medication
      vaccination
      history
      causeOfKidneyFailure
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const updateMachine = /* GraphQL */ `
  mutation UpdateMachine($update: UpdateMachineInput!) {
    result: updateMachine(update: $update) {
      id
      serialNo
      clinicID
      name
      manufacturer
      status
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const updateMachineStatus = /* GraphQL */ `
  mutation UpdateMachineStatus(
    $update: UpdateMachineStatusInput!
    $state: String
  ) {
    result: updateMachineStatus(update: $update, state: $state) {
      id
      machineID
      prevMachineStatusID
      name
      expiryDate
      nextAudit
      status
      remarks
      approvedBy
      approvedTimestamp
      mandatary
      machineSerialNo
      machineName
      machineClinicID
      machineClinicName
      key
      bucket
      region
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const updateSeat = /* GraphQL */ `
  mutation UpdateSeat($update: UpdateSeatInput!) {
    result: updateSeat(update: $update) {
      id
      clinicID
      machineID
      status
      name
      machineName
      machineSerialNo
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const createStaff = /* GraphQL */ `
  mutation CreateStaff($create: CreateStaffInput!, $password: String) {
    result: createStaff(create: $create, password: $password)
  }
`;
export const deleteStaff = /* GraphQL */ `
  mutation DeleteStaff($accountID: String!, $deleteCognito: Int) {
    result: deleteStaff(accountID: $accountID, deleteCognito: $deleteCognito)
  }
`;
export const updateStaff = /* GraphQL */ `
  mutation UpdateStaff($update: UpdateStaffInput!) {
    result: updateStaff(update: $update)
  }
`;
export const changePassword = /* GraphQL */ `
  mutation ChangePassword(
    $username: String!
    $password: String!
    $random: Int
    $permanent: Int
    $forceSignOut: Int
  ) {
    result: changePassword(
      username: $username
      password: $password
      random: $random
      permanent: $permanent
      forceSignOut: $forceSignOut
    )
  }
`;
export const updateCognitoUser = /* GraphQL */ `
  mutation UpdateCognitoUser(
    $username: String!
    $attributes: String
    $forceSignOut: Int
    $accountStatus: String
  ) {
    result: updateCognitoUser(
      username: $username
      attributes: $attributes
      forceSignOut: $forceSignOut
      accountStatus: $accountStatus
    )
  }
`;
export const startDialysis = /* GraphQL */ `
  mutation StartDialysis(
    $create: CreateDialysisInput!
    $appointmentID: Int!
    $redirectURL: String
  ) {
    result: startDialysis(
      create: $create
      appointmentID: $appointmentID
      redirectURL: $redirectURL
    ) {
      id
      patientID
      sessionMonth
      arriveTime
      startTime
      endTime
      dischargeTime
      accessDIALYSISREF
      startedBy
      endedBy
      clinicID
      preWeight
      lastWeight
      postWeight
      dryWeight
      height
      preTemp
      preEdema
      preSBP
      preDBP
      preHRT
      preRenalin
      ufTargetStart
      postTemp
      postEdema
      postSBP
      postDBP
      postHRT
      postRenalin
      KTVonline
      machineID
      dialyser
      MSA
      membraneUse
      dialysisOrderID
      notes
      lastUpdatedBy
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const checkoutDialysis = /* GraphQL */ `
  mutation CheckoutDialysis(
    $update: UpdateDialysisInput!
    $quickbookURL: String!
    $redirectURL: String
  ) {
    result: checkoutDialysis(
      update: $update
      quickbookURL: $quickbookURL
      redirectURL: $redirectURL
    )
  }
`;
export const checkoutDialysis2 = /* GraphQL */ `
  mutation CheckoutDialysis2(
    $update: UpdateDialysisInput!
    $quickbookURL: String
    $redirectURL: String
  ) {
    result: checkoutDialysis2(
      update: $update
      quickbookURL: $quickbookURL
      redirectURL: $redirectURL
    )
  }
`;
export const checkoutDialysis3 = /* GraphQL */ `
  mutation CheckoutDialysis3(
    $update: UpdateDialysisInput!
    $quickbookURL: String
    $redirectURL: String
  ) {
    result: checkoutDialysis3(
      update: $update
      quickbookURL: $quickbookURL
      redirectURL: $redirectURL
    )
  }
`;
export const submitDialysisInterval = /* GraphQL */ `
  mutation SubmitDialysisInterval(
    $create: CreateDialysisIntervalInput!
    $createMedicationAdministered: [CreateMedicationAdministeredInput]
  ) {
    result: submitDialysisInterval(
      create: $create
      createMedicationAdministered: $createMedicationAdministered
    ) {
      id
      dialysisID
      clinicID
      timestamp
      staffAccountID
      accessNum
      AP
      VP
      SBP
      DBP
      HR
      CDT
      KTV
      cUF
      QB
      DFLOW
      UFR
      temp
      tmp
      hepRate
      note
      symptoms
      lastUpdatedBy
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const linkAppointmentToQuickbooks = /* GraphQL */ `
  mutation LinkAppointmentToQuickbooks(
    $patientID: Int!
    $dialysisID: Int!
    $quickbookURL: String!
    $redirectURL: String!
  ) {
    result: linkAppointmentToQuickbooks(
      patientID: $patientID
      dialysisID: $dialysisID
      quickbookURL: $quickbookURL
      redirectURL: $redirectURL
    )
  }
`;
export const linkAppointmentToQuickbooks2 = /* GraphQL */ `
  mutation LinkAppointmentToQuickbooks2(
    $patientID: Int!
    $dialysisID: Int!
    $quickbookURL: String
    $redirectURL: String!
  ) {
    result: linkAppointmentToQuickbooks2(
      patientID: $patientID
      dialysisID: $dialysisID
      quickbookURL: $quickbookURL
      redirectURL: $redirectURL
    )
  }
`;
export const linkAppointmentToQuickbooks3 = /* GraphQL */ `
  mutation LinkAppointmentToQuickbooks3(
    $patientID: Int!
    $dialysisID: Int!
    $quickbookURL: String
    $redirectURL: String!
  ) {
    result: linkAppointmentToQuickbooks3(
      patientID: $patientID
      dialysisID: $dialysisID
      quickbookURL: $quickbookURL
      redirectURL: $redirectURL
    )
  }
`;
export const loginQuickbooks = /* GraphQL */ `
  mutation LoginQuickbooks(
    $quickbookURL: String
    $redirectURL: String
    $clinicIDs: [Int]
    $clinicID: Int
  ) {
    result: loginQuickbooks(
      quickbookURL: $quickbookURL
      redirectURL: $redirectURL
      clinicIDs: $clinicIDs
      clinicID: $clinicID
    )
  }
`;
export const createQBAccess = /* GraphQL */ `
  mutation CreateQBAccess(
    $input: CreateQBAccessInput!
    $condition: ModelQBAccessConditionInput
  ) {
    result: createQBAccess(input: $input, condition: $condition) {
      id
      clinicIDs
      expiresIn
      date
      createdOn
      updatedOn
      jwtToken
    }
  }
`;
export const updateQBAccess = /* GraphQL */ `
  mutation UpdateQBAccess(
    $input: UpdateQBAccessInput!
    $condition: ModelQBAccessConditionInput
  ) {
    result: updateQBAccess(input: $input, condition: $condition) {
      id
      clinicIDs
      expiresIn
      date
      createdOn
      updatedOn
      jwtToken
    }
  }
`;
export const deleteQBAccess = /* GraphQL */ `
  mutation DeleteQBAccess(
    $input: DeleteQBAccessInput!
    $condition: ModelQBAccessConditionInput
  ) {
    result: deleteQBAccess(input: $input, condition: $condition) {
      id
      clinicIDs
      expiresIn
      date
      createdOn
      updatedOn
      jwtToken
    }
  }
`;
