import React, { createContext, useState } from "react"

import path from "../utils/pathSettings"

export const DataContext = createContext()

const DataContextProvider = (props) => {
    const patientSessionRecords = [
        {
            id: 1,
            uid: "A123A",
            name: "Patient Name 001",
            nric: "S0000000A",
            status: 1, // 0: pending, 1: checked in, 2: checked out
            nextReadingDatetime: "",
        },
        {
            id: 2,
            uid: "A123A",
            name: "Patient Name 002",
            nric: "S0000000B",
            status: 0, // 0: pending, 1: checked in, 2: checked out
            nextReadingDatetime: "",
        },
        {
            id: 3,
            uid: "A123A",
            name: "PatientName003 withlongnospacingname",
            nric: "S0000000C",
            status: 2, // 0: pending, 1: checked in, 2: checked out
            nextReadingDatetime: "",
        },
        {
            id: 4,
            uid: "A123A",
            name: "PatientName004withlongnospacingnameatall",
            nric: "S0000000D",
            status: 1, // 0: pending, 1: checked in, 2: checked out
            nextReadingDatetime: "",
        },
        {
            id: 5,
            uid: "A123A",
            name: "Patient Name 005 with a very very lonnnnnnnnnnng name",
            nric: "S0000000E",
            status: 0, // 0: pending, 1: checked in, 2: checked out
            nextReadingDatetime: "",
        },
        {
            id: 6,
            uid: "A123A",
            name: "Patient Name 007",
            nric: "S0000000F",
            status: 0, // 0: pending, 1: checked in, 2: checked out
            nextReadingDatetime: "",
        },
        {
            id: 7,
            uid: "A123A",
            name: "Patient Name 008",
            nric: "S0000000G",
            status: 0, // 0: pending, 1: checked in, 2: checked out
            nextReadingDatetime: "",
        },
    ]

    const labResultsRecords = [
        {
            id: 3,
            datetime: "10 Apr 2021, 06:00",
            glucose: 5.4,
            tw: 12.6,
            plt: 283,
            hb: 8.8,
            mcv: 88,
            k: 4.4,
            na: 137.0,
            urea: 26.54,
            ureaPost: 7.5,
            creatinine: 983.0,
            hco3: 17,
            cl: 97,
            ca: 2.37,
            po4: 2.16,
            ipth: 127.0,
            un: "-",
            hepBAg: "NEG",
            2: "<2",
            hep: "-",
            hepElisa: "NEG",
            fe: 6,
            tibc: 30,
            ferritin: 349,
            protein: 349,
            alb: 37,
        },
        {
            id: 2,
            datetime: "10 Apr 2021, 06:00",
            glucose: 5.4,
            tw: 12.6,
            plt: 283,
            hb: 8.8,
            mcv: 88,
            k: 4.4,
            na: 137.0,
            urea: 26.54,
            ureaPost: 7.5,
            creatinine: 983.0,
            hco3: 17,
            cl: 97,
            ca: 2.37,
            po4: 2.16,
            ipth: 127.0,
            un: "-",
            hepBAg: "NEG",
            2: "<2",
            hep: "-",
            hepElisa: "NEG",
            fe: 6,
            tibc: 30,
            ferritin: 349,
            protein: 349,
            alb: 37,
        },
        {
            id: 1,
            datetime: "10 Apr 2021, 06:00",
            glucose: 5.4,
            tw: 12.6,
            plt: 283,
            hb: 8.8,
            mcv: 88,
            k: 4.4,
            na: 137.0,
            urea: 26.54,
            ureaPost: 7.5,
            creatinine: 983.0,
            hco3: 17,
            cl: 97,
            ca: 2.37,
            po4: 2.16,
            ipth: 127.0,
            un: "-",
            hepBAg: "NEG",
            2: "<2",
            hep: "-",
            hepElisa: "NEG",
            fe: 6,
            tibc: 30,
            ferritin: 349,
            protein: 349,
            alb: 37,
        },
    ]

    const clinicalNotesRecords = [
        {
            id: 5,
            datetime: "10 Apr 2021, 06:00",
            author: "Dr ABC",
            clinicEvent: "Clinic ABC",
            observation: "This is the review bla bla bla",
            assessment: "This is the assessment ah ah ah",
            plans: "This is the plan an an",
            intraDialysisNotes: "This is the notes. Ha ha ha",
        },
        {
            id: 4,
            datetime: "10 Apr 2021, 06:00",
            author: "Dr ABC",
            clinicEvent: "Clinic ABC",
            observation: "This is the review bla bla bla",
            assessment: "This is the assessment ah ah ah",
            plans: "This is the plan an an",
            intraDialysisNotes: "This is the notes. Ha ha ha",
        },
        {
            id: 3,
            datetime: "10 Apr 2021, 06:00",
            author: "Dr ABC",
            clinicEvent: "Clinic ABC",
            observation: "This is the review bla bla bla",
            assessment: "This is the assessment ah ah ah",
            plans: "This is the plan an an",
            intraDialysisNotes: "This is the notes. Ha ha ha",
        },
        {
            id: 2,
            datetime: "10 Apr 2021, 06:00",
            author: "Dr ABC",
            clinicEvent: "Clinic ABC",
            observation: "This is the review bla bla bla",
            assessment: "This is the assessment ah ah ah",
            plans: "This is the plan an an",
            intraDialysisNotes: "This is the notes. Ha ha ha",
        },
        {
            id: 1,
            datetime: "10 Apr 2021, 06:00",
            author: "Dr ABC",
            clinicEvent: "Clinic ABC",
            observation: "This is the review bla bla bla",
            assessment: "This is the assessment ah ah ah",
            plans: "This is the plan an an",
            intraDialysisNotes: "This is the notes. Ha ha ha",
        },
    ]

    const hospitalEntriesRecords = [
        {
            id: 5,
            day: "2 days",
            location: "ABC Hospital",
            primaryDiagnosis: "This is a diagnosis report. Blah blah blah",
        },
        {
            id: 4,
            day: "2 days",
            location: "ABC Hospital",
            primaryDiagnosis: "This is a diagnosis report. Blah blah blah",
        },
        {
            id: 3,
            day: "2 days",
            location: "ABC Hospital",
            primaryDiagnosis: "This is a diagnosis report. Blah blah blah",
        },
        {
            id: 2,
            day: "2 days",
            location: "ABC Hospital",
            primaryDiagnosis: "This is a diagnosis report. Blah blah blah",
        },
        {
            id: 1,
            day: "2 days",
            location: "ABC Hospital",
            primaryDiagnosis: "This is a diagnosis report. Blah blah blah",
        },
    ]

    // ====================

    // const menuDefault = [
    //     {
    //         name: "Dashboard",
    //         to: "/",
    //     },
    // ]

    // const menuDashboardNurse = [
    //     {
    //         name: "Dashboard",
    //         to: "/",
    //     },
    //     {
    //         name: "Latest Clinical Note",
    //         to: "submenu-latestclinicalnote",
    //         submenu: [
    //             {
    //                 name: "By Author",
    //                 to: path("latestClinicalNoteByAuthor"),
    //             },
    //             {
    //                 name: "By Centre",
    //                 to: path("latestClinicalNoteByCentre"),
    //             },
    //         ]
    //     },
    //     {
    //         name: "Import Lab Result",
    //         to: "submenu-importlabresult",
    //         submenu: [
    //             {
    //                 name: "Manual Input",
    //                 to: path("importLabResultManualInput"),
    //             },
    //             {
    //                 name: "File Upload",
    //                 to: path("importLabResultFileUpload"),
    //             },
    //         ]
    //     },        
    //     {
    //         name: "Availability",
    //         to: path("availability"),
    //     },
    //     {
    //         name: "Incident Report",
    //         to: path("incidentReport"),
    //     },
    //     {
    //         name: "Manage",
    //         to: "submenu-manage",
    //         submenu: [
    //             {
    //                 name: "Machine",
    //                 to: path("manageMachine"),
    //             },
    //             {
    //                 name: "Staff",
    //                 to: path("manageStaff"),
    //             },
    //         ]
    //     },
    // ]

    // const menuDashboardDoctor = [
    //     {
    //         name: "Dashboard",
    //         to: "/",
    //     },
    //     {
    //         name: "Latest Clinical Note",
    //         to: "submenu-latestclinicalnote",
    //         submenu: [
    //             {
    //                 name: "By Author",
    //                 to: path("latestClinicalNoteByAuthor"),
    //             },
    //             {
    //                 name: "By Centre",
    //                 to: path("latestClinicalNoteByCentre"),
    //             },
    //         ]
    //     },
    //     {
    //         name: "Import Lab Result",
    //         to: "submenu-importlabresult",
    //         submenu: [
    //             {
    //                 name: "Manual Input",
    //                 to: path("importLabResultManualInput"),
    //             },
    //             {
    //                 name: "File Upload",
    //                 to: path("importLabResultFileUpload"),
    //             },
    //         ]
    //     },        
    //     {
    //         name: "Availability",
    //         to: path("availability"),
    //     },
    //     {
    //         name: "Incident Report",
    //         to: path("incidentReport"),
    //     },
    //     {
    //         name: "Manage",
    //         to: "submenu-manage",
    //         submenu: [
    //             {
    //                 name: "Machine",
    //                 to: path("manageMachine"),
    //             },
    //             {
    //                 name: "Staff",
    //                 to: path("manageStaff"),
    //             },
    //             {
    //                 name: "Certification",
    //                 to: path("manageCertification"),
    //             },
    //         ]
    //     },
    // ]

    // const menuDashboardManagingNurseMedicalDirector = [
    //     {
    //         name: "Dashboard",
    //         to: "/",
    //     },
    //     {
    //         name: "Latest Clinical Note",
    //         to: "submenu-latestclinicalnote",
    //         submenu: [
    //             {
    //                 name: "By Author",
    //                 to: path("latestClinicalNoteByAuthor"),
    //             },
    //             {
    //                 name: "By Centre",
    //                 to: path("latestClinicalNoteByCentre"),
    //             },
    //         ]
    //     },
    //     {
    //         name: "Import Lab Result",
    //         to: "submenu-importlabresult",
    //         submenu: [
    //             {
    //                 name: "Manual Input",
    //                 to: path("importLabResultManualInput"),
    //             },
    //             {
    //                 name: "File Upload",
    //                 to: path("importLabResultFileUpload"),
    //             },
    //         ]
    //     },
    //     {
    //         name: "Availability",
    //         to: path("availability"),
    //     },
    //     {
    //         name: "Incident Report",
    //         to: path("incidentReport"),
    //     },
    //     {
    //         name: "Manage",
    //         to: path("manage"),
    //     },
    // ]

    // const menuInPatient = (id, inPatientOrigin) => {
    //     return ([
    //         {
    //             name: "< Back",
    //             to: "/",
    //         },
    //         {
    //             name: "Patient Info",
    //             to: path("patientInformation", [id]),
    //         },
    //         // {
    //         //     name: inPatientOrigin !== undefined && inPatientOrigin === "dialysis" ? "Dialysis Session" : "Patient Record",
    //         //     to: inPatientOrigin !== undefined && inPatientOrigin === "dialysis" ? path("patientDialysis", [id]) : path("patientRecord", [id]),
    //         // },
    //         {
    //             name: "Patient Record",
    //             to: path("patientRecord", [id]),
    //         },
    //         {
    //             name: "Dialysis Session",
    //             to: path("patientDialysis", [id]),
    //         },
    //         {
    //             name: "Incident Report",
    //             to: path("patientIncidentReport", [id]),
    //         },
    //         {
    //             name: "Lab Report",
    //             to: path("patientLabReport", [id]),
    //         },
    //         {
    //             name: "Document",
    //             to: path("patientDocument", [id]),
    //         },
    //         {
    //             name: "Appointment",
    //             to: path("patientAppointment", [id]),
    //         },
    //     ])
    // }

    const [dataState, setDataState] = useState({
        patientSessionRecords,
        labResultsRecords,
        clinicalNotesRecords,
        hospitalEntriesRecords,
        // ====================
        // menuDefault,
        // menuDashboardNurse,
        // menuDashboardDoctor,
        // menuDashboardManagingNurseMedicalDirector,
        // menuInPatient,
    })

    return (
        <DataContext.Provider value={{ dataState, setDataState }}>
            {props.children}
        </DataContext.Provider>
    )
}

export default DataContextProvider