import React, { useEffect, useState } from "react";
import {
    Button,
    Form,
    Input,
    Modal,
    notification,
    Popconfirm,
    Space,
    Spin,
    Table
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { API } from "aws-amplify";
import moment from "moment";

import { listDrug } from "../backend/graphql/queries";
import { createDrug, updateDrug } from "../backend/graphql/mutations";
import renderInputItems from "../utils/renderFormInputItems";
import iconMinus from "../media/Icon-Minus.png";

const ManageDrug = () => {
    const [formAdd] = Form.useForm();
    const [formSearch] = Form.useForm();
    const [filter, setFilter] = useState({
        search: ""
    });
    const [visibilityAddMedication, setVisibilityAddMedication] =
        useState(false);
    const validateMessages = {
        required: "Required."
    };
    const [table, setTable] = useState({
        data: -1,
        pagination: {
            current: 1,
            pageSize: 20,
            offset: 0,
            showSizeChanger: true
        },
        loading: false
    });
    const [displayList, setDisplayList] = useState([]);
    const [loadingModal, setLoadingModal] = useState(false);
    const columns = [
        {
            title: "Date Added",
            dataIndex: "createdOnDisplay"
        },
        {
            title: "Drug",
            dataIndex: "name"
        },
        {
            title: "Purpose",
            dataIndex: "purpose"
        },
        {
            title: "Manufacturer",
            dataIndex: "manufacturer"
        },
        {
            title: "Action",
            dataIndex: "action",
            render: (_, record) => {
                return (
                    <Space size="large">
                        <Popconfirm
                            title="Are you sure?"
                            onConfirm={() => handleDelete(record.id)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <div className="table-row-action clickable red">
                                <div className="icon">
                                    <img src={iconMinus} />
                                </div>
                                <div className="text">Delete</div>
                            </div>
                        </Popconfirm>
                    </Space>
                );
            }
        }
    ];

    const getDrugList = async (props, values) => {
        setTable({
            ...table,
            loading: true
        });
        try {
            let filter = {
                deleted: { eq: 0 }
            };

            if (values.search != "") {
                filter = {
                    ...filter,
                    name: { contains: values.search }
                };
            }

            const drugDetails = {
                pagination: {
                    limit: props.pageSize,
                    offset: props.offset,
                    orderby: "name ASC"
                },
                filter: filter
            };
            // console.log("drugDetails", drugDetails);
            const result = await API.graphql({
                query: listDrug,
                variables: drugDetails,
                authMode: "AMAZON_COGNITO_USER_POOLS"
            });
            // console.log(result);
            const data = result.data.result;
            // console.log("data", data);
            setTable({
                ...table,
                data: data.result,
                loading: data.result.length === 0 ? false : true,
                pagination: {
                    ...table.pagination,
                    pageSize: props.pageSize,
                    current: props.current,
                    offset: props.offset,
                    total:
                        props.offset >= data.count ? data.count + 1 : data.count // keeps the last pagination if it is the last record
                }
            });
        } catch (error) {
            notification.error({
                message: "Unable to retrieve records"
            });
            setTable({
                ...table,
                loading: false
            });
        }

        // const timer = setTimeout(() => {
        //     setTable({
        //         ...table,
        //         // loading: dataState.patientSessionRecords.length !== 0 ? true : false,
        //         loading: false, // set false for simulation
        //         data: [
        //             {
        //                 id: 1,
        //                 createdOn: "01 Jan 2022",
        //                 name: "Dizzy",
        //                 dose: "100mg",
        //                 manufacturer: "Merck & Co",
        //             },
        //             {
        //                 id: 2,
        //                 createdOn: "01 Jan 2022",
        //                 name: "Dizzy",
        //                 dose: "100mg",
        //                 manufacturer: "Merck & Co",
        //             },
        //             {
        //                 id: 3,
        //                 createdOn: "01 Jan 2022",
        //                 name: "Dizzy",
        //                 dose: "100mg",
        //                 manufacturer: "Merck & Co",
        //             },
        //             {
        //                 id: 4,
        //                 createdOn: "01 Jan 2022",
        //                 name: "Dizzy",
        //                 dose: "100mg",
        //                 manufacturer: "Merck & Co",
        //             },
        //         ]
        //     })
        //     clearTimeout(timer)
        // }, 1000)
    };

    const createDrugRecord = async (values) => {
        setLoadingModal(true);
        try {
            const drugDetails = {
                create: {
                    name: values.name,
                    purpose: values.purpose,
                    manufacturer: values.manufacturer
                }
            };
            // console.log("drugDetails", drugDetails);
            const result = await API.graphql({
                query: createDrug,
                variables: drugDetails,
                authMode: "AMAZON_COGNITO_USER_POOLS"
            });
            // console.log(result);
            // const data = result.data.result
            // console.log("data", data);
            notification.success({
                message: "Created successfully"
            });
            getDrugList(table.pagination, filter);
            formAdd.resetFields();
            handleModalVisibility("addDrug");
        } catch (error) {
            console.log("error", error);
            notification.error({
                message: "Unable to create record"
            });
        } finally {
            setLoadingModal(false);
        }
    };

    const deleteDrugRecord = async (id) => {
        setLoadingModal(true);
        try {
            const drugDetails = {
                update: {
                    id: id,
                    deleted: 1
                }
            };
            // console.log("drugDetails", drugDetails);
            const result = await API.graphql({
                query: updateDrug,
                variables: drugDetails,
                authMode: "AMAZON_COGNITO_USER_POOLS"
            });
            // console.log(result);
            // const data = result.data.result
            // console.log("data", data);
            notification.success({
                message: "Deleted successfully"
            });
            getDrugList(table.pagination, filter);
        } catch (error) {
            console.log("error", error);
            notification.error({
                message: "Unable to update record"
            });
        } finally {
            setLoadingModal(false);
        }
    };

    useEffect(() => {
        getDrugList(table.pagination, filter);
        formSearch.setFieldsValue(filter);
        formAdd.setFieldsValue({
            name: "",
            purpose: ""
        });
    }, []);

    useEffect(() => {
        if (table.data !== -1) {
            let array = [];
            for (let i = 0; i < table.data.length; i++) {
                let object = {
                    ...table.data[i],
                    createdOnDisplay: moment
                        .utc(table.data[i].createdOn)
                        .local()
                        .format("DD MMM YYYY")
                };
                array.push(object);
            }
            setDisplayList(array);
            setTable({
                ...table,
                loading: false
            });
        }
    }, [table.data]);

    const onFinishSearch = (values) => {
        // console.log("submit formSearch", values);
        setFilter(values);
        getDrugList(table.pagination, values);
    };

    const onFinishAdd = async (values) => {
        // console.log("onFinishAdd", values);
        await formAdd.validateFields();
        createDrugRecord(values);
        // setLoadingModal(true)
        // const timer = setTimeout(() => {
        //     setLoadingModal(false)
        //     clearTimeout(timer)
        //     handleModalVisibility("addDrug")
        // }, 1000)
    };

    const handleTableChange = (paginate) => {
        getDrugList(
            {
                ...table.pagination,
                pageSize: paginate.pageSize,
                current: paginate.current,
                offset: paginate.current * paginate.pageSize - paginate.pageSize
            },
            filter
        );
    };

    const handleDelete = async (id) => {
        // console.log("handle delete", id);
        deleteDrugRecord(id);
        // setTable({
        //     ...table,
        //     loading: true
        // })
        // const timer = setTimeout(() => {
        //     setTable({
        //         ...table,
        //         loading: false
        //     })
        //     clearTimeout(timer)
        // }, 1000)
    };

    const handleModalVisibility = (modal, id) => {
        // console.log("modal", modal);
        switch (modal) {
            case "addDrug":
                setVisibilityAddMedication(!visibilityAddMedication);
                break;
            default:
        }
    };

    return (
        <div className="content-content">
            <div className="manage-header">
                <div className="container-rows">
                    <div className="row1">
                        <div className="title">Manage Drugs</div>
                        <Button
                            type="primary"
                            onClick={() => handleModalVisibility("addDrug")}
                        >
                            Add Drug
                        </Button>
                    </div>
                    <Form
                        form={formSearch}
                        layout="horizontal"
                        onFinish={onFinishSearch}
                        validateMessages={validateMessages}
                        requiredMark={false}
                    >
                        <div className="container-row">
                            <div className="row2">
                                <div className="row">
                                    <Form.Item className="search" name="search">
                                        <Input
                                            autoComplete="off"
                                            prefix={<SearchOutlined />}
                                            placeholder="Search drug"
                                            value={filter.search}
                                            allowClear
                                        />
                                    </Form.Item>
                                    <Button type="primary" htmlType="submit">
                                        Search
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
            <div className="manage-content">
                <Table
                    columns={columns}
                    rowClassName={(record, index) => {
                        // console.log("index", index);
                        return index % 2 === 0 ? "highlight" : "";
                    }}
                    dataSource={displayList}
                    rowKey={(record) => record.id}
                    loading={table.loading}
                    pagination={table.pagination}
                    onChange={handleTableChange}
                />
            </div>
            <Modal
                title={<div className="modal-title">Add Drug</div>}
                visible={visibilityAddMedication}
                onCancel={() => handleModalVisibility("addDrug")}
                footer={[
                    <Button
                        key="back"
                        onClick={() => handleModalVisibility("addDrug")}
                    >
                        Cancel
                    </Button>,
                    <Popconfirm
                        title="Are you sure"
                        onConfirm={() => onFinishAdd(formAdd.getFieldsValue())}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button
                            key="submit"
                            type="primary"
                            loading={loadingModal}
                        >
                            Add
                        </Button>
                    </Popconfirm>
                ]}
                className="modal-manage"
                width={800}
            >
                <div className="modal-content">
                    <Spin spinning={loadingModal}>
                        <Form
                            form={formAdd}
                            layout="horizontal"
                            onFinish={onFinishAdd}
                            validateMessages={validateMessages}
                            requiredMark={false}
                        >
                            {renderInputItems([
                                {
                                    label: "Drug Name",
                                    name: "name",
                                    type: "input",
                                    placeholder: "Enter drug name",
                                    required: true
                                },
                                {
                                    label: "Purpose",
                                    name: "purpose",
                                    type: "input",
                                    placeholder: "Enter purpose"
                                },
                                {
                                    label: "Manufacturer",
                                    name: "manufacturer",
                                    type: "input",
                                    placeholder: "Enter manufacturer"
                                }
                            ])}
                        </Form>
                    </Spin>
                </div>
            </Modal>
        </div>
    );
};

export default ManageDrug;
