import React from "react"

import PatientRecordLastNonNullLab from "./Component-PatientRecord-LastNonNullLab"
import PatientRecordMedicalMilestone from "./Component-PatientRecord-MedicalMilestone"
import PatientRecordMedication from "./Component-PatientRecord-Medication"

const PatientRecordMedLabMilestone = (props) => {

    return (
        <div className="patientrecord-medlabmilestone">
            <PatientRecordMedication patientID={props?.patientID} />
            {/* <PatientRecordLastNonNullLab patientID={props?.patientID} /> */}
            &nbsp;&nbsp;&nbsp;
            <PatientRecordMedicalMilestone patientID={props?.patientID} />
        </div>
    )
}

export default PatientRecordMedLabMilestone