/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateAppointment = /* GraphQL */ `
  subscription OnCreateAppointment($clinicID: Int) {
    onCreateAppointment(clinicID: $clinicID) {
      id
      patientID
      clinicID
      seatID
      date
      shift
      quickbookInvoiceID
      time
      dialysisID
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const onUpdateAppointment = /* GraphQL */ `
  subscription OnUpdateAppointment($clinicID: Int) {
    onUpdateAppointment(clinicID: $clinicID) {
      id
      patientID
      clinicID
      seatID
      date
      shift
      quickbookInvoiceID
      time
      dialysisID
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const onCreateClinic = /* GraphQL */ `
  subscription OnCreateClinic {
    onCreateClinic {
      id
      name
      uid
      phoneNo
      email
      address
      status
      noOfSeat
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const onUpdateClinic = /* GraphQL */ `
  subscription OnUpdateClinic {
    onUpdateClinic {
      id
      name
      uid
      phoneNo
      email
      address
      status
      noOfSeat
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const onCreateMedicationAdministered = /* GraphQL */ `
  subscription OnCreateMedicationAdministered {
    onCreateMedicationAdministered {
      id
      accessID
      patientID
      dialysisID
      dialysisIntervalID
      medication
      dose
      route
      freq
      givenBy
      givenByStaffName
      witnessedBy
      witnessedByStaffName
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const onUpdateMedicationAdministered = /* GraphQL */ `
  subscription OnUpdateMedicationAdministered {
    onUpdateMedicationAdministered {
      id
      accessID
      patientID
      dialysisID
      dialysisIntervalID
      medication
      dose
      route
      freq
      givenBy
      givenByStaffName
      witnessedBy
      witnessedByStaffName
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const onCreateDialysisOrder = /* GraphQL */ `
  subscription OnCreateDialysisOrder {
    onCreateDialysisOrder {
      id
      staffAccountID
      patientID
      accessID
      MSA
      dOrder
      freqMth
      duration
      hepLoad
      hepCont
      antio
      dialysate
      accessPrim
      aNeedle
      vNeedle
      bicarbonate
      temp
      QD
      QB
      dryWeight
      notes
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const onSubmitDialysisInterval = /* GraphQL */ `
  subscription OnSubmitDialysisInterval($clinicID: Int, $dialysisID: Int) {
    onSubmitDialysisInterval(clinicID: $clinicID, dialysisID: $dialysisID) {
      id
      dialysisID
      clinicID
      timestamp
      staffAccountID
      accessNum
      AP
      VP
      SBP
      DBP
      HR
      CDT
      KTV
      cUF
      QB
      DFLOW
      UFR
      temp
      tmp
      hepRate
      note
      symptoms
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const onStartDialysis = /* GraphQL */ `
  subscription OnStartDialysis($clinicID: Int) {
    onStartDialysis(clinicID: $clinicID) {
      id
      patientID
      sessionMonth
      arriveTime
      startTime
      endTime
      dischargeTime
      accessDIALYSISREF
      startedBy
      endedBy
      clinicID
      preWeight
      lastWeight
      postWeight
      dryWeight
      height
      preTemp
      preEdema
      preSBP
      preDBP
      preHRT
      preRenalin
      ufTargetStart
      postTemp
      postEdema
      postSBP
      postDBP
      postHRT
      postRenalin
      KTVonline
      machineID
      dialyser
      MSA
      membraneUse
      dialysisOrderID
      notes
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const onCheckoutDialysis = /* GraphQL */ `
  subscription OnCheckoutDialysis {
    onCheckoutDialysis
  }
`;
