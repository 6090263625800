import React, { useContext, useEffect } from "react"
import { Link, useLocation } from "react-router-dom"

import { AppContext } from "../contexts/AppContext"
import path from "../utils/pathSettings"
import Logo from "../media/Logo-AEGISMedicalCare.png"
import CurrentDatetime from "./Component-CurrentDatetime";
import HeaderNav from "./Component-HeaderNav";
import HeaderProfile from "./Component-HeaderProfile";

const HeaderApp = () => {
    const { appState } = useContext(AppContext)
    const location = useLocation()
    // console.log("location", location);

    useEffect(() => {
        window.scrollTo({ top: 0 })
    }, [location])

    if (appState.validLogin === false | location.pathname === "/login" || location.pathname === "/settings/centre" || location.pathname === "/forgotpassword") {
        return ("")
    }
    else {
        return (
            <div className="header">
                <div className="header-content">
                    <div className="left">
                        <HeaderNav view="mobile" />
                        <Link to={path("dashboard")} className="item">
                            <img src={Logo} />
                        </Link>
                        <HeaderNav view="desktop" />
                        <CurrentDatetime />
                    </div>
                    <div className="right">
                        <HeaderProfile />
                    </div>
                </div>
            </div>
        );
    }
};

export default HeaderApp
