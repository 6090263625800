import React, { useContext, useState, useEffect } from "react";
import {
    Button,
    DatePicker,
    Form,
    Input,
    Modal,
    Table,
    notification,
    Popconfirm,
    Spin,
    Select,
    AutoComplete
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { API } from "aws-amplify";
import moment from "moment";

import { AppContext } from "../contexts/AppContext";
import { listHospitalSummary } from "../backend/graphql/queries";
import { createHospitalSummary } from "../backend/graphql/mutations";
import iconEye from "../media/Icon-Eye2.png";

const { TextArea } = Input;

const PatientRecordHospitalEntries = (props) => {
    const { appState } = useContext(AppContext);
    const [form] = Form.useForm();
    const { Option } = Select;
    const dateFormatList = ["DD MMM YYYY"];
    const validateMessages = {
        required: "This field is required."
    };
    const [visibilityAddHospitalEntry, setvisibilityAddHospitalEntry] =
        useState(false);
    const [visibilityHospitalEntry, setVisibilityHospitalEntry] =
        useState(false);
    const [hospitalEntry, setHospitalEntry] = useState({});
    const columns = [
        {
            title: "Admission Date",
            dataIndex: "admitTimestamp"
        },
        {
            title: "Discharge Date",
            dataIndex: "leaveTimestamp"
        },
        {
            title: "No. Day",
            dataIndex: "noOfDays"
        },
        {
            title: "Location",
            dataIndex: "location",
            ellipsis: true
        },
        {
            title: "Primary Diagnosis",
            dataIndex: "assessment",
            ellipsis: true
        },
        {
            title: "Main Reason",
            dataIndex: "hospitalMain",
            ellipsis: true
        },
        {
            title: "Other Reasons",
            dataIndex: "hospitalReason",
            ellipsis: true
        },
        {
            title: "",
            dataIndex: "action",
            width: 50,
            render: (_, record) => {
                return (
                    <div
                        className="clickable"
                        onClick={() => {
                            handleModalVisibility("hospitalEntry", record);
                        }}
                    >
                        <img src={iconEye} />
                    </div>
                );
            }
        }
    ];
    const [table, setTable] = useState({
        data: [],
        pagination: {
            current: 1,
            pageSize: 5,
            offset: 0,
            showSizeChanger: false
        },
        loading: false
    });
    const [loading, setLoading] = useState(false);
    const [selectedDischargeDate, setSelectedDischargeDate] = useState(null);

    useEffect(() => {
        setSelectedDischargeDate(moment());
    }, []);

    useEffect(() => {
        // console.log("PatientRecordAdministeredMedication", props?.patientID);
        if (Object.keys(appState.cognitoUser).length !== 0) {
            getHospitalSummary(null, table.pagination);
        }
        return () => {};
    }, [appState.cognitoUser]);

    const _listHospitalSummary = async (
        patientID,
        pagination = table.pagination,
        filter = null
    ) => {
        try {
            const variables = {
                pagination: {
                    limit: pagination.pageSize,
                    offset: pagination.offset,
                    orderby: `admitTimestamp DESC`
                    // orderby: `createdOn DESC`
                },
                filter: {
                    patientID: {
                        eq: patientID
                    },
                    type: {
                        eq: "HOSPITAL"
                    },
                    deleted: { eq: 0 }
                }
            };

            // console.log(variables);

            const result = await API.graphql({
                query: listHospitalSummary,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables
            });
            // console.log(API result PatientRecordHospitalEntries", result);

            const data = result.data.result;
            const array = result.data.result.result;

            // process data
            let _hopitalSummary = [];
            for (let v of array) {
                _hopitalSummary.push({
                    id: v.id,
                    accessIDHosp: v.accessIDHosp,
                    patientID: v.patientID,
                    clinicID: v.clinicID,
                    staffAccountID: v.staffAccountID,
                    staffName: v?.staffName
                        ? v?.staffName
                        : appState.staffList.length > 0
                        ? appState.staffList.filter(
                              (s) => s.accountID == v.staffAccountID
                          )[0]?.name
                        : v.staffAccountID,
                    admitTimestamp: v.admitTimestamp
                        ? moment
                              .utc(v.admitTimestamp)
                              .local()
                              .format("DD MMM yyyy")
                        : null,
                    type: v.type,
                    observation: v.observation,
                    assessment: v.assessment,
                    plan: v.plan,
                    notes: v.notes,
                    leaveTimestamp: v.leaveTimestamp
                        ? moment
                              .utc(v.leaveTimestamp)
                              .local()
                              .format("DD MMM yyyy")
                        : null,
                    noOfDays: v.noOfDays,
                    location: v.location,
                    reason: v.reason,
                    diagnosis: v.diagnosis,
                    outcome: v.outcome,
                    hospitalMain: v.hospitalMain,
                    hospitalReason: v.hospitalReason
                });
            }

            // console.log(_hopitalSummary);
            setTable({
                ...table,
                data: _hopitalSummary,
                loading: false,
                pagination: {
                    ...table.pagination,
                    current: pagination.current,
                    offset: pagination.offset,
                    total:
                        pagination.offset >= data.count
                            ? data.count + 1
                            : data.count // keeps the last pagination if it is the last record
                }
            });

            // console.log(_medicationAdministered);
        } catch (error) {
            console.log("error: ", error);
            notification.error({
                message: "Unable to retrieve Hospital Entries"
            });
        } finally {
        }
    };

    const _createHospitalSummary = async (values) => {
        setLoading(true);
        setTable({
            ...table,
            loading: true
        });
        try {
            let variables = {
                patientID: props?.patientID,
                clinicID: appState?.selectedClinicID,
                // staffAccountID: values.staffAccountID,
                staffAccountID: appState.cognitoUser.username,
                // admitTimestamp: moment().toISOString().split(".")[0],
                admitTimestamp: moment(values.admitTimestamp)
                    .toISOString()
                    .split(".")[0],
                leaveTimestamp: moment(values.leaveTimestamp)
                    .toISOString()
                    .split(".")[0],
                type: "HOSPITAL",
                noOfDays: values.noOfDays,
                location: values.location,
                // diagnosis: values.diagnosis
                assessment: values.diagnosis,
                hospitalMain: values.hospitalMain,
                hospitalReason: values.hospitalReason
            };

            variables = Object.entries(variables).reduce(
                (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
                {}
            );

            // console.log(variables);

            const result = await API.graphql({
                query: createHospitalSummary,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables: { create: variables }
            });
            // console.log("API result PatientRecordHospitalEntries createHospitalSummary", result);

            if (result?.data?.result) {
                getHospitalSummary(props?.patientID, {
                    ...table.pagination,
                    current: 1,
                    offset:
                        1 * table.pagination.pageSize -
                        table.pagination.pageSize
                });
                notification.success({
                    message: "Created successfully"
                });
                form.resetFields();
                setvisibilityAddHospitalEntry(false);
            }

            setTable({
                ...table,
                loading: false
            });
        } catch (error) {
            console.log("error", error);
            notification.error({
                message: "Unable to create new Hospital Entries"
            });
        } finally {
            setLoading(false);
        }
    };

    const getHospitalSummary = (filter, pagination) => {
        setTable({
            ...table,
            loading: true
        });

        _listHospitalSummary(props?.patientID, pagination, filter);
    };

    const onFinish = async (values) => {
        // console.log("onFinish clicked", values);
        const v = await form.validateFields();
        _createHospitalSummary(v);
    };

    const onValuesChange = async (values) => {
        // console.log('onValuesChange', values, Object.keys(values));

        if (Object.keys(values).includes("admitTimestamp")) {
            setSelectedDischargeDate(values.admitTimestamp);
        }
        if (
            Object.keys(values).includes("admitTimestamp") ||
            Object.keys(values).includes("leaveTimestamp")
        ) {
            let v = form.getFieldsValue(["admitTimestamp", "leaveTimestamp"]);
            // console.log(v);

            if (v.admitTimestamp && v.leaveTimestamp) {
                let noOfDays =
                    v.leaveTimestamp.diff(v.admitTimestamp, "days") + 1;
                // console.log(noOfDays);
                form.setFieldsValue({ noOfDays: `${noOfDays}` });
            }
        }
    };

    const handleDatePickerChange = (date, dateString) => {
        // console.log("handleDatePickerChange");
        // console.log(date, dateString);
    };

    const handleModalVisibility = (modal, record) => {
        // console.log("modal", modal);
        // console.log("record", record);
        switch (modal) {
            case "addHospitalEntry":
                setvisibilityAddHospitalEntry(!visibilityAddHospitalEntry);
                break;
            case "hospitalEntry":
                setHospitalEntry(record ? record : {});
                setVisibilityHospitalEntry(!visibilityHospitalEntry);
                break;
            default:
        }
    };

    const handleTableChange = (paginate) => {
        // console.log("handleTableChange", paginate)
        getHospitalSummary(null, {
            ...table.pagination,
            current: paginate.current,
            offset:
                paginate.current * table.pagination.pageSize -
                table.pagination.pageSize
        });
    };

    const renderListOptions = (
        array,
        key = "id",
        value = "id",
        name = "name"
    ) => {
        if (typeof array == "object" && array.length !== 0) {
            const listOptions = array.map((item, index) => {
                return (
                    <Option key={item[key]} value={item[value]}>
                        {item[name]}
                    </Option>
                );
            });

            return listOptions;
        } else {
            return <div />;
        }
    };

    return (
        <div className="patientrecord-hospitalentries">
            <div className="container-title">
                <div className="title">Hospital Entries</div>
                <Button
                    type="primary"
                    onClick={() =>
                        setvisibilityAddHospitalEntry("addHospitalEntry")
                    }
                >
                    <PlusOutlined /> Add Entry
                </Button>
            </div>
            <div>
                <Table
                    columns={columns}
                    // scroll={{ x: 1500, y: 500 }}
                    rowKey={(record) => record.id}
                    rowClassName={(record, index) => {
                        // console.log("index", index);
                        return index % 2 === 0 ? "highlight" : "";
                    }}
                    dataSource={table.data}
                    loading={table.loading}
                    pagination={
                        table.data.length > 0
                            ? { ...table.pagination, position: ["bottomLeft"] }
                            : false
                    }
                    onChange={handleTableChange}
                />
            </div>
            <Modal
                title={<div className="modal-title">Add Hospital Entry</div>}
                visible={visibilityAddHospitalEntry}
                onCancel={() => handleModalVisibility("addHospitalEntry")}
                footer={[
                    <Button
                        key="back"
                        onClick={() =>
                            handleModalVisibility("addHospitalEntry")
                        }
                    >
                        Cancel
                    </Button>,
                    <Popconfirm
                        title="Are you sure?"
                        onConfirm={() => onFinish(form.getFieldsValue())}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button key="submit" type="primary" loading={loading}>
                            Submit
                        </Button>
                    </Popconfirm>
                ]}
                className="modal-patientrecord-hospitalentries"
                width={800}
            >
                <div className="modal-content">
                    <Spin spinning={loading}>
                        <Form
                            className="addhospitalentry"
                            form={form}
                            layout="horizontal"
                            onFinish={onFinish}
                            onValuesChange={onValuesChange}
                            validateMessages={validateMessages}
                            requiredMark={false}
                        >
                            <Form.Item
                                label="Admission Date"
                                name="admitTimestamp"
                                rules={[
                                    {
                                        required: true
                                    }
                                ]}
                            >
                                <DatePicker
                                    onChange={handleDatePickerChange}
                                    format={dateFormatList}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Discharge Date"
                                name="leaveTimestamp"
                                dependencies={[
                                    "admitTimestamp",
                                    "leaveTimestamp"
                                ]}
                                rules={[
                                    {
                                        required: true
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (
                                                !value ||
                                                getFieldValue(
                                                    "admitTimestamp"
                                                ) <= value
                                            ) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(
                                                new Error(
                                                    `Discharge Time must be after Admission Time`
                                                )
                                            );
                                        }
                                    })
                                ]}
                            >
                                <DatePicker
                                    onChange={handleDatePickerChange}
                                    format={dateFormatList}
                                    disabledDate={(d) =>
                                        d.isBefore(selectedDischargeDate)
                                    }
                                />
                            </Form.Item>
                            <Form.Item
                                label="No. Day"
                                name="noOfDays"
                                rules={[
                                    {
                                        required: false
                                    }
                                ]}
                            >
                                <Input autoComplete="off" disabled={true} />
                            </Form.Item>
                            <Form.Item
                                label="Location"
                                name="location"
                                rules={[
                                    {
                                        required: true
                                    }
                                ]}
                            >
                                <Input autoComplete="off" />
                            </Form.Item>
                            <Form.Item
                                className="row2"
                                label="Primary Diagnosis"
                                name="diagnosis"
                                rules={[
                                    {
                                        required: false
                                    }
                                ]}
                            >
                                <TextArea rows={3} />
                            </Form.Item>
                            <Form.Item
                                className="row2"
                                label="Main Reason"
                                name="hospitalMain"
                                rules={[
                                    {
                                        required: false
                                    }
                                ]}
                            >
                                {/* <TextArea rows={3} /> */}
                                <AutoComplete
                                    showSearch
                                    // placeholder="Select Reason"
                                    filterOption={(input, option) =>
                                        option?.children
                                            ?.toLowerCase()
                                            .indexOf(input?.toLowerCase()) >= 0
                                    }
                                >
                                    {renderListOptions(
                                        appState.hospitalReasonList,
                                        "hospDetail",
                                        "hospDetail",
                                        "hospDetail"
                                    )}
                                </AutoComplete>
                            </Form.Item>
                            <Form.Item
                                className="row2"
                                label="Other Reasons"
                                name="hospitalReason"
                                rules={[
                                    {
                                        required: false
                                    }
                                ]}
                            >
                                {/* <TextArea rows={3} /> */}
                                <AutoComplete
                                    showSearch
                                    // placeholder="Select"
                                    filterOption={(input, option) =>
                                        option?.children
                                            ?.toLowerCase()
                                            .indexOf(input?.toLowerCase()) >= 0
                                    }
                                >
                                    {renderListOptions(
                                        appState.hospitalReasonList,
                                        "hospDetail",
                                        "hospDetail",
                                        "hospDetail"
                                    )}
                                </AutoComplete>
                            </Form.Item>
                        </Form>
                    </Spin>
                </div>
            </Modal>
            <Modal
                title={<div className="modal-title">Hospital Entry</div>}
                visible={visibilityHospitalEntry}
                onCancel={() => handleModalVisibility("hospitalEntry")}
                footer={[
                    <Button
                        key="back"
                        onClick={() => handleModalVisibility("hospitalEntry")}
                    >
                        Cancel
                    </Button>
                ]}
                className="modal-patientrecord-clinicnotes"
                width={800}
            >
                <div className="modal-content">
                    <div className="clinicnotes">
                        <div className="row1">
                            <div className="label">Admission Date:</div>
                            <div className="value">
                                {hospitalEntry.admitTimestamp}
                            </div>
                        </div>
                        <div className="row1">
                            <div className="label">Discharge Date:</div>
                            <div className="value">
                                {hospitalEntry.leaveTimestamp}
                            </div>
                        </div>
                        <div className="row1">
                            <div className="label">No. Day:</div>
                            <div className="value">
                                {hospitalEntry.noOfDays}
                            </div>
                        </div>
                        <div className="row1">
                            <div className="label">Location:</div>
                            <div className="value">
                                {hospitalEntry.location}
                            </div>
                        </div>
                        <div className="row2">
                            <div className="label">Primary Diagnosis:</div>
                            <div className="value">
                                {hospitalEntry.assessment}
                            </div>
                        </div>
                        <div className="row2">
                            <div className="label">Main Reason:</div>
                            <div className="value">
                                {hospitalEntry.hospitalMain}
                            </div>
                        </div>
                        <div className="row2">
                            <div className="label">Other Reasons:</div>
                            <div className="value">
                                {hospitalEntry.hospitalReason}
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default PatientRecordHospitalEntries;
