export const checkImageTypeValidity = (type) => {
    let valid = false
    switch (type.toLowerCase()) {
        case "image/png":
            valid = true
            break
        case "image/jpeg":
            valid = true
            break
        case "image/jpg":
            valid = true
            break
        default:
    }
    return valid
}

export const checkVideoTypeValidity = (type) => {
    let valid = false
    switch (type.toLowerCase()) {
        case "video/mp4":
            valid = true
            break
        case "video/quicktime":
            valid = true
            break
        case "video/MOV":
            valid = true
            break
        case "video/wmv":
            valid = true
            break
        case "video/flv":
            valid = true
            break
        default:
    }
    return valid
}