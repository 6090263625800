import React, { useContext, useEffect, useState } from "react";
import {
    Button,
    Form,
    Input,
    Modal,
    notification,
    Popconfirm,
    Space,
    Spin,
    Table,
    TreeSelect
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { API } from "aws-amplify";

import { listMachine, listSeat } from "../backend/graphql/queries";
import { AppContext } from "../contexts/AppContext";
import renderInputItems from "../utils/renderFormInputItems";
import iconEdit from "../media/Icon-Edit.png";
import iconMinus from "../media/Icon-Minus.png";
import { createSeat, updateSeat } from "../backend/graphql/mutations";

const ManageSeat = () => {
    const { appState } = useContext(AppContext);
    const [formAdd] = Form.useForm();
    const [formUpdate] = Form.useForm();
    const [formSearch] = Form.useForm();
    const { TreeNode, SHOW_PARENT } = TreeSelect;
    const [filter, setFilter] = useState({
        centre: [0],
        search: ""
    });
    const validateMessages = {
        required: "Required."
    };
    const [table, setTable] = useState({
        data: -1,
        pagination: {
            current: 1,
            pageSize: 20,
            offset: 0,
            showSizeChanger: true
        },
        loading: false
    });
    const [display, setDisplay] = useState([]);
    const [clinicList, setClinicList] = useState(-1);
    const [selectedClinicID, setSelectedClinicID] = useState(-1);
    const [machineList, setMachineList] = useState(-1);
    const [displayMachineList, setDisplayMachineList] = useState(-1);
    const [visibilityUpdateSeat, setVisibilityUpdateSeat] = useState(false);
    const [editSeat, setEditSeat] = useState({});
    const [visibilityAddSeat, setVisibilityAddSeat] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false);

    useEffect(() => {
        // getSeatList(table.pagination, filter)
        formSearch.setFieldsValue(filter);
        // formAdd.setFieldsValue({
        //     status: 'AVAILABLE',
        // })
    }, []);

    useEffect(() => {
        if (
            Object.keys(appState.cognitoUser).length !== 0 &&
            Object.keys(appState.clinicList).length !== 0 &&
            Object.keys(appState.machineList).length !== 0
        ) {
            getMachineList();
            getSeatList(table.pagination, filter);
        }
    }, [appState.cognitoUser, appState.clinicList, appState.machineList]);

    useEffect(() => {
        if (appState.fixedListLoaded === true) {
            setClinicList(appState.clinicList);
        }
    }, [appState.fixedListLoaded]);

    useEffect(() => {
        if (
            table.data !== -1 &&
            clinicList !== -1 &&
            Object.keys(appState.machineList).length !== 0
        ) {
            // console.log("clinicList", clinicList);
            let array = [];
            for (let i = 0; i < table.data.length; i++) {
                let object = {
                    ...table.data[i]
                };
                for (let j = 0; j < clinicList.length; j++) {
                    if (table.data[i].clinicID === clinicList[j].id) {
                        object = {
                            ...object,
                            centreName: clinicList[j].name
                        };
                        break;
                    }
                }
                // object.machineName = appState.machineList.find(s => s.id == table.data[i].machineID).name;
                // object.machineSerialNo = appState.machineList.find(s => s.id == table.data[i].machineID).serialNo;

                array.push(object);
            }
            setDisplay(array);
            setTable({
                ...table,
                loading: false
            });
        }
    }, [table.data, clinicList, appState.machineList]);

    useEffect(() => {
        formUpdate.setFieldsValue({
            ...editSeat,
            id: `${editSeat.id}`,
            // id: editSeat.id,
            centre: editSeat.clinicID,
            seatNo: editSeat.name,
            machine: editSeat.machineID,
            status: editSeat.status
        });
    }, [editSeat]);

    useEffect(() => {
        if (machineList != -1) {
            if (selectedClinicID > 0) {
                setDisplayMachineList(
                    machineList?.filter(
                        (s) =>
                            s.clinicID == selectedClinicID &&
                            s.status === "AVAILABLE"
                    )
                );
            } else {
                setDisplayMachineList(
                    machineList?.filter((s) => s.status === "AVAILABLE")
                );
            }
        }
    }, [machineList, selectedClinicID]);

    const getSeatList = async (props, values) => {
        // console.log("values", values);
        setTable({
            ...table,
            loading: true
        });
        try {
            let filter = {
                deleted: { eq: 0 }
            };

            if (values.search != "") {
                if (!filter.and) filter.and = [];

                filter.and.push({
                    or: [{ name: { contains: values.search } }]
                });
            }

            // console.log("values.centre[0]", values.centre[0]);
            if (
                values.centre &&
                values.centre.length >= 1 &&
                values.centre[0] !== 0
            ) {
                if (!filter.and) filter.and = [];

                let array = [];
                for (let i = 0; i < values.centre.length; i++) {
                    array.push({
                        clinicID: { eq: values.centre[i] }
                    });
                }
                filter.and.push({
                    or: array
                });
            }

            const seatDetails = {
                pagination: {
                    limit: props.pageSize,
                    offset: props.offset
                    // orderby: "name ASC"
                },
                filter: filter
            };
            // console.log("seatDetails", seatDetails);
            const result = await API.graphql({
                query: listSeat,
                variables: seatDetails,
                authMode: "AMAZON_COGNITO_USER_POOLS"
            });
            // console.log("result", result);
            const data = result.data.result;
            // console.log("data", data);
            setTable({
                ...table,
                data: data.result,
                loading: data.result.length === 0 ? false : true,
                pagination: {
                    ...table.pagination,
                    pageSize: props.pageSize,
                    current: props.current,
                    offset: props.offset,
                    total:
                        props.offset >= data.count ? data.count + 1 : data.count // keeps the last pagination if it is the last record
                }
            });
        } catch (error) {
            console.log("error", error);
            notification.error({
                message: "Unable to retrieve records"
            });
            setTable({
                ...table,
                loading: false
            });
        }
    };

    const getMachineList = async () => {
        // console.log("values", values);
        setTable({
            ...table,
            loading: true
        });
        try {
            let filter = {
                deleted: { eq: 0 }
            };

            const machineDetails = {
                pagination: {
                    orderby: "name ASC"
                },
                filter: filter
            };
            // console.log("machineDetails", machineDetails);
            const result = await API.graphql({
                query: listMachine,
                variables: machineDetails,
                authMode: "AMAZON_COGNITO_USER_POOLS"
            });
            // console.log(result);
            const data = result.data.result;
            // console.log("data", data);

            setMachineList(data.result);
        } catch (error) {
            notification.error({
                message: "Unable to retrieve records"
            });
            setTable({
                ...table,
                loading: false
            });
        }
    };

    const _addSeat = async (values) => {
        // console.log('_updateSeat', values);
        setLoadingModal(true);
        try {
            const variable = {
                create: {
                    name: values.seatNo,
                    machineID: values.machine,
                    clinicID: values.centre,
                    status: values.status.toUpperCase()
                }
            };

            // console.log("_updateSeat", variable);
            const result = await API.graphql({
                query: createSeat,
                variables: variable,
                authMode: "AMAZON_COGNITO_USER_POOLS"
            });
            // console.log(result);

            notification.success({
                message: "Created successfully"
            });
            handleModalVisibility("addSeat");
            getSeatList(table.pagination, filter);
        } catch (error) {
            console.log("error", error);
            notification.error({
                message: "Unable to create record"
            });
        } finally {
            setLoadingModal(false);
        }
    };

    const _updateSeat = async (values) => {
        // console.log('_updateSeat', values);
        setLoadingModal(true);
        try {
            const variable = {
                update: {
                    id: parseInt(values.id),
                    name: values.seatNo,
                    machineID: values.machine,
                    status: values.status.toUpperCase()
                }
            };

            // console.log("_updateSeat", variable);
            const result = await API.graphql({
                query: updateSeat,
                variables: variable,
                authMode: "AMAZON_COGNITO_USER_POOLS"
            });
            // console.log(result);

            notification.success({
                message: "Updated successfully"
            });
            handleModalVisibility("updateSeat");
            getSeatList(table.pagination, filter);
        } catch (error) {
            console.log("error", error);
            // console.log(error.errors[0].message);
            if (
                error.errors[0].message ==
                "MachineStatus expired. Or Machine not AVAILABLE"
            ) {
                notification.error({
                    message: "MachineStatus expired. Or Machine not AVAILABLE"
                });
            } else {
                notification.error({
                    message: "Unable to update record"
                });
            }
        } finally {
            setLoadingModal(false);
        }
    };

    const _deleteStaff = async (id) => {
        // console.log("handle delete", id);
        setTable({
            ...table,
            loading: true
        });

        let variables = {
            update: {
                id,
                deleted: 1
            }
        };

        try {
            const result = await API.graphql({
                query: updateSeat,
                variables: variables,
                authMode: "AMAZON_COGNITO_USER_POOLS"
            });
            // console.log("result", result);
            notification.success({
                message: "Deleted successfully"
            });
            getSeatList(table.pagination, filter);
        } catch (error) {
            console.log("error", error);
            notification.error({
                message: "Unable to delete record"
            });
        } finally {
            setTable({
                ...table,
                loading: false
            });
        }
    };

    const onFinishSearch = () => {
        // console.log("submit formSearch");
        // console.log(formSearch.getFieldsValue());
        let values = formSearch.getFieldsValue();
        setFilter(values);
        getSeatList(
            {
                ...table.pagination,
                offset: 0,
                current: 1
            },
            values
        );
    };

    const onFinishAddSeat = async (values) => {
        // console.log("onFinishAddSeat", values);
        await formAdd.validateFields();
        setLoadingModal(true);

        _addSeat(values);
    };

    const onFinishUpdateSeat = async (values) => {
        // console.log("onFinishUpdateSeat", values);
        await formUpdate.validateFields();
        // console.log('error');
        // setLoadingModal(true)
        // const timer = setTimeout(() => {
        //     setLoadingModal(false)
        //     clearTimeout(timer)
        //     handleModalVisibility("updateSeat")
        // }, 1000)
        _updateSeat(values);
    };

    const handleModalVisibility = (modal, record) => {
        // console.log("modal", modal);
        setSelectedClinicID(-1);
        switch (modal) {
            case "addSeat":
                formAdd.resetFields();
                setVisibilityAddSeat(!visibilityAddSeat);
                break;
            case "updateSeat":
                setEditSeat(record ? record : {});
                setSelectedClinicID(record?.clinicID);
                setVisibilityUpdateSeat(!visibilityUpdateSeat);
                break;
            default:
        }
    };

    const handleTreeSelectChange = (value, label, extra) => {
        // console.log("value", value);
        // console.log("label", label);
        // console.log("extra", extra);
    };

    const handleCentreSelectChange = (value) => {
        // console.log('handleCentreSelectChange', value);

        setSelectedClinicID(value);
    };

    const handleTableChange = (paginate) => {
        // console.log("handleTableChange", paginate)
        getSeatList(
            {
                ...table.pagination,
                current: paginate.current,
                pageSize: paginate.pageSize,
                offset:
                    paginate.current * table.pagination.pageSize -
                    table.pagination.pageSize
            },
            filter
        );
    };

    const renderTreeSelectOptions = (array) => {
        if (typeof array == "object" && array.length !== 0) {
            const listOptions = array.map((item, index) => {
                return (
                    <TreeNode title={item.name} value={item.id} key={item.id} />
                );
            });

            return listOptions;
        } else {
            return <div />;
        }
    };

    const columns = [
        {
            title: "Centre",
            dataIndex: "centreName"
        },
        {
            // title: "Seat No.",
            // dataIndex: "seatNo"
            title: "Seat Name",
            dataIndex: "name"
        },
        {
            title: "Machine Name",
            dataIndex: "machineName"
        },
        {
            title: "Machine ID",
            dataIndex: "machineSerialNo"
        },
        {
            title: "Status",
            dataIndex: "status"
        },
        {
            title: "Actions",
            dataIndex: "actions",
            render: (_, record) => {
                return (
                    <Space size="large">
                        <div
                            className="table-row-action clickable"
                            onClick={() =>
                                handleModalVisibility("updateSeat", record)
                            }
                        >
                            <div className="icon">
                                <img src={iconEdit} />
                            </div>
                            <div className="text">Edit</div>
                        </div>
                        <Popconfirm
                            title="Are you sure?"
                            onConfirm={() => _deleteStaff(record.id)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <div className="table-row-action clickable red">
                                <div className="icon">
                                    <img src={iconMinus} />
                                </div>
                                <div className="text">Delete</div>
                            </div>
                        </Popconfirm>
                    </Space>
                );
            }
        }
    ];

    return (
        <div className="content-content">
            <div className="manage-header">
                <div className="container-rows">
                    <div className="row1">
                        <div className="title">Manage Seats</div>
                        <Button
                            type="primary"
                            onClick={() => handleModalVisibility("addSeat")}
                        >
                            Add Seat
                        </Button>
                    </div>
                    <Form
                        form={formSearch}
                        layout="horizontal"
                        onFinish={onFinishSearch}
                        validateMessages={validateMessages}
                        requiredMark={false}
                    >
                        <div className="container-row">
                            <div className="row2">
                                <div className="row">
                                    <Form.Item
                                        key="centre"
                                        name="centre"
                                        rules={[
                                            {
                                                required: true
                                            }
                                        ]}
                                        style={{ minWidth: 430, width: "50%" }}
                                    >
                                        <TreeSelect
                                            showSearch
                                            allowClear
                                            multiple
                                            treeDefaultExpandAll
                                            treeCheckable
                                            placeholder="Select clinics"
                                            showCheckedStrategy={SHOW_PARENT}
                                            filterTreeNode={(input, treenode) =>
                                                treenode.title
                                                    .toLowerCase()
                                                    .indexOf(
                                                        input.toLowerCase()
                                                    ) >= 0
                                            }
                                            defaultValue={0}
                                            onChange={handleTreeSelectChange}
                                        >
                                            <TreeNode value={0} title="All">
                                                {clinicList !== -1
                                                    ? renderTreeSelectOptions(
                                                          clinicList
                                                      )
                                                    : ""}
                                            </TreeNode>
                                        </TreeSelect>
                                    </Form.Item>
                                </div>
                                <div className="row">
                                    <Form.Item
                                        className="display-none"
                                        name="searchTypeDaily"
                                    >
                                        <Input autoComplete="off" />
                                    </Form.Item>
                                    <Form.Item className="search" name="search">
                                        <Input
                                            autoComplete="off"
                                            prefix={<SearchOutlined />}
                                            placeholder="Search seat name"
                                            value={filter.search}
                                            allowClear
                                        />
                                    </Form.Item>
                                    <Button type="primary" htmlType="submit">
                                        Search
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
            <div className="manage-content">
                <Table
                    columns={columns}
                    rowKey={(record) => record.id}
                    rowClassName={(record, index) => {
                        // console.log("index", index);
                        return index % 2 === 0 ? "highlight" : "";
                    }}
                    loading={table.loading}
                    dataSource={display}
                    pagination={table.pagination}
                    onChange={handleTableChange}
                    // dataSource={[
                    //     {
                    //         id: "1",
                    //         centreID: 1,
                    //         centreName: "Centre ABC",
                    //         seatNo: "1",
                    //         machine: 1,
                    //         machineName: "Machine 123",
                    //         machineID: "rts123123123",
                    //         statusID: 1,
                    //         status: "Ready",
                    //     },
                    //     {
                    //         id: "2",
                    //         centreID: 2,
                    //         centreName: "Centre DEF",
                    //         seatNo: "1",
                    //         machine: 2,
                    //         machineName: "Machine 456",
                    //         machineID: "rts123123123",
                    //         statusID: 2,
                    //         status: "Needs Maintenance",
                    //     },
                    //     {
                    //         id: "3",
                    //         centreID: 3,
                    //         centreName: "Centre GHI",
                    //         seatNo: "1",
                    //         machine: 3,
                    //         machineName: "Machine 789",
                    //         machineID: "rts123123123",
                    //         statusID: 1,
                    //         status: "Ready",
                    //     },
                    // ]}
                />
            </div>
            <Modal
                title={<div className="modal-title">Edit Seat</div>}
                visible={visibilityUpdateSeat}
                onCancel={() => handleModalVisibility("updateSeat")}
                footer={[
                    <Button
                        key="back"
                        onClick={() => handleModalVisibility("updateSeat")}
                    >
                        Cancel
                    </Button>,
                    <Popconfirm
                        title="Are you sure?"
                        onConfirm={() =>
                            onFinishUpdateSeat(formUpdate.getFieldsValue())
                        }
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button
                            key="submit"
                            type="primary"
                            loading={loadingModal}
                        >
                            Update
                        </Button>
                    </Popconfirm>
                ]}
                className="modal-manage"
                width={800}
            >
                <div className="modal-content">
                    <Spin spinning={loadingModal}>
                        <Form
                            form={formUpdate}
                            layout="horizontal"
                            onFinish={onFinishUpdateSeat}
                            validateMessages={validateMessages}
                            requiredMark={false}
                        >
                            {renderInputItems([
                                {
                                    label: "id",
                                    name: "id",
                                    type: "input",
                                    placeholder: "id",
                                    required: true,
                                    hidden: true
                                },
                                {
                                    label: "Centre",
                                    name: "centre",
                                    type: "select",
                                    placeholder: "Select centre",
                                    handleSelect: handleCentreSelectChange,
                                    required: true,
                                    optionList: appState.clinicList
                                },
                                {
                                    label: "Seat No.",
                                    name: "seatNo",
                                    type: "input",
                                    placeholder: "Enter seat no",
                                    required: true
                                },
                                {
                                    label: "Machine Name",
                                    name: "machine",
                                    type: "select",
                                    placeholder: "Select machine",
                                    required: true,
                                    // optionList: appState.machineList,
                                    optionList: displayMachineList
                                        ? displayMachineList
                                        : []
                                },
                                {
                                    label: "Status",
                                    name: "status",
                                    type: "select",
                                    placeholder: "Select status",
                                    required: true,
                                    optionList: [
                                        { id: "AVAILABLE", name: "AVAILABLE" },
                                        {
                                            id: "NOTAVAILABLE",
                                            name: "NOT AVAILABLE"
                                        }
                                        // { id: 2, name: "Needs Maintenance" },
                                    ]
                                }
                            ])}
                        </Form>
                    </Spin>
                </div>
            </Modal>
            <Modal
                title={<div className="modal-title">Add Seat</div>}
                visible={visibilityAddSeat}
                onCancel={() => handleModalVisibility("addSeat")}
                footer={[
                    <Button
                        key="back"
                        onClick={() => handleModalVisibility("addSeat")}
                    >
                        Cancel
                    </Button>,
                    <Popconfirm
                        title="Are you sure"
                        onConfirm={() =>
                            onFinishAddSeat(formAdd.getFieldsValue())
                        }
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button
                            key="submit"
                            type="primary"
                            loading={loadingModal}
                        >
                            Add
                        </Button>
                    </Popconfirm>
                ]}
                className="modal-manage"
                width={800}
            >
                <div className="modal-content">
                    <Spin spinning={loadingModal}>
                        <Form
                            form={formAdd}
                            layout="horizontal"
                            onFinish={onFinishUpdateSeat}
                            validateMessages={validateMessages}
                            requiredMark={false}
                        >
                            {renderInputItems([
                                {
                                    label: "Centre",
                                    name: "centre",
                                    type: "select",
                                    placeholder: "Select centre",
                                    handleSelect: handleCentreSelectChange,
                                    required: true,
                                    optionList: appState.clinicList
                                        ? appState.clinicList
                                        : []
                                },
                                {
                                    label: "Seat No.",
                                    name: "seatNo",
                                    type: "input",
                                    placeholder: "Enter seat no",
                                    required: true
                                },
                                {
                                    label: "Machine Name",
                                    name: "machine",
                                    type: "select",
                                    placeholder: "Select machine",
                                    required: true,
                                    optionList: displayMachineList
                                        ? displayMachineList
                                        : []
                                },
                                {
                                    label: "Status",
                                    name: "status",
                                    type: "select",
                                    placeholder: "Select status",
                                    required: true,
                                    optionList: [
                                        { id: "AVAILABLE", name: "AVAILABLE" },
                                        {
                                            id: "NOTAVAILABLE",
                                            name: "NOT AVAILABLE"
                                        }
                                        // { id: 2, name: "Needs Maintenance" },
                                    ]
                                }
                            ])}
                        </Form>
                    </Spin>
                </div>
            </Modal>
        </div>
    );
};

export default ManageSeat;
