import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
// import { Form } from "antd"

import path from "../utils/pathSettings"
// import PatientRecordHeader from "./Component-PatientRecord-Header"
// import PatientRecordPreweights from "./Component-PatientRecord-Preweights"
// import PatientRecordDialysisOrder from "./Component-PatientRecord-DialysisOrder"
import PatientRecordAdministeredMedication from "./Component-PatientRecord-AdministeredMedication"
import PatientRecordDialysisHistory from "./Component-PatientRecord-DialysisHistory"
import PatientRecordMedLabMilestone from "./Component-PatientRecord-MedLabMilestone"
import PatientRecordClinicNHospitalE from "./Component-PatientRecord-ClinicNHospitalE"
import PatientRecordCalculatedLabResults from "./Component-PatientRecord-CalculatedLabResults"
import PatientRecordLabResults from "./Component-PatientRecord-LabResults"
import PatientRecordPostweightsDialysisOrder from "./Component-PatientRecord-PostweightsDialysisOrder"
import PatientRecordLastNonNullLab from "./Component-PatientRecord-LastNonNullLab"

const PatientDialysisCheckOut = (props) => {
    const history = useHistory()
    const patientID = props?.match?.params?.id;
    const dialysisID = props?.match?.params?.id2;
    const values = props?.history?.location?.state;
    const quickbook = values?.quickbook;

    useEffect(() => {
        // 1. dialysis cycle not finished ? check props for additional notes : proceed
        // 2. additional notes does not exist ? redirect to dialysis page : proceed
        // console.log('PatientDialysisCheckOut', props);
        // console.log(values, quickbook);

        if (props.location.state === undefined || props.location.state.checkOutException === undefined) {
            history.push(path('patientDialysis', [patientID, dialysisID]))
        }

        return () => {}
    }, [])

    // useEffect(() => {
    //     form.setFieldsValue(values)
    // }, [values])

    // const onFinish = () => {
    //     console.log("Handle check in clicked");
    // }

    return (
        <div className="content-content">
            <div className="container-row">
                <div className="view-desktop view-desktop-md">
                    <PatientRecordPostweightsDialysisOrder page={"checkout"} patientID={patientID} dialysisID={dialysisID} values={values} quickbook={quickbook} />
                    <PatientRecordDialysisHistory patientID={patientID} />
                    <div className="importlabresult-content">
                        <PatientRecordLastNonNullLab patientID={props?.match?.params?.id}  />
                        <br />
                    </div>
                    <PatientRecordMedLabMilestone patientID={patientID} />
                    <PatientRecordAdministeredMedication patientID={patientID} />
                    <PatientRecordClinicNHospitalE patientID={patientID} />
                    <PatientRecordCalculatedLabResults patientID={patientID} />
                    <PatientRecordLabResults patientID={patientID} />
                </div>
            </div>
        </div>
    )
}

export default PatientDialysisCheckOut