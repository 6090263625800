import React, { useContext, useEffect, useState } from "react";
import { Button, Form, Input, Modal, notification, Spin } from "antd";

import PatientRecordHeader from "./Component-PatientRecord-Header";
import PatientRecordPostweights from "./Component-PatientRecord-Postweights";
import PatientRecordDialysisOrder from "./Component-PatientRecord-DialysisOrder";
import { AppContext } from "../contexts/AppContext";
import moment from "moment";
import path from "../utils/pathSettings";
import { useHistory } from "react-router-dom";
import { listDialysis } from "../backend/graphql/queries";
import { API, Auth } from "aws-amplify";
import {
    checkoutDialysis,
    checkoutDialysis2,
    checkoutDialysis3,
    updateDialysis
} from "../backend/graphql/mutations";

const PatientRecordPostweightsDialysisOrder = (props) => {
    const { appState, actionSetQuickBookRedirect } = useContext(AppContext);
    const {
        page,
        patientID,
        appointmentID,
        dialysisID,
        values: _values,
        quickbook
    } = props;
    // const { notes } = props?.location?.state || { notes: "" }
    const history = useHistory();
    const [form] = Form.useForm();
    const [form2] = Form.useForm();
    const { TextArea } = Input;
    const [values, setValues] = useState({
        sessionMonth: "",
        preWt: "",
        lastWt: "",
        dw: "",

        preTemp: "",
        preEdema: "",
        preSBP: "",
        preDBP: "",
        preHRT: "",

        postWt: "",
        postTemp: "",
        postEdema: "",
        postSBP: "",
        postDBP: "",
        postHRT: "",
        ufTargetAtStart: "",
        // wtGain: "",
        clinicID: "",
        machineID: "",
        dialyser: "",
        msa: "",
        membraneUse: "",
        renalinPrecheck: "",

        dialysisOrderID: ""
    });
    const [dialysisOrderID, setDialysisOrderID] = useState(0);
    const [patientDisplay, setPatientDisplay] = useState({});
    const validateMessages = {
        required: "Required."
    };
    const [visibilityModal, setVisibilityModal] = useState(false);
    const [dialysis, setDialysis] = useState({});
    const [loading, setLoading] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false);
    const [checkOutException, setCheckOutException] = useState(false);

    useEffect(() => {
        // console.log("PROPS", props);
        if (props.values?.checkOutException === true) {
            setCheckOutException(true);
        }
    }, []);

    useEffect(() => {
        // console.log('PatientRecordPostweightsDialysisOrder', props);
        if (Object.keys(appState.cognitoUser).length !== 0) {
            // console.log(quickbook, _values);
            if (quickbook != undefined && quickbook.length > 0) {
                // setValues(_values);
                form.setFieldsValue({ ..._values });
                form2.setFieldsValue({
                    additionalNotes: _values.additionalNotes
                });
                onFinishConfirm(quickbook, _values);
            } else {
                _listDialysis(patientID, dialysisID);
            }
        }
        return () => {};
    }, [appState.cognitoUser]);

    useEffect(() => {
        // console.log(values);
        form.setFieldsValue(values);
        // form.validateFields()
    }, [values]);

    const onFinish = async () => {
        // console.log("Handle check out clicked");
        let v = await form.validateFields();
        // console.log(v);
        if (page === "checkoutEdit") {
            // console.log("Update check out details");
            // == Call update dialysis API here ==
            _updateDialysis(patientID, dialysisID, v);
        } else {
            setVisibilityModal(true);
        }
    };

    const onFinishConfirm = async (url = null, _values = null) => {
        // console.log("Handle check out clicked", url);
        // _updateDialysis(patientID, form.getFieldValue());
        await form2.validateFields();

        if ((!url || url.length == 0) && !appState.useRefreshToken) {
            // console.log('redirect', window.location.hostname, path('redirectQuickbooks'))
            actionSetQuickBookRedirect(patientID, dialysisID, {
                ...form2.getFieldValue(),
                ...form.getFieldValue()
            });

            let locURL = window.location.href.split("//");
            let domainURL = locURL[1].split("/")[0];
            // let redirect_uri = (window.location.hostname == 'localhost' ? `http://localhost:3000` : window.location.href.split('//')[0]) + path('redirectQuickbooks');
            let redirect_uri =
                locURL[0] + "//" + domainURL + path("redirectQuickbooks") + "/";
            // console.log('redirectURL', redirect_uri);

            window.location.assign(
                `https://appcenter.intuit.com/connect/oauth2?client_id=${process.env.REACT_APP_QUICKBOOKS_CLIENTID}&response_type=code&scope=com.intuit.quickbooks.accounting&redirect_uri=${redirect_uri}&state=testState`
            );
        } else {
            // console.log('PatientRecordPostweightsDialysisOrder after quickbook', _values, url)
            if (_values) {
                _checkoutDialysis(patientID, _values, _values, url);
            } else {
                _checkoutDialysis(
                    patientID,
                    form.getFieldValue(),
                    form2.getFieldValue(),
                    url
                );
            }
        }
        // _checkoutDialysis(patientID, form.getFieldValue(), form2.getFieldValue(), url);
    };

    const getWeight = (weight) => {
        if (!weight) return null;

        let weightString = weight.toString();
        let weightResult;

        // check if is decimal
        if (weightString.includes(".")) {
            weightResult = parseFloat(weightString) * 1000;
        }
        // check if within 999 (kg)
        else if (weightString.length <= 3) {
            weightResult = parseInt(weightString) * 1000;
        }
        // otherwise more than 4 character
        else {
            weightResult = parseInt(weightString);
        }

        // console.log('getWight', weightString, weightResult);

        return parseInt(weightResult);
    };

    const _listDialysis = async (patientID, dialysisID) => {
        setLoading(true);
        try {
            const variables = {
                filter: {
                    patientID: {
                        eq: patientID
                    },
                    id: {
                        eq: dialysisID
                    }
                }
            };

            // console.log(variables);

            const result = await API.graphql({
                query: listDialysis,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables
            });

            // console.log("API result PatientRecordPostweightsDialysisOrder _listDialysis", result);

            const data = result.data.result;
            const array = result.data.result.result;

            // process data
            let r = { ...array[0] };
            r.lastWeight = r.lastWeight
                ? (parseInt(r.lastWeight) / 1000.0).toFixed(1)
                : "";
            r.preWeight =
                parseInt(r.preWeight) >= 0
                    ? (parseInt(r.preWeight) / 1000.0).toFixed(1)
                    : null;
            r.postWeight =
                parseInt(r.postWeight) >= 0
                    ? (parseInt(r.postWeight) / 1000.0).toFixed(1)
                    : null;
            r.dryWeight =
                parseInt(r.dryWeight) >= 0
                    ? (parseInt(r.dryWeight) / 1000.0).toFixed(1)
                    : null;

            r.preTemp =
                parseFloat(r.preTemp) >= 0
                    ? parseFloat(r.preTemp).toFixed(1)
                    : null;
            r.postTemp =
                parseFloat(r.postTemp) >= 0
                    ? parseFloat(r.postTemp).toFixed(1)
                    : null;

            r.timestamp = r.startTime
                ? moment
                      .utc(r.startTime)
                      .local()
                      .format("DD MMM YYYY, HH:mm:ss")
                : "";
            r.postEdema = r?.postEdema?.toString();
            r.postSBP = r?.postSBP?.toString();
            r.postDBP = r?.postDBP?.toString();
            r.postHRT = r?.postHRT?.toString();
            r.KTVonline = r?.KTVonline?.toString();

            r.postSBPDBP = r.postSBP + " / " + r.postDBP;

            // save result to values
            // setDialysis(r);
            let newResult = {
                ...values,
                ...r,
                preWt: r.preWeight,
                postWt: r.postWeight,
                lastWt: r.lastWeight,
                dw: r.dryWeight,

                ufTargetAtStart: r.ufTargetStart,
                renalinPrecheck: r.preRenalin,
                renalinPostcheck: r.postRenalin,
                expectedDWDifference:
                    r.preWeight - r.ufTargetStart - r.dryWeight,
                expectedLWDifference:
                    r.preWeight - r.ufTargetStart - r.lastWeight,

                postEdema: r.postEdema ? r.postEdema.toString() : r.postEdema,
                postSBP: r.postSBP ? r.postSBP.toString() : r.postSBP,
                postDBP: r.postDBP ? r.postDBP.toString() : r.postDBP,
                postHRT: r.postHRT ? r.postHRT.toString() : r.postHRT,
                KTVonline: r.KTVonline ? r.KTVonline.toString() : r.KTVonline
            };
            // console.log(newResult", newResult);
            setValues(newResult);

            setLoading(false);
        } catch (error) {
            console.log("error: ", error);
            notification.error({
                message: "Unable to retrieve dialysis record"
            });
            setLoading(false);
        } finally {
        }
    };

    const _updateDialysis = async (patientID, dialysisID, values) => {
        setLoading(true);
        try {
            const variables = {
                update: {
                    id: parseInt(dialysisID),
                    postWeight: getWeight(values?.postWt) ?? null,

                    postTemp:
                        parseFloat(values.postTemp) >= 0
                            ? parseFloat(values.postTemp)
                            : null,
                    postEdema:
                        parseInt(values.postEdema) >= 0
                            ? parseInt(values.postEdema)
                            : null,
                    postSBP:
                        parseInt(values.postSBP) >= 0
                            ? parseInt(values.postSBP)
                            : null,
                    postDBP:
                        parseInt(values.postDBP) >= 0
                            ? parseInt(values.postDBP)
                            : null,
                    postHRT:
                        parseInt(values.postHRT) >= 0
                            ? parseInt(values.postHRT)
                            : null,
                    // postRenalin: values.renalinPostcheck,
                    KTVonline:
                        Number(values.KTVonline) >= 0
                            ? Number(values.KTVonline)
                            : null,
                    endTime: moment().toISOString().split(".")[0]
                }
            };

            // if (notes && notes.length > 0) {
            //     variables.update.notes = notes;
            // }

            let user = await Auth.currentAuthenticatedUser();
            // console.log(user);
            variables.update.endedBy = user.username;

            variables.update = Object.entries(variables.update).reduce(
                (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
                {}
            );

            // console.log(variables);

            const result = await API.graphql({
                query: updateDialysis,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables
            });
            // console.log("API result updateDialysis", result);

            const data = result.data.result;
            const array = result.data.result.result;

            // console.log('updateDialysis', data, array);

            setLoading(false);
            history.push(path("dashboard"));

            return data;

            // console.log(_labReport);
        } catch (error) {
            console.log("error: ", error);
            notification.error({
                message: "Unable to retrieve Dialysis"
            });
        } finally {
        }
    };

    const _checkoutDialysis = async (
        patientID,
        values,
        values2,
        quickbookURL
    ) => {
        setLoading(true);
        setLoadingModal(true);
        try {
            let locURL = window.location.href.split("//");
            let domainURL = locURL[1].split("/")[0];
            let redirect_uri =
                locURL[0] + "//" + domainURL + path("redirectQuickbooks") + "/";

            const variables = {
                update: {
                    id: parseInt(dialysisID),
                    patientID: parseInt(patientID),

                    clinicID: parseInt(values.clinicID),
                    preWeight: getWeight(values.preWt) ?? null,
                    lastWeight: getWeight(values.lastWt) ?? null,
                    dryWeight: getWeight(values.dw) ?? null,
                    postWeight: getWeight(values.postWt),

                    preTemp:
                        parseFloat(values.preTemp) >= 0
                            ? parseFloat(values.preTemp)
                            : null,
                    preEdema:
                        parseInt(values.preEdema) >= 0
                            ? parseInt(values.preEdema)
                            : null,
                    preSBP:
                        parseInt(values.preSBP) >= 0
                            ? parseInt(values.preSBP)
                            : null,
                    preDBP:
                        parseInt(values.preDBP) >= 0
                            ? parseInt(values.preDBP)
                            : null,
                    preHRT:
                        parseInt(values.preHRT) >= 0
                            ? parseInt(values.preHRT)
                            : null,
                    // preRenalin: values.renalinPrecheck,
                    ufTargetStart: parseFloat(values.ufTargetAtStart) || null,

                    machineID: parseInt(values.machineID) || null,
                    dialyser: values.dialyser || null,
                    MSA: values.MSA ? parseFloat(values.MSA) : null,
                    membraneUse: values.membraneUse,

                    dialysisOrderID: parseInt(dialysisOrderID),

                    postTemp:
                        parseFloat(values.postTemp) >= 0
                            ? parseFloat(values.postTemp)
                            : null,
                    postEdema:
                        parseInt(values.postEdema) >= 0
                            ? parseInt(values.postEdema)
                            : null,
                    postSBP:
                        parseInt(values.postSBP) >= 0
                            ? parseInt(values.postSBP)
                            : null,
                    postDBP:
                        parseInt(values.postDBP) >= 0
                            ? parseInt(values.postDBP)
                            : null,
                    postHRT:
                        parseInt(values.postHRT) >= 0
                            ? parseInt(values.postHRT)
                            : null,
                    postRenalin: values.renalinPostcheck,
                    KTVonline:
                        Number(values.KTVonline) >= 0
                            ? Number(values.KTVonline)
                            : null
                    // endTime: moment().toISOString().split('.')[0],
                },
                quickbookURL: quickbookURL ?? "",
                redirectURL: redirect_uri
            };

            // console.log("values2", values2);

            if (
                values2?.additionalNotes !== undefined &&
                values2?.additionalNotes !== null &&
                values2?.additionalNotes !== ""
            ) {
                variables.update.notes = values2.additionalNotes;
            }

            // if (notes && notes.length > 0) {
            //     variables.update.notes = notes;
            // }

            // let user = await Auth.currentAuthenticatedUser();
            // // console.log(user);
            // variables.update.endedBy = user.username;

            variables.update = Object.entries(variables.update).reduce(
                (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
                {}
            );

            // console.log('checkoutDialysis variables', variables);

            const result = await API.graphql({
                query: appState.useRefreshToken
                    ? checkoutDialysis3
                    : checkoutDialysis,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables
            });
            // console.log("API result checkoutDialysis", result);

            const data = result.data.result;
            // const array = result.data.result.result
            const parseData = JSON.parse(data);
            const invoiceURL = parseData?.invoiceURL;
            const qbMessage = parseData?.qbMessage;

            // console.log('checkoutDialysis', data);
            // console.log(parseData, invoiceURL);

            if (invoiceURL) window.open(invoiceURL, "_blank"); //.focus();
            else if (qbMessage) {
                notification.warning({
                    message:
                        "Quickbooks Invoice not created. Quickbooks authentication failed."
                });
            }

            history.push(path("patientRecord", [patientID]));

            setLoading(false);
            // return data;

            // console.log(_labReport);
        } catch (error) {
            console.log("error: ", error);
            if (
                error &&
                error.errors.length > 0 &&
                error.errors[0].message === "Quickbooks token expired."
            ) {
                setVisibilityModal(false);
                notification.error({
                    message:
                        "QuickBooks token expired. Please sign in to QuickBooks and Refresh",
                    duration: null
                });

                // let locURL = window.location.href.split('//');
                // let domainURL = locURL[1].split('/')[0];
                // // let redirect_uri = (window.location.hostname == 'localhost' ? `http://localhost:3000` : window.location.href.split('//')[0]) + path('redirectQuickbooks');
                // let redirect_uri = locURL[0] + '//' + domainURL + path('redirectQuickbooks') + '/';
                // // console.log('redirectURL', redirect_uri);

                // window.open(`https://appcenter.intuit.com/connect/oauth2?client_id=${process.env.REACT_APP_QUICKBOOKS_CLIENTID}&response_type=code&scope=com.intuit.quickbooks.accounting&redirect_uri=${redirect_uri}&state=testState`, '_blank');
            } else {
                notification.error({
                    message: "Unable to checkout Dialysis"
                });
            }
        } finally {
            setLoadingModal(false);
            setLoading(false);
        }
    };

    return (
        <Spin spinning={loading}>
            <Form
                form={form}
                layout="horizontal"
                validateMessages={validateMessages}
                requiredMark={true}
            >
                <PatientRecordHeader
                    onFinish={onFinish}
                    page={page}
                    patientID={patientID}
                    setPatientDisplay={setPatientDisplay}
                />
                <div className="patientrecord-checkin">
                    <PatientRecordPostweights
                        values={values}
                        setValues={setValues}
                        onFinish={onFinish}
                        page={page}
                        patientID={props?.patientID}
                        form={form}
                        checkOutException={checkOutException}
                        patientDisplay={patientDisplay}
                    />
                    <PatientRecordDialysisOrder
                        patientID={props?.patientID}
                        values={values}
                        setValues={setValues}
                        dialysisOrderID={dialysisOrderID}
                        setDialysisOrderID={setDialysisOrderID}
                    />
                </div>
                <Modal
                    title={<div className="modal-title">Check Out Patient</div>}
                    visible={visibilityModal}
                    onCancel={() => setVisibilityModal(false)}
                    footer={[
                        <Button
                            key="cancel"
                            className="cancel"
                            onClick={() => setVisibilityModal(false)}
                        >
                            Cancel
                        </Button>,
                        <Button
                            key="checkout"
                            type="primary"
                            className="okay"
                            onClick={() => onFinishConfirm()}
                            loading={loadingModal}
                        >
                            Check Out
                        </Button>
                    ]}
                    className="modal-patientrecord-checkout"
                >
                    <Spin spinning={loadingModal}>
                        <div style={{ fontWeight: 700 }}>
                            Are you sure you want to check out this patient?
                        </div>
                        <br />
                        <div
                            style={{
                                fontWeight: 700,
                                textTransform: "uppercase"
                            }}
                        >
                            Make sure the post data is accurate.
                        </div>
                        <br />
                        <Form
                            form={form2}
                            layout="vertical"
                            onFinish={onFinishConfirm}
                            validateMessages={validateMessages}
                            requiredMark={false}
                        >
                            <Form.Item
                                label="Additional Notes"
                                name="additionalNotes"
                                rules={[
                                    {
                                        required: checkOutException
                                    }
                                ]}
                                className={
                                    checkOutException === true
                                        ? "additionalnotes"
                                        : null
                                }
                            >
                                <TextArea rows={3} />
                            </Form.Item>
                        </Form>
                    </Spin>
                </Modal>
            </Form>
        </Spin>
    );
};

export default PatientRecordPostweightsDialysisOrder;
