import React, { forwardRef, useEffect, useRef, useState } from "react"
import { Button, Divider, Empty, notification, Spin, Table } from "antd"
import { PrinterOutlined } from '@ant-design/icons'
import { useReactToPrint } from "react-to-print"
import PatientRecordExportPatientSummary from "./Component-Patient-Record-Export-PatientSummary"
import PatientRecordExportPatientDialysisHistory from "./Component-Patient-Record-Export-PatientDialysisHistory"

const PatientRecordExport = (props) => {
    const componentRef = useRef()

    const [tableLastLab, setTableLastLab] = useState({
        data: -1,
        pagination: {
            current: 1,
            pageSize: 20,
            offset: 0
        },
        loading: false,
    })
    const [tableDiaylsisHistorySummary, setTableDialysisHistorySummary] = useState({
        data: -1,
        pagination: {
            current: 1,
            pageSize: 20,
            offset: 0
        },
        loading: false,
    })
    const [tableCurrentMedication, setTableCurrentMedication] = useState({
        data: -1,
        pagination: {
            current: 1,
            pageSize: 20,
            offset: 0
        },
        loading: false,
    })
    const [tableDialysisHistory, setTableDialysisHistory] = useState({
        data: -1,
        pagination: {
            current: 1,
            pageSize: 20,
            offset: 0
        },
        loading: false,
    })
    const [tableMedicalMilestone, setTableMedicalMilestone] = useState({
        data: -1,
        pagination: {
            current: 1,
            pageSize: 20,
            offset: 0
        },
        loading: false,
    })
    const [displayLastLab, setDisplayLastLab] = useState([])
    const [displayDialysisHistorySummary, setDisplayDialysisHistorySummary] = useState([])
    const [displayCurrentMedication, setDisplayCurrentMedication] = useState([])
    const [displayDialysisHistory, setDisplayDialysisHistory] = useState([])
    const [displayMedicalMilestone, setDisplayMedicalMilestone] = useState([])

    const [latestClinicalNote, setLatestClinicalNote] = useState(-1)
    const [dialysisOrder, setDialysisOrder] = useState(-1)
    const [loadingLatestClinicalNote, setLoadingLatestClinicalNote] = useState(false)
    const [loadingDialysisOrder, setLoadingDialysisOrder] = useState(false)
    const [patient, setPatient] = useState(-1)

    const getLastLabRecord = (id) => {
        setTableLastLab({
            ...tableLastLab,
            loading: true,
        })
        try {
            const timer = setTimeout(() => {
                setTableLastLab({
                    ...tableLastLab,
                    loading: true,
                    data: [
                        {
                            glucose: "10",
                            tw: "10",
                            Plt: "10",
                            Hb: "10",
                            MCV: "10",
                            K: "10",
                            Na: "10",
                            ureaPre: "10",
                            ureaPost: "10",
                            creatinine: "10",
                            HCO3: "10",
                            chlorine: "10",
                            Ca: "10",
                            Po4: "10",
                            IPTH: "10",
                            HepBsAg: "10",
                            HepBsAb: "10",
                            _two: "10",
                            Fe: "10",
                            TIBC: "10",
                            ferritin: "10",
                            protein: "10",
                            Alb: "10",
                            Ublood: "10",
                            Urbc: "10",
                            Uprt: "10",
                            Uwbc: "10",
                            TUP24: "10",
                            TUP12: "10",
                            Uvol: "10",
                            CCT: "10",
                            uric: "10",
                            HCV: "10",
                            HIV: "10",
                            ggt: "10",
                            images: "10",
                            others: "10",
                            othersDetails: "10",
                            TC: "10",
                            HDL: "10",
                            LDL: "10",
                            TG: "10",
                            Ratio: "10",
                            Timing: "10",
                            Biltotal: "10",
                            SAP: "10",
                            SGPT_ALT: "10",
                            SGOT_AST: "10",
                            Hba1c: "10",
                            labsNoted: "10",
                            SBP: "10",
                            DBP: "10",
                            Mg: "10",
                            Tac: "10",
                            urineMA: "10",
                            MACr: "10",
                            uCreatinine: "10",
                            ESR: "10",
                            CRP: "10",
                            CNI: "10",
                            Myco: "10",
                            Pred: "10",
                            noted: "10",
                            t4: "10",
                            FT4: "10",
                            TSH: "10",
                            gis: "10",
                            hte: "10",
                        },
                    ]
                })
                clearTimeout(timer)
            }, 1000)
        }
        catch (error) {
            console.log("error", error);
            notification.error({
                message: "Unable to retrieve last lab"
            })
            setTableLastLab({
                ...tableLastLab,
                loading: false
            })
        }
    }

    const getDialysisHistorySummaryRecords = (id) => {
        setTableDialysisHistorySummary({
            ...tableDiaylsisHistorySummary,
            loading: true,
        })
        try {
            const timer = setTimeout(() => {
                setTableDialysisHistorySummary({
                    ...tableDiaylsisHistorySummary,
                    loading: true,
                    data: [
                        {
                            id: 4,
                            month: "4",
                            total: "10",
                            dw: "10",
                            wtLast: "10",
                            wtPre: "10",
                            wtPost: "10",
                            ufTarget: "10",
                            wtOffTarget: "10",
                            sbpPost: "10",
                            dbpPost: "10",
                            ktv: "10",
                        },
                        {
                            id: 3,
                            month: "3",
                            total: "10",
                            dw: "10",
                            wtLast: "10",
                            wtPre: "10",
                            wtPost: "10",
                            ufTarget: "10",
                            wtOffTarget: "10",
                            sbpPost: "10",
                            dbpPost: "10",
                            ktv: "10",
                        },
                    ]
                })
                clearTimeout(timer)
            }, 1000)
        }
        catch (error) {
            console.log("error", error);
            notification.error({
                message: "Unable to retrieve dialysis history summary"
            })
            setTableDialysisHistorySummary({
                ...tableDiaylsisHistorySummary,
                loading: false
            })
        }
    }

    const getCurrentMedicationRecords = (id) => {
        setTableCurrentMedication({
            ...tableCurrentMedication,
            loading: true,
        })
        try {
            const timer = setTimeout(() => {
                setTableCurrentMedication({
                    ...tableCurrentMedication,
                    loading: true,
                    data: [
                        {
                            dateStart: "DD MMM YYYY",
                            medication: "Medication ABC 10mg",
                            route: "po",
                            freq: "3x/wk",
                        },
                        {
                            dateStart: "DD MMM YYYY",
                            medication: "Medication ABC 10mg",
                            route: "po",
                            freq: "3x/wk",
                        },
                        {
                            dateStart: "DD MMM YYYY",
                            medication: "Medication ABC 10mg",
                            route: "po",
                            freq: "3x/wk",
                        },
                        {
                            dateStart: "DD MMM YYYY",
                            medication: "Medication ABC 10mg",
                            route: "po",
                            freq: "3x/wk",
                        },
                    ]
                })
                clearTimeout(timer)
            }, 1000)
        }
        catch (error) {
            console.log("error", error);
            notification.error({
                message: "Unable to retrieve current medication"
            })
            setTableCurrentMedication({
                ...tableCurrentMedication,
                loading: false
            })
        }
    }

    const getDiaysisHistoryRecords = (id) => {
        setTableDialysisHistory({
            ...tableDialysisHistory,
            loading: true,
        })
        try {
            const timer = setTimeout(() => {
                setTableDialysisHistory({
                    ...tableDialysisHistory,
                    loading: true,
                    data: [
                        {
                            id: 3,
                            timestampDisplay: "DD MMM YYYY",
                            sessionMonth: "3",
                            QB: "20",
                            AP: "20",
                            VP: "20",
                            DFLOW: "20",
                            CDT: "20",
                            lastWeight: "20",
                            preEdema: "20",
                            postEdema: "20",
                            preWeight: "20",
                            dryWeight: "20",
                            ufTargetStart: "20",
                            postWeight: "20",
                            offTarget: "20",
                            postSBPDBP: "20",
                            SBP: "20",
                            DBP: "20",
                            HR: "20",
                            temp: "20",
                            preTemp: "20",
                            postTemp: "20",
                            MSA: "20",
                            dialyser: "20",
                            KTVonline: "20",
                            notes: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
                            displaySymptoms: "20",
                        },
                        {
                            id: 2,
                            timestampDisplay: "DD MMM YYYY",
                            sessionMonth: "2",
                            QB: "20",
                            AP: "20",
                            VP: "20",
                            DFLOW: "20",
                            CDT: "20",
                            lastWeight: "20",
                            preEdema: "20",
                            postEdema: "20",
                            preWeight: "20",
                            dryWeight: "20",
                            ufTargetStart: "20",
                            postWeight: "20",
                            offTarget: "20",
                            postSBPDBP: "20",
                            SBP: "20",
                            DBP: "20",
                            HR: "20",
                            temp: "20",
                            preTemp: "20",
                            postTemp: "20",
                            MSA: "20",
                            dialyser: "20",
                            KTVonline: "20",
                            notes: "20",
                            displaySymptoms: "20",
                        },
                        {
                            id: 1,
                            timestampDisplay: "DD MMM YYYY",
                            sessionMonth: "1",
                            QB: "20",
                            AP: "20",
                            VP: "20",
                            DFLOW: "20",
                            CDT: "20",
                            lastWeight: "20",
                            preEdema: "20",
                            postEdema: "20",
                            preWeight: "20",
                            dryWeight: "20",
                            ufTargetStart: "20",
                            postWeight: "20",
                            offTarget: "20",
                            postSBPDBP: "20",
                            SBP: "20",
                            DBP: "20",
                            HR: "20",
                            temp: "20",
                            preTemp: "20",
                            postTemp: "20",
                            MSA: "20",
                            dialyser: "20",
                            KTVonline: "20",
                            notes: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.",
                            displaySymptoms: "20",
                        },
                    ]
                })
                clearTimeout(timer)
            }, 1000)
        }
        catch (error) {
            console.log("error", error);
            notification.error({
                message: "Unable to retrieve current medication"
            })
            setTableCurrentMedication({
                ...tableCurrentMedication,
                loading: false
            })
        }
    }

    const getLatestClinicalNoteRecord = (id) => {
        setLoadingLatestClinicalNote(true)
        try {
            const timer = setTimeout(() => {
                setLatestClinicalNote("This is the latest clinical note. Blah blah blah, 123, abc, hahahaha.")
                clearTimeout(timer)
            }, 1000)
        }
        catch (error) {
            console.log("error", error);
            notification.error({
                message: "Unable to retrieve latest clinical note"
            })
            setLoadingLatestClinicalNote(false)
        }
    }

    const getDialysisOrderRecord = (id) => {
        setLoadingDialysisOrder(true)
        try {
            const timer = setTimeout(() => {
                setDialysisOrder({
                    msa: "2.1",
                    dorderDate: "21 Apr 2021",
                    freqmth: "12",
                    duration: "240",
                    hepload: "2000",
                    hepcont: "1000",
                    antioOthers: "n/a",
                    dialysate: "LC",
                    primaryAccess: "AVF LEF",
                    aNeedle: "16",
                    vNeedle: "+2",
                    bicarbonate: "+2",
                    temp: "35",
                    qd: "500",
                    qb: "3",
                    dw: "16",
                })
                clearTimeout(timer)
            }, 1000)
        }
        catch (error) {
            console.log("error", error);
            notification.error({
                message: "Unable to retrieve dilysis order"
            })
            setLoadingDialysisOrder(false)
        }
    }

    const getMedicalMilestoneRecords = (id) => {
        setTableMedicalMilestone({
            ...tableMedicalMilestone,
            loading: true,
        })
        try {
            const timer = setTimeout(() => {
                setTableMedicalMilestone({
                    ...tableMedicalMilestone,
                    loading: true,
                    data: [
                        {
                            id: "6",
                            date: "DD MMM YYYY",
                            milestone: "This is a milestone 6 abc 123 xyz 789. This is a milestone 6 abc 123 xyz 789. This is a milestone 6 abc 123 xyz 789. This is a milestone 6 abc 123 xyz 789.",
                            note: "This is some additional notes for this milestone 6."
                        },
                        {
                            id: "5",
                            date: "DD MMM YYYY",
                            milestone: "This is a milestone 5 abc 123 xyz 789.",
                            note: "This is some additional notes for this milestone 5. This is some additional notes for this milestone 5. This is some additional notes for this milestone 5. This is some additional notes for this milestone 5."
                        },
                        {
                            id: "4",
                            date: "DD MMM YYYY",
                            milestone: "This is a milestone 4 abc 123 xyz 789.",
                            note: "This is some additional notes for this milestone 4."
                        },
                        {
                            id: "3",
                            date: "DD MMM YYYY",
                            milestone: "This is a milestone 3 abc 123 xyz 789.",
                            note: "This is some additional notes for this milestone 3."
                        },
                        {
                            id: "2",
                            date: "DD MMM YYYY",
                            milestone: "This is a milestone 2 abc 123 xyz 789.",
                            note: "This is some additional notes for this milestone 2."
                        },
                        {
                            id: "1",
                            date: "DD MMM YYYY",
                            milestone: "This is a milestone 1 abc 123 xyz 789.",
                            note: "This is some additional notes for this milestone 1."
                        },
                    ]
                })
                clearTimeout(timer)
            }, 1000)
        }
        catch (error) {
            console.log("error", error);
            notification.error({
                message: "Unable to retrieve medical milestones"
            })
            setTableMedicalMilestone({
                ...tableMedicalMilestone,
                loading: false
            })
        }
    }

    const columnsLastLabPart1 = [
        {
            title: "Hb",
            dataIndex: "Hb",
            render(text, record) {
                let outOfRange = parseFloat(text) <= 10;
                return {
                    props: {
                        style: { color: outOfRange ? "red" : "", fontWeight: outOfRange ? "bold" : "normal" }
                    },
                    children: text
                };
            }
        },
        {
            title: "MCV",
            dataIndex: "MCV"
        },
        {
            title: "TW",
            dataIndex: "tw"
        },
        {
            title: "Plt",
            dataIndex: "Plt"
        },
        {
            title: "K",
            dataIndex: "K",
            render(text, record) {
                let outOfRange = parseFloat(text) >= 5.5;
                return {
                    props: {
                        style: { color: outOfRange ? "red" : "", fontWeight: outOfRange ? "bold" : "normal" }
                    },
                    children: text
                };
            }
        },
        {
            title: "HCO3",
            dataIndex: "HCO3",
            render(text, record) {
                let outOfRange = parseFloat(text) < 20;
                return {
                    props: {
                        style: { color: outOfRange ? "red" : "", fontWeight: outOfRange ? "bold" : "normal" }
                    },
                    children: text
                };
            }
        },
        {
            title: "Na",
            dataIndex: "Na"
        },
        {
            title: "Cl",
            dataIndex: "chlorine"
        },
        {
            title: "Creatinine",
            dataIndex: "creatinine"
        },
        {
            title: "Glucose",
            dataIndex: "glucose"
        },
        {
            title: "CCT",
            dataIndex: "CCT"
        },
        {
            title: "Ca",
            dataIndex: "Ca",
            render(text, record) {
                let outOfRange = parseFloat(text) <= 2;
                return {
                    props: {
                        style: { color: outOfRange ? "red" : "", fontWeight: outOfRange ? "bold" : "normal" }
                    },
                    children: text
                };
            }
        },
        {
            title: "Po4",
            dataIndex: "Po4",
            render(text, record) {
                let outOfRange = parseFloat(text) >= 1.7;
                return {
                    props: {
                        style: { color: outOfRange ? "red" : "", fontWeight: outOfRange ? "bold" : "normal" }
                    },
                    children: text
                };
            }
        },
        {
            title: "iPTH",
            dataIndex: "IPTH"
        },
        {
            title: "Urea Pre",
            dataIndex: "ureaPre"
        },
        {
            title: "Urea Post",
            dataIndex: "ureaPost"
        },
        {
            title: "HepBsAg",
            dataIndex: "HepBsAg",
            render(text, record) {
                let outOfRange = text?.toLowerCase()?.includes('pos') || text?.toLowerCase() === ('weak reactive') || text?.toLowerCase() === ('reactive');
                return {
                    props: {
                        style: { color: outOfRange ? "red" : "", fontWeight: outOfRange ? "bold" : "normal" }
                    },
                    children: text
                };
            },
        },
        {
            title: "HepBsAb",
            dataIndex: "HepBsAb"
        },
        {
            title: "HCV",
            dataIndex: "HCV",
            render(text, record) {
                let outOfRange = text?.toLowerCase()?.includes('pos') || text?.toLowerCase() === ('reactive');
                return {
                    props: {
                        style: { color: outOfRange ? "red" : "", fontWeight: outOfRange ? "bold" : "normal" }
                    },
                    children: text
                };
            },
        },
        {
            title: "HIV",
            dataIndex: "HIV",
            render(text, record) {
                let outOfRange = text?.toLowerCase()?.includes('pos') || text?.toLowerCase() === ('reactive');
                return {
                    props: {
                        style: { color: outOfRange ? "red" : "", fontWeight: outOfRange ? "bold" : "normal" }
                    },
                    children: text
                };
            },
        },
        {
            title: "Fe",
            dataIndex: "Fe"
        },
        {
            title: "TIBC",
            dataIndex: "TIBC"
        },
        {
            title: "Ferritin",
            dataIndex: "ferritin",
            render(text, record) {
                let outOfRange = parseFloat(text) >= 700;
                return {
                    props: {
                        style: { color: outOfRange ? "red" : "", fontWeight: outOfRange ? "bold" : "normal" }
                    },
                    children: text
                };
            }
        },
        {
            title: "ESR",
            dataIndex: "ESR"
        },
        {
            title: "CRP",
            dataIndex: "CRP"
        },
        {
            title: "uricAcid",
            dataIndex: "uric"
        },
        {
            title: "TC",
            dataIndex: "TC"
        },
        {
            title: "HDL",
            dataIndex: "HDL"
        },
        {
            title: "LDL",
            dataIndex: "LDL"
        },
        {
            title: "TG",
            dataIndex: "TG"
        },
    ]

    const columnsLastLabPart2 = [
        {
            title: "Ratio",
            dataIndex: "Ratio"
        },
        {
            title: "Hba1c",
            dataIndex: "Hba1c",
            render(text, record) {
                let outOfRange = parseFloat(text) >= 7;
                return {
                    props: {
                        style: { color: outOfRange ? "red" : "", fontWeight: outOfRange ? "bold" : "normal" }
                    },
                    children: text
                };
            }
        },
        {
            title: "Protein",
            dataIndex: "protein"
        },
        {
            title: "Alb",
            dataIndex: "Alb"
        },
        {
            title: "Biltotal",
            dataIndex: "Biltotal"
        },
        {
            title: "Sap",
            dataIndex: "SAP"
        },
        {
            title: "Ast",
            dataIndex: "SGOT_AST"
        },
        {
            title: "Alt",
            dataIndex: "SGPT_ALT"
        },
        {
            title: "Ggt",
            dataIndex: "ggt"
        },
        {
            title: "Mg",
            dataIndex: "Mg"
        },
        {
            title: "Gis",
            dataIndex: "gis"
        },
        {
            title: "Hte",
            dataIndex: "hte"
        },
        {
            title: "Timing",
            dataIndex: "Timing"
        },
        {
            title: "TUP 24 Hrs",
            dataIndex: "TUP24"
        },
        {
            title: "TUP 12 Hrs",
            dataIndex: "TUP12"
        },
        {
            title: "Uvol",
            dataIndex: "Uvol"
        },
        {
            title: "Ublood",
            dataIndex: "Ublood"
        },
        {
            title: "Urbc",
            dataIndex: "Urbc"
        },
        {
            title: "Uprt",
            dataIndex: "Uprt"
        },
        {
            title: "Uwbc",
            dataIndex: "Uwbc"
        },
        {
            title: "Urine MA",
            dataIndex: "urineMA"
        },
        {
            title: "MACr",
            dataIndex: "MACr"
        },
        {
            title: "U creatinine",
            dataIndex: "uCreatinine"
        },
        {
            title: "Tac",
            dataIndex: "Tac"
        },
        {
            title: "CNI",
            dataIndex: "CNI"
        },
        {
            title: "Myco",
            dataIndex: "Myco"
        },
        {
            title: "Pred",
            dataIndex: "Pred"
        },
    ]

    const columnsDialysisHistorySummary = [
        {
            title: "MONTH",
            dataIndex: "month"
        },
        {
            title: "TOTAL",
            dataIndex: "total"
        },
        {
            title: "DW",
            dataIndex: "dw"
        },
        {
            title: "WT Last",
            dataIndex: "wtLast"
        },
        {
            title: "WT (Pre)",
            dataIndex: "wtPre"
        },
        {
            title: "WT (Post)",
            dataIndex: "wtPost"
        },
        {
            title: "UF Target",
            dataIndex: "ufTarget"
        },
        {
            title: "WT off Target",
            dataIndex: "wtOffTarget"
        },
        {
            title: "SBP (Post)",
            dataIndex: "sbpPost"
        },
        {
            title: "DBP (Post)",
            dataIndex: "dbpPost"
        },
        {
            title: "KTV",
            dataIndex: "ktv"
        },
    ]

    const columnsCurrentMedication = [
        {
            title: "Start Date",
            dataIndex: "dateStart"
        },
        {
            title: "Medication",
            dataIndex: "medication"
        },
        {
            title: "Route",
            dataIndex: "route"
        },
        {
            title: "Frequency",
            dataIndex: "freq"
        },
    ]

    const columnsDialysisHistoryPart1 = [
        {
            title: "Date / Time",
            dataIndex: "timestampDisplay",
            width: 150
        },
        {
            title: "SE",
            dataIndex: "sessionMonth",
        },
        {
            title: "QB",
            dataIndex: "QB",
        },
        {
            title: "AP",
            dataIndex: "AP"
        },
        {
            title: "VP",
            dataIndex: "VP"
        },
        {
            title: "FLOW",
            dataIndex: "DFLOW"
        },
        {
            title: "COND",
            dataIndex: "CDT"
        },
        {
            title: "LAST WT",
            dataIndex: "lastWeight",
        },
        {
            title: "Pre Edema",
            dataIndex: "preEdema"
        },
        {
            title: "Post Edema",
            dataIndex: "postEdema"
        },
        {
            title: "PRE WT",
            dataIndex: "preWeight",
        },
        {
            title: "DW",
            dataIndex: "dryWeight",
        },
        {
            title: "UFT",
            dataIndex: "ufTargetStart"
        },
        // ===
        {
            title: "POST WT",
            dataIndex: "ufTargetStart",
        },
        {
            title: "OFF Target",
            dataIndex: "offTarget",
        },
        {
            title: "Post SBP / DBP",
            dataIndex: "postSBPDBP",
        },
        {
            title: "SBP",
            dataIndex: "SBP"
        },
        {
            title: "DBP",
            dataIndex: "DBP"
        },
        {
            title: "HR",
            dataIndex: "HR"
        },
    ]

    const columnsDialysisHistoryPart2 = [
        {
            title: "Date / Time",
            dataIndex: "timestampDisplay",
            width: 120
        },
        {
            title: "Temp",
            dataIndex: "temp"
        },
        {
            title: "Temp Admit",
            dataIndex: "preTemp",
        },
        {
            title: "Temp Discharge",
            dataIndex: "postTemp",
        },
        {
            title: "MSA",
            dataIndex: "MSA"
        },
        {
            title: "Dialyser",
            dataIndex: "dialyser"
        },
        {
            title: "KTV Online",
            dataIndex: "KTVonline"
        },
        {
            title: "Dialysis Note",
            dataIndex: "notes",
            width: 450,
            render(text, record) {
                return {
                    props: {
                        style: { display: "inline-block", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }
                    },
                    children: text
                };
            }
        },
        {
            title: "Symptoms",
            dataIndex: "displaySymptoms",
        },
    ]

    const renderHeaderItems = (array) => {
        const items = array.map((item, index) => {
            // console.log("item", item);
            const width = item.width !== undefined ? item.width : null

            return (
                // <div key={item.dataIndex} className={item.dataIndex !== "action" ? "column" : ""} style={width !== null ? { width: width } : {}}>
                <div key={item.dataIndex} className={"column"} style={width !== null ? { width: width } : {}}>
                    {item.title}
                </div>
            )
        })
        return items
    }

    const renderCardResultHeader = (array) => {
        return (
            (array.length === 0) ? (
                ""
            ) : (
                renderHeaderItems(array)
            )
        )
    }

    const renderItems = (columns, array) => {
        if (Object.keys(array).length > 0) {
            const items = array.map((item, index) => {
                const renderItemColumns = (columns, rowItem) => {
                    // console.log("columns", columns);
                    // console.log("rowItem", rowItem);
                    const renderRowItems = columns.map((columnItem, index) => {
                        // console.log("columns item", columnItem);
                        const width = columnItem.width !== undefined ? columnItem.width : null
                        let value = ""
                        let style = {};
                        for (const key in rowItem) {
                            // console.log("key", key);
                            // console.log("value", rowItem[key]);
                            if (columnItem.dataIndex === key) {
                                value = rowItem[key]
                                if (columnItem?.render) {
                                    style = columnItem?.render(value, rowItem)?.props?.style
                                }
                                break
                            }
                        }
                        return (
                            // <div key={columnItem.dataIndex} className={columnItem.dataIndex === "datetime" ? "column date" : (columnItem.dataIndex !== "action" ? "column" : "")} style={width !== null ? { width: width } : {}}>
                            <div key={columnItem.dataIndex} className={columnItem.dataIndex === "datetime" ? "column date" : "column"} style={width !== null ? { width: width, ...style } : { ...style }}>
                                {value}
                            </div>
                        )
                    })
                    return renderRowItems
                }

                return (
                    <div key={item.id} className={index % 2 === 0 ? "record highlight" : "record"}>
                        {
                            renderItemColumns(columns, item)
                        }
                    </div>
                )
            })
            return items
        } else {
            return null
        }
    }

    const renderCardResult = (columns, array) => {
        return (
            (array.length === 0) ? (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            ) : (
                renderItems(columns, array)
            )
        )
    }

    const renderDisplayDescriptionItems = (array) => {
        const items = array.map((item, index) => {
            return (
                <div className="item">
                    <div className="item-label">{item.label}</div>
                    <div className="item-value">{item.value}</div>
                </div>
            )
        })
        return items
    }

    const renderDisplayValueItems = (array) => {
        const items = array.map((item, index) => {
            return (
                <div className="item-medicalmilestone">
                    <div className="milestone">{item.date}: {item.milestone}</div>
                    <div className="note">{item.note}</div>
                </div>
            )
        })
        return items
    }

    const ComponentToPrint = forwardRef((props, ref) => {
        // console.log("props", props);
        // console.log("ref", ref);

        return (
            <div ref={ref} className="export-patientsummary">
                <style type="text/css">
                    {"@media print{@page {size: landscape}}"}
                </style>
                {/* <style type="text/css" media="print">{"\
                @page {\ size: landscape;\ }\
                "}</style> */}
                <div className="container-border row">
                    <div className="column flex" style={displayMedicalMilestone.length > 2 ? { paddingRight: "10px", borderRight: "1px solid #000000" } : null}>
                        {renderDisplayValueItems(displayMedicalMilestone.filter((item, index) => index < 2))}
                    </div>
                    <div className={displayMedicalMilestone.length > 2 ? "column flex" : ""} style={displayMedicalMilestone.length > 4 ? { paddingRight: "10px", borderRight: "1px solid #000000" } : null}>
                        {renderDisplayValueItems(displayMedicalMilestone.filter((item, index) => index >= 2 && index < 4))}
                    </div>
                    <div className={displayMedicalMilestone.length > 4 ? "column flex" : ""}>
                        {renderDisplayValueItems(displayMedicalMilestone.filter((item, index) => index >= 4 && index < 6))}
                    </div>
                </div>
                <div className="row">
                    <div className="container-border row">
                        <div className="column" style={{ paddingRight: "10px", borderRight: "1px solid #000000" }}>
                            {
                                renderDisplayDescriptionItems([
                                    {
                                        label: "iDpt",
                                        value: "-1231321",
                                    },
                                    {
                                        label: "Shift",
                                        value: "1NN",
                                    },
                                    {
                                        label: "PRINT",
                                        value: "DD MMM YYYY, HH:mm",
                                    },
                                    {
                                        label: "CURRENT DC",
                                        value: "-1231321",
                                    },
                                    {
                                        label: "Relation",
                                        value: "1NN",
                                    },
                                    {
                                        label: "Years ESRD",
                                        value: "2.5",
                                    },
                                ])
                            }
                        </div>
                        <div className="column">
                            {
                                renderDisplayDescriptionItems([
                                    {
                                        label: "DESRD",
                                        value: "DD MMM YYYY",
                                    },
                                    {
                                        label: "DOB",
                                        value: "DD MMM YYYY",
                                    },
                                    {
                                        label: "GENDER",
                                        value: "F",
                                    },
                                    {
                                        label: "Age At Print * yrs",
                                        value: "57.2",
                                    },
                                ])
                            }
                        </div>
                    </div>
                    <div className="container-table flex">
                        <div className="row">
                            <div className="title">Last Individual Lab</div>
                            &nbsp;&nbsp;&nbsp;
                            <div className="subtitle">Date Lab: 01 Apr 2022</div>
                        </div>
                        <Spin spinning={tableLastLab.loading}>
                            <div className="card lastlab">
                                <div className="card-header">
                                    {
                                        renderCardResultHeader(columnsLastLabPart1)
                                    }
                                </div>
                                <div className="card-content">
                                    <div className="record-content">
                                        {
                                            renderCardResult(columnsLastLabPart1, displayLastLab)
                                        }
                                    </div>
                                </div>
                            </div>
                        </Spin>
                        <Spin spinning={tableLastLab.loading}>
                            <div className="card lastlab">
                                <div className="card-header">
                                    {
                                        renderCardResultHeader(columnsLastLabPart2)
                                    }
                                </div>
                                <div className="card-content">
                                    <div className="record-content">
                                        {
                                            renderCardResult(columnsLastLabPart2, displayLastLab)
                                        }
                                    </div>
                                </div>
                            </div>
                        </Spin>
                        {/* <Table
                            columns={columnsLastLabPart1}
                            rowKey={record => record.id}
                            loading={tableLastLab.loading}
                            rowClassName={(record, index) => {
                                // console.log("index", index);
                                return index % 2 === 0 ? "highlight" : ""
                            }}
                            dataSource={displayLastLab}
                            key={record => record.id}
                            pagination={false}
                        />
                        <Table
                            columns={columnsLastLabPart2}
                            rowKey={record => record.id}
                            loading={tableLastLab.loading}
                            rowClassName={(record, index) => {
                                // console.log("index", index);
                                return index % 2 === 0 ? "highlight" : ""
                            }}
                            dataSource={displayLastLab}
                            key={record => record.id}
                            pagination={false}
                        /> */}
                    </div>
                </div>
                <div className="row">
                    <div className="column container-table">
                        <div className="container-table">
                            <div className="title">Dialysis Summary</div>
                            <Spin spinning={tableDiaylsisHistorySummary.loading}>
                                <div className="card">
                                    <div className="card-header">
                                        {
                                            renderCardResultHeader(columnsDialysisHistorySummary)
                                        }
                                    </div>
                                    <div className="card-content">
                                        <div className="record-content">
                                            {
                                                renderCardResult(columnsDialysisHistorySummary, displayDialysisHistorySummary)
                                            }
                                        </div>
                                    </div>
                                </div>
                            </Spin>
                        </div>
                        <div className="container-table">
                            <div className="title">Dialysis History Part 1</div>
                            <Spin spinning={tableDialysisHistory.loading}>
                                <div className="card">
                                    <div className="card-header">
                                        {
                                            renderCardResultHeader(columnsDialysisHistoryPart1)
                                        }
                                    </div>
                                    <div className="card-content">
                                        <div className="record-content">
                                            {
                                                renderCardResult(columnsDialysisHistoryPart1, displayDialysisHistory)
                                            }
                                        </div>
                                    </div>
                                </div>
                            </Spin>
                        </div>
                        <div className="container-table">
                            <div className="title">Dialysis History Part 2</div>
                            <Spin spinning={tableDiaylsisHistorySummary.loading}>
                                <div className="card">
                                    <div className="card-header">
                                        {
                                            renderCardResultHeader(columnsDialysisHistoryPart2)
                                        }
                                    </div>
                                    <div className="card-content">
                                        <div className="record-content">
                                            {
                                                renderCardResult(columnsDialysisHistoryPart2, displayDialysisHistory)
                                            }
                                        </div>
                                    </div>
                                </div>
                            </Spin>
                        </div>
                    </div>
                    <div className="column flex">
                        <div className="container-table">
                            <div className="title">Current Medication</div>
                            <Spin spinning={tableCurrentMedication.loading}>
                                <div className="card currentmed">
                                    <div className="card-header">
                                        {
                                            renderCardResultHeader(columnsCurrentMedication)
                                        }
                                    </div>
                                    <div className="card-content">
                                        <div className="record-content">
                                            {
                                                renderCardResult(columnsCurrentMedication, displayCurrentMedication)
                                            }
                                        </div>
                                    </div>
                                </div>
                            </Spin>
                        </div>
                        <div style={{ marginTop: "10px", fontWeight: "700" }}>
                            Last Clinical Note
                        </div>
                        <Spin spinning={loadingLatestClinicalNote}>
                            <div className="container-border row">
                                {/* <div className="container-border"> */}
                                <div className="column" style={{ borderRight: "1px solid #000000" }}>
                                    {/* <div className="column" style={{ borderBottom: "1px solid #000000" }}> */}
                                    {
                                        renderDisplayDescriptionItems([
                                            {
                                                label: "LAST 3MTHS",
                                                value: "DD MMM YYYY",
                                            },
                                            {
                                                label: "NAMECODE",
                                                value: "MAYNOTEXIST",
                                            },
                                        ])
                                    }
                                </div>
                                <div className="column clinicalnote">
                                    {latestClinicalNote !== -1 ? latestClinicalNote : ""}
                                </div>
                            </div>
                        </Spin>
                        <Spin spinning={loadingDialysisOrder}>
                            <div className="container-border row">
                                <div className="column dialysisorder" style={{ paddingRight: "10px", borderRight: "1px solid #000000" }}>
                                    {
                                        renderDisplayDescriptionItems([
                                            {
                                                label: "MSA",
                                                value: dialysisOrder.msa,
                                            },
                                            {
                                                label: "DORDER",
                                                value: dialysisOrder.dorderDate
                                            },
                                            {
                                                label: "FREQMTH",
                                                value: dialysisOrder.freqmth,
                                            },
                                            {
                                                label: "Duration",
                                                value: dialysisOrder.duration,
                                            },
                                            {
                                                label: "HEP (load)",
                                                value: dialysisOrder.hepload
                                            },
                                            {
                                                label: "HEP (cont)",
                                                value: dialysisOrder.hepcont
                                            },
                                            {
                                                label: "COAG (Others)",
                                                value: dialysisOrder.antioOthers,
                                            },
                                            {
                                                label: "Dialysate (%)",
                                                value: dialysisOrder.dialysate,
                                            },
                                        ])
                                    }
                                </div>
                                <div className="column dialysisorder">
                                    {
                                        renderDisplayDescriptionItems([
                                            {
                                                label: "Primary Access",
                                                value: dialysisOrder.primaryAccess,
                                            },
                                            {
                                                label: "BICARBONATE",
                                                value: dialysisOrder.bicarbonate
                                            },
                                            {
                                                label: "'A' Needle",
                                                value: dialysisOrder.aNeedle,
                                            },
                                            {
                                                label: "'V' Needle",
                                                value: dialysisOrder.vNeedle,
                                            },
                                            {
                                                label: <>Temp (&#8451;)</>,
                                                value: dialysisOrder.temp
                                            },
                                            {
                                                label: "QD",
                                                value: dialysisOrder.qd
                                            },
                                            {
                                                label: "QB",
                                                value: dialysisOrder.qb,
                                            },
                                            {
                                                label: "DW",
                                                value: dialysisOrder.dw,
                                            },
                                        ])
                                    }
                                </div>
                            </div>
                        </Spin>
                    </div>
                </div>
            </div>
        )
    })

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: "Patient - ",
    })
    const [enablePrint, setEnablePrint] = useState(false)

    useEffect(() => {
        getLastLabRecord(props.match.params.id)
        getDialysisHistorySummaryRecords(props.match.params.id)
        getCurrentMedicationRecords(props.match.params.id)
        getDiaysisHistoryRecords(props.match.params.id)
        getLatestClinicalNoteRecord(props.match.params.id)
        getDialysisOrderRecord(props.match.params.id)
        getMedicalMilestoneRecords(props.match.params.id)
    }, [])

    useEffect(() => {
        if (tableLastLab.data !== -1) {
            setDisplayLastLab(tableLastLab.data)
            setTableLastLab({
                ...tableLastLab,
                loading: false
            })
        }
    }, [tableLastLab.data])

    useEffect(() => {
        if (tableDiaylsisHistorySummary.data !== -1) {
            setDisplayDialysisHistorySummary(tableDiaylsisHistorySummary.data)
            setTableDialysisHistorySummary({
                ...tableDiaylsisHistorySummary,
                loading: false
            })
        }
    }, [tableDiaylsisHistorySummary.data])

    useEffect(() => {
        if (tableCurrentMedication.data !== -1) {
            setDisplayCurrentMedication(tableCurrentMedication.data)
            setTableCurrentMedication({
                ...tableCurrentMedication,
                loading: false
            })
        }
    }, [tableCurrentMedication.data])

    useEffect(() => {
        if (tableDialysisHistory.data !== -1) {
            setDisplayDialysisHistory(tableDialysisHistory.data)
            setTableDialysisHistory({
                ...tableDialysisHistory,
                loading: false
            })
        }
    }, [tableDialysisHistory.data])

    useEffect(() => {
        if (latestClinicalNote !== -1) {
            setLoadingLatestClinicalNote(false)
        }
    }, [latestClinicalNote])

    useEffect(() => {
        if (dialysisOrder !== -1) {
            setLoadingDialysisOrder(false)
        }
    }, [dialysisOrder])

    useEffect(() => {
        if (tableMedicalMilestone.data !== -1) {
            setDisplayMedicalMilestone(tableMedicalMilestone.data)
            setTableMedicalMilestone({
                ...tableMedicalMilestone,
                loading: false
            })
        }
    }, [tableMedicalMilestone.data])

    return (
        <div className="content-content">
            {/* <Button type="primary" onClick={handlePrint} disabled={!enablePrint}><PrinterOutlined />Print</Button> */}
            {/* <Button type="primary" onClick={handlePrint}><PrinterOutlined />Print</Button>
            <br /><br />
            <div style={{ border: "1px solid #000000", backgroundColor: "#FFFFFF" }}>
                <ComponentToPrint ref={componentRef} />
            </div> */}
            <PatientRecordExportPatientSummary patientID={props.match.params.id} patient={patient} setPatient={setPatient} />
            <Divider />
            <PatientRecordExportPatientDialysisHistory patientID={props.match.params.id} patient={patient} setPatient={setPatient} />
        </div>
    )
}

export default PatientRecordExport