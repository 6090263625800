import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import awsmobile from "./amplifyconfiguration.json";
import DataContextProvider from "./contexts/DataContext";
import AppContextProvider from "./contexts/AppContext";

import { Amplify } from "aws-amplify";

Amplify.configure(awsmobile);

ReactDOM.render(
    <React.StrictMode>
        <DataContextProvider>
            <AppContextProvider>
                <App />
            </AppContextProvider>
        </DataContextProvider>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
