// rename g,l
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAppointment = /* GraphQL */ `
  query GetAppointment($id: Int!) {
    result: getAppointment(id: $id) {
      id
      patientID
      clinicID
      seatID
      date
      shift
      quickbookInvoiceID
      time
      dialysisID
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const listAppointment = /* GraphQL */ `
  query ListAppointment(
    $filter: ListAppointmentFilterInput
    $pagination: PaginationInput
  ) {
    result: listAppointment(filter: $filter, pagination: $pagination) {
      result {
        id
        patientID
        clinicID
        seatID
        date
        shift
        quickbookInvoiceID
        time
        dialysisID
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const listAppointmentDetail = /* GraphQL */ `
  query ListAppointmentDetail(
    $filter: ListAppointmentDetailFilterInput
    $pagination: PaginationInput
  ) {
    result: listAppointmentDetail(filter: $filter, pagination: $pagination) {
      result {
        id
        patientID
        clinicID
        seatID
        date
        shift
        time
        dialysisID
        quickbookInvoiceID
        name
        nric
        key
        bucket
        region
        seatName
        dialysisStartedBy
        dialysisStartTime
        dialysisEndedBy
        dialysisEndTime
        lastDialysisIntervalID
        lastDialysisIntervalTimestamp
        dialysis {
          id
          patientID
          sessionMonth
          arriveTime
          startTime
          endTime
          dischargeTime
          accessDIALYSISREF
          startedBy
          endedBy
          clinicID
          preWeight
          lastWeight
          postWeight
          dryWeight
          height
          preTemp
          preEdema
          preSBP
          preDBP
          preHRT
          preRenalin
          ufTargetStart
          postTemp
          postEdema
          postSBP
          postDBP
          postHRT
          postRenalin
          KTVonline
          machineID
          dialyser
          MSA
          membraneUse
          dialysisOrderID
          notes
          lastUpdatedBy
          createdOn
          updatedOn
          deleted
          deletedOn
        }
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const getCalculatedLabResult = /* GraphQL */ `
  query GetCalculatedLabResult($id: Int!) {
    result: getCalculatedLabResult(id: $id) {
      id
      patientID
      clinicID
      timestamp
      dialysisID
      labReportID
      latest
      URRD
      KTVonline
      KTVe
      ureaPre
      ureaPost
      dryWeight
      UF
      MSA
      dialyser
      URR
      QB
      QD
      minQA
      maxQA
      dialysisArriveTime
      dialysisStartTime
      dialysisEndTime
      dialysisDischargeTime
      dialysisIntervalLastTime
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const listCalculatedLabResult = /* GraphQL */ `
  query ListCalculatedLabResult(
    $filter: ListCalculatedLabResultFilterInput
    $pagination: PaginationInput
  ) {
    result: listCalculatedLabResult(filter: $filter, pagination: $pagination) {
      result {
        id
        patientID
        clinicID
        timestamp
        dialysisID
        labReportID
        latest
        URRD
        KTVonline
        KTVe
        ureaPre
        ureaPost
        dryWeight
        UF
        MSA
        dialyser
        URR
        QB
        QD
        minQA
        maxQA
        dialysisArriveTime
        dialysisStartTime
        dialysisEndTime
        dialysisDischargeTime
        dialysisIntervalLastTime
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const getClinic = /* GraphQL */ `
  query GetClinic($id: Int!) {
    result: getClinic(id: $id) {
      id
      name
      uid
      phoneNo
      email
      address
      status
      noOfSeat
      qbClientID
      qbRealmID
      qbItemID
      qbTaxCodeID
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const listClinic = /* GraphQL */ `
  query ListClinic(
    $filter: ListClinicFilterInput
    $pagination: PaginationInput
  ) {
    result: listClinic(filter: $filter, pagination: $pagination) {
      result {
        id
        name
        uid
        phoneNo
        email
        address
        status
        noOfSeat
        qbClientID
        qbRealmID
        qbItemID
        qbTaxCodeID
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const listClinicSummary = /* GraphQL */ `
  query ListClinicSummary(
    $filter: ListClinicFilterInput
    $pagination: PaginationInput
  ) {
    result: listClinicSummary(filter: $filter, pagination: $pagination) {
      result {
        id
        name
        uid
        phoneNo
        email
        address
        status
        noOfSeat
        totalMedicalDirector
        totalManagingNurse
        totalDoctor
        totalNurse
        totalSeat
        totalMachine
        qbRealmID
        qbItemID
        qbTaxCodeID
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const getClinicStatus = /* GraphQL */ `
  query GetClinicStatus($id: Int!) {
    result: getClinicStatus(id: $id) {
      id
      name
      expiryDate
      nextAudit
      status
      remarks
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const listClinicStatus = /* GraphQL */ `
  query ListClinicStatus(
    $filter: ListClinicStatusFilterInput
    $pagination: PaginationInput
  ) {
    result: listClinicStatus(filter: $filter, pagination: $pagination) {
      result {
        id
        name
        expiryDate
        nextAudit
        status
        remarks
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const getDialysis = /* GraphQL */ `
  query GetDialysis($id: Int!) {
    result: getDialysis(id: $id) {
      id
      patientID
      sessionMonth
      arriveTime
      startTime
      endTime
      dischargeTime
      accessDIALYSISREF
      startedBy
      endedBy
      clinicID
      preWeight
      lastWeight
      postWeight
      dryWeight
      height
      preTemp
      preEdema
      preSBP
      preDBP
      preHRT
      preRenalin
      ufTargetStart
      postTemp
      postEdema
      postSBP
      postDBP
      postHRT
      postRenalin
      KTVonline
      machineID
      dialyser
      MSA
      membraneUse
      dialysisOrderID
      notes
      lastUpdatedBy
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const listDialysis = /* GraphQL */ `
  query ListDialysis(
    $filter: ListDialysisFilterInput
    $pagination: PaginationInput
  ) {
    result: listDialysis(filter: $filter, pagination: $pagination) {
      result {
        id
        patientID
        sessionMonth
        arriveTime
        startTime
        endTime
        dischargeTime
        accessDIALYSISREF
        startedBy
        endedBy
        clinicID
        preWeight
        lastWeight
        postWeight
        dryWeight
        height
        preTemp
        preEdema
        preSBP
        preDBP
        preHRT
        preRenalin
        ufTargetStart
        postTemp
        postEdema
        postSBP
        postDBP
        postHRT
        postRenalin
        KTVonline
        machineID
        dialyser
        MSA
        membraneUse
        dialysisOrderID
        notes
        lastUpdatedBy
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const listDialysisSummary = /* GraphQL */ `
  query ListDialysisSummary(
    $patientID: Int
    $startDate: String
    $endDate: String
    $timezoneOffsetMinutes: Int
  ) {
    result: listDialysisSummary(
      patientID: $patientID
      startDate: $startDate
      endDate: $endDate
      timezoneOffsetMinutes: $timezoneOffsetMinutes
    ) {
      patientID
      totalSession
      yearMonth
      dryWeightMin
      dryWeightMax
      dryWeightAverage
      lastWeightMin
      lastWeightMax
      lastWeightAverage
      preWeightMin
      preWeightMax
      preWeightAverage
      postWeightMin
      postWeightMax
      postWeightAverage
      ufTargetStartMin
      ufTargetStartMax
      ufTargetStartAverage
      offTargetMin
      offTargetMax
      offTargetAverage
      postSBPMin
      postSBPMax
      postSBPAverage
      postDBPMin
      postDBPMax
      postDBPAverage
      KTVonlineMin
      KTVonlineMax
      KTVonlineAverage
    }
  }
`;
export const getDialysisInterval = /* GraphQL */ `
  query GetDialysisInterval($id: Int!) {
    result: getDialysisInterval(id: $id) {
      id
      dialysisID
      clinicID
      timestamp
      staffAccountID
      accessNum
      AP
      VP
      SBP
      DBP
      HR
      CDT
      KTV
      cUF
      QB
      DFLOW
      UFR
      temp
      tmp
      hepRate
      note
      symptoms
      lastUpdatedBy
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const listDialysisInterval = /* GraphQL */ `
  query ListDialysisInterval(
    $filter: ListDialysisIntervalFilterInput
    $pagination: PaginationInput
  ) {
    result: listDialysisInterval(filter: $filter, pagination: $pagination) {
      result {
        id
        dialysisID
        clinicID
        timestamp
        staffAccountID
        accessNum
        AP
        VP
        SBP
        DBP
        HR
        CDT
        KTV
        cUF
        QB
        DFLOW
        UFR
        temp
        tmp
        hepRate
        note
        symptoms
        lastUpdatedBy
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const listDialysisIntervalByRef = /* GraphQL */ `
  query ListDialysisIntervalByRef(
    $filter: ListDialysisIntervalByRefFilterInput
    $pagination: PaginationInput
  ) {
    result: listDialysisIntervalByRef(filter: $filter, pagination: $pagination) {
      result {
        dialysisIntervalID
        dialysisID
        accessDIALYSISREF
      }
      count
    }
  }
`;
export const getDialysisOrder = /* GraphQL */ `
  query GetDialysisOrder($id: Int!) {
    result: getDialysisOrder(id: $id) {
      id
      uid
      staffAccountID
      patientID
      accessID
      MSA
      dOrder
      freqMth
      duration
      hepLoad
      hepCont
      antio
      dialysate
      accessPrim
      aNeedle
      vNeedle
      bicarbonate
      temp
      QD
      QB
      dryWeight
      notes
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const listDialysisOrder = /* GraphQL */ `
  query ListDialysisOrder(
    $filter: ListDialysisOrderFilterInput
    $pagination: PaginationInput
  ) {
    result: listDialysisOrder(filter: $filter, pagination: $pagination) {
      result {
        id
        uid
        staffAccountID
        patientID
        accessID
        MSA
        dOrder
        freqMth
        duration
        hepLoad
        hepCont
        antio
        dialysate
        accessPrim
        aNeedle
        vNeedle
        bicarbonate
        temp
        QD
        QB
        dryWeight
        notes
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const getDrug = /* GraphQL */ `
  query GetDrug($id: Int!) {
    result: getDrug(id: $id) {
      id
      name
      purpose
      manufacturer
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const listDrug = /* GraphQL */ `
  query ListDrug($filter: ListDrugFilterInput, $pagination: PaginationInput) {
    result: listDrug(filter: $filter, pagination: $pagination) {
      result {
        id
        name
        purpose
        manufacturer
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const getAllergy = /* GraphQL */ `
  query GetAllergy($id: Int!) {
    result: getAllergy(id: $id) {
      id
      patientID
      name
      knownDate
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const listAllergy = /* GraphQL */ `
  query ListAllergy(
    $filter: ListAllergyFilterInput
    $pagination: PaginationInput
  ) {
    result: listAllergy(filter: $filter, pagination: $pagination) {
      result {
        id
        patientID
        name
        knownDate
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const listFunderReport = /* GraphQL */ `
  query ListFunderReport(
    $clinicID: Int!
    $startDate: String!
    $endDate: String
    $timezoneOffsetMinutes: Int
  ) {
    result: listFunderReport(
      clinicID: $clinicID
      startDate: $startDate
      endDate: $endDate
      timezoneOffsetMinutes: $timezoneOffsetMinutes
    ) {
      id
      patientID
      sessionMonth
      arriveTime
      startTime
      endTime
      dischargeTime
      accessDIALYSISREF
      startedBy
      endedBy
      clinicID
      preWeight
      lastWeight
      postWeight
      dryWeight
      height
      preTemp
      preEdema
      preSBP
      preDBP
      preHRT
      preRenalin
      ufTargetStart
      postTemp
      postEdema
      postSBP
      postDBP
      postHRT
      postRenalin
      KTVonline
      machineID
      dialyser
      MSA
      membraneUse
      dialysisOrderID
      notes
      lastUpdatedBy
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const getHospitalReason = /* GraphQL */ `
  query GetHospitalReason($id: Int!) {
    result: getHospitalReason(id: $id) {
      id
      hospCode
      hospDetail
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const listHospitalReason = /* GraphQL */ `
  query ListHospitalReason(
    $filter: ListHospitalReasonFilterInput
    $pagination: PaginationInput
  ) {
    result: listHospitalReason(filter: $filter, pagination: $pagination) {
      result {
        id
        hospCode
        hospDetail
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const getHospitalSummary = /* GraphQL */ `
  query GetHospitalSummary($id: Int!) {
    result: getHospitalSummary(id: $id) {
      id
      accessIDHosp
      patientID
      clinicID
      staffAccountID
      staffName
      admitTimestamp
      type
      observation
      assessment
      plan
      notes
      leaveTimestamp
      noOfDays
      location
      reason
      hospitalMain
      hospitalReason
      diagnosis
      outcome
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const listHospitalSummary = /* GraphQL */ `
  query ListHospitalSummary(
    $filter: ListHospitalSummaryFilterInput
    $pagination: PaginationInput
  ) {
    result: listHospitalSummary(filter: $filter, pagination: $pagination) {
      result {
        id
        accessIDHosp
        patientID
        clinicID
        staffAccountID
        staffName
        admitTimestamp
        type
        observation
        assessment
        plan
        notes
        leaveTimestamp
        noOfDays
        location
        reason
        hospitalMain
        hospitalReason
        diagnosis
        outcome
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const listHospitalSummaryWithPatient = /* GraphQL */ `
  query ListHospitalSummaryWithPatient(
    $filter: ListHospitalSummaryWithPatientFilterInput
    $pagination: PaginationInput
    $groupBy: String
    $clinicID: Int
    $staffAccountID: String
  ) {
    result: listHospitalSummaryWithPatient(
      filter: $filter
      pagination: $pagination
      groupBy: $groupBy
      clinicID: $clinicID
      staffAccountID: $staffAccountID
    ) {
      result {
        id
        accessIDHosp
        patientID
        clinicID
        staffAccountID
        staffName
        admitTimestamp
        type
        observation
        assessment
        plan
        notes
        leaveTimestamp
        noOfDays
        location
        reason
        diagnosis
        outcome
        hospitalMain
        hospitalReason
        patientKey
        patientBucket
        patientRegion
        patientName
        patientNric
        patientExistingClinicID
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const getLabReport = /* GraphQL */ `
  query GetLabReport($id: Int!) {
    result: getLabReport(id: $id) {
      id
      patientID
      clinicID
      timestamp
      source
      accessIDlab
      name
      glucose
      TW
      Plt
      Hb
      MCV
      K
      Na
      ureaPre
      ureaPost
      creatinine
      HCO3
      chlorine
      Ca
      Po4
      IPTH
      HepBsAg
      HepBsAb
      _two
      Fe
      TIBC
      ferritin
      protein
      Alb
      Ublood
      Urbc
      Uprt
      Uwbc
      TUP24
      TUP12
      Uvol
      CCT
      uric
      HCV
      HIV
      ggt
      images
      others
      othersDetails
      TC
      HDL
      LDL
      TG
      Ratio
      Timing
      Biltotal
      SAP
      SGPT_ALT
      SGOT_AST
      Hba1c
      labsNoted
      SBP
      DBP
      Mg
      Tac
      urineMA
      MACr
      uCreatinine
      ESR
      CRP
      CNI
      Myco
      Pred
      noted
      t4
      FT4
      TSH
      gis
      hte
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const getLabReportLatestValue = /* GraphQL */ `
  query GetLabReportLatestValue($patientID: Int!) {
    result: getLabReportLatestValue(patientID: $patientID) {
      id
      patientID
      glucose
      glucose_timestamp
      TW
      TW_timestamp
      Plt
      Plt_timestamp
      Hb
      Hb_timestamp
      MCV
      MCV_timestamp
      K
      K_timestamp
      Na
      Na_timestamp
      ureaPre
      ureaPre_timestamp
      ureaPost
      ureaPost_timestamp
      creatinine
      creatinine_timestamp
      HCO3
      HCO3_timestamp
      chlorine
      chlorine_timestamp
      Ca
      Ca_timestamp
      Po4
      Po4_timestamp
      IPTH
      IPTH_timestamp
      HepBsAg
      HepBsAg_timestamp
      HepBsAb
      HepBsAb_timestamp
      _two
      _two_timestamp
      Fe
      Fe_timestamp
      TIBC
      TIBC_timestamp
      ferritin
      ferritin_timestamp
      protein
      protein_timestamp
      Alb
      Alb_timestamp
      Ublood
      Ublood_timestamp
      Urbc
      Urbc_timestamp
      Uprt
      Uprt_timestamp
      Uwbc
      Uwbc_timestamp
      TUP24
      TUP24_timestamp
      TUP12
      TUP12_timestamp
      Uvol
      Uvol_timestamp
      CCT
      CCT_timestamp
      uric
      uric_timestamp
      HCV
      HCV_timestamp
      HIV
      HIV_timestamp
      ggt
      ggt_timestamp
      images
      images_timestamp
      others
      others_timestamp
      othersDetails
      othersDetails_timestamp
      TC
      TC_timestamp
      HDL
      HDL_timestamp
      LDL
      LDL_timestamp
      TG
      TG_timestamp
      Ratio
      Ratio_timestamp
      Timing
      Timing_timestamp
      Biltotal
      Biltotal_timestamp
      SAP
      SAP_timestamp
      SGPT_ALT
      SGPT_ALT_timestamp
      SGOT_AST
      SGOT_AST_timestamp
      Hba1c
      Hba1c_timestamp
      labsNoted
      labsNoted_timestamp
      SBP
      SBP_timestamp
      DBP
      DBP_timestamp
      Mg
      Mg_timestamp
      Tac
      Tac_timestamp
      urineMA
      urineMA_timestamp
      MACr
      MACr_timestamp
      uCreatinine
      uCreatinine_timestamp
      ESR
      ESR_timestamp
      CRP
      CRP_timestamp
      CNI
      CNI_timestamp
      Myco
      Myco_timestamp
      Pred
      Pred_timestamp
      noted
      noted_timestamp
      t4
      t4_timestamp
      FT4
      FT4_timestamp
      TSH
      TSH_timestamp
      gis
      gis_timestamp
      hte
      hte_timestamp
    }
  }
`;
export const listLabReport = /* GraphQL */ `
  query ListLabReport(
    $filter: ListLabReportFilterInput
    $pagination: PaginationInput
  ) {
    result: listLabReport(filter: $filter, pagination: $pagination) {
      result {
        id
        patientID
        clinicID
        timestamp
        source
        accessIDlab
        name
        glucose
        TW
        Plt
        Hb
        MCV
        K
        Na
        ureaPre
        ureaPost
        creatinine
        HCO3
        chlorine
        Ca
        Po4
        IPTH
        HepBsAg
        HepBsAb
        _two
        Fe
        TIBC
        ferritin
        protein
        Alb
        Ublood
        Urbc
        Uprt
        Uwbc
        TUP24
        TUP12
        Uvol
        CCT
        uric
        HCV
        HIV
        ggt
        images
        others
        othersDetails
        TC
        HDL
        LDL
        TG
        Ratio
        Timing
        Biltotal
        SAP
        SGPT_ALT
        SGOT_AST
        Hba1c
        labsNoted
        SBP
        DBP
        Mg
        Tac
        urineMA
        MACr
        uCreatinine
        ESR
        CRP
        CNI
        Myco
        Pred
        noted
        t4
        FT4
        TSH
        gis
        hte
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const getLabElectrolytes = /* GraphQL */ `
  query GetLabElectrolytes($id: Int!) {
    result: getLabElectrolytes(id: $id) {
      id
      patientID
      clinicID
      timestamp
      K
      K_timestamp
      HCO3
      HCO3_timestamp
      creatinine
      creatinine_timestamp
      ureaPre
      ureaPre_timestamp
      ureaPost
      ureaPost_timestamp
      URR
      URR_timestamp
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const listLabElectrolytes = /* GraphQL */ `
  query ListLabElectrolytes(
    $filter: ListLabElectrolytesFilterInput
    $pagination: PaginationInput
  ) {
    result: listLabElectrolytes(filter: $filter, pagination: $pagination) {
      result {
        id
        patientID
        clinicID
        timestamp
        K
        K_timestamp
        HCO3
        HCO3_timestamp
        creatinine
        creatinine_timestamp
        ureaPre
        ureaPre_timestamp
        ureaPost
        ureaPost_timestamp
        URR
        URR_timestamp
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const getLabLFTData = /* GraphQL */ `
  query GetLabLFTData($id: Int!) {
    result: getLabLFTData(id: $id) {
      id
      patientID
      clinicID
      timestamp
      protein
      protein_timestamp
      Alb
      Alb_timestamp
      Biltotal
      Biltotal_timestamp
      SAP
      SAP_timestamp
      SGPT_ALT
      SGPT_ALT_timestamp
      SGOT_AST
      SGOT_AST_timestamp
      ggt
      ggt_timestamp
      glucose
      glucose_timestamp
      Hba1c
      Hba1c_timestamp
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const listLabLFTData = /* GraphQL */ `
  query ListLabLFTData(
    $filter: ListLabLFTDataFilterInput
    $pagination: PaginationInput
  ) {
    result: listLabLFTData(filter: $filter, pagination: $pagination) {
      result {
        id
        patientID
        clinicID
        timestamp
        protein
        protein_timestamp
        Alb
        Alb_timestamp
        Biltotal
        Biltotal_timestamp
        SAP
        SAP_timestamp
        SGPT_ALT
        SGPT_ALT_timestamp
        SGOT_AST
        SGOT_AST_timestamp
        ggt
        ggt_timestamp
        glucose
        glucose_timestamp
        Hba1c
        Hba1c_timestamp
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const getLabHbCaData = /* GraphQL */ `
  query GetLabHbCaData($id: Int!) {
    result: getLabHbCaData(id: $id) {
      id
      patientID
      clinicID
      timestamp
      Hb
      Hb_timestamp
      MCV
      MCV_timestamp
      TW
      TW_timestamp
      Plt
      Plt_timestamp
      Fe
      Fe_timestamp
      TIBC
      TIBC_timestamp
      LDL
      LDL_timestamp
      Po4
      Po4_timestamp
      Ca
      Ca_timestamp
      IPTH
      IPTH_timestamp
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const listLabHbCaData = /* GraphQL */ `
  query ListLabHbCaData(
    $filter: ListLabHbCaDataFilterInput
    $pagination: PaginationInput
  ) {
    result: listLabHbCaData(filter: $filter, pagination: $pagination) {
      result {
        id
        patientID
        clinicID
        timestamp
        Hb
        Hb_timestamp
        MCV
        MCV_timestamp
        TW
        TW_timestamp
        Plt
        Plt_timestamp
        Fe
        Fe_timestamp
        TIBC
        TIBC_timestamp
        LDL
        LDL_timestamp
        Po4
        Po4_timestamp
        Ca
        Ca_timestamp
        IPTH
        IPTH_timestamp
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const getLabVirologyLIPDS = /* GraphQL */ `
  query GetLabVirologyLIPDS($id: Int!) {
    result: getLabVirologyLIPDS(id: $id) {
      id
      patientID
      clinicID
      timestamp
      HepBsAg
      HepBsAg_timestamp
      HepBsAb
      HepBsAb_timestamp
      HCV
      HCV_timestamp
      HIV
      HIV_timestamp
      TC
      TC_timestamp
      HDL
      HDL_timestamp
      LDL
      LDL_timestamp
      TG
      TG_timestamp
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const listLabVirologyLIPDS = /* GraphQL */ `
  query ListLabVirologyLIPDS(
    $filter: ListLabVirologyLIPDSFilterInput
    $pagination: PaginationInput
  ) {
    result: listLabVirologyLIPDS(filter: $filter, pagination: $pagination) {
      result {
        id
        patientID
        clinicID
        timestamp
        HepBsAg
        HepBsAg_timestamp
        HepBsAb
        HepBsAb_timestamp
        HCV
        HCV_timestamp
        HIV
        HIV_timestamp
        TC
        TC_timestamp
        HDL
        HDL_timestamp
        LDL
        LDL_timestamp
        TG
        TG_timestamp
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const getIncident = /* GraphQL */ `
  query GetIncident($id: Int!) {
    result: getIncident(id: $id) {
      id
      reportedBy
      clinicID
      patientID
      location
      datetimeOccurred
      eventCategory
      eventType
      eventOutcome
      description
      cause
      action
      furtherAction
      order
      responseBy
      responseTimestamp
      response
      patientName
      patientNric
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const listIncident = /* GraphQL */ `
  query ListIncident(
    $filter: ListIncidentFilterInput
    $pagination: PaginationInput
    $groupByPatient: Int
    $groupByClinic: Int
  ) {
    result: listIncident(
      filter: $filter
      pagination: $pagination
      groupByPatient: $groupByPatient
      groupByClinic: $groupByClinic
    ) {
      result {
        id
        reportedBy
        clinicID
        patientID
        location
        datetimeOccurred
        eventCategory
        eventType
        eventOutcome
        description
        cause
        action
        furtherAction
        order
        responseBy
        responseTimestamp
        response
        patientName
        patientNric
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const listIncidentCentre = /* GraphQL */ `
  query ListIncidentCentre(
    $filter: ListIncidentFilterInput
    $pagination: PaginationInput
  ) {
    result: listIncidentCentre(filter: $filter, pagination: $pagination) {
      result {
        id
        reportedBy
        clinicID
        patientID
        location
        datetimeOccurred
        eventCategory
        eventType
        eventOutcome
        description
        cause
        action
        furtherAction
        order
        responseBy
        responseTimestamp
        response
        patientName
        patientNric
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const getMachine = /* GraphQL */ `
  query GetMachine($id: Int!) {
    result: getMachine(id: $id) {
      id
      serialNo
      clinicID
      name
      manufacturer
      status
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const listMachine = /* GraphQL */ `
  query ListMachine(
    $filter: ListMachineFilterInput
    $pagination: PaginationInput
  ) {
    result: listMachine(filter: $filter, pagination: $pagination) {
      result {
        id
        serialNo
        clinicID
        name
        manufacturer
        status
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const getMachineStatus = /* GraphQL */ `
  query GetMachineStatus($id: Int!) {
    result: getMachineStatus(id: $id) {
      id
      machineID
      prevMachineStatusID
      name
      expiryDate
      nextAudit
      status
      remarks
      approvedBy
      approvedTimestamp
      mandatary
      machineSerialNo
      machineName
      machineClinicID
      machineClinicName
      key
      bucket
      region
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const listMachineStatus = /* GraphQL */ `
  query ListMachineStatus(
    $filter: ListMachineStatusFilterInput
    $pagination: PaginationInput
    $groupByName: Int
  ) {
    result: listMachineStatus(
      filter: $filter
      pagination: $pagination
      groupByName: $groupByName
    ) {
      result {
        id
        machineID
        prevMachineStatusID
        name
        expiryDate
        nextAudit
        status
        remarks
        approvedBy
        approvedTimestamp
        mandatary
        machineSerialNo
        machineName
        machineClinicID
        machineClinicName
        key
        bucket
        region
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const getMedicalMilestone = /* GraphQL */ `
  query GetMedicalMilestone($id: Int!) {
    result: getMedicalMilestone(id: $id) {
      id
      patientID
      timestamp
      milestone
      note
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const listMedicalMilestone = /* GraphQL */ `
  query ListMedicalMilestone(
    $filter: ListMedicalMilestoneFilterInput
    $pagination: PaginationInput
  ) {
    result: listMedicalMilestone(filter: $filter, pagination: $pagination) {
      result {
        id
        patientID
        timestamp
        milestone
        note
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const getMedication = /* GraphQL */ `
  query GetMedication($id: Int!) {
    result: getMedication(id: $id) {
      id
      accessID
      patientID
      medication
      status
      dose
      route
      freq
      start
      end
      note
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const listMedication = /* GraphQL */ `
  query ListMedication(
    $filter: ListMedicationFilterInput
    $pagination: PaginationInput
  ) {
    result: listMedication(filter: $filter, pagination: $pagination) {
      result {
        id
        accessID
        patientID
        medication
        status
        dose
        route
        freq
        start
        end
        note
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const getMedicationAdministered = /* GraphQL */ `
  query GetMedicationAdministered($id: Int!) {
    result: getMedicationAdministered(id: $id) {
      id
      accessID
      patientID
      dialysisID
      dialysisIntervalID
      medication
      dose
      route
      freq
      givenBy
      givenByStaffName
      witnessedBy
      witnessedByStaffName
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const listMedicationAdministered = /* GraphQL */ `
  query ListMedicationAdministered(
    $filter: ListMedicationAdministeredFilterInput
    $pagination: PaginationInput
  ) {
    result: listMedicationAdministered(filter: $filter, pagination: $pagination) {
      result {
        id
        accessID
        patientID
        dialysisID
        dialysisIntervalID
        medication
        dose
        route
        freq
        givenBy
        givenByStaffName
        witnessedBy
        witnessedByStaffName
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const getPatient = /* GraphQL */ `
  query GetPatient($id: Int!) {
    result: getPatient(id: $id) {
      id
      accessIDPt
      namecode
      registeredDate
      quickbookCustomerID
      key
      bucket
      region
      firstDialysisHospital
      firstDialysisHospitalDate
      referringSource
      referrer
      referringSourceStart
      referringSourceEnd
      existingClinicID
      status
      travelType
      schedulePattern
      scheduleSession
      name
      idType
      nric
      gender
      dob
      race
      religion
      maritalStatus
      accommodation
      address
      phoneNo
      homeNo
      email
      nokName
      nokPhoneNo
      nokAddress
      nokRelation
      height
      weight
      bloodGroup
      mobilityStatus
      diabeticStatus
      smoker
      alcohol
      amputee
      stage
      wheelchairWeight
      medicalAlert
      socialWorker
      vwoSupport
      firstConsultDate
      DESRD
      DExit
      RExit
      hepB
      FHepB
      FHCV
      FHIV
      FAccess
      comanaging
      procedure
      medication
      vaccination
      history
      causeOfKidneyFailure
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const listPatient = /* GraphQL */ `
  query ListPatient(
    $filter: ListPatientFilterInput
    $pagination: PaginationInput!
    $unmasked: Int
  ) {
    result: listPatient(filter: $filter, pagination: $pagination, unmasked: $unmasked) {
      result {
        id
        accessIDPt
        namecode
        registeredDate
        quickbookCustomerID
        key
        bucket
        region
        firstDialysisHospital
        firstDialysisHospitalDate
        referringSource
        referrer
        referringSourceStart
        referringSourceEnd
        existingClinicID
        status
        travelType
        schedulePattern
        scheduleSession
        name
        idType
        nric
        gender
        dob
        race
        religion
        maritalStatus
        accommodation
        address
        phoneNo
        homeNo
        email
        nokName
        nokPhoneNo
        nokAddress
        nokRelation
        height
        weight
        bloodGroup
        mobilityStatus
        diabeticStatus
        smoker
        alcohol
        amputee
        stage
        wheelchairWeight
        medicalAlert
        socialWorker
        vwoSupport
        firstConsultDate
        DESRD
        DExit
        RExit
        hepB
        FHepB
        FHCV
        FHIV
        FAccess
        comanaging
        procedure
        medication
        vaccination
        history
        causeOfKidneyFailure
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const getPatientClinic = /* GraphQL */ `
  query GetPatientClinic($id: Int!) {
    result: getPatientClinic(id: $id) {
      patientID
      clinicID
      quickbookCustomerID
    }
  }
`;
export const listPatientClinic = /* GraphQL */ `
  query ListPatientClinic(
    $filter: ListPatientClinicFilterInput
    $pagination: PaginationInput
  ) {
    result: listPatientClinic(filter: $filter, pagination: $pagination) {
      result {
        patientID
        clinicID
        quickbookCustomerID
      }
      count
    }
  }
`;
export const getPatientDocument = /* GraphQL */ `
  query GetPatientDocument($id: Int!) {
    result: getPatientDocument(id: $id) {
      id
      staffAccountID
      staffName
      patientID
      title
      status
      key
      bucket
      region
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const listPatientDocument = /* GraphQL */ `
  query ListPatientDocument(
    $filter: ListPatientDocumentFilterInput
    $pagination: PaginationInput
  ) {
    result: listPatientDocument(filter: $filter, pagination: $pagination) {
      result {
        id
        staffAccountID
        staffName
        patientID
        title
        status
        key
        bucket
        region
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const getSeat = /* GraphQL */ `
  query GetSeat($id: Int!) {
    result: getSeat(id: $id) {
      id
      clinicID
      machineID
      status
      name
      machineName
      machineSerialNo
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const listSeat = /* GraphQL */ `
  query ListSeat($filter: ListSeatFilterInput, $pagination: PaginationInput) {
    result: listSeat(filter: $filter, pagination: $pagination) {
      result {
        id
        clinicID
        machineID
        status
        name
        machineName
        machineSerialNo
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const getStaff = /* GraphQL */ `
  query GetStaff($id: Int!) {
    result: getStaff(id: $id) {
      accountID
      clinicIDs
      designation
      accessCode
      name
      contactNo
      email
      username
      gender
      dob
      maritalStatus
      address
      postal
      profilePictureKey
      profilePictureBucket
      profilePictureRegion
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const listStaff = /* GraphQL */ `
  query ListStaff($filter: ListStaffFilterInput, $pagination: PaginationInput) {
    result: listStaff(filter: $filter, pagination: $pagination) {
      result {
        accountID
        clinicIDs
        designation
        accessCode
        name
        contactNo
        email
        username
        gender
        dob
        maritalStatus
        address
        postal
        profilePictureKey
        profilePictureBucket
        profilePictureRegion
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const getStaffStatus = /* GraphQL */ `
  query GetStaffStatus($id: Int!) {
    result: getStaffStatus(id: $id) {
      id
      accountID
      name
      status
      issued
      expiry
      approvedBy
      approvedTimestamp
      staffName
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const listStaffStatus = /* GraphQL */ `
  query ListStaffStatus(
    $filter: ListStaffStatusFilterInput
    $pagination: PaginationInput
  ) {
    result: listStaffStatus(filter: $filter, pagination: $pagination) {
      result {
        id
        accountID
        name
        status
        issued
        expiry
        approvedBy
        approvedTimestamp
        staffName
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const getSymptom = /* GraphQL */ `
  query GetSymptom($id: Int!) {
    result: getSymptom(id: $id) {
      id
      name
      class
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const listSymptom = /* GraphQL */ `
  query ListSymptom(
    $filter: ListSymptomFilterInput
    $pagination: PaginationInput
  ) {
    result: listSymptom(filter: $filter, pagination: $pagination) {
      result {
        id
        name
        class
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const listAppointmentAvailability = /* GraphQL */ `
  query ListAppointmentAvailability(
    $calendarStartDay: Int!
    $calenderEndDay: Int!
    $start: String!
    $end: String!
    $patientID: Int
    $clinicIDs: [Int]!
  ) {
    result: listAppointmentAvailability(
      calendarStartDay: $calendarStartDay
      calenderEndDay: $calenderEndDay
      start: $start
      end: $end
      patientID: $patientID
      clinicIDs: $clinicIDs
    )
  }
`;
export const getQBAccess = /* GraphQL */ `
  query GetQBAccess($id: ID!) {
    result: getQBAccess(id: $id) {
      id
      clinicIDs
      expiresIn
      date
      createdOn
      updatedOn
      jwtToken
    }
  }
`;
export const listQBAccesses = /* GraphQL */ `
  query ListQBAccesses(
    $filter: ModelQBAccessFilterInput
    $limit: Int
    $nextToken: String
  ) {
    result: listQBAccesses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        clinicIDs
        expiresIn
        date
        createdOn
        updatedOn
        jwtToken
      }
      nextToken
    }
  }
`;
