const path = (page, payload) => {
    let path = "/"
    switch (page) {
        case "availability":
            path = "/availability"
            break
        case "latestClinicalNoteByAuthor":
            path = "/clinicalnote/author"
            break
        case "latestClinicalNoteByCentre":
            path = "/clinicalnote/centre"
            break
        case "forgotPassword":
            path = "/forgotpassword"
            break
        case "importLabResultFileUploadReview":
            path = "/labresult/import/upload/review"
            break
        case "importLabResultFileUpload":
            path = "/labresult/import/upload"
            break
        case "importLabResultManualInputPatient":
            path = "/labresult/import/input/" + payload[0]
            break
        case "importLabResultManualInput":
            path = "/labresult/import/input"
            break
        case "incidentReport":
            path = "/incidentreport"
            break
        case "incidentReportCentre":
            path = "/incidentreport/centre"
            break
        case "incidentReportCentreCreate":
            path = "/incidentreport/centre/" + payload[0] + "/create"
            break
        case "login":
            path = "/login"
            break
        case "manageCentre":
            path = "/manage/centre"
            break
        case "manageDrug":
            path = "/manage/drug"
            break
        case "manageFunderReport":
            path = "/manage/fund"
            break
        case "manageSymptom":
            path = "/manage/symptom"
            break
        case "manageSeat":
            path = "/manage/seat"
            break
        case "manageStaff":
            path = "/manage/staff"
            break
        case "manageMachine":
            path = "/manage/machine"
            break
        case "manageMachineStatus":
            path = "/manage/machinestatus"
            break
        case "manageCertification":
            path = "/manage/certification"
            break
        case "manageDialysisSession":
            path = "/manage/dialysis"
            break
        case "manageDialysisSessionPatientRecord":
            path = "/manage/dialysis/patientrecord/" + payload[0]
            break
        case "manageUnlinkedInvoice":
            path = "/manage/invoice/unlinked"
            break
        case "manage":
            path = "/manage"
            break
        case "patientCreate":
            path = "/patient/create"
            break
        case "patientDialysisCheckIn":
            path = "/patient/" + payload[0] + "/dialysis/appointment/" + payload[1]
            break
        case "patientDialysisCheckInEdit":
            path = "/patient/" + payload[0] + "/dialysis/" + payload[1] + "/checkin/edit"
            break
        case "patientDialysisCheckOut":
            path = "/patient/" + payload[0] + "/dialysis/" + payload[1] + "/checkout"
            break
        case "patientDialysisCheckOutEdit":
            path = "/patient/" + payload[0] + "/dialysis/" + payload[1] + "/checkout/edit"
            break
        case "patientDialysis":
            path = "/patient/" + payload[0] + "/dialysis/" + payload[1]
            break
        case "patientRecordExport":
            path = "/patient/" + payload[0] + "/patientrecord/export"
            break
        case "patientRecord":
            path = "/patient/" + payload[0] + "/patientrecord"
            break
        case "patientIncidentReport":
            path = "/patient/" + payload[0] + "/incidentreport"
            break
        case "patientLabReport":
            path = "/patient/" + payload[0] + "/patientrecord/#labreport"
            break
        case "patientClinicNHospitalE":
            path = "/patient/" + payload[0] + "/patientrecord/#clinicalnotes"
            break
        case "patientDocument":
            path = "/patient/" + payload[0] + "/document"
            break
        case "patientAppointment":
            path = "/patient/" + payload[0] + "/appointment"
            break
        case "patientInformationEdit":
            path = "/patient/" + payload[0] + "/edit"
            break
        case "patientInformation":
            path = "/patient/" + payload[0]
            break
        case "routineOrder":
            path = "/routineorder"
            break
        case "settingsChangePassword":
            path = "/settings/password/change"
            break
        case "settingsManageAccountEdit":
            path = "/settings/account/edit"
            break
        case "settingsManageAccount":
            path = "/settings/account"
            break
        case "settingsCentreSelection":
            path = "/settings/centre"
            break
        case "redirectQuickbooks":
            path = "/redirect/quickbooks"
            break
        case "redirectQuickbooksLink":
            path = "/redirect/quickbooks/link"
            break
        case "dashboard":
            path = "/"
            break
        default:
    }
    return path
}

export default path