import React, { useContext, useEffect, useState } from "react";
import { Avatar, Dropdown, Menu, notification } from "antd";
import { DownOutlined, UserOutlined } from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import { AppContext } from "../contexts/AppContext";
import { Auth } from "aws-amplify";

import path from "../utils/pathSettings";

const HeaderProfile = (props) => {
    const { appState, actionLogout, actionSetClinicID } =
        useContext(AppContext);
    const [displayClinic, setDisplayClinic] = useState("");

    const handleClick = async (e, id) => {
        switch (id) {
            case "switchcentre":
                actionSetClinicID("");
                break;
            case "logout":
                // console.log("logout clicked");
                await Auth.signOut();
                actionLogout();
                notification.success({
                    message: "Log out successfully"
                });
                break;
            default:
        }
    };

    const content = (
        <Menu style={{ maxWidth: "250px" }}>
            {/* <div style={{ padding: "5px 12px", fontWeight: "bold" }}>Name</div>
            <Menu.Divider /> */}
            {/* <Menu.Item key={path("settingsCentreSelection")}>
                <NavLink to={path("settingsCentreSelection")}>Switch Centre</NavLink>
            </Menu.Item> */}
            <Menu.Item
                key="/logout"
                onClick={(e) => handleClick(e, "switchcentre")}
            >
                Switch Centre
            </Menu.Item>
            <Menu.Item key={path("settingsManageAccount")}>
                <NavLink to={path("settingsManageAccount")}>
                    Manage Account
                </NavLink>
            </Menu.Item>
            {/* <Menu.Item key={path("settingsChangePassword")}>
                <NavLink to={path("settingsChangePassword")}>Change Password</NavLink>
            </Menu.Item> */}
            <Menu.Item key="/logout" onClick={(e) => handleClick(e, "logout")}>
                Log Out
            </Menu.Item>
            <div className="version">v1.0.0</div>
        </Menu>
    );

    useEffect(() => {
        // console.log("header appState", appState);
        if (appState.clinicList !== -1 && appState.selectedClinicID !== "") {
            for (let i = 0; i < appState.clinicList.length; i++) {
                if (
                    appState.clinicList[i].id.toString() ===
                    appState.selectedClinicID.toString()
                ) {
                    setDisplayClinic(appState.clinicList[i].name);
                    break;
                }
            }
        }
    }, [appState.clinicList, appState.selectedClinicID]);

    return (
        <Dropdown overlay={content} trigger={["click"]}>
            <div className="item profile">
                <div className="profile-picture">
                    <Avatar
                        className="avatar"
                        icon={<UserOutlined />}
                        shape="square"
                        src={appState?.profilePicture ?? ""}
                    />
                </div>
                <div className="profile-content">
                    <div className="name">
                        {appState?.cognitoUser?.attributes?.name}
                    </div>
                    <div className="role">
                        {appState?.loginType
                            ? appState.loginType == "ManagingNurse"
                                ? "Nurse Manager"
                                : appState?.loginType == "MedicalDirector"
                                ? "Medical Director"
                                : appState.loginType
                            : "Role"}
                    </div>
                    {displayClinic}
                </div>
                <DownOutlined />
            </div>
        </Dropdown>
    );
};

export default HeaderProfile;
