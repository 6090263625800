import React, {
    forwardRef,
    useContext,
    useEffect,
    useRef,
    useState
} from "react";
import { Button, Empty, notification, Spin, Table } from "antd";
import { PrinterOutlined } from "@ant-design/icons";
import { useReactToPrint } from "react-to-print";
import { API } from "aws-amplify";
import {
    getLabReportLatestValue,
    getMedicationAdministered,
    listDialysis,
    listDialysisInterval,
    listDialysisOrder,
    listDialysisSummary,
    listHospitalSummary,
    listMedicalMilestone,
    listMedication,
    listMedicationAdministered
} from "../backend/graphql/queries";
import moment from "moment";
import { AppContext } from "../contexts/AppContext";

const PatientRecordExportPatientDialysisHistory = (props) => {
    const { appState } = useContext(AppContext);
    const componentRef = useRef();
    const { patientID, patient, setPatient } = props;

    const [tableLastLab, setTableLastLab] = useState({
        data: -1,
        pagination: {
            current: 1,
            pageSize: 20,
            offset: 0
        },
        loading: false
    });
    const [tableDiaylsisHistorySummary, setTableDialysisHistorySummary] =
        useState({
            data: -1,
            pagination: {
                current: 1,
                pageSize: 20,
                offset: 0
            },
            loading: false
        });
    const [tableCurrentMedication, setTableCurrentMedication] = useState({
        data: -1,
        pagination: {
            current: 1,
            pageSize: 20,
            offset: 0
        },
        loading: false
    });
    const [tableMedicationAdministered, setTableMedicationAdministered] =
        useState({
            data: -1,
            pagination: {
                current: 1,
                pageSize: 20,
                offset: 0
            },
            loading: false
        });
    const [tableDialysisHistory, setTableDialysisHistory] = useState({
        data: -1,
        pagination: {
            current: 1,
            pageSize: 20,
            offset: 0
        },
        loading: false
    });
    const [tableDialysisHistoryInterval, setTableDialysisHistoryInterval] =
        useState({
            data: -1,
            pagination: {
                current: 1,
                pageSize: 20,
                offset: 0
            },
            loading: false
        });
    const [tableMedicalMilestone, setTableMedicalMilestone] = useState({
        data: -1,
        pagination: {
            current: 1,
            pageSize: 20,
            offset: 0
        },
        loading: false
    });
    const [tableIntraDialysis, setTableIntraDialysis] = useState({
        data: -1,
        pagination: {
            current: 1,
            pageSize: 20,
            offset: 0
        },
        loading: false
    });
    const [displayLastLab, setDisplayLastLab] = useState([]);
    const [displayDialysisHistorySummary, setDisplayDialysisHistorySummary] =
        useState([]);
    const [displayCurrentMedication, setDisplayCurrentMedication] = useState(
        []
    );
    const [displayMedicationAdministered, setDisplayMedicationAdministered] =
        useState([]);
    const [displayDialysisHistory, setDisplayDialysisHistory] = useState([]);
    const [displayDialysisHistoryInterval, setDisplayDialysisHistoryInterval] =
        useState([]);
    const [displayMedicalMilestone, setDisplayMedicalMilestone] = useState([]);
    const [displayIntraDialysis, setDisplayIntraDialysis] = useState([]);

    const [latestClinicalNote, setLatestClinicalNote] = useState(-1);
    const [dialysisOrder, setDialysisOrder] = useState(-1);
    const [patientPrePostWeight, setPatientPrePostWeight] = useState(-1);
    const [loadingLatestClinicalNote, setLoadingLatestClinicalNote] =
        useState(false);
    const [loadingDialysisOrder, setLoadingDialysisOrder] = useState(false);
    const [loadingPatientPrePostWeight, setLoadingPatientPrePostWeight] =
        useState(false);
    const [enablePrint, setEnablePrint] = useState(false);
    const [printing, setPrinting] = useState(true);
    const [patientClinic, setPatientClinic] = useState(-1);

    const getLastLabRecord = async (id) => {
        setTableLastLab({
            ...tableLastLab,
            loading: true
        });
        try {
            const variables = {
                patientID: parseInt(id)
            };

            // console.log('PatientRecordExportPatientDialysisHistory getLabReportLatestValue', variables);

            const result = await API.graphql({
                query: getLabReportLatestValue,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables
            });
            // console.log("API result PatientRecordExportPatientDialysisHistory getLabReportLatestValue", result);
            let data = result.data.result ?? {};
            setTableLastLab({
                ...tableLastLab,
                data: [data],
                loading: false
            });
            // const timer = setTimeout(() => {
            //     setTableLastLab({
            //         ...tableLastLab,
            //         loading: true,
            //         data: [
            //             {
            //                 glucose: "10",
            //                 tw: "10",
            //                 Plt: "10",
            //                 Hb: "10",
            //                 MCV: "10",
            //                 K: "10",
            //                 Na: "10",
            //                 ureaPre: "10",
            //                 ureaPost: "10",
            //                 creatinine: "10",
            //                 HCO3: "10",
            //                 chlorine: "10",
            //                 Ca: "10",
            //                 Po4: "10",
            //                 IPTH: "10",
            //                 HepBsAg: "10",
            //                 HepBsAb: "10",
            //                 _two: "10",
            //                 Fe: "10",
            //                 TIBC: "10",
            //                 ferritin: "10",
            //                 protein: "10",
            //                 Alb: "10",
            //                 Ublood: "10",
            //                 Urbc: "10",
            //                 Uprt: "10",
            //                 Uwbc: "10",
            //                 TUP24: "10",
            //                 TUP12: "10",
            //                 Uvol: "10",
            //                 CCT: "10",
            //                 uric: "10",
            //                 HCV: "10",
            //                 HIV: "10",
            //                 ggt: "10",
            //                 images: "10",
            //                 others: "10",
            //                 othersDetails: "10",
            //                 TC: "10",
            //                 HDL: "10",
            //                 LDL: "10",
            //                 TG: "10",
            //                 Ratio: "10",
            //                 Timing: "10",
            //                 Biltotal: "10",
            //                 SAP: "10",
            //                 SGPT_ALT: "10",
            //                 SGOT_AST: "10",
            //                 Hba1c: "10",
            //                 labsNoted: "10",
            //                 SBP: "10",
            //                 DBP: "10",
            //                 Mg: "10",
            //                 Tac: "10",
            //                 urineMA: "10",
            //                 MACr: "10",
            //                 uCreatinine: "10",
            //                 ESR: "10",
            //                 CRP: "10",
            //                 CNI: "10",
            //                 Myco: "10",
            //                 Pred: "10",
            //                 noted: "10",
            //                 t4: "10",
            //                 FT4: "10",
            //                 TSH: "10",
            //                 gis: "10",
            //                 hte: "10",
            //             },
            //         ]
            //     })
            //     clearTimeout(timer)
            // }, 1000)
        } catch (error) {
            console.log("error", error);
            notification.error({
                message: "Unable to retrieve last lab"
            });
            setTableLastLab({
                ...tableLastLab,
                loading: false
            });
        }
    };

    const getDialysisHistorySummaryRecords = async (id) => {
        setTableDialysisHistorySummary({
            ...tableDiaylsisHistorySummary,
            loading: true
        });
        try {
            const variables = {
                patientID: parseInt(id),
                endDate: moment().toISOString().split(".")[0],
                startDate: moment()
                    .add(-1, "months")
                    .utc()
                    .format("yyyy-MM-01T00:00:00")
            };

            // console.log('PatientRecordExportPatientDialysisHistory listDialysisSummary', variables);

            const result = await API.graphql({
                query: listDialysisSummary,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables
            });
            // console.log("API result PatientRecordExportPatientDialysisHistory listDialysisSummary", result);
            let data = result.data.result;
            // if (manageDialysis === true) {
            //     data.result = data.result.filter(item => item.endTime !== null && item.endTime !== undefined && item.endTime !== "")
            // }

            setTableDialysisHistorySummary({
                ...setTableDialysisHistorySummary,
                // loading: _dialysisHistory.length !== 0 ? true : false,
                // data: _dialysisHistory,
                loading: data.length !== 0 ? true : false,
                data: data,
                pagination: {
                    ...tableDialysisHistory.pagination,
                    current: props.current,
                    offset: props.offset,
                    total:
                        props.offset >= data.count ? data.count + 1 : data.count // keeps the last pagination if it is the last record
                }
            });
            // const timer = setTimeout(() => {
            //     setTableDialysisHistorySummary({
            //         ...tableDiaylsisHistorySummary,
            //         loading: true,
            //         data: [
            //             {
            //                 id: 4,
            //                 month: "4",
            //                 total: "10",
            //                 dw: "10",
            //                 wtLast: "10",
            //                 wtPre: "10",
            //                 wtPost: "10",
            //                 ufTarget: "10",
            //                 wtOffTarget: "10",
            //                 sbpPost: "10",
            //                 dbpPost: "10",
            //                 ktv: "10",
            //             },
            //             {
            //                 id: 3,
            //                 month: "3",
            //                 total: "10",
            //                 dw: "10",
            //                 wtLast: "10",
            //                 wtPre: "10",
            //                 wtPost: "10",
            //                 ufTarget: "10",
            //                 wtOffTarget: "10",
            //                 sbpPost: "10",
            //                 dbpPost: "10",
            //                 ktv: "10",
            //             },
            //         ]
            //     })
            //     clearTimeout(timer)
            // }, 1000)
        } catch (error) {
            console.log("error", error);
            notification.error({
                message: "Unable to retrieve dialysis history summary"
            });
            setTableDialysisHistorySummary({
                ...tableDiaylsisHistorySummary,
                loading: false
            });
        }
    };

    const getCurrentMedicationRecords = async (
        id,
        props = tableCurrentMedication.pagination
    ) => {
        setTableCurrentMedication({
            ...tableCurrentMedication,
            loading: true
        });
        try {
            const variables = {
                pagination: {
                    limit: props.pageSize,
                    offset: props.offset,
                    orderby: `(status = 'ACTIVE') ASC, createdOn DESC`
                },
                filter: {
                    patientID: {
                        eq: parseInt(id)
                    },
                    end: {
                        attributeExists: false
                    }
                }
            };

            // console.log(variables);

            const result = await API.graphql({
                query: listMedication,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables
            });
            // console.log("API result PatientRecordExportPatientDialysisHistory listMedication", result);

            let data = result.data.result;
            setTableCurrentMedication({
                ...tableCurrentMedication,
                data: data.result
            });

            // const timer = setTimeout(() => {
            //     setTableCurrentMedication({
            //         ...tableCurrentMedication,
            //         loading: true,
            //         data: [
            //             {
            //                 dateStart: "DD MMM YYYY",
            //                 medication: "Medication ABC 10mg",
            //                 route: "po",
            //                 freq: "3x/wk",
            //             },
            //             {
            //                 dateStart: "DD MMM YYYY",
            //                 medication: "Medication ABC 10mg",
            //                 route: "po",
            //                 freq: "3x/wk",
            //             },
            //             {
            //                 dateStart: "DD MMM YYYY",
            //                 medication: "Medication ABC 10mg",
            //                 route: "po",
            //                 freq: "3x/wk",
            //             },
            //             {
            //                 dateStart: "DD MMM YYYY",
            //                 medication: "Medication ABC 10mg",
            //                 route: "po",
            //                 freq: "3x/wk",
            //             },
            //         ]
            //     })
            //     clearTimeout(timer)
            // }, 1000)
        } catch (error) {
            console.log("error", error);
            notification.error({
                message: "Unable to retrieve current medication"
            });
            setTableCurrentMedication({
                ...tableCurrentMedication,
                loading: false
            });
        }
    };

    const getDiaysisHistoryRecords = async (
        id,
        props = tableDialysisHistory.pagination
    ) => {
        setTableDialysisHistory({
            ...tableDialysisHistory,
            loading: true
        });
        try {
            const variables = {
                pagination: {
                    limit: props.pageSize,
                    offset: props.offset,
                    orderby: "startTime DESC"
                }
            };

            if (patientID > 0) {
                variables.filter = {
                    patientID: { eq: parseInt(id) }
                    // endedBy: {attributeExists: false} // not working
                };
            }

            // if (appState.selectedClinicID > 0) {
            // if (appState.selectedClinicID !== "") {
            //     variables.filter = {
            //         ...variables.filter,
            //         clinicID: {
            //             eq: appState.selectedClinicID
            //         }
            //     }
            // }

            // console.log('PatientRecordExportPatientDialysisHistory', variables);

            const result = await API.graphql({
                query: listDialysis,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables
            });
            // console.log("API result PatientRecordExportPatientDialysisHistory listDialysis", result);
            let data = result.data.result;
            // if (manageDialysis === true) {
            //     data.result = data.result.filter(item => item.endTime !== null && item.endTime !== undefined && item.endTime !== "")
            // }

            setTableDialysisHistory({
                ...tableDialysisHistory,
                // loading: _dialysisHistory.length !== 0 ? true : false,
                // data: _dialysisHistory,
                loading: data.result.length !== 0 ? true : false,
                data: data.result,
                pagination: {
                    ...tableDialysisHistory.pagination,
                    current: props.current,
                    offset: props.offset,
                    total:
                        props.offset >= data.count ? data.count + 1 : data.count // keeps the last pagination if it is the last record
                }
            });
            // const timer = setTimeout(() => {
            //     setTableDialysisHistory({
            //         ...tableDialysisHistory,
            //         loading: true,
            //         data: [
            //             {
            //                 id: 3,
            //                 timestampDisplay: "DD MMM YYYY",
            //                 sessionMonth: "3",
            //                 QB: "20",
            //                 AP: "20",
            //                 VP: "20",
            //                 DFLOW: "20",
            //                 CDT: "20",
            //                 lastWeight: "20",
            //                 preEdema: "20",
            //                 postEdema: "20",
            //                 preWeight: "20",
            //                 dryWeight: "20",
            //                 ufTargetStart: "20",
            //                 postWeight: "20",
            //                 offTarget: "20",
            //                 postSBPDBP: "20",
            //                 SBP: "20",
            //                 DBP: "20",
            //                 HR: "20",
            //                 temp: "20",
            //                 preTemp: "20",
            //                 postTemp: "20",
            //                 MSA: "20",
            //                 dialyser: "20",
            //                 KTVonline: "20",
            //                 notes: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
            //                 displaySymptoms: "20",
            //             },
            //             {
            //                 id: 2,
            //                 timestampDisplay: "DD MMM YYYY",
            //                 sessionMonth: "2",
            //                 QB: "20",
            //                 AP: "20",
            //                 VP: "20",
            //                 DFLOW: "20",
            //                 CDT: "20",
            //                 lastWeight: "20",
            //                 preEdema: "20",
            //                 postEdema: "20",
            //                 preWeight: "20",
            //                 dryWeight: "20",
            //                 ufTargetStart: "20",
            //                 postWeight: "20",
            //                 offTarget: "20",
            //                 postSBPDBP: "20",
            //                 SBP: "20",
            //                 DBP: "20",
            //                 HR: "20",
            //                 temp: "20",
            //                 preTemp: "20",
            //                 postTemp: "20",
            //                 MSA: "20",
            //                 dialyser: "20",
            //                 KTVonline: "20",
            //                 notes: "20",
            //                 displaySymptoms: "20",
            //             },
            //             {
            //                 id: 1,
            //                 timestampDisplay: "DD MMM YYYY",
            //                 sessionMonth: "1",
            //                 QB: "20",
            //                 AP: "20",
            //                 VP: "20",
            //                 DFLOW: "20",
            //                 CDT: "20",
            //                 lastWeight: "20",
            //                 preEdema: "20",
            //                 postEdema: "20",
            //                 preWeight: "20",
            //                 dryWeight: "20",
            //                 ufTargetStart: "20",
            //                 postWeight: "20",
            //                 offTarget: "20",
            //                 postSBPDBP: "20",
            //                 SBP: "20",
            //                 DBP: "20",
            //                 HR: "20",
            //                 temp: "20",
            //                 preTemp: "20",
            //                 postTemp: "20",
            //                 MSA: "20",
            //                 dialyser: "20",
            //                 KTVonline: "20",
            //                 notes: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.",
            //                 displaySymptoms: "20",
            //             },
            //         ]
            //     })
            //     clearTimeout(timer)
            // }, 1000)
        } catch (error) {
            console.log("error", error);
            notification.error({
                message: "Unable to retrieve current medication"
            });
            setTableCurrentMedication({
                ...tableCurrentMedication,
                loading: false
            });
        }
    };

    const getMedicationAdministeredRecords = async (
        id,
        dialysisIDs,
        minStartTime,
        maxEndTime,
        props = tableDialysisHistory.pagination
    ) => {
        setTableMedicationAdministered({
            ...tableMedicationAdministered,
            loading: true
        });
        try {
            const variables = {
                pagination: {
                    // limit: props.pageSize,
                    // offset: props.offset,
                    orderby: "createdOn DESC"
                }
            };

            if (patientID > 0) {
                variables.filter = {
                    patientID: { eq: parseInt(id) },
                    or: [
                        {
                            createdOn: {
                                ge: minStartTime
                            }
                        },
                        {
                            createdOn: {
                                le: maxEndTime
                            }
                        }
                    ]
                };
            }
            dialysisIDs.forEach((id) =>
                variables.filter.or.push({ dialysisID: { eq: id } })
            );

            const result = await API.graphql({
                query: listMedicationAdministered,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables
            });
            // console.log(API result PatientRecordExportPatientDialysisHistory listMedicationAdministered", result);
            let data = result.data.result;

            if (data?.result)
                setTableMedicationAdministered({
                    ...tableMedicationAdministered,
                    loading: data?.result?.length !== 0 ? true : false,
                    data: data?.result,
                    pagination: {
                        ...tableMedicationAdministered.pagination,
                        current: props.current,
                        offset: props.offset,
                        total:
                            props.offset >= data.count
                                ? data.count + 1
                                : data.count // keeps the last pagination if it is the last record
                    }
                });
        } catch (error) {
            console.log("error", error);
            notification.error({
                message: "Unable to retrieve current medication administered"
            });
            setTableMedicationAdministered({
                ...tableMedicationAdministered,
                loading: false
            });
        }
    };

    const getDiaysisHistoryIntervalRecords = async (dialysisIDs) => {
        setTableDialysisHistory({
            ...tableDialysisHistory,
            loading: true
        });
        try {
            const variables = {
                pagination: {
                    // limit: props.pageSize,
                    // offset: props.offset,
                    orderby: "timestamp DESC"
                }
            };

            variables.filter = {
                ...variables.filter,
                or: [],
                deleted: { eq: 0 }
            };
            dialysisIDs.forEach((id) =>
                variables.filter.or.push({ dialysisID: { eq: id } })
            );

            // console.log('listDialysisInterval', variables);

            const result = await API.graphql({
                query: listDialysisInterval,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables
            });
            // console.log("API result PatientRecordExportPatientDialysisHistory listDialysisInterval", result);
            let data = result.data.result;
            // if (manageDialysis === true) {
            //     data.result = data.result.filter(item => item.endTime !== null && item.endTime !== undefined && item.endTime !== "")
            // }

            setTableDialysisHistoryInterval({
                ...tableDialysisHistoryInterval,
                data: data.result,
                loading: data.result.length !== 0 ? true : false,
                dialysisIDs: dialysisIDs
            });
        } catch (error) {
            console.log("error", error);
            notification.error({
                message: "Unable to retrieve current medication"
            });
            setTableCurrentMedication({
                ...tableCurrentMedication,
                loading: false
            });
        }
    };

    const getLatestClinicalNoteRecord = async (id) => {
        setLoadingLatestClinicalNote(true);
        try {
            const variables = {
                pagination: {
                    limit: 1,
                    offset: 0,
                    orderby: `admitTimestamp DESC`
                },
                filter: {
                    patientID: {
                        eq: parseInt(id)
                    },
                    or: [
                        {
                            type: {
                                eq: "CLINIC"
                            }
                        },
                        {
                            type: {
                                eq: "EVENT"
                            }
                        }
                    ]
                }
            };

            // console.log(variables);

            const result = await API.graphql({
                query: listHospitalSummary,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables
            });

            const data = result.data.result;
            const array = result.data.result.result;
            const clinicalNote = array[0];

            setLatestClinicalNote(clinicalNote);

            // const timer = setTimeout(() => {
            //     setLatestClinicalNote("This is the latest clinical note. Blah blah blah, 123, abc, hahahaha.")
            //     clearTimeout(timer)
            // }, 1000)
        } catch (error) {
            console.log("error", error);
            notification.error({
                message: "Unable to retrieve latest clinical note"
            });
            setLoadingLatestClinicalNote(false);
        }
    };

    const getDialysisOrderRecord = async (id) => {
        setLoadingDialysisOrder(true);
        try {
            const variables = {
                pagination: {
                    limit: 1,
                    orderby: "dOrder DESC"
                },
                filter: {
                    patientID: {
                        eq: parseInt(id)
                    }
                }
            };

            // console.log(variables);

            const result = await API.graphql({
                query: listDialysisOrder,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables
            });
            // console.log("API result PatientRecordDialysisOrder", result);

            const data = result.data.result;
            const array = result.data.result.result;

            if (array.length > 0) {
                const v = array[0];

                // process data
                setDialysisOrder({
                    id: v.id,
                    msa: v.MSA,
                    dorderDate: moment(v.dOrder).format("DD MMM yyyy"),
                    freqmth: v.freqMth,
                    duration: v.duration,
                    hepload: v.hepLoad,
                    hepcont: v.hepCont,
                    antioOthers: v.antio,
                    dialysate: v.dialysate,
                    primaryAccess: v.accessPrim,
                    aNeedle: v.aNeedle ?? "None",
                    vNeedle: v.vNeedle ?? "None",
                    bicarbonate: v.bicarbonate,
                    temp: v.temp,
                    qd: v.QD,
                    qb: v.QB,
                    dw: v.dryWeight ? (v.dryWeight / 1000.0)?.toFixed(1) : "",
                    notes: v.notes
                });
            }
            // const timer = setTimeout(() => {
            //     setDialysisOrder({
            //         msa: "2.1",
            //         dorderDate: "21 Apr 2021",
            //         freqmth: "12",
            //         duration: "240",
            //         hepload: "2000",
            //         hepcont: "1000",
            //         antioOthers: "n/a",
            //         dialysate: "LC",
            //         primaryAccess: "AVF LEF",
            //         aNeedle: "16",
            //         vNeedle: "+2",
            //         bicarbonate: "+2",
            //         temp: "35",
            //         qd: "500",
            //         qb: "3",
            //         dw: "16",
            //     })
            //     clearTimeout(timer)
            // }, 1000)
        } catch (error) {
            console.log("error", error);
            notification.error({
                message: "Unable to retrieve dilysis order"
            });
            setLoadingDialysisOrder(false);
        }
    };

    const getMedicalMilestoneRecords = async (
        id,
        pagination = tableMedicalMilestone.pagination
    ) => {
        setTableMedicalMilestone({
            ...tableMedicalMilestone,
            loading: true
        });
        try {
            const variables = {
                pagination: {
                    limit: pagination.pageSize,
                    offset: pagination.offset,
                    orderby: `timestamp DESC`
                },
                filter: {
                    patientID: {
                        eq: parseInt(id)
                    }
                }
            };

            // console.log(variables);

            const result = await API.graphql({
                query: listMedicalMilestone,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables
            });
            // console.log("API result PatientRecordMedicalMilestone", result);

            const data = result.data.result;
            const array = result.data.result.result;

            // process data
            let _medicalMilestone = [];
            for (let v of array) {
                _medicalMilestone.push({
                    id: v.id,
                    patientID: v.patientID,
                    date: v.timestamp,
                    milestone: v.milestone,
                    note: v.note
                });
            }

            setTableMedicalMilestone({
                ...tableMedicalMilestone,
                data: _medicalMilestone,
                loading: false
            });
            // const timer = setTimeout(() => {
            //     setTableMedicalMilestone({
            //         ...tableMedicalMilestone,
            //         loading: true,
            //         data: [
            //             {
            //                 id: "6",
            //                 date: "DD MMM YYYY",
            //                 milestone: "This is a milestone 6 abc 123 xyz 789. This is a milestone 6 abc 123 xyz 789. This is a milestone 6 abc 123 xyz 789. This is a milestone 6 abc 123 xyz 789.",
            //                 note: "This is some additional notes for this milestone 6."
            //             },
            //             {
            //                 id: "5",
            //                 date: "DD MMM YYYY",
            //                 milestone: "This is a milestone 5 abc 123 xyz 789.",
            //                 note: "This is some additional notes for this milestone 5. This is some additional notes for this milestone 5. This is some additional notes for this milestone 5. This is some additional notes for this milestone 5."
            //             },
            //             {
            //                 id: "4",
            //                 date: "DD MMM YYYY",
            //                 milestone: "This is a milestone 4 abc 123 xyz 789.",
            //                 note: "This is some additional notes for this milestone 4."
            //             },
            //             {
            //                 id: "3",
            //                 date: "DD MMM YYYY",
            //                 milestone: "This is a milestone 3 abc 123 xyz 789.",
            //                 note: "This is some additional notes for this milestone 3."
            //             },
            //             {
            //                 id: "2",
            //                 date: "DD MMM YYYY",
            //                 milestone: "This is a milestone 2 abc 123 xyz 789.",
            //                 note: "This is some additional notes for this milestone 2."
            //             },
            //             {
            //                 id: "1",
            //                 date: "DD MMM YYYY",
            //                 milestone: "This is a milestone 1 abc 123 xyz 789.",
            //                 note: "This is some additional notes for this milestone 1."
            //             },
            //         ]
            //     })
            //     clearTimeout(timer)
            // }, 1000)
        } catch (error) {
            console.log("error", error);
            notification.error({
                message: "Unable to retrieve medical milestones"
            });
            setTableMedicalMilestone({
                ...tableMedicalMilestone,
                loading: false
            });
        }
    };

    const getIntraDialysisRecords = (id) => {
        setTableIntraDialysis({
            ...tableIntraDialysis,
            loading: true
        });
        try {
            const timer = setTimeout(() => {
                setTableIntraDialysis({
                    ...tableIntraDialysis,
                    loading: true,
                    data: [
                        {
                            id: "6",
                            dialysisID: "6",
                            timestamp: "HH:mm",
                            AP: "1",
                            VP: "1",
                            SBP: "1",
                            DBP: "1",
                            HR: "1",
                            CDT: "1",
                            cUF: "1",
                            QB: "1",
                            DFLOW: "1",
                            UFR: "1",
                            temp: "1",
                            tmp: "1",
                            hepRate: "1",
                            note: "This is the intra dialysis note taken. Blah Blah Blah.",
                            symptoms:
                                "This is the intra dialysis symptoms taken. Blah Blah Blah."
                        },
                        {
                            id: "5",
                            dialysisID: "5",
                            timestamp: "HH:mm",
                            AP: "1",
                            VP: "1",
                            SBP: "1",
                            DBP: "1",
                            HR: "1",
                            CDT: "1",
                            cUF: "1",
                            QB: "1",
                            DFLOW: "1",
                            UFR: "1",
                            temp: "1",
                            tmp: "1",
                            hepRate: "1",
                            note: "This is the intra dialysis note taken. Blah Blah Blah.",
                            symptoms:
                                "This is the intra dialysis symptoms taken. Blah Blah Blah."
                        },
                        {
                            id: "4",
                            dialysisID: "4",
                            timestamp: "HH:mm",
                            AP: "1",
                            VP: "1",
                            SBP: "1",
                            DBP: "1",
                            HR: "1",
                            CDT: "1",
                            cUF: "1",
                            QB: "1",
                            DFLOW: "1",
                            UFR: "1",
                            temp: "1",
                            tmp: "1",
                            hepRate: "1",
                            note: "This is the intra dialysis note taken. Blah Blah Blah.",
                            symptoms:
                                "This is the intra dialysis symptoms taken. Blah Blah Blah."
                        },
                        {
                            id: "3",
                            dialysisID: "3",
                            timestamp: "HH:mm",
                            AP: "1",
                            VP: "1",
                            SBP: "1",
                            DBP: "1",
                            HR: "1",
                            CDT: "1",
                            cUF: "1",
                            QB: "1",
                            DFLOW: "1",
                            UFR: "1",
                            temp: "1",
                            tmp: "1",
                            hepRate: "1",
                            note: "This is the intra dialysis note taken. Blah Blah Blah.",
                            symptoms:
                                "This is the intra dialysis symptoms taken. Blah Blah Blah."
                        },
                        {
                            id: "2",
                            dialysisID: "2",
                            timestamp: "HH:mm",
                            AP: "1",
                            VP: "1",
                            SBP: "1",
                            DBP: "1",
                            HR: "1",
                            CDT: "1",
                            cUF: "1",
                            QB: "1",
                            DFLOW: "1",
                            UFR: "1",
                            temp: "1",
                            tmp: "1",
                            hepRate: "1",
                            note: "This is the intra dialysis note taken. Blah Blah Blah.",
                            symptoms:
                                "This is the intra dialysis symptoms taken. Blah Blah Blah."
                        },
                        {
                            id: "1",
                            dialysisID: "1",
                            timestamp: "HH:mm",
                            AP: "1",
                            VP: "1",
                            SBP: "1",
                            DBP: "1",
                            HR: "1",
                            CDT: "1",
                            cUF: "1",
                            QB: "1",
                            DFLOW: "1",
                            UFR: "1",
                            temp: "1",
                            tmp: "1",
                            hepRate: "1",
                            note: "This is the intra dialysis note taken. Blah Blah Blah.",
                            symptoms:
                                "This is the intra dialysis symptoms taken. Blah Blah Blah."
                        }
                    ]
                });
                clearTimeout(timer);
            }, 1000);
        } catch (error) {
            console.log("error", error);
            notification.error({
                message: "Unable to retrieve medical milestones"
            });
            setTableMedicalMilestone({
                ...tableMedicalMilestone,
                loading: false
            });
        }
    };

    const getPatientPrePostWeight = async (id) => {
        setLoadingPatientPrePostWeight(true);
        try {
            const variables = {
                pagination: {
                    limit: 4,
                    offset: 0,
                    orderby: "startTime DESC"
                }
            };

            if (patientID > 0) {
                variables.filter = {
                    patientID: { eq: parseInt(id) }
                    // endedBy: {attributeExists: false} // not working
                };
            }

            const result = await API.graphql({
                query: listDialysis,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables
            });
            // console.log("API result PatientRecordExportPatientDialysisHistory listDialysis", result);
            let data = result.data.result;

            setTableDialysisHistory({
                ...tableDialysisHistory,
                // loading: _dialysisHistory.length !== 0 ? true : false,
                // data: _dialysisHistory,
                loading: data.result.length !== 0 ? true : false,
                data: data.result,
                pagination: {
                    ...tableDialysisHistory.pagination,
                    current: props.current,
                    offset: props.offset,
                    total:
                        props.offset >= data.count ? data.count + 1 : data.count // keeps the last pagination if it is the last record
                }
            });
            // const timer = setTimeout(() => {
            //     setPatientPrePostWeight({
            //         namecode: "BCHS234",
            //         sessionMonth: "4",
            //         registration: "DD MMM YYYY, HH:mm",
            //         discharge: "DD MMM YYYY, HH:mm",
            //         eventAssessment: "This is a long long text. Blah blah blah. Hahaha.",

            //         preWt: "10",
            //         lastWt: "10",
            //         dw: "10",

            //         preTemp: "36.7",
            //         preEdema: "10",
            //         preSBP: "10",
            //         preDBP: "10",
            //         preHRT: "10",

            //         postWt: "10",
            //         postTemp: "36.8",
            //         postEdema: "10",
            //         postSBP: "10",
            //         postDBP: "10",
            //         postHRT: "10",
            //         ufTargetAtStart: "10",
            //         clinic: "Clinic ABC",
            //         machine: "Machine 123",
            //         dialyser: "10",
            //         msa: "10",
            //         membraneUse: "10",
            //         renalinPrecheck: "10",
            //         renalinPostcheck: "10",

            //         dialysisOrderID: "10",
            //     })
            //     clearTimeout(timer)
            // }, 1000)
        } catch (error) {
            console.log("error", error);
            notification.error({
                message: "Unable to retrieve dilysis order"
            });
            setLoadingDialysisOrder(false);
        }
    };

    const columnsLastLabPart1 = [
        {
            title: "Hb",
            dataIndex: "Hb",
            render(text, record, index) {
                let outOfRange =
                    Object.keys(record ?? {}).includes("Hb_timestamp") &&
                    parseFloat(text) <= 10;
                if (text !== "DD MM YY") {
                    return {
                        props: {
                            style: {
                                color: outOfRange ? "red" : "",
                                fontWeight: outOfRange ? "bold" : "normal",
                                textAlign: "center"
                            }
                        },
                        children: text
                    };
                } else if (index == 1) {
                    return {
                        props: {
                            style: { color: "white" }
                        },
                        children: "DD MM YYYY"
                    };
                }
            }
        },
        {
            title: "MCV",
            dataIndex: "MCV",
            render(text, record, index) {
                if (text !== "DD MM YY") {
                    return {
                        props: {
                            style: { color: "black", textAlign: "center" }
                        },
                        children: text
                    };
                } else if (index == 1) {
                    return {
                        props: {
                            style: { color: "white" }
                        },
                        children: "DD MM YYYY"
                    };
                }
            }
        },
        {
            title: "TW",
            dataIndex: "TW",
            render(text, record, index) {
                if (text !== "DD MM YY") {
                    return {
                        props: {
                            style: { color: "black", textAlign: "center" }
                        },
                        children: text
                    };
                } else if (index == 1) {
                    return {
                        props: {
                            style: { color: "white" }
                        },
                        children: "DD MM YYYY"
                    };
                }
            }
        },
        {
            title: "Plt",
            dataIndex: "Plt",
            render(text, record, index) {
                if (text !== "DD MM YY") {
                    return {
                        props: {
                            style: { color: "black", textAlign: "center" }
                        },
                        children: text
                    };
                } else if (index == 1) {
                    return {
                        props: {
                            style: { color: "white" }
                        },
                        children: "DD MM YYYY"
                    };
                }
            }
        },
        {
            title: "K",
            dataIndex: "K",
            render(text, record, index) {
                let outOfRange =
                    Object.keys(record ?? {}).includes("K_timestamp") &&
                    parseFloat(text) >= 5.5;
                if (text !== "DD MM YY") {
                    return {
                        props: {
                            style: {
                                color: outOfRange ? "red" : "",
                                fontWeight: outOfRange ? "bold" : "normal",
                                textAlign: "center"
                            }
                        },
                        children: text
                    };
                } else if (index == 1) {
                    return {
                        props: {
                            style: { color: "white" }
                        },
                        children: "DD MM YYYY"
                    };
                }
            }
        },
        {
            title: "HCO3",
            dataIndex: "HCO3",
            render(text, record, index) {
                let outOfRange =
                    Object.keys(record ?? {}).includes("HCO3_timestamp") &&
                    parseFloat(text) < 20;
                if (text !== "DD MM YY") {
                    return {
                        props: {
                            style: {
                                color: outOfRange ? "red" : "",
                                fontWeight: outOfRange ? "bold" : "normal",
                                textAlign: "center"
                            }
                        },
                        children: text
                    };
                } else if (index == 1) {
                    return {
                        props: {
                            style: { color: "white" }
                        },
                        children: "DD MM YYYY"
                    };
                }
            }
        },
        {
            title: "Na",
            dataIndex: "Na",
            render(text, record, index) {
                if (text !== "DD MM YY") {
                    return {
                        props: {
                            style: { color: "black", textAlign: "center" }
                        },
                        children: text
                    };
                } else if (index == 1) {
                    return {
                        props: {
                            style: { color: "white" }
                        },
                        children: "DD MM YYYY"
                    };
                }
            }
        },
        {
            title: "Cl",
            dataIndex: "chlorine",
            render(text, record, index) {
                if (text !== "DD MM YY") {
                    return {
                        props: {
                            style: { color: "black", textAlign: "center" }
                        },
                        children: text
                    };
                } else if (index == 1) {
                    return {
                        props: {
                            style: { color: "white" }
                        },
                        children: "DD MM YYYY"
                    };
                }
            }
        },
        {
            title: "Creatinine",
            dataIndex: "creatinine",
            render(text, record, index) {
                if (text !== "DD MM YY") {
                    return {
                        props: {
                            style: { color: "black", textAlign: "center" }
                        },
                        children: text
                    };
                } else if (index == 1) {
                    return {
                        props: {
                            style: { color: "white" }
                        },
                        children: "DD MM YYYY"
                    };
                }
            }
        },
        {
            title: "Glucose",
            dataIndex: "glucose",
            render(text, record, index) {
                if (text !== "DD MM YY") {
                    return {
                        props: {
                            style: { color: "black", textAlign: "center" }
                        },
                        children: text
                    };
                } else if (index == 1) {
                    return {
                        props: {
                            style: { color: "white" }
                        },
                        children: "DD MM YYYY"
                    };
                }
            }
        },
        // {
        //     title: "CCT",
        //     dataIndex: "CCT",
        //     render(text, record, index) {
        //         if (text !== 'DD MM YY') {
        //             return {
        //                 props: {
        //                     style: { color: "black", textAlign: "center" }
        //                 },
        //                 children: text
        //             }
        //         } else if (index == 1) {
        //             return {
        //                 props: {
        //                     style: { color: "white" }
        //                 },
        //                 children: "DD MMM YY"
        //             }
        //         }
        //     }
        // },
        {
            title: "Ca",
            dataIndex: "Ca",
            render(text, record, index) {
                let outOfRange =
                    Object.keys(record ?? {}).includes("Ca_timestamp") &&
                    parseFloat(text) <= 2;
                if (text !== "DD MM YY") {
                    return {
                        props: {
                            style: {
                                color: outOfRange ? "red" : "",
                                fontWeight: outOfRange ? "bold" : "normal",
                                textAlign: "center"
                            }
                        },
                        children: text
                    };
                } else if (index == 1) {
                    return {
                        props: {
                            style: { color: "white" }
                        },
                        children: "DD MM YYYY"
                    };
                }
            }
        },
        {
            title: "Po4",
            dataIndex: "Po4",
            render(text, record, index) {
                if (text !== "DD MM YY") {
                    let outOfRange =
                        Object.keys(record ?? {}).includes("Po4_timestamp") &&
                        parseFloat(text) >= 1.7;
                    return {
                        props: {
                            style: {
                                color: outOfRange ? "red" : "",
                                fontWeight: outOfRange ? "bold" : "normal",
                                textAlign: "center"
                            }
                        },
                        children: text
                    };
                } else if (index == 1) {
                    return {
                        props: {
                            style: { color: "white" }
                        },
                        children: "DD MM YYYY"
                    };
                }
            }
        },
        {
            title: "iPTH",
            dataIndex: "IPTH",
            render(text, record, index) {
                if (text !== "DD MM YY") {
                    let outOfRange =
                        Object.keys(record ?? {}).includes("IPTH_timestamp") &&
                        parseFloat(text) > 80;
                    return {
                        props: {
                            style: {
                                color: outOfRange ? "red" : "",
                                fontWeight: outOfRange ? "bold" : "normal",
                                textAlign: "center"
                            }
                        },
                        children: text
                    };
                } else if (index == 1) {
                    return {
                        props: {
                            style: { color: "white" }
                        },
                        children: "DD MM YYYY"
                    };
                }
            }
        },
        {
            title: "Urea Pre",
            dataIndex: "ureaPre",
            render(text, record, index) {
                if (text !== "DD MM YY") {
                    return {
                        props: {
                            style: { color: "black", textAlign: "center" }
                        },
                        children: text
                    };
                } else if (index == 1) {
                    return {
                        props: {
                            style: { color: "white" }
                        },
                        children: "DD MM YYYY"
                    };
                }
            }
        },
        {
            title: "Urea Post",
            dataIndex: "ureaPost",
            render(text, record, index) {
                if (text !== "DD MM YY") {
                    return {
                        props: {
                            style: { color: "black", textAlign: "center" }
                        },
                        children: text
                    };
                } else if (index == 1) {
                    return {
                        props: {
                            style: { color: "white" }
                        },
                        children: "DD MM YYYY"
                    };
                }
            }
        },
        {
            title: "Hep Bs Ag",
            dataIndex: "HepBsAg",
            render(text, record, index) {
                if (text !== "DD MM YY") {
                    let outOfRange =
                        Object.keys(record ?? {}).includes(
                            "HepBsAg_timestamp"
                        ) &&
                        (text?.toLowerCase()?.includes("pos") ||
                            text?.toLowerCase() === "weak reactive" ||
                            text?.toLowerCase() === "reactive");
                    let outOfDate =
                        !Object.keys(record ?? {}).includes(
                            "HepBsAg_timestamp"
                        ) &&
                        moment().isAfter(
                            moment
                                .utc(text)
                                .add(
                                    moment.duration(3.5, "months").asDays(),
                                    "days"
                                )
                        );
                    return {
                        props: {
                            style: {
                                color: outOfRange ? "red" : "",
                                fontWeight: outOfRange ? "bold" : "normal",
                                textAlign: "center"
                            },
                            timestampStyle: {
                                color: outOfDate ? "red" : "",
                                fontWeight: outOfDate ? "bold" : "normal",
                                textAlign: "center"
                            }
                        },
                        children: text
                    };
                } else if (index == 1) {
                    return {
                        props: {
                            style: { color: "white" }
                        },
                        children: "DD MM YYYY"
                    };
                }
            }
        },
        {
            title: "Hep Bs Ab",
            dataIndex: "HepBsAb",
            render(text, record, index) {
                if (text !== "DD MM YY") {
                    return {
                        props: {
                            style: { color: "black", textAlign: "center" }
                        },
                        children: text
                    };
                } else if (index == 1) {
                    return {
                        props: {
                            style: { color: "white" }
                        },
                        children: "DD MM YYYY"
                    };
                }
            }
        },
        {
            title: "HCV",
            dataIndex: "HCV",
            render(text, record, index) {
                if (text !== "DD MM YY") {
                    let outOfRange =
                        Object.keys(record ?? {}).includes("HCV_timestamp") &&
                        (text?.toLowerCase()?.includes("pos") ||
                            text?.toLowerCase() === "reactive");
                    let outOfDate =
                        !Object.keys(record ?? {}).includes("HCV_timestamp") &&
                        moment().isAfter(
                            moment
                                .utc(text)
                                .add(
                                    moment.duration(3.5, "months").asDays(),
                                    "days"
                                )
                        );
                    return {
                        props: {
                            style: {
                                color: outOfRange ? "red" : "",
                                fontWeight: outOfRange ? "bold" : "normal",
                                textAlign: "center"
                            },
                            timestampStyle: {
                                color: outOfDate ? "red" : "",
                                fontWeight: outOfDate ? "bold" : "normal",
                                textAlign: "center"
                            }
                        },
                        children: text
                    };
                } else if (index == 1) {
                    return {
                        props: {
                            style: { color: "white" }
                        },
                        children: "DD MM YYYY"
                    };
                }
            }
        },
        {
            title: "HIV",
            dataIndex: "HIV",
            render(text, record, index) {
                if (text !== "DD MM YY") {
                    let outOfRange =
                        Object.keys(record ?? {}).includes("HIV_timestamp") &&
                        (text?.toLowerCase()?.includes("pos") ||
                            text?.toLowerCase() === "reactive");
                    let outOfDate =
                        !Object.keys(record ?? {}).includes("HIV_timestamp") &&
                        moment().isAfter(
                            moment
                                .utc(text)
                                .add(
                                    moment.duration(5.5, "months").asDays(),
                                    "days"
                                )
                        );
                    return {
                        props: {
                            style: {
                                color: outOfRange ? "red" : "",
                                fontWeight: outOfRange ? "bold" : "normal",
                                textAlign: "center"
                            },
                            timestampStyle: {
                                color: outOfDate ? "red" : "",
                                fontWeight: outOfDate ? "bold" : "normal",
                                textAlign: "center"
                            }
                        },
                        children: text
                    };
                } else if (index == 1) {
                    return {
                        props: {
                            style: { color: "white" }
                        },
                        children: "DD MM YYYY"
                    };
                }
            }
        }
    ];

    const columnsLastLabPart2 = [
        // {
        //     title: "Hep Bs Ab",
        //     dataIndex: "HepBsAb"
        // },
        {
            title: "Fe",
            dataIndex: "Fe",
            render(text, record, index) {
                if (text !== "DD MM YY") {
                    return {
                        props: {
                            style: { color: "black", textAlign: "center" }
                        },
                        children: text
                    };
                } else if (index == 1) {
                    return {
                        props: {
                            style: { color: "white" }
                        },
                        children: "DD MM YYYY"
                    };
                }
            }
        },
        {
            title: "TIBC",
            dataIndex: "TIBC",
            render(text, record, index) {
                if (text !== "DD MM YY") {
                    return {
                        props: {
                            style: { color: "black", textAlign: "center" }
                        },
                        children: text
                    };
                } else if (index == 1) {
                    return {
                        props: {
                            style: { color: "white" }
                        },
                        children: "DD MM YYYY"
                    };
                }
            }
        },
        {
            title: "Ferritin",
            dataIndex: "ferritin",
            render(text, record, index) {
                if (text !== "DD MM YY") {
                    let outOfRange =
                        Object.keys(record ?? {}).includes(
                            "ferritin_timestamp"
                        ) && parseFloat(text) >= 700;
                    return {
                        props: {
                            style: {
                                color: outOfRange ? "red" : "",
                                fontWeight: outOfRange ? "bold" : "normal",
                                textAlign: "center"
                            }
                        },
                        children: text
                    };
                } else if (index == 1) {
                    return {
                        props: {
                            style: { color: "white" }
                        },
                        children: "DD MM YYYY"
                    };
                }
            }
        },
        // {
        //     title: "ESR",
        //     dataIndex: "ESR",
        //     render(text, record, index) {
        //         if (text !== 'DD MM YY') {
        //             return {
        //                 props: {
        //                     style: { color: "black", textAlign: "center" }
        //                 },
        //                 children: text
        //             }
        //         } else if (index == 1) {
        //             return {
        //                 props: {
        //                     style: { color: "white" }
        //                 },
        //                 children: "DD MM YYYY"
        //             }
        //         }
        //     }
        // },
        {
            title: "CRP",
            dataIndex: "CRP",
            render(text, record, index) {
                if (text !== "DD MM YY") {
                    return {
                        props: {
                            style: { color: "black", textAlign: "center" }
                        },
                        children: text
                    };
                } else if (index == 1) {
                    return {
                        props: {
                            style: { color: "white" }
                        },
                        children: "DD MM YYYY"
                    };
                }
            }
        },
        {
            title: "uricAcid",
            dataIndex: "uric",
            render(text, record, index) {
                if (text !== "DD MM YY") {
                    return {
                        props: {
                            style: { color: "black", textAlign: "center" }
                        },
                        children: text
                    };
                } else if (index == 1) {
                    return {
                        props: {
                            style: { color: "white" }
                        },
                        children: "DD MM YYYY"
                    };
                }
            }
        },
        {
            title: "TC",
            dataIndex: "TC",
            render(text, record, index) {
                if (text !== "DD MM YY") {
                    return {
                        props: {
                            style: { color: "black", textAlign: "center" }
                        },
                        children: text
                    };
                } else if (index == 1) {
                    return {
                        props: {
                            style: { color: "white" }
                        },
                        children: "DD MM YYYY"
                    };
                }
            }
        },
        {
            title: "HDL",
            dataIndex: "HDL",
            render(text, record, index) {
                if (text !== "DD MM YY") {
                    return {
                        props: {
                            style: { color: "black", textAlign: "center" }
                        },
                        children: text
                    };
                } else if (index == 1) {
                    return {
                        props: {
                            style: { color: "white" }
                        },
                        children: "DD MM YYYY"
                    };
                }
            }
        },
        {
            title: "LDL",
            dataIndex: "LDL",
            render(text, record, index) {
                if (text !== "DD MM YY") {
                    return {
                        props: {
                            style: { color: "black", textAlign: "center" }
                        },
                        children: text
                    };
                } else if (index == 1) {
                    return {
                        props: {
                            style: { color: "white" }
                        },
                        children: "DD MM YYYY"
                    };
                }
            }
        },
        {
            title: "TG",
            dataIndex: "TG",
            render(text, record, index) {
                if (text !== "DD MM YY") {
                    return {
                        props: {
                            style: { color: "black", textAlign: "center" }
                        },
                        children: text
                    };
                } else if (index == 1) {
                    return {
                        props: {
                            style: { color: "white" }
                        },
                        children: "DD MM YYYY"
                    };
                }
            }
        },
        {
            title: "Ratio",
            dataIndex: "Ratio",
            render(text, record, index) {
                if (text !== "DD MM YY") {
                    return {
                        props: {
                            style: { color: "black", textAlign: "center" }
                        },
                        children: text
                    };
                } else if (index == 1) {
                    return {
                        props: {
                            style: { color: "white" }
                        },
                        children: "DD MM YYYY"
                    };
                }
            }
        },
        {
            title: "Hba1c",
            dataIndex: "Hba1c",
            render(text, record, index) {
                if (text !== "DD MM YY") {
                    let outOfRange =
                        Object.keys(record ?? {}).includes("Hba1c_timestamp") &&
                        parseFloat(text) >= 7;
                    return {
                        props: {
                            style: {
                                color: outOfRange ? "red" : "",
                                fontWeight: outOfRange ? "bold" : "normal",
                                textAlign: "center"
                            }
                        },
                        children: text
                    };
                } else if (index == 1) {
                    return {
                        props: {
                            style: { color: "white" }
                        },
                        children: "DD MM YYYY"
                    };
                }
            }
        },
        {
            title: "Protein",
            dataIndex: "protein",
            render(text, record, index) {
                if (text !== "DD MM YY") {
                    return {
                        props: {
                            style: { color: "black", textAlign: "center" }
                        },
                        children: text
                    };
                } else if (index == 1) {
                    return {
                        props: {
                            style: { color: "white" }
                        },
                        children: "DD MM YYYY"
                    };
                }
            }
        },
        {
            title: "Alb",
            dataIndex: "Alb",
            render(text, record, index) {
                if (text !== "DD MM YY") {
                    return {
                        props: {
                            style: { color: "black", textAlign: "center" }
                        },
                        children: text
                    };
                } else if (index == 1) {
                    return {
                        props: {
                            style: { color: "white" }
                        },
                        children: "DD MM YYYY"
                    };
                }
            }
        },
        {
            title: "Biltotal",
            dataIndex: "Biltotal",
            render(text, record, index) {
                if (text !== "DD MM YY") {
                    return {
                        props: {
                            style: { color: "black", textAlign: "center" }
                        },
                        children: text
                    };
                } else if (index == 1) {
                    return {
                        props: {
                            style: { color: "white" }
                        },
                        children: "DD MM YYYY"
                    };
                }
            }
        },

        {
            title: "Sap",
            dataIndex: "SAP",
            render(text, record, index) {
                if (text !== "DD MM YY") {
                    return {
                        props: {
                            style: { color: "black", textAlign: "center" }
                        },
                        children: text
                    };
                } else if (index == 1) {
                    return {
                        props: {
                            style: { color: "white" }
                        },
                        children: "DD MM YYYY"
                    };
                }
            }
        },
        {
            title: "Ast",
            dataIndex: "SGOT_AST",
            render(text, record, index) {
                if (text !== "DD MM YY") {
                    return {
                        props: {
                            style: { color: "black", textAlign: "center" }
                        },
                        children: text
                    };
                } else if (index == 1) {
                    return {
                        props: {
                            style: { color: "white" }
                        },
                        children: "DD MM YYYY"
                    };
                }
            }
        },
        {
            title: "Alt",
            dataIndex: "SGPT_ALT",
            render(text, record, index) {
                if (text !== "DD MM YY") {
                    return {
                        props: {
                            style: { color: "black", textAlign: "center" }
                        },
                        children: text
                    };
                } else if (index == 1) {
                    return {
                        props: {
                            style: { color: "white" }
                        },
                        children: "DD MM YYYY"
                    };
                }
            }
        },
        {
            title: "Ggt",
            dataIndex: "ggt",
            render(text, record, index) {
                if (text !== "DD MM YY") {
                    return {
                        props: {
                            style: { color: "black", textAlign: "center" }
                        },
                        children: text
                    };
                } else if (index == 1) {
                    return {
                        props: {
                            style: { color: "white" }
                        },
                        children: "DD MM YYYY"
                    };
                }
            }
        },
        {
            title: "Mg",
            dataIndex: "Mg",
            render(text, record, index) {
                if (text !== "DD MM YY") {
                    return {
                        props: {
                            style: { color: "black", textAlign: "center" }
                        },
                        children: text
                    };
                } else if (index == 1) {
                    return {
                        props: {
                            style: { color: "white" }
                        },
                        children: "DD MM YYYY"
                    };
                }
            }
        }
    ];

    const columnsDialysisHistorySummary = [
        {
            title: "MONTH",
            dataIndex: "month",
            flex: "0 0 45px"
            // render(text, record) {
            //     return {
            //         props: {
            //             style: { flex: "0 0 100px" }
            //         },
            //         children: text
            //     };
            // }
        },
        {
            title: "TOTAL",
            dataIndex: "total",
            flex: "0 0 30px",
            render(text, record, index) {
                if (text !== null)
                    return {
                        props: {
                            style: { textAlign: "center" }
                        },
                        children: text
                    };
            }
        },
        {
            title: "DW",
            dataIndex: "dw",
            render(text, record, index) {
                if (text !== null)
                    return {
                        props: {
                            style: { textAlign: "center" }
                        },
                        children: text
                    };
            }
        },
        {
            title: "WT Last",
            dataIndex: "wtLast",
            render(text, record, index) {
                if (text !== null)
                    return {
                        props: {
                            style: { textAlign: "center" }
                        },
                        children: text
                    };
            }
        },
        {
            title: "WT (Pre)",
            dataIndex: "wtPre",
            render(text, record, index) {
                if (text !== null)
                    return {
                        props: {
                            style: { textAlign: "center" }
                        },
                        children: text
                    };
            }
        },
        {
            title: "WT (Post)",
            dataIndex: "wtPost",
            render(text, record, index) {
                if (text !== null)
                    return {
                        props: {
                            style: { textAlign: "center" }
                        },
                        children: text
                    };
            }
        },
        {
            title: "UF Target",
            dataIndex: "ufTarget",
            flex: "0 0 70px",
            render(text, record, index) {
                if (text !== null)
                    return {
                        props: {
                            style: { textAlign: "center" }
                        },
                        children: text
                    };
            }
        },
        {
            title: "WT off Target",
            dataIndex: "wtOffTarget",
            flex: "0 0 80px",
            render(text, record, index) {
                if (text !== null)
                    return {
                        props: {
                            style: { textAlign: "center" }
                        },
                        children: text
                    };
            }
        },
        {
            title: "SBP (Post)",
            dataIndex: "sbpPost",
            flex: "1.2",
            render(text, record, index) {
                if (text !== null)
                    return {
                        props: {
                            style: { textAlign: "center" }
                        },
                        children: text
                    };
            }
        },
        {
            title: "DBP (Post)",
            dataIndex: "dbpPost",
            render(text, record, index) {
                if (text !== null)
                    return {
                        props: {
                            style: { textAlign: "center" }
                        },
                        children: text
                    };
            }
        },
        {
            title: "KTV",
            dataIndex: "ktv",
            flex: "0 0 70px",
            render(text, record, index) {
                if (text !== null)
                    return {
                        props: {
                            style: { textAlign: "center" }
                        },
                        children: text
                    };
            }
        }
    ];

    const columnsCurrentMedication = [
        // {
        //     title: "Start Date",
        //     dataIndex: "dateStart"
        // },
        {
            title: "Medication",
            dataIndex: "medication"
            // width: "100%",
        }
        // {
        //     title: "Route",
        //     dataIndex: "route"
        // },
        // {
        //     title: "Frequency",
        //     dataIndex: "freq"
        // },
    ];

    const columnsMedicationAdministered = [
        {
            title: "Medication Administered",
            dataIndex: "medicationAdministered"
            // width: "100%",
        }
    ];

    const columnsDialysisHistoryPart1 = [
        {
            title: "Date / Time",
            dataIndex: "timestampDisplay",
            width: 150
        },
        {
            title: "SE",
            dataIndex: "sessionMonth"
        },
        {
            title: "QB",
            dataIndex: "QB"
        },
        {
            title: "AP",
            dataIndex: "AP"
        },
        {
            title: "VP",
            dataIndex: "VP"
        },
        {
            title: "FLOW",
            dataIndex: "DFLOW"
        },
        {
            title: "COND",
            dataIndex: "CDT"
        },
        {
            title: "LAST WT",
            dataIndex: "lastWeight"
        },
        {
            title: "Pre Edema",
            dataIndex: "preEdema"
        },
        {
            title: "Post Edema",
            dataIndex: "postEdema"
        },
        {
            title: "PRE WT",
            dataIndex: "preWeight"
        },
        {
            title: "DW",
            dataIndex: "dryWeight"
        },
        {
            title: "UFT",
            dataIndex: "ufTargetStart"
        },
        // ===
        {
            title: "POST WT",
            dataIndex: "postWeight"
        },
        {
            title: "OFF Target",
            dataIndex: "offTarget"
        },
        {
            title: "Post SBP / DBP",
            dataIndex: "postSBPDBP"
        },
        {
            title: "SBP",
            dataIndex: "SBP"
        },
        {
            title: "DBP",
            dataIndex: "DBP"
        },
        {
            title: "HR",
            dataIndex: "HR"
        }
    ];

    const columnsDialysisHistoryPart2 = [
        {
            title: "Date / Time",
            dataIndex: "timestampDisplay",
            width: 120
        },
        {
            title: "Temp",
            dataIndex: "temp"
        },
        {
            title: "Temp Admit",
            dataIndex: "preTemp"
        },
        {
            title: "Temp Discharge",
            dataIndex: "postTemp"
        },
        {
            title: "MSA",
            dataIndex: "MSA"
        },
        {
            title: "Dialyser",
            dataIndex: "dialyser"
        },
        {
            title: "KTV Online",
            dataIndex: "KTVonline"
        },
        {
            title: "Dialysis Note",
            dataIndex: "notes",
            width: 450,
            render(text, record) {
                return {
                    props: {
                        style: {
                            display: "inline-block",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis"
                        }
                    },
                    children: text
                };
            }
        },
        {
            title: "Symptoms",
            dataIndex: "displaySymptoms"
        }
    ];

    const columnsIntraDialysis = [
        {
            title: "Date / Time",
            dataIndex: "timestamp",
            flex: "0 0 65px",
            render(text, record, index) {
                if (text !== null)
                    return {
                        props: {
                            style: { textAlign: "center" }
                        },
                        children: text
                    };
            }
        },
        {
            title: "SBP",
            dataIndex: "SBP",
            flex: "0 0 35px",
            render(text, record, index) {
                if (text !== null)
                    return {
                        props: {
                            style: { textAlign: "center" }
                        },
                        children: text
                    };
            }
        },
        {
            title: "DBP",
            dataIndex: "DBP",
            flex: "0 0 35px",
            render(text, record, index) {
                if (text !== null)
                    return {
                        props: {
                            style: { textAlign: "center" }
                        },
                        children: text
                    };
            }
        },
        {
            title: "HR",
            dataIndex: "HR",
            flex: "0 0 35px",
            render(text, record, index) {
                if (text !== null)
                    return {
                        props: {
                            style: { textAlign: "center" }
                        },
                        children: text
                    };
            }
        },
        {
            title: "QB",
            dataIndex: "QB",
            flex: "0 0 35px",
            render(text, record, index) {
                if (text !== null)
                    return {
                        props: {
                            style: { textAlign: "center" }
                        },
                        children: text
                    };
            }
        },
        {
            title: "AP",
            dataIndex: "AP",
            flex: "0 0 35px",
            render(text, record, index) {
                if (text !== null)
                    return {
                        props: {
                            style: { textAlign: "center" }
                        },
                        children: text
                    };
            }
        },
        {
            title: "VP",
            dataIndex: "VP",
            flex: "0 0 35px",
            render(text, record, index) {
                if (text !== null)
                    return {
                        props: {
                            style: { textAlign: "center" }
                        },
                        children: text
                    };
            }
        },
        {
            title: "TMP",
            dataIndex: "tmp",
            flex: "0 0 35px",
            render(text, record, index) {
                if (text !== null)
                    return {
                        props: {
                            style: { textAlign: "center" }
                        },
                        children: text
                    };
            }
        },
        {
            title: "UFR (ℓ)",
            dataIndex: "UFR",
            flex: "0 0 55px",
            render(text, record, index) {
                if (text !== null)
                    return {
                        props: {
                            style: { textAlign: "center" }
                        },
                        children: text
                    };
            }
        },
        {
            title: "UFV (ℓ)",
            dataIndex: "cUF",
            flex: "0 0 55px",
            render(text, record, index) {
                if (text !== null)
                    return {
                        props: {
                            style: { textAlign: "center" }
                        },
                        children: text
                    };
            }
        },
        {
            title: "FLOW",
            dataIndex: "DFLOW",
            flex: "0 0 35px",
            render(text, record, index) {
                if (text !== null)
                    return {
                        props: {
                            style: { textAlign: "center" }
                        },
                        children: text
                    };
            }
        },
        {
            title: "COND",
            dataIndex: "CDT",
            flex: "0 0 35px",
            render(text, record, index) {
                if (text !== null)
                    return {
                        props: {
                            style: { textAlign: "center" }
                        },
                        children: text
                    };
            }
        },
        {
            title: "Temp",
            dataIndex: "temp",
            flex: "0 0 35px",
            render(text, record, index) {
                if (text !== null)
                    return {
                        props: {
                            style: { textAlign: "center" }
                        },
                        children: text
                    };
            }
        },
        {
            title: "HepRate",
            dataIndex: "hepRate",
            flex: "0 0 55px",
            render(text, record, index) {
                if (text !== null)
                    return {
                        props: {
                            style: { textAlign: "center" }
                        },
                        children: text
                    };
            }
        },
        {
            title: "Intra Dialysis Note",
            dataIndex: "note",
            // width: 400,
            flex: 4,
            render(text, record) {
                return {
                    props: {
                        style: {
                            display: "inline-block",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "400px"
                        }
                    },
                    children: text
                };
            }
        },
        {
            title: "Symptoms",
            dataIndex: "displaySymptoms",
            // width: 200,
            flex: 2,
            render(text, record) {
                return {
                    props: {
                        style: {
                            display: "inline-block",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "200px"
                        }
                    },
                    children: text
                };
            }
        }
    ];

    const renderHeaderItems = (array) => {
        const items = array.map((item, index) => {
            // console.log("item", item);
            // const width = item.width !== undefined ? item.width : null
            const flex = item.flex !== undefined ? item.flex : null;

            let style = {};
            if (item?.render) {
                style = item?.render(null, null)?.props?.style;
            }

            return (
                // <div key={item.dataIndex} className={item.dataIndex !== "action" ? "column" : ""} style={width !== null ? { width: width } : {}}>
                // <div key={item.dataIndex} className={"column"} style={width !== null ? { width: width, ...style } : { ...style }}>
                <div
                    key={item.dataIndex}
                    className={"column"}
                    style={
                        flex !== null
                            ? {
                                  flex: flex,
                                  ...style,
                                  fontWeight: 700,
                                  textAlign: "center"
                              }
                            : { ...style, fontWeight: 700, textAlign: "center" }
                    }
                >
                    {item.title}
                </div>
            );
        });
        return items;
    };

    const renderCardResultHeader = (array) => {
        return array.length === 0 ? "" : renderHeaderItems(array);
    };

    const renderItems = (columns, array) => {
        if (Object.keys(array).length > 0) {
            const items = array.map((item, arrIndex) => {
                const renderItemColumns = (columns, rowItem) => {
                    // console.log("columns", columns);
                    // console.log("rowItem", rowItem);
                    const renderRowItems = columns.map((columnItem, index) => {
                        // console.log("columns item", columnItem);
                        const width =
                            columnItem.width !== undefined
                                ? columnItem.width
                                : null;
                        const flex =
                            columnItem.flex !== undefined
                                ? columnItem.flex
                                : null;
                        let value = "";
                        let style = {};
                        for (const key in rowItem) {
                            // console.log("key", key);
                            // console.log("value", rowItem[key]);
                            if (columnItem.dataIndex === key) {
                                value = rowItem[key];
                                if (columnItem?.render) {
                                    let props = columnItem?.render(
                                        value,
                                        rowItem,
                                        arrIndex
                                    )?.props;
                                    style =
                                        arrIndex === 1 && props?.timestampStyle
                                            ? props?.timestampStyle
                                            : props?.style;
                                }
                                break;
                            }
                        }
                        return (
                            // <div key={columnItem.dataIndex} className={columnItem.dataIndex === "datetime" ? "column date" : (columnItem.dataIndex !== "action" ? "column" : "")} style={width !== null ? { width: width } : {}}>
                            // <div key={columnItem.dataIndex} className={columnItem.dataIndex === "datetime" ? "column date" : "column"} style={width !== null ? { width: width, ...style } : { ...style }}>
                            <div
                                key={columnItem.dataIndex}
                                className={
                                    columnItem.dataIndex === "datetime"
                                        ? "column date"
                                        : "column"
                                }
                                style={
                                    flex !== null
                                        ? { flex: flex, ...style }
                                        : { ...style }
                                }
                            >
                                {value}&nbsp;
                            </div>
                        );
                    });
                    return renderRowItems;
                };

                return (
                    <div
                        key={item.id}
                        className={
                            arrIndex % 2 === 0 ? "record highlight" : "record"
                        }
                    >
                        {renderItemColumns(columns, item)}
                    </div>
                );
            });
            return items;
        } else {
            return null;
        }
    };

    const renderCardResult = (columns, array) => {
        return array.length === 0 ? (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ) : (
            renderItems(columns, array)
        );
    };

    const renderDisplayDescriptionItems = (array) => {
        const items = array.map((item, index) => {
            return (
                <div className="item">
                    <div className="item-label">{item.label}</div>
                    <div className="item-value">{item.value}</div>
                </div>
            );
        });
        return items;
    };

    const renderDisplayValueItems = (array) => {
        const items = array.map((item, index) => {
            return (
                <div className="item-medicalmilestone">
                    <div className="milestone">
                        {item.date}: {item.milestone}
                    </div>
                    <div className="note">{item.note}</div>
                </div>
            );
        });
        return items;
    };

    const ComponentToPrint = forwardRef((props, ref) => {
        // console.log("props", props);
        // console.log("ref", ref);
        let pages = [];
        for (let [index, item] of displayDialysisHistory.entries()) {
            let patientPrePostWeight = item;
            let displayIntraDialysis = displayDialysisHistoryInterval.filter(
                (s) => s.dialysisID == item.id
            );
            pages.push(
                <div>
                    {printing === true && patientClinic !== -1 ? (
                        <div style={{ textAlign: "center" }}>
                            {[
                                patientClinic?.name + " Center",
                                patientClinic?.address,
                                patientClinic?.phoneNo,
                                patientClinic?.email
                            ]
                                .filter((s) => s && s.length > 0)
                                .join(" | ")}
                        </div>
                    ) : (
                        <></>
                    )}
                    <div className="row">
                        <div className="flex">
                            <Spin spinning={loadingPatientPrePostWeight}>
                                <div className="container-border row">
                                    <div
                                        className="column dialysisorder"
                                        style={{
                                            paddingRight: "10px",
                                            borderRight: "1px solid #000000"
                                        }}
                                    >
                                        {renderDisplayDescriptionItems([
                                            {
                                                label: "Name",
                                                value: patient?.name
                                            },
                                            {
                                                label: "NRIC",
                                                value: patient?.nric
                                            },
                                            {
                                                label: "Session Month",
                                                value: patientPrePostWeight.sessionMonth
                                            },
                                            {
                                                label: "Registration",
                                                value: patientPrePostWeight.timestampDisplay
                                            },
                                            {
                                                label: "Discharge",
                                                value: patientPrePostWeight.dischargeDisplay
                                            },
                                            {
                                                label: "Event and Assessment: Triage to Discharge",
                                                value: patientPrePostWeight.notes
                                            },
                                            {
                                                label: "PRE-WT (KG)",
                                                value: patientPrePostWeight.preWeight
                                            },
                                            {
                                                label: "POST-WT (KG)",
                                                value: patientPrePostWeight.postWeight
                                            },
                                            {
                                                label: "LAST-WT (KG)",
                                                value: patientPrePostWeight.lastWeight
                                            }
                                        ])}
                                    </div>
                                    <div
                                        className="column dialysisorder"
                                        style={{ paddingRight: "10px" }}
                                    >
                                        {renderDisplayDescriptionItems([
                                            {
                                                label: <>Pre-Temp (&#8451;)</>,
                                                value: patientPrePostWeight.preTemp
                                            },
                                            {
                                                label: "Pre Edema",
                                                value: patientPrePostWeight.preEdema
                                            },
                                            {
                                                label: "Pre SBP",
                                                value: patientPrePostWeight.preSBP
                                            },
                                            {
                                                label: "Pre DBP",
                                                value: patientPrePostWeight.preDBP
                                            },
                                            {
                                                label: "Pre HRT",
                                                value: patientPrePostWeight.preHRT
                                            },
                                            {
                                                label: "Expected DW Difference",
                                                value: patientPrePostWeight.expectedDWDifference
                                            },
                                            {
                                                label: "Expected LW Difference",
                                                value: patientPrePostWeight.expectedLWDifference
                                            },
                                            {
                                                label: (
                                                    <>
                                                        UF Target At Start
                                                        (&#8467;)
                                                    </>
                                                ),
                                                value: patientPrePostWeight.ufTargetStart
                                            },
                                            {
                                                label: "DW (KG)",
                                                value: patientPrePostWeight.dryWeight
                                            }
                                        ])}
                                    </div>
                                    <div
                                        className="column dialysisorder"
                                        style={{
                                            paddingRight: "10px",
                                            borderRight: "1px solid #000000"
                                        }}
                                    >
                                        {renderDisplayDescriptionItems([
                                            {
                                                label: <>Post-Temp (&#8451;)</>,
                                                value: patientPrePostWeight.postTemp
                                            },
                                            {
                                                label: "Post Edema",
                                                value: patientPrePostWeight.postEdema
                                            },
                                            {
                                                label: "Post SBP",
                                                value: patientPrePostWeight.postSBP
                                            },
                                            {
                                                label: "Post DBP",
                                                value: patientPrePostWeight.postDBP
                                            },
                                            {
                                                label: "Post HRT",
                                                value: patientPrePostWeight.postHRT
                                            },
                                            {
                                                label: "KTV Online",
                                                value: patientPrePostWeight.KTVonline
                                            }
                                        ])}
                                    </div>
                                    <div className="column dialysisorder">
                                        {renderDisplayDescriptionItems([
                                            {
                                                label: "Clinic",
                                                value: patientPrePostWeight.clinic
                                            },
                                            {
                                                label: "Machine",
                                                value: patientPrePostWeight.machine
                                            },
                                            {
                                                label: "Dialyser",
                                                value: patientPrePostWeight.dialyser
                                            },
                                            {
                                                label: "MSA",
                                                value: patientPrePostWeight.MSA
                                            },
                                            {
                                                label: "Membrane Use",
                                                value: patientPrePostWeight.membraneUse
                                            }
                                            // {
                                            //     label: "Renalin Postcheck",
                                            //     value: patientPrePostWeight.postRenalin
                                            // },
                                        ])}
                                    </div>
                                </div>
                            </Spin>
                        </div>
                        <Spin spinning={loadingDialysisOrder}>
                            <div className="container-border row">
                                <div
                                    className="column dialysisorder"
                                    style={{
                                        paddingRight: "10px",
                                        borderRight: "1px solid #000000"
                                    }}
                                >
                                    {renderDisplayDescriptionItems([
                                        {
                                            label: "MSA",
                                            value: dialysisOrder.msa
                                        },
                                        {
                                            label: "DORDER",
                                            value: dialysisOrder.dorderDate
                                        },
                                        {
                                            label: "FREQMTH",
                                            value: dialysisOrder.freqmth
                                        },
                                        {
                                            label: "Duration",
                                            value: dialysisOrder.duration
                                        },
                                        {
                                            label: "HEP (load)",
                                            value: dialysisOrder.hepload
                                        },
                                        {
                                            label: "HEP (cont)",
                                            value: dialysisOrder.hepcont
                                        },
                                        {
                                            label: "COAG (Others)",
                                            value: dialysisOrder.antioOthers
                                        },
                                        {
                                            label: "Dialysate (%)",
                                            value: dialysisOrder.dialysate
                                        }
                                    ])}
                                </div>
                                <div className="column dialysisorder">
                                    {renderDisplayDescriptionItems([
                                        {
                                            label: "Primary Access",
                                            value: dialysisOrder.primaryAccess
                                        },
                                        {
                                            label: "BICARBONATE",
                                            value: dialysisOrder.bicarbonate
                                        },
                                        {
                                            label: "'A' Needle",
                                            value: dialysisOrder.aNeedle
                                        },
                                        {
                                            label: "'V' Needle",
                                            value: dialysisOrder.vNeedle
                                        },
                                        {
                                            label: <>Temp (&#8451;)</>,
                                            value: dialysisOrder.temp
                                        },
                                        {
                                            label: "QD",
                                            value: dialysisOrder.qd
                                        },
                                        {
                                            label: "QB",
                                            value: dialysisOrder.qb
                                        },
                                        {
                                            label: "DW",
                                            value: dialysisOrder.dw
                                        }
                                    ])}
                                </div>
                            </div>
                        </Spin>
                    </div>
                    <div className="row">
                        <div className="column flex">
                            <div
                                className="container-table"
                                style={{ whiteSpace: "nowrap" }}
                            >
                                <div className="row">
                                    <div className="title">
                                        Last Individual Lab
                                    </div>
                                    &nbsp;&nbsp;&nbsp;
                                    {/* <div className="subtitle">Date Lab: */}
                                    {/* 01 Apr 2022 */}
                                    {/* {displayLastLab[0]?.timestamp} */}
                                    {/* </div> */}
                                </div>
                                <Spin spinning={tableLastLab.loading}>
                                    <div className="card lastlab">
                                        <div className="card-header">
                                            {renderCardResultHeader(
                                                columnsLastLabPart1
                                            )}
                                        </div>
                                        <div className="card-content">
                                            <div className="record-content">
                                                {renderCardResult(
                                                    columnsLastLabPart1,
                                                    displayLastLab
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </Spin>
                                <Spin spinning={tableLastLab.loading}>
                                    <div
                                        className="card lastlab"
                                        style={{ marginTop: "5px" }}
                                    >
                                        <div className="card-header">
                                            {renderCardResultHeader(
                                                columnsLastLabPart2
                                            )}
                                        </div>
                                        <div className="card-content">
                                            <div className="record-content">
                                                {renderCardResult(
                                                    columnsLastLabPart2,
                                                    displayLastLab
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </Spin>
                            </div>
                            <div className="container-table">
                                <div className="title">Intra Dialysis</div>
                                <Spin spinning={tableIntraDialysis.loading}>
                                    <div
                                        className="card"
                                        style={{ whiteSpace: "nowrap" }}
                                    >
                                        <div className="card-header">
                                            {renderCardResultHeader(
                                                columnsIntraDialysis
                                            )}
                                        </div>
                                        <div className="card-content">
                                            <div className="record-content">
                                                {renderCardResult(
                                                    columnsIntraDialysis,
                                                    displayIntraDialysis
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </Spin>
                            </div>
                            <div className="container-table">
                                <div className="title">Dialysis Summary</div>
                                <Spin
                                    spinning={
                                        tableDiaylsisHistorySummary.loading
                                    }
                                >
                                    <div
                                        className="card"
                                        style={{ whiteSpace: "nowrap" }}
                                    >
                                        <div className="card-header">
                                            {renderCardResultHeader(
                                                columnsDialysisHistorySummary
                                            )}
                                        </div>
                                        <div className="card-content">
                                            <div className="record-content">
                                                {renderCardResult(
                                                    columnsDialysisHistorySummary,
                                                    displayDialysisHistorySummary
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </Spin>
                            </div>
                        </div>
                        <div className="column" style={{ minWidth: "250px" }}>
                            <div className="container-table">
                                <div className="title">Current Medication</div>
                                <Spin spinning={tableCurrentMedication.loading}>
                                    <div className="card currentmed">
                                        <div className="card-header">
                                            {renderCardResultHeader(
                                                columnsCurrentMedication
                                            )}
                                        </div>
                                        <div className="card-content">
                                            <div className="record-content">
                                                {renderCardResult(
                                                    columnsCurrentMedication,
                                                    displayCurrentMedication
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </Spin>
                            </div>
                            <div
                                className="container-table"
                                style={{ width: "250px" }}
                            >
                                <div className="title">
                                    Medication Administered
                                </div>
                                <Spin spinning={tableCurrentMedication.loading}>
                                    <div className="card currentmed">
                                        <div className="card-header">
                                            {renderCardResultHeader(
                                                columnsMedicationAdministered
                                            )}
                                        </div>
                                        <div className="card-content">
                                            <div className="record-content">
                                                {renderCardResult(
                                                    columnsMedicationAdministered,
                                                    displayMedicationAdministered.filter(
                                                        (s) =>
                                                            s.dialysisID ===
                                                                item.id ||
                                                            (s.createdOn >=
                                                                item.startTime &&
                                                                s.createdOn <=
                                                                    item.endTime)
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </Spin>
                            </div>
                        </div>
                    </div>
                    {index !== displayDialysisHistory.length - 1 ? (
                        <div className="page-break">&nbsp;</div>
                    ) : (
                        ""
                    )}
                </div>
            );
        }
        return (
            <div ref={ref} className="export-patientsummary">
                <style type="text/css">
                    {"@media print{@page {size: landscape}}"}
                </style>
                {pages}
            </div>
        );
    });

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: `Patient Dialysis - ${patient.name} - ${moment().format(
            "DD MMM YYYY"
        )}`,
        onBeforeGetContent: () => {
            setPrinting(true);
        },
        onAfterPrint: () => {
            // setPrinting(false);
        }
    });

    useEffect(() => {
        getLastLabRecord(patientID);
        getDialysisHistorySummaryRecords(patientID);
        getCurrentMedicationRecords(patientID);
        // getDiaysisHistoryRecords(patientID)
        getLatestClinicalNoteRecord(patientID);
        getDialysisOrderRecord(patientID);
        getMedicalMilestoneRecords(patientID);
        // getIntraDialysisRecords(patientID)
        getPatientPrePostWeight(patientID);
        // const timer = setTimeout(() => {
        //     setEnablePrint(true)
        //     clearTimeout(timer)
        // }, 3000)
    }, []);

    useEffect(() => {
        if (
            tableLastLab.data !== -1 &&
            tableDiaylsisHistorySummary.data !== -1 &&
            tableCurrentMedication.data !== -1 &&
            tableDialysisHistory.data !== -1 &&
            tableDialysisHistoryInterval.data !== -1 &&
            latestClinicalNote !== -1 &&
            dialysisOrder !== -1 &&
            tableMedicalMilestone.data !== -1 &&
            patient !== -1
        ) {
            // console.log('enablePrint', tableLastLab.data, tableDiaylsisHistorySummary.data, tableCurrentMedication.data, tableDialysisHistory.data, tableDialysisHistoryInterval.data, latestClinicalNote, dialysisOrder, tableMedicalMilestone.data, tableLastLab.data && tableDiaylsisHistorySummary.data && tableCurrentMedication.data && tableDialysisHistory.data && tableDialysisHistoryInterval.data && latestClinicalNote && dialysisOrder && tableMedicalMilestone.data);
            setEnablePrint(true);
        }
    }, [
        tableLastLab.data,
        tableDiaylsisHistorySummary.data,
        tableCurrentMedication.data,
        tableDialysisHistory.data,
        tableDialysisHistoryInterval.data,
        latestClinicalNote,
        dialysisOrder,
        tableMedicalMilestone.data,
        patient
    ]);

    useEffect(() => {
        if (tableLastLab.data !== -1) {
            let lastLab = [];
            for (let item of tableLastLab.data) {
                lastLab.push({
                    ...item,
                    timestamp: item.timestamp
                        ? moment.utc(item.timestamp).local().format("DD MMM YY")
                        : "-"
                });
                let attributeTimestamp = {};
                for (let i of Object.keys(item)) {
                    if (i.includes("_timestamp")) {
                        attributeTimestamp[i.split("_timestamp")[0]] = item[i]
                            ? moment.utc(item[i]).local().format("DD MMM YY")
                            : "DD MM YY";
                    }
                }
                lastLab.push(attributeTimestamp);
            }
            setDisplayLastLab(lastLab);
            setTableLastLab({
                ...tableLastLab,
                loading: false
            });
        }
    }, [tableLastLab.data]);

    useEffect(() => {
        if (tableDiaylsisHistorySummary.data !== -1) {
            let _dialysisHistorySummaries = [];
            for (let item of tableDiaylsisHistorySummary.data) {
                let offTargetAverage = (
                    item.offTargetAverage / 1000.0
                )?.toFixed(1);
                _dialysisHistorySummaries.push({
                    ...item,
                    month: item.yearMonth,
                    total: item.totalSession,

                    dw: `${(item.dryWeightAverage / 1000.0)?.toFixed(1)} (${(
                        item.dryWeightMin / 1000.0
                    )?.toFixed(1)} - ${(item.dryWeightMax / 1000.0)?.toFixed(
                        1
                    )})`,

                    wtLast: `${(item.lastWeightAverage / 1000.0)?.toFixed(
                        1
                    )} (${(item.lastWeightMin / 1000.0)?.toFixed(1)} - ${(
                        item.lastWeightMax / 1000.0
                    )?.toFixed(1)})`,

                    wtPre: `${(item.preWeightAverage / 1000.0)?.toFixed(1)} (${(
                        item.preWeightMin / 1000.0
                    )?.toFixed(1)} - ${(item.preWeightMax / 1000.0)?.toFixed(
                        1
                    )})`,

                    wtPost: `${(item.postWeightAverage / 1000.0)?.toFixed(
                        1
                    )} (${(item.postWeightMin / 1000.0)?.toFixed(1)} - ${(
                        item.postWeightMax / 1000.0
                    )?.toFixed(1)})`,

                    ufTarget: `${item.ufTargetStartAverage?.toFixed(
                        1
                    )} (${item.ufTargetStartMin?.toFixed(
                        1
                    )} - ${item.ufTargetStartMax?.toFixed(1)})`,

                    wtOffTarget: `${
                        offTargetAverage == "-0.0" ? 0.0 : offTargetAverage
                    } (${(item.offTargetMin / 1000.0)?.toFixed(1)} - ${(
                        item.offTargetMax / 1000.0
                    )?.toFixed(1)})`,

                    sbpPost: item.postSBPAverage
                        ? `${item.postSBPAverage?.toFixed(
                              1
                          )} (${item.postSBPMin?.toFixed(
                              1
                          )} - ${item.postSBPMax?.toFixed(1)})`
                        : "-",

                    dbpPost: item.postDBPAverage
                        ? `${item.postDBPAverage?.toFixed(
                              1
                          )} (${item.postDBPMin?.toFixed(
                              1
                          )} - ${item.postDBPMax?.toFixed(1)})`
                        : "-",

                    ktv: item.KTVonlineAverage
                        ? `${item.KTVonlineAverage?.toFixed(
                              1
                          )} (${item.KTVonlineMin?.toFixed(
                              1
                          )} - ${item.KTVonlineMax?.toFixed(1)})`
                        : "-"
                });
            }
            setDisplayDialysisHistorySummary(_dialysisHistorySummaries);
            setTableDialysisHistorySummary({
                ...tableDiaylsisHistorySummary,
                loading: false
            });
        }
    }, [tableDiaylsisHistorySummary.data]);

    useEffect(() => {
        if (tableCurrentMedication.data !== -1) {
            let currentMedications = [];
            for (let item of tableCurrentMedication.data) {
                currentMedications.push({
                    id: item.id,
                    // date: item.start,
                    // end: item.end,
                    dateStart: moment
                        .utc(item.start)
                        .local()
                        .format("DD MMM YY"),
                    dateEnd: item.end,
                    active: item.status == "ACTIVE",
                    // medication: item.drugName,
                    // medication: item.medication,
                    freq: item.freq,
                    route: item.route,

                    medication: `${moment
                        .utc(item.start)
                        .local()
                        .format("DD MMM YY")} ${item.medication ?? ""} ${
                        item.dose ?? ""
                    } ${item.route ?? ""} ${item.freq ?? ""}`
                });
            }
            setDisplayCurrentMedication(currentMedications);
            setTableCurrentMedication({
                ...tableCurrentMedication,
                loading: false
            });
        }
    }, [tableCurrentMedication.data]);

    useEffect(() => {
        if (tableMedicationAdministered.data !== -1) {
            let medicationAdministereds = [];
            for (let item of tableMedicationAdministered.data) {
                medicationAdministereds.push({
                    ...item,
                    medicationAdministered: `${moment
                        .utc(item.createdOn)
                        .local()
                        .format("DD MMM YY")} ${item.medication ?? ""} ${
                        item.dose ?? ""
                    } ${item.route ?? ""} ${item.freq ?? ""}`
                });
            }
            setDisplayMedicationAdministered(medicationAdministereds);
        }
    }, [tableMedicationAdministered.data]);

    useEffect(() => {
        if (tableDialysisHistory.data !== -1) {
            let dialysisIDs = tableDialysisHistory.data.map((s) => s.id);
            let minStartTime = tableDialysisHistory.data
                .map((s) => s.startTime)
                .sort((a, b) => (a > b ? 1 : -1))[0];
            let maxEndTime = tableDialysisHistory.data
                .map((s) => s.endTime)
                .sort((a, b) => (a > b ? -1 : 1))[0];
            // console.log('dialysisIDs', dialysisIDs);
            if (dialysisIDs.length !== 0) {
                getDiaysisHistoryIntervalRecords(
                    dialysisIDs,
                    tableDialysisHistoryInterval.pagination
                );
                getMedicationAdministeredRecords(
                    patientID,
                    dialysisIDs,
                    minStartTime,
                    maxEndTime
                );
            } else {
                setTableDialysisHistoryInterval({
                    ...tableDialysisHistoryInterval,
                    loading: false
                });
            }
        }

        return () => {};
    }, [tableDialysisHistory.data]);

    useEffect(() => {
        if (
            tableDialysisHistoryInterval.data !== -1 &&
            appState.fixedListLoaded === true
        ) {
            let _dialysisInterval = [];
            for (let r of tableDialysisHistoryInterval.data) {
                r.timestampDisplay = moment
                    .utc(r.timestamp)
                    .local()
                    .format("DD MMM YY, HH:mm:ss");

                _dialysisInterval.push({
                    ...r,
                    hepRate: r?.hepRate?.toFixed(1),
                    timestamp: r.timestamp
                        ? moment.utc(r.timestamp).local().format("HH:mm")
                        : "",
                    type: "intraDialysisNoteSymptoms",
                    notes: r.note ?? "",
                    note: r.note ?? "",
                    displaySymptoms: r.symptoms
                        ? appState?.symptomList
                              ?.filter((s) =>
                                  JSON.parse(r.symptoms).includes(s.id)
                              )
                              .map((s) => s.name)
                              .join(", ")
                        : "",
                    UFR: (Number(r?.UFR) / 1000.0 ?? "").toFixed(2),
                    cUF: (Number(r?.cUF) / 1000.0 ?? "").toFixed(1)
                });
            }

            let _dialysisHistory = [];

            for (let d of tableDialysisHistory.data) {
                d.type = "dialysisNoteSymptoms";
                d.lastWeight =
                    d.lastWeight != null && d.lastWeight >= 0
                        ? (parseInt(d.lastWeight) / 1000.0).toFixed(1)
                        : "";
                d.preWeight =
                    d.preWeight != null && d.preWeight >= 0
                        ? (parseInt(d.preWeight) / 1000.0).toFixed(1)
                        : "";
                d.postWeight =
                    d.postWeight != null && d.postWeight >= 0
                        ? (parseInt(d.postWeight) / 1000.0).toFixed(1)
                        : "";
                d.dryWeight =
                    d.dryWeight != null && d.dryWeight >= 0
                        ? (parseInt(d.dryWeight) / 1000.0).toFixed(1)
                        : "";

                d.preTemp =
                    d.preTemp != null && d.preTemp >= 0
                        ? parseFloat(d.preTemp).toFixed(1)
                        : "";
                d.postTemp =
                    d.postTemp != null && d.postTemp >= 0
                        ? parseFloat(d.postTemp).toFixed(1)
                        : "";

                d.KTVonline =
                    d.KTVonline != null && d.KTVonline >= 0
                        ? parseFloat(d.KTVonline)?.toFixed(1)
                        : "";

                d.timestampDisplay = d.startTime
                    ? moment.utc(d.startTime).local().format("DD MMM YY, HH:mm")
                    : "";
                if (!d.endedBy) {
                    // d.timestampDisplay += ' (In Progress)';
                }

                d.dischargeDisplay = d.dischargeTime
                    ? moment
                          .utc(d.dischargeTime)
                          .local()
                          .format("DD MMM YY, HH:mm")
                    : "";

                d.postSBPDBP =
                    d.postSBP && d.postDBP ? d.postSBP + " / " + d.postDBP : "";
                d.offTarget =
                    d.postWeight && d.dryWeight
                        ? (d.postWeight - d.dryWeight)?.toFixed(1)
                        : "0";

                d.ufTargetStart = d.ufTargetStart?.toFixed(1);

                d.expectedLWDifference =
                    d.preWeight && d.ufTargetStart && d.lastWeight
                        ? (
                              parseFloat(d.preWeight) -
                              parseFloat(d.ufTargetStart) -
                              parseFloat(d.lastWeight)
                          )?.toFixed(1)
                        : "-";

                d.expectedDWDifference =
                    d.preWeight && d.ufTargetStart && d.dryWeight
                        ? (
                              parseFloat(d.preWeight) -
                              parseFloat(d.ufTargetStart) -
                              parseFloat(d.dryWeight)
                          )?.toFixed(1)
                        : "-";

                let filteredDialysisInterval = _dialysisInterval.filter(
                    (s) => s.dialysisID == d.id
                );
                // console.log('filteredDialysisInterval', filteredDialysisInterval);

                // if (d.id == dialysisID) {
                let QBs = filteredDialysisInterval.map((s) => s.QB);
                d.QB = QBs.length
                    ? Math.min(...QBs) + " to " + Math.max(...QBs)
                    : "";

                let APs = filteredDialysisInterval.map((s) => s.AP);
                d.AP = APs.length
                    ? Math.min(...APs) + " to " + Math.max(...APs)
                    : "";

                let VPs = filteredDialysisInterval.map((s) => s.VP);
                d.VP = VPs.length
                    ? Math.min(...VPs) + " to " + Math.max(...VPs)
                    : "";

                let DFLOWs = filteredDialysisInterval.map((s) => s.DFLOW);
                d.DFLOW = DFLOWs.length
                    ? Math.min(...DFLOWs) + " to " + Math.max(...DFLOWs)
                    : "";

                let CDTs = filteredDialysisInterval.map((s) => s.CDT);
                d.CDT = CDTs.length
                    ? Math.min(...CDTs) + " to " + Math.max(...CDTs)
                    : "";

                let SBPs = filteredDialysisInterval.map((s) => s.SBP);
                d.SBP = SBPs.length
                    ? Math.min(...SBPs) + " to " + Math.max(...SBPs)
                    : "";

                let DBPs = filteredDialysisInterval.map((s) => s.DBP);
                d.DBP = DBPs.length
                    ? Math.min(...DBPs) + " to " + Math.max(...DBPs)
                    : "";

                let HRs = filteredDialysisInterval.map((s) => s.HR);
                d.HR = HRs.length
                    ? Math.min(...HRs) + " to " + Math.max(...HRs)
                    : "";

                let temps = filteredDialysisInterval.map((s) => s.temp);
                d.temp = temps.length
                    ? Math.min(...temps) + " to " + Math.max(...temps)
                    : "";

                let KTVs = filteredDialysisInterval.map((s) => s.KTV);
                d.KTV = KTVs.length
                    ? Math.min(...KTVs) + " to " + Math.max(...KTVs)
                    : "";

                d.notes = d.notes;
                // d.notes = d.notes ? d.notes + filteredDialysisInterval.map(s => s.notes).join(" | ") : filteredDialysisInterval.map(s => s.notes).join("\n");

                let allSymoptoms = filteredDialysisInterval.reduce(
                    (a, b) =>
                        // {
                        // if (b?.symptoms) console.log(a, JSON.parse(b?.symptoms));
                        // return
                        a.concat(
                            b?.symptoms && b?.symptoms?.length > 0
                                ? JSON.parse(b?.symptoms)
                                : []
                        ),
                    // }
                    []
                );
                // console.log('allSymoptoms', allSymoptoms);
                d.displaySymptoms = allSymoptoms.length
                    ? appState?.symptomList
                          ?.filter((s) => allSymoptoms.includes(s.id))
                          .map((s) => s.name)
                          .join(", ")
                    : "";

                let clinic = appState.fixedClinicList.find(
                    (s) => s.id == d.clinicID
                );
                d.clinic = `${clinic?.name} (${clinic?.uid})`;

                let machine = appState.machineList.find(
                    (s) => s.id == d.machineID
                );
                d.machine = machine?.name;

                // console.log(d);
                // }
                // })
                // })

                _dialysisHistory.push(d);
            }

            setDisplayDialysisHistoryInterval(_dialysisInterval);
            setDisplayDialysisHistory(_dialysisHistory);
            setTableDialysisHistoryInterval({
                ...tableDialysisHistoryInterval,
                loading: false
            });
            setTableDialysisHistory({
                ...tableDialysisHistory,
                loading: false
            });
            setPatientPrePostWeight({
                ...patientPrePostWeight,
                loading: false
            });
        }
    }, [tableDialysisHistoryInterval.data, appState.fixedListLoaded]);

    useEffect(() => {
        if (patient?.existingClinicID > 0 && appState?.fixedClinicList !== -1) {
            setPatientClinic(
                appState?.fixedClinicList?.find(
                    (s) => s.id == patient?.existingClinicID
                )
            );
        }
    }, [patient?.existingClinicID, appState.fixedClinicList]);

    useEffect(() => {
        if (latestClinicalNote !== -1) {
            setLoadingLatestClinicalNote(false);
        }
    }, [latestClinicalNote]);

    useEffect(() => {
        if (dialysisOrder !== -1) {
            setLoadingDialysisOrder(false);
        }
    }, [dialysisOrder]);

    useEffect(() => {
        if (patientPrePostWeight !== -1) {
            setLoadingPatientPrePostWeight(false);
        }
    }, [patientPrePostWeight]);

    useEffect(() => {
        if (tableMedicalMilestone.data !== -1) {
            let medicalMilestones = [];
            for (let item of tableMedicalMilestone.data) {
                medicalMilestones.push({
                    ...item,
                    date: moment.utc(item.date).local().format("DD MMM YY")
                });
            }
            setDisplayMedicalMilestone(medicalMilestones);
            setTableMedicalMilestone({
                ...tableMedicalMilestone,
                loading: false
            });
        }
    }, [tableMedicalMilestone.data]);

    useEffect(() => {
        if (tableIntraDialysis.data !== -1) {
            setDisplayIntraDialysis(tableIntraDialysis.data);
            setTableIntraDialysis({
                ...tableIntraDialysis,
                loading: false
            });
        }
    }, [tableIntraDialysis.data]);

    return (
        <div>
            <Button
                type="primary"
                onClick={handlePrint}
                loading={!enablePrint}
                disabled={!enablePrint}
            >
                <PrinterOutlined />
                Print - Patient Dialysis History
            </Button>
            {/* <Button type="primary" onClick={handlePrint}><PrinterOutlined />Print - Patient Dialysis History</Button> */}
            <br />
            <br />
            <div
                style={{
                    border: "1px solid #000000",
                    backgroundColor: "#FFFFFF"
                }}
            >
                <ComponentToPrint ref={componentRef} />
            </div>
        </div>
    );
};

export default PatientRecordExportPatientDialysisHistory;
