
export const listFixedList = /* GraphQL */ `
	query listFixedList(
		$filter: ListClinicFilterInput
		$pagination: PaginationInput
		$pagination2: PaginationInput
		$pagination4: PaginationInput
		$pagination5: PaginationInput
		$pagination6: PaginationInput
		$filter2: ListDrugFilterInput
    $filter3: ListMachineFilterInput
    $filter4: ListSymptomFilterInput
    $filter5: ListStaffFilterInput
    $filter5: ListStaffFilterInput
    $filter6: ListHospitalReasonFilterInput
	) {
		listClinic(filter: $filter, pagination: $pagination) {
			result {
        id
        name
        uid
        phoneNo
        email
        address
        status
        noOfSeat
        createdOn
        updatedOn
        deleted
        deletedOn
			}
			count
		}
    listDrug(filter: $filter2, pagination: $pagination2) {
      result {
        id
        name
        purpose
        manufacturer
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
    listMachine(filter: $filter3, pagination: $pagination) {
      result {
        id
        serialNo
        clinicID
        name
        manufacturer
        status
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
    listSymptom(filter: $filter4, pagination: $pagination4) {
      result {
        id
        name
        class
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
    listStaff(filter: $filter5, pagination: $pagination5) {
      result {
        accountID
        clinicIDs
        designation
        name
        contactNo
        email
        username
        gender
        dob
        maritalStatus
        address
        postal
        profilePictureKey
        profilePictureBucket
        profilePictureRegion
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
    listHospitalReason(filter: $filter6, pagination: $pagination6) {
      result {
        id
        hospCode
        hospDetail
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const listClinicRelatedList = /* GraphQL */ `
	query listClinicRelatedList(
    $filter: ListMachineFilterInput,
    $filter2: ListSeatFilterInput,
    $filter3: ModelQBAccessFilterInput
		$pagination: PaginationInput,
	) {
    listMachine(filter: $filter, pagination: $pagination) {
      result {
        id
        serialNo
        clinicID
        name
        manufacturer
        status
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
    listSeat(filter: $filter2, pagination: $pagination) {
      result {
        id
        clinicID
        machineID
        status
        name
        machineName
        machineSerialNo
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
    listQBAccesses(filter: $filter3, limit: 1) {
      items {
        id
        expiresIn
        date
      }
      nextToken
    }
  }
`;
export const listPatientAndAllergyAndDialysis_PatientClinic = /* GraphQL */ `
  query listPatientAndAllergyAndDialysis(
    $filter: ListPatientFilterInput
    $filter2: ListAllergyFilterInput
    $filter3: ListDialysisFilterInput
    $filter4: ListPatientClinicFilterInput
    $pagination: PaginationInput!
    $pagination2: PaginationInput
    $pagination3: PaginationInput
    $pagination4: PaginationInput
    $unmasked: Int
  ) {
    result: listPatient(filter: $filter, pagination: $pagination, unmasked: $unmasked) {
      result {
        id
        accessIDPt
        namecode
        registeredDate
        quickbookCustomerID
        key
        bucket
        region
        firstDialysisHospital
        firstDialysisHospitalDate
        referringSource
        referrer
        referringSourceStart
        referringSourceEnd
        existingClinicID
        status
        travelType
        schedulePattern
        scheduleSession
        name
        idType
        nric
        gender
        dob
        race
        religion
        maritalStatus
        accommodation
        address
        phoneNo
        homeNo
        email
        nokName
        nokPhoneNo
        nokAddress
        nokRelation
        height
        weight
        bloodGroup
        mobilityStatus
        diabeticStatus
        smoker
        alcohol
        amputee
        wheelchairWeight
        history
        causeOfKidneyFailure
        medicalAlert
        socialWorker
        vwoSupport
        firstConsultDate
        DESRD
        DExit
        RExit
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
    result2: listAllergy(filter: $filter2, pagination: $pagination2) {
      result {
        id
        patientID
        name
        knownDate
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
    result3: listDialysis(filter: $filter3, pagination: $pagination3) {
      result {
        id
        patientID
        sessionMonth
        arriveTime
        startTime
        endTime
        dischargeTime
        accessDIALYSISREF
        startedBy
        endedBy
        clinicID
        preWeight
        lastWeight
        postWeight
        dryWeight
        height
        preTemp
        preEdema
        preSBP
        preDBP
        preHRT
        preRenalin
        ufTargetStart
        postTemp
        postEdema
        postSBP
        postDBP
        postHRT
        postRenalin
        KTVonline
        machineID
        dialyser
        MSA
        membraneUse
        dialysisOrderID
        notes
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
    result4: listPatientClinic(filter: $filter4, pagination: $pagination4) {
      result {
        patientID
        clinicID
        quickbookCustomerID
      }
      count
    }
  }
`;
export const listPatientAndAllergy = /* GraphQL */ `
  query listPatientAndAllergy(
    $filter: ListPatientFilterInput
    $filter2: ListAllergyFilterInput
    $pagination: PaginationInput!
    $pagination2: PaginationInput
    $unmasked: Int
  ) {
    result: listPatient(filter: $filter, pagination: $pagination, unmasked: $unmasked) {
      result {
        id
        accessIDPt
        namecode
        registeredDate
        quickbookCustomerID
        key
        bucket
        region
        firstDialysisHospital
        firstDialysisHospitalDate
        referringSource
        referrer
        referringSourceStart
        referringSourceEnd
        existingClinicID
        status
        travelType
        schedulePattern
        scheduleSession
        name
        idType
        nric
        gender
        dob
        race
        religion
        maritalStatus
        accommodation
        address
        phoneNo
        homeNo
        email
        nokName
        nokPhoneNo
        nokAddress
        nokRelation
        height
        weight
        bloodGroup
        mobilityStatus
        diabeticStatus
        smoker
        alcohol
        amputee
        wheelchairWeight
        history
        causeOfKidneyFailure
        medicalAlert
        socialWorker
        vwoSupport
        firstConsultDate
        DESRD
        DExit
        RExit
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
    result2: listAllergy(filter: $filter2, pagination: $pagination2) {
      result {
        id
        patientID
        name
        knownDate
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const listMedicationAndAllergy = /* GraphQL */ `
  query listMedicationAndAllergy(
    $filter: ListMedicationFilterInput
    $filter2: ListAllergyFilterInput
    $pagination: PaginationInput
    $pagination2: PaginationInput
  ) {
    result: listMedication(filter: $filter, pagination: $pagination) {
      result {
        id
        accessID
        patientID
        medication
        status
        dose
        route
        freq
        start
        end
        note
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
    result2: listAllergy(filter: $filter2, pagination: $pagination2) {
      result {
        id
        patientID
        name
        knownDate
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const listLastNonNullLab = /* GraphQL */ `
  query listLastNonNullLab(
    $filter: ListLabElectrolytesFilterInput
    $filter2: ListLabLFTDataFilterInput
    $filter3: ListLabHbCaDataFilterInput
    $filter4: ListLabVirologyLIPDSFilterInput
    $pagination: PaginationInput
  ) {
    result: listLabElectrolytes(filter: $filter, pagination: $pagination) {
      result {
        id
        patientID
        clinicID
        timestamp
        K
        K_timestamp
        HCO3
        HCO3_timestamp
        creatinine
        creatinine_timestamp
        ureaPre
        ureaPre_timestamp
        ureaPost
        ureaPost_timestamp
        URR
        URR_timestamp
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
    result2: listLabLFTData(filter: $filter2, pagination: $pagination) {
      result {
        id
        patientID
        clinicID
        timestamp
        protein
        protein_timestamp
        Alb
        Alb_timestamp
        Biltotal
        Biltotal_timestamp
        SAP
        SAP_timestamp
        SGPT_ALT
        SGPT_ALT_timestamp
        SGOT_AST
        SGOT_AST_timestamp
        ggt
        ggt_timestamp
        glucose
        glucose_timestamp
        Hba1c
        Hba1c_timestamp
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
    result3: listLabHbCaData(filter: $filter3, pagination: $pagination) {
      result {
        id
        patientID
        clinicID
        timestamp
        Hb
        Hb_timestamp
        MCV
        MCV_timestamp
        TW
        TW_timestamp
        Plt
        Plt_timestamp
        Fe
        Fe_timestamp
        TIBC
        TIBC_timestamp
        LDL
        LDL_timestamp
        Po4
        Po4_timestamp
        Ca
        Ca_timestamp
        IPTH
        IPTH_timestamp
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
    result4: listLabVirologyLIPDS(filter: $filter4, pagination: $pagination) {
      result {
        id
        patientID
        clinicID
        timestamp
        HepBsAg
        HepBsAg_timestamp
        HepBsAb
        HepBsAb_timestamp
        HCV
        HCV_timestamp
        HIV
        HIV_timestamp
        TC
        TC_timestamp
        HDL
        HDL_timestamp
        LDL
        LDL_timestamp
        TG
        TG_timestamp
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const listMachineAndMachineStatus = /* GraphQL */ `
  query listMachineAndMachineStatus(
    $filter: ListMachineStatusFilterInput
    $pagination: PaginationInput
    $groupByName: Int
    $filter2: ListMachineFilterInput
    $pagination2: PaginationInput
    $filter3: ListMachineStatusFilterInput
    $pagination3: PaginationInput
    $groupByName3: Int
  ) {
    result: listMachineStatus(
      filter: $filter
      pagination: $pagination
      groupByName: $groupByName
    ) {
      result {
        id
        machineID
        prevMachineStatusID
        name
        expiryDate
        nextAudit
        status
        remarks
        approvedBy
        approvedTimestamp
        mandatary
        machineSerialNo
        machineName
        machineClinicID
        key
        bucket
        region
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
    result2: listMachine(filter: $filter2, pagination: $pagination2) {
      count
    }
    result3: listMachineStatus(
      filter: $filter3
      pagination: $pagination3
      groupByName: $groupByName3
    ) {
      count
    }
  }
`;
export const listStaffStatusWithMaintenanceStatusCount = /* GraphQL */ `
  query listStaffStatusWithMaintenanceStatusCount(
    $filter: ListStaffStatusFilterInput
    $pagination: PaginationInput
    $filter2: ListStaffStatusFilterInput
    $pagination2: PaginationInput
  ) {
    result: listStaffStatus(filter: $filter, pagination: $pagination) {
      result {
        id
        accountID
        name
        status
        issued
        expiry
        approvedBy
        approvedTimestamp
        staffName
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
    result2: listStaffStatus(filter: $filter2, pagination: $pagination2) {
      count
    }
  }
`;
export const listAppointmentDetail_Seat = /* GraphQL */ `
  query listAppointmentDetail_Seat(
    $filter: ListAppointmentDetailFilterInput
    $pagination: PaginationInput
    $filter2: ListSeatFilterInput
    $pagination2: PaginationInput
  ) {
    result: listAppointmentDetail(filter: $filter, pagination: $pagination) {
      result {
        id
        patientID
        clinicID
        seatID
        date
        shift
        time
        dialysisID
        quickbookInvoiceID
        name
        nric
        key
        bucket
        region
        seatName
        dialysisStartedBy
        dialysisStartTime
        dialysisEndedBy
        dialysisEndTime
        lastDialysisIntervalID
        lastDialysisIntervalTimestamp
        dialysis {
          id
          patientID
          sessionMonth
          arriveTime
          startTime
          endTime
          dischargeTime
          accessDIALYSISREF
          startedBy
          endedBy
          clinicID
          preWeight
          lastWeight
          postWeight
          dryWeight
          height
          preTemp
          preEdema
          preSBP
          preDBP
          preHRT
          preRenalin
          ufTargetStart
          postTemp
          postEdema
          postSBP
          postDBP
          postHRT
          postRenalin
          KTVonline
          machineID
          dialyser
          MSA
          membraneUse
          dialysisOrderID
          notes
          createdOn
          updatedOn
          deleted
          deletedOn
        }
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
    result2: listSeat(filter: $filter2, pagination: $pagination2) {
      result {
        id
        clinicID
        machineID
        status
        name
        machineName
        machineSerialNo
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const listQBAccess = /* GraphQL */ `
  query ListQBAccesses(
    $filter: ModelQBAccessFilterInput
    $limit: Int
    $nextToken: String
    $filter2: ListClinicFilterInput
    $pagination2: PaginationInput
  ) {
    result: listQBAccesses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        expiresIn
        date
      }
    }
    result2: listClinic(filter: $filter2, pagination: $pagination2) {
      result {
        id
        name
        uid
        phoneNo
        email
        address
        status
        noOfSeat
        qbClientID
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const listMachine_AvailableMachine_UnavailableMachine = /* GraphQL */ `
  query ListMachine_AvailableMachine_UnavailableMachine(
    $filter: ListMachineFilterInput
    $pagination: PaginationInput
    $filter2: ListMachineFilterInput
    $pagination2: PaginationInput
    $filter3: ListMachineFilterInput
    $pagination3: PaginationInput
  ) {
    result: listMachine(filter: $filter, pagination: $pagination) {
      result {
        id
        serialNo
        clinicID
        name
        manufacturer
        status
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
    result2: listMachine(filter: $filter2, pagination: $pagination2) {
      count
    }
    result3: listMachine(filter: $filter3, pagination: $pagination3) {
      count
    }
  }
`;
export const listHospitalSummary_Patient_DialysisOrder = /* GraphQL */ `
  query ListHospitalSummary_Patient(
    $filter: ListHospitalSummaryFilterInput
    $pagination: PaginationInput
    $filter2: ListPatientFilterInput
    $pagination2: PaginationInput!
    $unmasked: Int
    $filter3: ListDialysisOrderFilterInput
    $pagination3: PaginationInput
  ) {
    result: listHospitalSummary(filter: $filter, pagination: $pagination) {
      result {
        id
        accessIDHosp
        patientID
        clinicID
        staffAccountID
        staffName
        admitTimestamp
        type
        observation
        assessment
        plan
        notes
        leaveTimestamp
        noOfDays
        location
        reason
        hospitalMain
        hospitalReason
        diagnosis
        outcome
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
    result2: listPatient(filter: $filter2, pagination: $pagination2, unmasked: $unmasked) {
      result {
        id
        accessIDPt
        namecode
        registeredDate
        quickbookCustomerID
        key
        bucket
        region
        firstDialysisHospital
        firstDialysisHospitalDate
        referringSource
        referrer
        referringSourceStart
        referringSourceEnd
        existingClinicID
        status
        travelType
        schedulePattern
        scheduleSession
        name
        idType
        nric
        gender
        dob
        race
        religion
        maritalStatus
        accommodation
        address
        phoneNo
        homeNo
        email
        nokName
        nokPhoneNo
        nokAddress
        nokRelation
        height
        weight
        bloodGroup
        mobilityStatus
        diabeticStatus
        smoker
        alcohol
        amputee
        stage
        wheelchairWeight
        medicalAlert
        socialWorker
        vwoSupport
        firstConsultDate
        DESRD
        DExit
        RExit
        hepB
        FHepB
        FHCV
        FHIV
        FAccess
        comanaging
        procedure
        medication
        vaccination
        history
        causeOfKidneyFailure
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
    result3: listDialysisOrder(filter: $filter3, pagination: $pagination3) {
      result {
        id
        uid
        staffAccountID
        patientID
        accessID
        MSA
        dOrder
        freqMth
        duration
        hepLoad
        hepCont
        antio
        dialysate
        accessPrim
        aNeedle
        vNeedle
        bicarbonate
        temp
        QD
        QB
        dryWeight
        notes
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;