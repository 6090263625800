import path from "./pathSettings"
import iconCentre from "../media/Icon-Manage-Centre.png"
import iconIncidentReport from "../media/Icon-Manage-IncidentReport.png"
import iconMedication from "../media/Icon-Manage-Medication.png"
import iconSymptom from "../media/Icon-Manage-Symptom.png"
import iconSeat from "../media/Icon-Manage-Seats.png"
import iconMachine from "../media/Icon-Manage-Machine.png"
import iconMachineStatus from "../media/Icon-Manage-MachineStatus.png"
import iconCertifcation from "../media/Icon-Manage-Certification.png"
import iconStaff from "../media/Icon-Manage-Staff.png"
import iconDialysisSessions from "../media/Icon-Manage-DialysisSessions.png"
import iconUnlinkedInvoices from "../media/Icon-Manage-UnlinkedInvoices.png"
import iconFunderReport from "../media/Icon-Manage-FunderReport.png"
import logo from "../media/Logo-AEGISMedicalCare.png"

const manageModuleMenuSettings = (row) => {
    const menu = [
        {
            name: "Manage Centres",
            to: path("manageCentre"),
            imgSrc: iconCentre,
        },
        {
            name: "Manage Machines",
            to: path("manageMachine"),
            imgSrc: iconMachine,
        },
        {
            name: "Manage Machine Status",
            to: path("manageMachineStatus"),
            imgSrc: iconMachineStatus,
        },
        {
            name: "Manage Seats",
            to: path("manageSeat"),
            imgSrc: iconSeat,
        },
        {
            name: "Manage Drug",
            to: path("manageDrug"),
            imgSrc: iconMedication,
        },
        {
            name: "Manage Symptoms",
            to: path("manageSymptom"),
            imgSrc: iconSymptom,
        },
        {
            name: "Manage Staff",
            to: path("manageStaff"),
            imgSrc: iconStaff,
        },
        {
            name: "Manage Certifications",
            to: path("manageCertification"),
            imgSrc: iconCertifcation,
        },
        {
            name: "Centre Incident Report",
            to: path("incidentReportCentre"),
            imgSrc: iconIncidentReport,
        },
        {
            name: "Manage Dialysis Session",
            to: path("manageDialysisSession"),
            imgSrc: logo,
        },
        {
            name: "Manage Unlinked Invoice",
            to: path("manageUnlinkedInvoice"),
            imgSrc: logo,
        },
    ]

    const menu1 = [
        {
            name: "Manage Centres",
            to: path("manageCentre"),
            imgSrc: iconCentre,
        },
        {
            name: "Manage Machines",
            to: path("manageMachine"),
            imgSrc: iconMachine,
        },
        {
            name: "Manage Machine Status",
            to: path("manageMachineStatus"),
            imgSrc: iconMachineStatus,
        },
        {
            name: "Manage Seats",
            to: path("manageSeat"),
            imgSrc: iconSeat,
        },
    ]

    const menu2 = [
        {
            name: "Manage Drug",
            to: path("manageDrug"),
            imgSrc: iconMedication,
        },
        {
            name: "Manage Symptoms",
            to: path("manageSymptom"),
            imgSrc: iconSymptom,
        },
    ]

    const menu3 = [
        {
            name: "Manage Staff",
            to: path("manageStaff"),
            imgSrc: iconStaff,
        },
        {
            name: "Manage Certifications",
            to: path("manageCertification"),
            imgSrc: iconCertifcation,
        },
        {
            name: "Centre Incident Report",
            to: path("incidentReportCentre"),
            imgSrc: iconIncidentReport,
        },
        {
            name: "Manage Dialysis Session",
            to: path("manageDialysisSession"),
            imgSrc: iconDialysisSessions,
        },
        {
            name: "Manage Unlinked Invoice",
            to: path("manageUnlinkedInvoice"),
            imgSrc: iconUnlinkedInvoices,
        },
        {
            name: "Funder Report",
            to: path("manageFunderReport"),
            imgSrc: iconFunderReport,
        },
    ]

    switch (row) {
        case "row1":
            return menu1
        case "row2":
            return menu2
        case "row3":
            return menu3
        default:
            return menu
    }

    // const menu = [
    //     {
    //         name: "Manage Centres",
    //         to: path("manageCentre"),
    //         imgSrc: iconCentre,
    //     },
    //     {
    //         name: "Manage Drug",
    //         to: path("manageDrug"),
    //         imgSrc: iconMedication,
    //     },
    //     {
    //         name: "Manage Symptoms",
    //         to: path("manageSymptom"),
    //         imgSrc: iconSymptom,
    //     },
    //     {
    //         name: "Manage Seats",
    //         to: path("manageSeat"),
    //         imgSrc: iconSeat,
    //     },
    //     {
    //         name: "Manage Machines",
    //         to: path("manageMachine"),
    //         imgSrc: iconMachine,
    //     },
    //     {
    //         name: "Manage Machine Status",
    //         to: path("manageMachineStatus"),
    //         imgSrc: iconMachineStatus,
    //     },
    //     {
    //         name: "Manage Certifications",
    //         to: path("manageCertification"),
    //         imgSrc: iconCertifcation,
    //     },
    //     {
    //         name: "Manage Staff",
    //         to: path("manageStaff"),
    //         imgSrc: iconStaff,
    //     },
    //     {
    //         name: "Centre Incident Report",
    //         to: path("incidentReportCentre"),
    //         imgSrc: iconIncidentReport,
    //     },
    //     {
    //         name: "Manage Dialysis Session",
    //         to: path("manageDialysisSession"),
    //         imgSrc: logo,
    //     },
    // ]

    // return menu 
}

export default manageModuleMenuSettings