import React, { useEffect, useState } from "react";
import { Button, Form, Input, Modal, notification, Spin, Upload } from "antd";
import { UploadOutlined, PlusOutlined } from "@ant-design/icons";

import imgFileUpload from "../media/Image-FileUpload.png";
import Papa from "papaparse";
import { API } from "aws-amplify";
import { listPatient } from "../backend/graphql/queries";
import { useHistory } from "react-router-dom";
import path from "../utils/pathSettings";

const ImportLabResultFileUpload = () => {
    const [loading, setLoading] = useState(false);
    const [labs, setLabs] = useState([]);
    const [provider, setProvider] = useState("");
    const [labsWithPatient, setLabsWithPatient] = useState([]);
    const history = useHistory();

    useEffect(() => {
        return () => {};
    }, []);

    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };
    const uploadButton = (
        <div>
            <img src={imgFileUpload} />
            <div className="upload-text">
                <div>Drag and Drop file here</div>
                <div>or</div>
                <div>Click here to browse</div>
            </div>
        </div>
    );
    const [state, setState] = useState({
        fileList: []
    });

    const handleCancel = () => {
        setState({ ...state, previewVisible: false });
    };

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        setState({
            ...state,
            previewImage: file.url || file.preview,
            previewVisible: true,
            previewTitle:
                file.name || file.url?.substring(file.url?.lastIndexOf("/") + 1)
        });
    };

    const handleChange = (file) => {
        // console.log(file);
        // console.log(optionNum);
        let { fileList } = file;
        // fileList = fileList.slice(-1)
        setState({ ...state, fileList: fileList });
    };

    const handleLabButtonOnClick = (button) => {
        // console.log(button);
        switch (button) {
            case "Innoquest":
                // Associate patient to lab report records
                setLoading(true);
                setProvider(button);
                // console.log(state.fileList, Object.keys(state.fileList[0]));
                let _labs = [];
                Papa.parse(state.fileList[0].originFileObj, {
                    // worker: true,
                    header: true,
                    skipEmptyLines: true,
                    step: function (results) {
                        // console.log(Row:", results);
                        if (
                            results.data[`TC (mmol/L)`] ||
                            results.data[`HDL (mmol/L)`] ||
                            results.data[`LDL (mmol/L)`] ||
                            results.data[`TC/HDL (Ratio)`] ||
                            results.data[`TRIG (mmol/L)`] ||
                            results.data[`GluF (mmol/L)`] ||
                            results.data[`GluR (mmol/L)`] ||
                            results.data[`HBA1C (%)`] ||
                            results.data[`TP (g/L)`] ||
                            results.data[`ALB (g/L)`] ||
                            results.data[`GLO (g/L)`] ||
                            results.data[`AG (Ratio)`] ||
                            results.data[`TB (umol/L)`] ||
                            results.data[`ALP (U/L)`] ||
                            results.data[`SGPT (U/L)`] ||
                            results.data[`SGOT (U/L)`] ||
                            results.data[`GGT (U/L)`] ||
                            results.data[`PO4 (mmol/L)`] ||
                            results.data[`CA (mmol/L)`] ||
                            results.data[`UA (mmol/L)`] ||
                            results.data[`RA (IU/mL)`] ||
                            results.data[`NA (mmol/L)`] ||
                            results.data[`K (mmol/L)`] ||
                            results.data[`CL (mmol/L)`] ||
                            results.data[`UREA (mmol/L)`] ||
                            results.data[`UREA (Post)(mmol/L)`] ||
                            results.data[`CREAT (umol/L)`] ||
                            results.data[`uALB (mg/L)`] ||
                            results.data[`uCRT (mmol/L)`] ||
                            results.data[`uALB/uCRT (mg/mmol)`] ||
                            results.data[`CRP (mg/L)`] ||
                            results.data[`HBSAG`] ||
                            results.data[`HBSAB`] ||
                            results.data[`HBSAB_v (mIU/mL)`] ||
                            results.data[`HAVT`] ||
                            results.data[`HCV`] ||
                            results.data[`VD_SYPHILIS_AB`] ||
                            results.data[`VDRL`] ||
                            results.data[`VDRL_Titre`] ||
                            results.data[`TPPA`] ||
                            results.data[`TPPA_Titre`] ||
                            results.data[`H_PYLORI`] ||
                            results.data[`IGF-1 (ng/mL)`] ||
                            results.data[`PTH (pmol/L)`] ||
                            results.data[`PRL (ug/L)`] ||
                            results.data[`TESTO (nmol/L)`] ||
                            results.data[`PROG (nmol/L)`] ||
                            results.data[`E2 (pmol/L)`] ||
                            results.data[`LH (IU/L`] ||
                            results.data[`FSH (IU/L)`] ||
                            results.data[`VitD3 (ng/mL)`] ||
                            results.data[`FT3 (pmol/L)`] ||
                            results.data[`FT4 (pmol/L)`] ||
                            results.data[`TSH (mIU/L)`] ||
                            results.data[`AFP (ug/L)`] ||
                            results.data[`CEA (ug/L)`] ||
                            results.data[`CA199 (U/mL)`] ||
                            results.data[`CA125 (U/mL)`] ||
                            results.data[`CA153 (U/mL)`] ||
                            results.data[`EBV (RU)`] ||
                            results.data[`BHCG (IU/L)`] ||
                            results.data[`PSA (ug/L)`] ||
                            results.data[`HB (g/dL)`] ||
                            results.data[`WBC (x10^9/L)`] ||
                            results.data[`NEUP (x10^9/L)`] ||
                            results.data[`LYMP (x10^9/L)`] ||
                            results.data[`MONP (x10^9/L)`] ||
                            results.data[`EOSP (x10^9/L)`] ||
                            results.data[`BASP (x10^9/L)`] ||
                            results.data[`RBC (x10^12/L)`] ||
                            results.data[`PCV`] ||
                            results.data[`MCV (fL)`] ||
                            results.data[`MCH (pg)`] ||
                            results.data[`MCHC (g/dL)`] ||
                            results.data[`RDW (%)`] ||
                            results.data[`PLT (x10^9/L)`] ||
                            results.data[`PBF`] ||
                            results.data[`ESR (mm/hr)`] ||
                            results.data[`ABO`] ||
                            results.data[`Rhesus`] ||
                            results.data[`U_APPR`] ||
                            results.data[`U_COLOR`] ||
                            results.data[`U_CLARITY`] ||
                            results.data[`pH`] ||
                            results.data[`SG`] ||
                            results.data[`NITRITE`] ||
                            results.data[`UPRO`] ||
                            results.data[`UGLU`] ||
                            results.data[`KET`] ||
                            results.data[`UROBIL`] ||
                            results.data[`UBIL`] ||
                            results.data[`BLD`] ||
                            results.data[`WC(/uL)`] ||
                            results.data[`RC(/uL)`] ||
                            results.data[`EPI(/uL)`] ||
                            results.data[`CAST`] ||
                            results.data[`CRYST`] ||
                            results.data[`BACT`] ||
                            results.data[`MUCOUS_THREAD`] ||
                            results.data[`YEASTS`] ||
                            results.data[`OTHERS`] ||
                            results.data[`OCC`] ||
                            results.data[`OCC(IM)`] ||
                            results.data[`HBA2`] ||
                            results.data[`HAEMO_F`] ||
                            results.data[`HBH`] ||
                            results.data[`Height (cm)`] ||
                            results.data[`Weight (kg)`] ||
                            results.data[`Waist Circ (cm)`] ||
                            results.data[`BMI`] ||
                            results.data[`Iron (umol/L)`] ||
                            results.data[`TIBC (umol/L)`] ||
                            results.data[`Iron Saturation (%)`] ||
                            results.data[`Transferrin (g/L)`] ||
                            results.data[`Ferritin (ug/L)`] ||
                            results.data[`24hr Urine Vol (ml/24hr)`] ||
                            results.data[`Urine Creatine 24hr (mmol/L)`] ||
                            results.data[
                                `24hr Creatinine Excretion (mmol/24hr)`
                            ] ||
                            results.data[`Creatinine Clearance (mL/min)`] ||
                            results.data[`24HR Urine T.Protein (g/L)`] ||
                            results.data[
                                `24HR Urine T.Protein Excretion (g/24hr`
                            ]
                        ) {
                            _labs.push({
                                ...results.data
                            });
                        }
                    },
                    complete: function (results) {
                        setLabs(_labs);
                        // console.log(Finished:", _labs);
                    },
                    beforeFirstChunk: function (chunk) {
                        // Check if headers are same
                        if (
                            !chunk.includes(
                                `Collect,Collect_Name,Serial_No,Patient_Name,NRIC,Sex,Age,Age_units,Lab_No,Date,Profiles,TC (mmol/L),HDL (mmol/L),LDL (mmol/L),TC/HDL (Ratio),TRIG (mmol/L),GluF (mmol/L),GluR (mmol/L),HBA1C (%),TP (g/L),ALB (g/L),GLO (g/L),AG (Ratio),TB (umol/L),ALP (U/L),SGPT (U/L),SGOT (U/L),GGT (U/L),PO4 (mmol/L),CA (mmol/L),UA (mmol/L),RA (IU/mL),NA (mmol/L),K (mmol/L),CL (mmol/L),UREA (mmol/L),UREA (Post)(mmol/L),CREAT (umol/L),uALB (mg/L),uCRT (mmol/L),uALB/uCRT (mg/mmol),CRP (mg/L),HBSAG,HBSAB,HBSAB_v (mIU/mL),HAVT,HCV,VD_SYPHILIS_AB,VDRL,VDRL_Titre,TPPA,TPPA_Titre,H_PYLORI,IGF-1 (ng/mL),PTH (pmol/L),PRL (ug/L),TESTO (nmol/L),PROG (nmol/L),E2 (pmol/L),LH (IU/L,FSH (IU/L),VitD3 (ng/mL),FT3 (pmol/L),FT4 (pmol/L),TSH (mIU/L),AFP (ug/L),CEA (ug/L),CA199 (U/mL),CA125 (U/mL),CA153 (U/mL),EBV (RU),BHCG (IU/L),PSA (ug/L),HB (g/dL),WBC (x10^9/L),NEUP (x10^9/L),LYMP (x10^9/L),MONP (x10^9/L),EOSP (x10^9/L),BASP (x10^9/L),RBC (x10^12/L),PCV,MCV (fL),MCH (pg),MCHC (g/dL),RDW (%),PLT (x10^9/L),PBF,ESR (mm/hr),ABO,Rhesus,U_APPR,U_COLOR,U_CLARITY,pH,SG,NITRITE,UPRO,UGLU,KET,UROBIL,UBIL,BLD,WC(/uL),RC(/uL),EPI(/uL),CAST,CRYST,BACT,MUCOUS_THREAD,YEASTS,OTHERS,OCC,OCC(IM),HBA2,HAEMO_F,HBH,Height (cm),Weight (kg),Waist Circ (cm),BMI,Iron (umol/L),TIBC (umol/L),Iron Saturation (%),Transferrin (g/L),Ferritin (ug/L),24hr Urine Vol (ml/24hr),Urine Creatine 24hr (mmol/L),24hr Creatinine Excretion (mmol/24hr),Creatinine Clearance (mL/min),24HR Urine T.Protein (g/L),24HR Urine T.Protein Excretion (g/24hr)`
                            )
                        ) {
                            notification.error({
                                message: `Wrong csv format for ${button}.`
                            });
                            // throw Error('');

                            setLoading(false);
                            return null;
                        }
                        return chunk;
                    }
                });
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        if (labs.length > 0) {
            switch (provider) {
                case "Innoquest":
                    let nrics = [...new Set(labs.map((s) => s.NRIC))];
                    nrics = nrics.filter((s) => s);
                    // console.log(nrics);

                    if (nrics.length > 0) {
                        _listPatientAndAllergy(nrics);
                    } else {
                        notification.error({
                            message: "Unable to parse NRIC. Wrong csv format."
                        });
                        setLoading(false);
                    }
                    break;
                default:
                    break;
            }
        }

        return () => {};
    }, [labs, provider]);

    useEffect(() => {
        if (labsWithPatient.length > 0)
            history.push(path("importLabResultFileUploadReview"), {
                labsWithPatient,
                provider
            });
    }, [labsWithPatient]);

    const _listPatientAndAllergy = async (ics = null) => {
        setLoading(true);
        try {
            const variables = {
                filter: {},
                pagination: {
                    // limit: ics.length,
                },
                unmasked: 1
            };

            if (ics && ics?.length > 0) {
                if (!variables.filter.or) variables.filter.or = [];

                for (let ic of ics) {
                    variables.filter.or.push({
                        nric: {
                            eq: ic
                        },
                        deleted: {
                            eq: 0
                        }
                    });
                }
            }

            // console.log('ImportLabResultFileUpload', variables, JSON.stringify(variables, null, 2));

            const result = await API.graphql({
                query: listPatient,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables
            });
            // console.log("API result", result, JSON.stringify(result, null, 2));
            const data = result?.data?.result;
            const array = data?.result;

            let _data = [];
            // process data
            if (array) {
                for (let l of labs) {
                    _data.push({
                        // patient: a,
                        // ...labs.find(s=> s.nric == a.nric)
                        ...l,
                        patient:
                            provider === "Innoquest"
                                ? array.find(
                                      (s) =>
                                          s.nric?.toLowerCase() ==
                                          l.NRIC?.toLowerCase()
                                  )
                                : {}
                    });
                }

                setLabsWithPatient(_data);
                // console.log('ParseData', _data);
            }

            setLoading(false);
        } catch (error) {
            console.log("error: ", error);
            notification.error("Unable to process lab import.");
        } finally {
        }
    };

    const renderLabButtons = (array) => {
        if (array.length !== 0) {
            const listButtons = array.map((item, index) => {
                return (
                    <Button
                        className="labbutton"
                        onClick={() =>
                            handleLabButtonOnClick(item.labReportName)
                        }
                        disabled={state.fileList.length <= 0}
                    >
                        {item.labReportName}
                    </Button>
                );
            });

            return listButtons;
        } else {
            return "";
        }
    };

    return (
        <div className="content-content">
            <div className="importlabresult-header">
                <div className="title">Import Lab Result - File Upload</div>
            </div>
            <Spin spinning={loading}>
                <div className="importlabresult-content">
                    <Form.Item>
                        <Upload
                            className="fileupload"
                            action={getBase64}
                            listType="picture-card"
                            fileList={state.fileList}
                            onPreview={handlePreview}
                            onChange={(file) => handleChange(file)}
                        >
                            {state.fileList.length >= 1 ? null : uploadButton}
                        </Upload>
                        <Modal
                            visible={state.previewVisible}
                            title={state.previewTitle}
                            footer={null}
                            onCancel={handleCancel}
                        >
                            <img
                                alt="upload preview"
                                style={{ width: "100%" }}
                                src={state.previewImage}
                            />
                        </Modal>
                        <div
                            style={{
                                color: "#040a45",
                                fontWeight: 700,
                                width: "80%",
                                margin: "auto"
                            }}
                        >
                            *Only CSV and Excel files can be accepted.
                        </div>
                    </Form.Item>
                    <br />
                    <div className="container-labbutton">
                        {renderLabButtons([
                            {
                                id: 1,
                                labReportName: "Innoquest"
                            }
                            // {
                            //     id: 2,
                            //     labReportName: "Lab 2"
                            // },
                            // {
                            //     id: 3,
                            //     labReportName: "Lab 3"
                            // },
                        ])}
                    </div>
                    <div
                        style={{
                            color: "#040a45",
                            fontWeight: 700,
                            margin: "16px auto auto"
                        }}
                    >
                        Select the source of the file
                    </div>
                </div>
            </Spin>
        </div>
    );
};

export default ImportLabResultFileUpload;
