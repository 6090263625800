import React, { useContext, useEffect, useState } from "react"
import { Descriptions } from "antd"

import { AppContext } from "../contexts/AppContext"

const PrePostDialysis = (props) => {
    const { appState } = useContext(AppContext);
    const { page, values } = props
    const [currentClinicMachine, setCurrentClinicMachine] = useState([])
    const [prePostDialysis, setPrePostDialysis] = useState({
        sessionMonth: "",
        preWt: "",
        lastWt: "",
        dw: "",
        preTemp: "",
        preEdema: "",
        preSBP: "",
        preDBP: "",
        preHRT: "",
        ufTargetAtStart: "",
        clinicID: "",
        machineID: "",
        dialyser: "",
        MSA: "",
        membraneUse: "",

        machineName: "",

        postWt: "",
        postTemp: "",
        postEdema: "",
        postSBP: "",
        postDBP: "",
        postHRT: "",
        KTVonline: "",
    })

    const getMachineName = () => {
        let machineName = ""
        for (let i = 0; i < currentClinicMachine.length; i++) {
            if (prePostDialysis.machineID === currentClinicMachine[i].id) {
                machineName = currentClinicMachine[i].serialNo
                break
            }
        }
        return machineName
    }

    useEffect(() => {
        // console.log(values", values);
        // console.log(prePostDialysis", prePostDialysis);
        setPrePostDialysis({
            ...prePostDialysis,
            ...values
        })
    }, [values])

    useEffect(() => {
        setPrePostDialysis({
            ...prePostDialysis,
            ...values,
            machineName: getMachineName()
        })
    }, [prePostDialysis.machineID])

    useEffect(() => {
        if (appState.machineList !== -1 && appState.selectedClinicID !== "") {
            // console.log("appState.machineList", appState.machineList);
            const array = appState.machineList.filter(value => value.clinicID.toString() === appState.selectedClinicID.toString() && value.status !== "EXPIRED")
            setCurrentClinicMachine(array)
        }
    }, [appState.machineList, appState.selectedClinicID])

    return (
        <div className="modal-patientrecord-dialysisorder">
            <div className="row2" style={{ marginTop: 0, display: "flex", flexDirection: "row" }}>
                <Descriptions
                    className="column"
                    column={{ xs: 1, sm: 1 }}
                >
                    <Descriptions.Item label="PRE-WT (KG)">{prePostDialysis.preWt}</Descriptions.Item>
                    <Descriptions.Item label="LAST-WT (KG)">{prePostDialysis.lastWt}</Descriptions.Item>
                    <Descriptions.Item label="Pre-Temp (&#8451;)">{prePostDialysis.preTemp}</Descriptions.Item>
                    <Descriptions.Item label="Pre Edema">{prePostDialysis.preEdema}</Descriptions.Item>
                    <Descriptions.Item label="Pre SBP">{prePostDialysis.preSBP}</Descriptions.Item>
                    <Descriptions.Item label="Pre DBP">{prePostDialysis.preDBP}</Descriptions.Item>
                    <Descriptions.Item label="Pre HRT">{prePostDialysis.preHRT}</Descriptions.Item>
                    <Descriptions.Item label="UF Target At Start (&#8467;)">{prePostDialysis.ufTargetAtStart}</Descriptions.Item>
                    <Descriptions.Item label="DW (KG)">{prePostDialysis.dw}</Descriptions.Item>
                    <Descriptions.Item label="Machine">{prePostDialysis.machineName}</Descriptions.Item>
                    <Descriptions.Item label="Dialyser">{prePostDialysis.dialyser}</Descriptions.Item>
                    <Descriptions.Item label="MSA">{prePostDialysis.MSA}</Descriptions.Item>
                    <Descriptions.Item label="Membrane use">{prePostDialysis.membraneUse}</Descriptions.Item>
                    {/* {
                        page === "checkout" ? (
                            <>
                                <Descriptions.Item label="Post-Temp (&#8451;)">{prePostDialysis.postTemp}</Descriptions.Item>
                                <Descriptions.Item label="Post Edema">{prePostDialysis.postEdema}</Descriptions.Item>
                                <Descriptions.Item label="Post SBP">{prePostDialysis.postSBP}</Descriptions.Item>
                                <Descriptions.Item label="Post DBP">{prePostDialysis.postDBP}</Descriptions.Item>
                                <Descriptions.Item label="Post HRT">{prePostDialysis.postHRT}</Descriptions.Item>
                                <Descriptions.Item label="KTV online">{prePostDialysis.KTVonline}</Descriptions.Item>
                            </>
                        ) : ("")
                    } */}
                </Descriptions>
                {
                    page === "checkout" ? (
                        <Descriptions
                            className="column"
                            column={{ xs: 1, sm: 1 }}
                        >
                            <Descriptions.Item label="Post-Temp (&#8451;)">{prePostDialysis.postTemp}</Descriptions.Item>
                            <Descriptions.Item label="Post Edema">{prePostDialysis.postEdema}</Descriptions.Item>
                            <Descriptions.Item label="Post SBP">{prePostDialysis.postSBP}</Descriptions.Item>
                            <Descriptions.Item label="Post DBP">{prePostDialysis.postDBP}</Descriptions.Item>
                            <Descriptions.Item label="Post HRT">{prePostDialysis.postHRT}</Descriptions.Item>
                            <Descriptions.Item label="KTV online">{prePostDialysis.KTVonline}</Descriptions.Item>
                        </Descriptions>
                    ) : ("")
                }

            </div>
        </div>
    )
}

export default PrePostDialysis