import React from "react"
import { Button } from "antd"
import { DownloadOutlined } from "@ant-design/icons"
import { useHistory } from "react-router-dom"

import path from "../utils/pathSettings"
import PatientDetailsSummary from "./Component-PatientDetailsSummary"

const PatientRecordHeader = (props) => {
    const { onFinish, page, patientID, setPatientDisplay, manageDialysis = false } = props
    // const { onFinish, page, patientID, dialysisCompleted } = props
    const history = useHistory()

    const exportToPDF = () => {
        // console.log("export to pdf function");
        history.push(path("patientRecordExport", [patientID]))
    }

    const className = page === "checkin" || page === "intra" || page === "checkout" ? "patientrecord-header above-md" : "patientrecord-header"

    return (
        // <div className="patientrecord-header above-md">
        <div className={className}>
            <div className="column1 title">
                {
                    page === "checkin" || page === "intra" || page === "checkout" ? (
                        "Dialysis Session"
                    ) :
                        (
                            manageDialysis === false ? "Patient Record" : "Manage Dialysis Session"
                        )
                }
            </div>
            <div className="column2">
                <PatientDetailsSummary patientID={patientID} setPatientDisplay={setPatientDisplay} />
            </div>
            <div className="column3">
                {
                    page === "checkin" ? (
                        <Button type="primary" onClick={onFinish}>
                            Check In
                        </Button>
                    ) : (
                        page === "checkinEdit" || page === "checkoutEdit" ? (
                            <Button type="primary" onClick={onFinish}>
                                Update
                            </Button>
                        ) : (
                            page === "intra" || page === "checkout" ? (
                                <Button className="btn-finishcheckout" onClick={onFinish}>
                                    Check Out
                                </Button>
                            ) : (
                                page === "record" && manageDialysis === false ? (
                                    <Button className="btn-finishcheckout" onClick={exportToPDF}>
                                        <DownloadOutlined /> Export to PDF
                                    </Button>
                                ) : ("")
                            )
                        )
                    )
                }
            </div>
            {/* {
                dialysisCompleted === true ? "" : (
                    <div className="column3">
                        {
                            page === "checkin" ? (
                                <Button type="primary" onClick={onFinish}>
                                    Check In
                                </Button>
                            ) : (
                                page === "intra" || page === "checkout" ? (
                                    <Button className="btn-finishcheckout" onClick={onFinish}>
                                        Check Out
                                    </Button>
                                ) : ("")
                            )
                        }
                    </div>
                )
            } */}
        </div>
    )
}

export default PatientRecordHeader