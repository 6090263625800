import React, { useEffect } from "react"
import ReactDOM from "react-dom"

const MyWindowPortal = (props) => {
    const containerE1 = document.createElement("div")
    let externalWindow = null

    function copyStyles(sourceDoc, targetDoc) {
        Array.from(sourceDoc.styleSheets).forEach(styleSheet => {
            if (styleSheet.cssRules) { // true for inline styles
                const newStyleEl = sourceDoc.createElement('style');

                Array.from(styleSheet.cssRules).forEach(cssRule => {
                    newStyleEl.appendChild(sourceDoc.createTextNode(cssRule.cssText));
                });

                targetDoc.head.appendChild(newStyleEl);
            } else if (styleSheet.href) { // true for stylesheets loaded from a URL
                const newLinkEl = sourceDoc.createElement('link');

                newLinkEl.rel = 'stylesheet';
                newLinkEl.href = styleSheet.href;
                targetDoc.head.appendChild(newLinkEl);
            }
        });
    }

    useEffect(() => {
        externalWindow = window.open("", "", "width=600, height=600, left=650, top=200")
        externalWindow.document.body.appendChild(containerE1)
        externalWindow.document.title = 'Add Clinical Note'
        copyStyles(document, externalWindow.document)
        externalWindow.addEventListener('beforeunload', () => {
            props.closeWindowPortal();
        })

        return () => {
            externalWindow.close()
        }
    }, [])

    return (
        ReactDOM.createPortal(props.children, containerE1)
    )
}

export default MyWindowPortal