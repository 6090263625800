import React, { useEffect } from "react"
import { Divider } from "antd"
import { Link } from "react-router-dom"

import manageModuleMenuSettings from "../utils/manageModuleMenuSettings"

const Manage = () => {
    useEffect(() => {
        return () => {}
    }, [])

    const renderManageIcons = (array) => {
        const items = array.map((item, index) => {
            return (
                <Link to={item.to}>
                    <div className="icon">
                        <div className="container-image">
                            <img src={item.imgSrc} />
                        </div>
                        <div className="title">
                            {item.name}
                        </div>
                    </div>
                </Link>
            )
        })

        return items
    }

    const renderManageIconsContainer = (array) => {
        return (
            (array.length === 0) ? (
                ""
            ) : (
                <div className="container-manageicon">
                    {renderManageIcons(array)}
                </div>
            )
        )
    }

    return (
        <div className="content-content">
            <div className="manage-header">
                <div className="title">
                    Manage
                </div>
            </div>
            <div className="manage-content">
                {renderManageIconsContainer(manageModuleMenuSettings("row1"))}
                {renderManageIconsContainer(manageModuleMenuSettings("row2"))}
                {renderManageIconsContainer(manageModuleMenuSettings("row3"))}
            </div>
        </div>
    )
}

export default Manage