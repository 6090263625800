import React, { useContext, useState, useEffect } from "react"
import { Dropdown, Menu } from "antd"
import { MenuOutlined } from "@ant-design/icons"
import { useLocation } from "react-router-dom"
import { NavHashLink } from "react-router-hash-link"

import { AppContext } from "../contexts/AppContext"
import navigationMenuSettings from "../utils/navigationMenuSettings"

const HeaderNav = (props) => {
    const { view } = props
    const { SubMenu } = Menu;
    const { appState, getCurrentAuthenticatedUser } = useContext(AppContext)
    const location = useLocation()

    // console.log("navigationMenuSettings", navigationMenuSettings());

    // const [menu, setMenu] = useState([
    //     {
    //         name: "Machines",
    //         to: "/machines",
    //     },
    //     {
    //         name: "Staff Database",
    //         to: "/staffdatabase",
    //     },
    //     {
    //         name: "Routine Order",
    //         to: "/routineorder",
    //     },
    //     {
    //         name: "Import Lab Result",
    //         to: "/importlabresult",
    //     },
    //     {
    //         name: "Availability",
    //         to: "/availability",
    //     },
    // ])

    const renderDropdownMenuItems = (array) => {
        const menuItems = array.map((item) => {
            if (item.to.startsWith("submenu-")) {
                return (
                    <SubMenu key={item.to} title={item.name}>
                        {renderDropdownMenuItems(item.submenu)}
                    </SubMenu>
                )
            }
            else {
                return (
                    <Menu.Item key={item.to} >
                        <NavHashLink to={item.to}>{item.name}</NavHashLink>
                    </Menu.Item>
                )
            }
        })
        return menuItems
    }

    const renderPageDropdownMenuItems = (array) => {
        return (
            (array.length === 0) ? (
                ""
            ) : (
                renderDropdownMenuItems(array)
            )
        )
    }

    const dropdownMenuContent = (array) => {
        return (
            <Menu style={{ maxWidth: "250px" }}>
                {renderPageDropdownMenuItems(array)}
            </Menu>
        )
    }

    const renderMenuItems = (array) => {
        const menuItems = array.map((item) => {
            // render for submenu vs navigation link item
            if (item.to.startsWith("submenu-")) {
                return (
                    <Dropdown key={item.to} overlay={dropdownMenuContent(item.submenu)} trigger={["click"]}>
                        <div className="menu-item">
                            <NavHashLink to="#">{item.name}</NavHashLink>
                        </div>
                    </Dropdown>
                )
            }
            else {
                return (
                    <div className="menu-item" key={item.to}>
                        <NavHashLink to={item.to}>{item.name}</NavHashLink>
                        {/* <NavLink to={item.to} className="above-min">{item.name}</NavLink> */}
                    </div>
                    // <Menu.Item key={item.to}>
                    //     <NavLink to={item.to}>{item.name}</NavLink>
                    // </Menu.Item>
                )
            }
        })
        return menuItems
    }

    const renderPageMenuItems = (array) => {
        return (
            (array.length === 0) ? (
                ""
            ) : (
                renderMenuItems(array)
            )
        )
    }

    return (
        // <div className={view === "desktop" ? "item above-min" : "item"}>
        <div className={view === "desktop" ? "item above-sm" : "item"}>
            {
                view === "mobile" ? (
                    // <Dropdown overlay={dropdownMenuContent(appState.menu)} trigger={["click"]}>
                    <Dropdown overlay={dropdownMenuContent(navigationMenuSettings(appState.loginType, location.pathname))} trigger={["click"]}>
                        {/* <MenuOutlined className="below-min" /> */}
                        <MenuOutlined className="below-sm" />
                    </Dropdown>
                ) : ("")
            }
            {
                view === "desktop" ? (
                    // <div className="row nav above-min">
                    <div className="row nav above-sm">
                        {/* {renderPageMenuItems(appState.menu)} */}
                        {renderPageMenuItems(navigationMenuSettings(appState.loginType, location.pathname))}
                    </div>
                ) : ("")
            }
        </div>
    )
}

export default HeaderNav