import React, { useContext, useEffect, useState } from "react";
import {
    Button,
    DatePicker,
    Descriptions,
    Empty,
    Form,
    Input,
    Modal,
    notification,
    Popconfirm,
    Select,
    Space,
    Spin
} from "antd";
import { API } from "aws-amplify";
import moment from "moment";

import { listIncident } from "../backend/graphql/queries";
import { createIncident, updateIncident } from "../backend/graphql/mutations";
import { AppContext } from "../contexts/AppContext";
import iconAdd from "../media/Icon-Add.png";
import iconEdit from "../media/Icon-Edit.png";
import iconEye from "../media/Icon-Eye2.png";
import PatientDetailsSummary from "./Component-PatientDetailsSummary";
import useSWR from "swr";
import { fetcher } from "../utils/useSWR";

const { TextArea } = Input;

const PatientIncidentReport = (props) => {
    const { appState } = useContext(AppContext);
    const patientID = props?.match?.params?.id;
    const [form] = Form.useForm();
    const [formUpdate] = Form.useForm();
    const { Option } = Select;
    const dateFormatList = ["DD MMM YYYY"];
    const validateMessages = {
        required: "Required."
    };
    const [table, setTable] = useState({
        data: -1,
        pagination: {
            current: 1,
            pageSize: 20,
            offset: 0
        },
        loading: false
    });
    const [display, setDisplay] = useState([]);
    const [visibilityAddIncidentFollowUp, setVisibilityAddIncidentFollowUp] =
        useState(false);
    const [visibilityViewIncident, setVisibilityViewIncident] = useState(false);
    const [incident, setIncident] = useState({});

    const [queryOption, setQueryOption] = useState(null);
    const { data, error, mutate, isValidating } = useSWR(queryOption, fetcher, {
        dedupingInterval: 60000,
        focusThrottleInterval: 60000
        // revalidateIfStale: false,
        // revalidateOnFocus: false,
        // revalidateOnReconnect: false
    });

    useEffect(() => {
        if (data && queryOption && isValidating == false) {
            // console.log(data, queryOption);
            const result = data;

            // console.log("result", result);
            const _data = result?.data?.result;
            const array = result?.data?.result?.result;
            // console.log("data", data);

            if (array.length >= 0) {
                setTable({
                    ...table,
                    loading: false,
                    data: array,
                    pagination: {
                        ...table.pagination,
                        current:
                            queryOption?.variables?.pagination?.offset /
                            queryOption?.variables?.pagination?.limit,
                        offset: queryOption?.variables?.pagination?.offset,
                        total:
                            queryOption?.variables?.pagination?.offset >=
                            _data.count
                                ? _data.count + 1
                                : _data.count // keeps the last pagination if it is the last record
                    }
                });
            }
        }
    }, [data, isValidating]);

    const handleDatePickerChange = (date, dateString) => {
        // console.log("handleDatePickerChange");
        // console.log(date, dateString);
    };

    const handleModalVisibility = (modal, record) => {
        // console.log("modal", modal);
        switch (modal) {
            case "addIncidentFollowUp":
                setIncident(record ? record : {});
                setVisibilityAddIncidentFollowUp(
                    !visibilityAddIncidentFollowUp
                );
                formUpdate.setFieldsValue({
                    ...record
                });
                break;
            case "viewIncident":
                setIncident(record ? record : {});
                setVisibilityViewIncident(!visibilityViewIncident);
                break;
            default:
        }
    };

    const renderListOptions = (
        array,
        key = "id",
        value = "id",
        name = "name"
    ) => {
        if (typeof array == "object" && array.length !== 0) {
            const listOptions = array.map((item, index) => {
                return (
                    <Option key={item[key]} value={item[value]}>
                        {item[name]}
                    </Option>
                );
            });

            return listOptions;
        } else {
            return <div />;
        }
    };

    const columns = [
        {
            // title: "Timestamp",
            title: "Submitted",
            dataIndex: "timestamp",
            disabled: true
        },
        {
            title: "Centre/Group Reporting",
            dataIndex: "reportingCentre",
            renderInput: (
                <Form.Item
                    key="reportingCentre"
                    name="reportingCentre"
                    rules={[
                        {
                            required: true
                        }
                    ]}
                    // style={{ width: 160 }}
                >
                    <Select
                        // style={{ width: 160 }}
                        showSearch
                        placeholder="Select centre"
                        filterOption={(input, option) =>
                            option?.children
                                ?.toLowerCase()
                                .indexOf(input?.toLowerCase()) >= 0
                        }
                        disabled={false}
                    >
                        {renderListOptions(
                            appState.clinicList
                            // [
                            //     {
                            //         id: 1,
                            //         name: "Clinic A"
                            //     },
                            //     {
                            //         id: 2,
                            //         name: "Clinic B"
                            //     },
                            //     {
                            //         id: 3,
                            //         name: "Clinic C"
                            //     },
                            // ]
                        )}
                    </Select>
                </Form.Item>
            )
        },
        // {
        //     title: "No",
        //     dataIndex: "id",
        //     disabled: true,
        // },
        {
            // title: "Location of Event",
            title: "Location",
            dataIndex: "location",
            required: true,
            renderInput: (
                <Form.Item
                    key="location"
                    name="location"
                    rules={[
                        {
                            required: true
                        }
                    ]}
                    // style={{ width: 160 }}
                >
                    <Select
                        // style={{ width: 160 }}
                        showSearch
                        placeholder="Select centre"
                        filterOption={(input, option) =>
                            option?.children
                                ?.toLowerCase()
                                .indexOf(input?.toLowerCase()) >= 0
                        }
                        disabled={false}
                    >
                        {renderListOptions(
                            [
                                ...(appState.clinicList &&
                                appState.clinicList.length > 0
                                    ? appState.clinicList
                                    : []),
                                { id: "Home", name: "Home" },
                                { id: "Hospital", name: "Hospital" },
                                { id: "Transit", name: "Transit" },
                                { id: "Work", name: "Work" }
                            ],
                            "name",
                            "name",
                            "name"
                        )}
                    </Select>
                </Form.Item>
            )
        },
        {
            // title: "Date / Time of Event",
            title: "Date / Time",
            dataIndex: "datetime",
            // width: 160,
            renderInput: (
                <Form.Item
                    key="datetime"
                    name="datetime"
                    rules={[
                        {
                            required: true
                        }
                    ]}
                    // style={{ width: 160 }}
                >
                    <DatePicker
                        onChange={handleDatePickerChange}
                        showTime
                        format={["DD MMM YYYY HH:mm"]}
                    />
                </Form.Item>
            )
        },
        {
            title: "Reported By",
            dataIndex: "reportedBy",
            renderInput: (
                <Form.Item
                    key="reportedBy"
                    name="reportedBy"
                    rules={[
                        {
                            required: true
                        }
                    ]}
                    // style={{ width: 160 }}
                >
                    <Select
                        // style={{ width: 160 }}
                        showSearch
                        placeholder="Select reporter"
                        filterOption={(input, option) =>
                            option?.children
                                ?.toLowerCase()
                                .indexOf(input?.toLowerCase()) >= 0
                        }
                        disabled={false}
                    >
                        {renderListOptions(
                            appState.staffList,
                            "accountID"
                            // [
                            //     {
                            //         id: 1,
                            //         name: "Option A"
                            //     },
                            //     {
                            //         id: 2,
                            //         name: "Option B"
                            //     },
                            //     {
                            //         id: 3,
                            //         name: "Option C"
                            //     },
                            // ]
                        )}
                    </Select>
                </Form.Item>
            )
        },
        // {
        //     title: "Centre/Patient Event",
        //     dataIndex: "centrePatientEvent",
        //     renderInput: (
        //         <Form.Item
        //             key="centrePatientEvent"
        //             name="centrePatientEvent"
        //             rules={[
        //                 {
        //                     required: true,
        //                 },
        //             ]}
        //         // style={{ width: 160 }}
        //         >
        //             <Select
        //                 // style={{ width: 160 }}
        //                 showSearch
        //                 placeholder="Select event"
        //                 filterOption={(input, option) =>
        //                     option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
        //                 }
        //                 disabled={false}
        //             >
        //                 {renderListOptions([
        //                     { id: 'PatientEvent', name: "Patient Event" },
        //                     { id: 'CentreEvent', name: "Centre Event" },
        //                 ])}
        //             </Select>
        //         </Form.Item>
        //     )
        // },
        {
            // title: "Event Type",
            title: "Type",
            dataIndex: "eventType",
            required: true,
            renderInput: (
                <Form.Item
                    key="eventType"
                    name="eventType"
                    rules={[
                        {
                            required: true
                        }
                    ]}
                    // style={{ width: 160 }}
                >
                    <Select
                        // style={{ width: 160 }}
                        showSearch
                        placeholder="Select event type"
                        filterOption={(input, option) =>
                            option?.children
                                ?.toLowerCase()
                                .indexOf(input?.toLowerCase()) >= 0
                        }
                        disabled={false}
                    >
                        {renderListOptions([
                            {
                                id: "Vascular Access Issues",
                                name: "Vascular Access Issues"
                            },
                            {
                                id: "Sepsis Non-access",
                                name: "Sepsis Non-access"
                            },
                            {
                                id: "SepsisAccess related",
                                name: "Sepsis Access related"
                            },
                            { id: "Missed Dialysis", name: "Missed Dialysis" },
                            {
                                id: "Tubing or Needle Issues",
                                name: "Tubing or Needle Issues"
                            },
                            {
                                id: "Medication Issues",
                                name: "Medication Issues"
                            },
                            { id: "Fall", name: "Fall" },
                            { id: "Others", name: "Others" }
                        ])}
                    </Select>
                </Form.Item>
            )
        },
        // {
        //     title: "Name Code",
        //     dataIndex: "nameCode"
        // },
        {
            // title: "Description Event",
            title: "Description",
            dataIndex: "description",
            required: true
        },
        {
            // title: "Probable Event Cause",
            title: "Probable Cause",
            dataIndex: "probableCause",
            disabled: true
        },
        {
            title: "Action Taken [dated]",
            dataIndex: "actionTaken",
            disabled: true
        },
        {
            // title: "Event Outcome",
            title: "Outcome",
            dataIndex: "eventOutcome",
            // required: true,
            disabled: true
        },
        {
            title: "Further Action Planned",
            dataIndex: "furtherActionPlanned",
            disabled: true
        },
        {
            title: (
                <>
                    Additional Orders
                    <br />
                    Medical Director
                </>
            ),
            dataIndex: "addedOrders",
            disabled: true
        },
        {
            title: "Action",
            dataIndex: "action",
            width: 160
        }
    ];

    useEffect(() => {
        getIncidentRecords(patientID);
        return () => {};
    }, []);

    useEffect(() => {
        if (appState.selectedClinicID > 0)
            form.setFieldsValue({
                reportingCentre: parseInt(appState.selectedClinicID),
                centrePatientEvent: "PatientEvent"
            });
    }, [appState.selectedClinicID]);

    useEffect(() => {
        if (
            table.data != -1 &&
            appState?.staffList?.length > 0 &&
            appState?.clinicList?.length > 0
        ) {
            let _datas = [];
            for (let d of table.data) {
                let _data = {
                    id: d.id,
                    timestamp: moment
                        .utc(d.createdOn)
                        .local()
                        .format("DD MMM YYYY, HH:mm"),
                    reportingCentre: appState.clinicList?.find(
                        (s) => s.id == d.clinicID
                    )?.name,
                    no: d.id,
                    location: d.location,
                    datetime: moment
                        .utc(d.datetimeOccurred)
                        .local()
                        .format("DD MMM YYYY, HH:mm"),
                    reportedBy: appState.staffList?.find(
                        (s) => s.accountID == d.reportedBy
                    )?.name,
                    centrePatientEvent:
                        d.eventCategory == "PatientEvent"
                            ? "Patient Event"
                            : "Centre Event",
                    eventType: d.eventType,
                    eventOutcome: d.eventOutcome,
                    description: d.description,
                    probableCause: d.cause,
                    actionTaken: d.action,
                    furtherActionPlanned: d.furtherAction,
                    addedOrders: d.order
                };
                _datas.push(_data);
            }
            setDisplay(_datas);
        }
    }, [table.data, appState.clinicList, appState.staffList]);

    const getIncidentRecords = async (id, props = table.pagination) => {
        setTable({
            ...table,
            loading: true
        });
        try {
            const variables = {
                pagination: {
                    limit: props.pageSize,
                    offset: props.offset,
                    orderby: "id desc"
                },
                filter: {
                    patientID: {
                        eq: id
                    }
                }
            };

            // console.log('variables', variables);
            let _queryOption = {
                query: listIncident,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables
            };
            setQueryOption(_queryOption);
            // const result = await API.graphql({
            //     query: listIncident,
            //     authMode: 'AMAZON_COGNITO_USER_POOLS',
            //     variables,
            // })

            // // console.log("result", result);
            // const data = result.data.result
            // const array = result.data.result?.result
            // // console.log("data", data);

            // if (array.length >= 0) {
            //     setTable({
            //         ...table,
            //         loading: false,
            //         data: array,
            //         pagination: {
            //             ...table.pagination,
            //             current: props.current,
            //             offset: props.offset,
            //             total: props.offset >= data.count ? data.count + 1 : data.count // keeps the last pagination if it is the last record
            //         }
            //     })
            // }
        } catch (error) {
            console.log("error:", error);
            notification.error({
                message: "Unable to retrieve records"
            });
            setTable({
                ...table,
                loading: false
            });
        }
    };

    const createIncidentRecord = async (values) => {
        setTable({
            ...table,
            loading: true
        });
        try {
            // {
            //     "reportingCentre": 1,
            //     "location": "1",
            //     "datetime": "2022-03-14T13:54:11.926Z",
            //     "reportedBy": "0fe8e218-b716-470f-b263-d11c3a2f0e01",
            //     "centrePatientEvent": 2,
            //     "eventType": "1",
            //     "eventOutcome": "1",
            //     "description": "1",
            //     "probableCause": "1",
            //     "actionTaken": "1",
            //     "furtherActionPlanned": "1",
            //     "addedOrders": "1"
            // }
            const variables = {
                create: {
                    reportedBy: values.reportedBy,
                    clinicID: values.reportingCentre,
                    patientID: patientID,
                    location: values.location,
                    datetimeOccurred: values.datetime
                        .toISOString()
                        .split(".")[0],

                    eventCategory: values.centrePatientEvent,
                    eventType: values.eventType,
                    description: values.description,
                    cause: values.probableCause,
                    action: values.actionTaken,
                    eventOutcome: values.eventOutcome,
                    furtherAction: values.furtherActionPlanned,
                    order: values.addedOrders
                }
            };

            // console.log('variables', variables);
            const result = await API.graphql({
                query: createIncident,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables
            });
            // console.log("result", result);
            const data = result.data.result;
            const array = result.data.result?.result;
            // console.log("data", data);

            if (data) {
                form.resetFields();
                notification.success({ message: "Created successfully" });
                // getIncidentRecords(patientID)
                mutate();
            }
        } catch (error) {
            console.log("error:", error);
            notification.error({
                message: "Unable to retrieve records"
            });
            setTable({
                ...table,
                loading: false
            });
        }
    };

    const updateIncidentRecord = async (values) => {
        setTable({
            ...table,
            loading: true
        });
        try {
            // {
            //     "reportingCentre": 1,
            //     "location": "1",
            //     "datetime": "2022-03-14T13:54:11.926Z",
            //     "reportedBy": "0fe8e218-b716-470f-b263-d11c3a2f0e01",
            //     "centrePatientEvent": 2,
            //     "eventType": "1",
            //     "eventOutcome": "1",
            //     "description": "1",
            //     "probableCause": "1",
            //     "actionTaken": "1",
            //     "furtherActionPlanned": "1",
            //     "addedOrders": "1"
            // }
            const variables = {
                update: {
                    id: values.id,

                    cause: values.probableCause,
                    action: values.actionTaken,
                    eventOutcome: values.eventOutcome,
                    furtherAction: values.furtherActionPlanned,
                    order: values.addedOrders,
                    responseBy: appState.cognitoUser.username,
                    responseTimestamp: moment().toISOString().split(".")[0]
                }
            };

            // console.log('variables', variables);
            const result = await API.graphql({
                query: updateIncident,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables
            });
            // console.log("result", result);
            const data = result.data.result;
            const array = result.data.result?.result;
            // console.log("data", data);

            if (data) {
                form.resetFields();
                notification.success({ message: "Updated successfully" });
                // getIncidentRecords(patientID)
                mutate();
                handleModalVisibility("addIncidentFollowUp");
            }
        } catch (error) {
            console.log("error:", error);
            notification.error({
                message: "Unable to retrieve records"
            });
            setTable({
                ...table,
                loading: false
            });
        }
    };

    const onFinish = async (values) => {
        // console.log("onFinish clicked", values);
        await form.validateFields();
        createIncidentRecord(values);
    };

    const onFinishIncidentResponse = async (values) => {
        // console.log("onFinishIncidentResponse clicked", values);
        let _values = await formUpdate.validateFields();

        _values = Object.entries(_values).reduce(
            (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
            {}
        );
        if (Object.keys(_values).length > 1) {
            updateIncidentRecord(_values);
        } else {
            notification.warning({ message: "No message to update." });
        }
    };

    // render custom table header
    const renderHeaderItems = (array) => {
        const items = array.map((item, index) => {
            // console.log("item", item);
            const width = item.width !== undefined ? item.width : null;

            return (
                // <div key={item.dataIndex} className={item.dataIndex !== "action" ? "column" : ""} style={width !== null ? { width: width } : {}}>
                <div
                    key={item.dataIndex}
                    className={"column"}
                    style={width !== null ? { width: width } : {}}
                >
                    {item.title}
                </div>
            );
        });
        return items;
    };

    const renderIncidentReportHeader = (array) => {
        return array.length === 0 ? "" : renderHeaderItems(array);
    };

    // render custom table input row
    const renderInputItems = (array) => {
        const items = array.map((item, index) => {
            // console.log("item", item);
            // console.log("item width", item.width);
            const width = item.width !== undefined ? item.width : null;
            if (item.renderInput !== undefined) {
                return item.renderInput;
            } else {
                if (item.dataIndex === "action") {
                    return (
                        <Form.Item
                            key={item.dataIndex}
                            style={width !== null ? { width: width } : {}}
                        >
                            <Popconfirm
                                title="Are you sure?"
                                onConfirm={() =>
                                    onFinish(form.getFieldsValue())
                                }
                                okText="Yes"
                                cancelText="No"
                                placement="topLeft"
                            >
                                {/* <Button className="circle" htmlType="submit" shape="circle" icon={<PlusOutlined />} /> */}
                                <div className="clickable">
                                    <img src={iconAdd} />
                                </div>
                            </Popconfirm>
                        </Form.Item>
                    );
                } else {
                    return (
                        <Form.Item
                            key={item.dataIndex}
                            name={item.dataIndex}
                            style={width !== null ? { width: width } : {}}
                            rules={[
                                {
                                    required: item.required
                                        ? item.required
                                        : false
                                }
                            ]}
                        >
                            <Input
                                autoComplete="off"
                                disabled={item.disabled ? item.disabled : false}
                            />
                        </Form.Item>
                    );
                }

                // if (width !== null) {
                //     return (
                //         <Form.Item
                //             key={item.dataIndex}
                //             name={item.dataIndex}
                //             style={{ width: width }}
                //             rules={[
                //                 {
                //                     required: true,
                //                 },
                //             ]}
                //         >
                //             <Input autoComplete="off" />
                //         </Form.Item>
                //     )
                // }
                // else {
                //     if (item.dataIndex === "action") {
                //         return (
                //             <Form.Item key={item.dataIndex} style={{ width: 160 }}>
                //                 <Popconfirm title="Are you sure?" onConfirm={() => onFinish(form.getFieldsValue())} okText="Yes" cancelText="No" placement="topRight">
                //                     {/* <Button className="circle" htmlType="submit" shape="circle" icon={<PlusOutlined />} /> */}
                //                     <div className="clickable">
                //                         <img src={iconAdd} />
                //                     </div>
                //                 </Popconfirm>
                //             </Form.Item>
                //         )
                //     }
                //     else {
                //         return (
                //             <Form.Item
                //                 key={item.dataIndex}
                //                 name={item.dataIndex}
                //                 rules={[
                //                     {
                //                         required: true,
                //                     },
                //                 ]}
                //             >
                //                 <Input autoComplete="off" />
                //             </Form.Item>
                //         )
                //     }
                // }
            }
        });
        return items;
    };

    const renderIncidentReportInputRow = (array) => {
        return array.length === 0 ? "" : renderInputItems(array);
    };

    const renderItems = (columns, array) => {
        const items = array.map((item, index) => {
            // console.log("item", item);
            const renderItemColumns = (columns, rowItem) => {
                // console.log("columns", columns);
                // console.log("rowItem", rowItem);
                const renderRowItems = columns.map((columnItem, index) => {
                    // console.log("columns item", columnItem);
                    const width =
                        columnItem.width !== undefined
                            ? columnItem.width
                            : null;
                    let value = "";
                    for (const key in rowItem) {
                        // console.log("key", key);
                        // console.log("value", rowItem[key]);
                        if (columnItem.dataIndex === key) {
                            value = rowItem[key];
                            break;
                        }
                    }

                    return (
                        // <div key={columnItem.dataIndex} className={columnItem.dataIndex !== "action" ? "column" : ""} style={width !== null ? { width: width } : {}}>
                        <div
                            key={columnItem.dataIndex}
                            className={"column"}
                            style={width !== null ? { width: width } : {}}
                        >
                            {/* {value} */}
                            {columnItem.dataIndex !== "action" ? (
                                value
                            ) : (
                                <Space size="middle">
                                    <div
                                        className="clickable"
                                        onClick={() => {
                                            handleModalVisibility(
                                                "viewIncident",
                                                rowItem
                                            );
                                        }}
                                    >
                                        <img src={iconEye} />
                                    </div>
                                    <div
                                        className="action-item"
                                        onClick={() =>
                                            handleModalVisibility(
                                                "addIncidentFollowUp",
                                                rowItem
                                            )
                                        }
                                    >
                                        <div className="icon">
                                            <img src={iconEdit} />
                                        </div>
                                        &nbsp;&nbsp;
                                        <div className="text">
                                            Write Response
                                        </div>
                                    </div>
                                </Space>
                            )}
                        </div>
                    );
                });
                return renderRowItems;
            };

            return (
                <div
                    key={item.id}
                    className={index % 2 === 0 ? "record highlight" : "record"}
                >
                    {renderItemColumns(columns, item)}
                </div>
            );
        });
        return items;
    };

    const renderIncidentReport = (columns, array) => {
        return array.length === 0 ? (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ) : (
            renderItems(columns, array)
        );
    };

    return (
        <div className="content-content">
            <div className="patientincidentreport-header">
                <div className="column1 title">Patient Incident Report</div>
                <PatientDetailsSummary patientID={patientID} />
            </div>
            <div className="patientincidentreport-content">
                <div className="card">
                    <div className="card-header">
                        {renderIncidentReportHeader(columns)}
                    </div>
                    <Spin spinning={table.loading}>
                        <div className="card-content">
                            <Form
                                form={form}
                                layout="horizontal"
                                onFinish={onFinish}
                                validateMessages={validateMessages}
                                requiredMark={false}
                            >
                                <div className="record-add">
                                    {renderIncidentReportInputRow(columns)}
                                </div>
                            </Form>
                            <div className="record-content">
                                {renderIncidentReport(
                                    columns,
                                    display
                                    // [
                                    //     {
                                    //         id: 1,
                                    //         timestamp: "10 Apr 2021, 06:00",
                                    //         reportingCentre: "Centre A",
                                    //         no: 1,
                                    //         location: "Location A",
                                    //         datetime: "10 Apr 2021, 08:00",
                                    //         reportedBy: "Nurse ABC",
                                    //         centrePatientEvent: "Patient Event",
                                    //         eventType: "Event Type",
                                    //         nameCode: "Name Code",
                                    //         eventOutcome: "Event Outcome",
                                    //         description: "Description",
                                    //         probableCause: "Probable Cause",
                                    //         actionTaken: "Action Taken",
                                    //         furtherActionPlanned: "Further Action Planned",
                                    //         addedOrders: "Added Orders",
                                    //     },
                                    //     {
                                    //         id: 2,
                                    //         timestamp: "10 Apr 2021, 06:00",
                                    //         reportingCentre: "Centre A",
                                    //         no: 2,
                                    //         location: "Location A",
                                    //         datetime: "10 Apr 2021, 08:00",
                                    //         reportedBy: "Nurse ABC",
                                    //         centrePatientEvent: "Patient Event",
                                    //         eventType: "Event Type",
                                    //         nameCode: "Name Code",
                                    //         eventOutcome: "Event Outcome",
                                    //         description: "Description",
                                    //         probableCause: "Probable Cause",
                                    //         actionTaken: "Action Taken",
                                    //         furtherActionPlanned: "Further Action Planned",
                                    //         addedOrders: "Added Orders",
                                    //     },
                                    //     {
                                    //         id: 3,
                                    //         timestamp: "10 Apr 2021, 06:00",
                                    //         reportingCentre: "Centre A",
                                    //         no: 3,
                                    //         location: "Location A",
                                    //         datetime: "10 Apr 2021, 08:00",
                                    //         reportedBy: "Nurse ABC",
                                    //         centrePatientEvent: "Patient Event",
                                    //         eventType: "Event Type",
                                    //         nameCode: "Name Code",
                                    //         eventOutcome: "Event Outcome",
                                    //         description: "Description",
                                    //         probableCause: "Probable Cause",
                                    //         actionTaken: "Action Taken",
                                    //         furtherActionPlanned: "Further Action Planned",
                                    //         addedOrders: "Added Orders",
                                    //     },
                                    // ]
                                )}
                            </div>
                        </div>
                    </Spin>
                </div>
            </div>
            <Modal
                title={
                    <div className="modal-title">
                        Incident Report - Write Responses
                    </div>
                }
                visible={visibilityAddIncidentFollowUp}
                onCancel={() => handleModalVisibility("addIncidentFollowUp")}
                footer={[
                    <Button
                        key="back"
                        onClick={() =>
                            handleModalVisibility("addIncidentFollowUp")
                        }
                    >
                        Cancel
                    </Button>,
                    <Popconfirm
                        title="Are you sure?"
                        onConfirm={() =>
                            onFinishIncidentResponse(
                                formUpdate.getFieldsValue()
                            )
                        }
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button key="submit" type="primary">
                            Submit
                        </Button>
                    </Popconfirm>
                ]}
                className="modal-patient-incidentreport"
                // width={800}
                width={1000}
            >
                <div className="modal-content">
                    <Descriptions layout="horizontal">
                        <Descriptions.Item label="Submitted" span={3}>
                            {incident.timestamp}
                        </Descriptions.Item>
                        <Descriptions.Item
                            label="Centre/Group Reporting"
                            span={3}
                        >
                            {incident.reportingCentre}
                        </Descriptions.Item>
                        <Descriptions.Item label="No" span={3}>
                            {incident.id}
                        </Descriptions.Item>
                        <Descriptions.Item label="Location" span={3}>
                            {incident.location}
                        </Descriptions.Item>
                        <Descriptions.Item label="Date / Time" span={3}>
                            {incident.datetime}
                        </Descriptions.Item>
                        <Descriptions.Item label="Reported By" span={3}>
                            {incident.reportedBy}
                        </Descriptions.Item>
                        <Descriptions.Item label="Type" span={3}>
                            {incident.eventType}
                        </Descriptions.Item>
                        {/* <Descriptions.Item label="Outcome" span={3}>{incident.eventOutcome}</Descriptions.Item> */}
                        <Descriptions.Item label="Description" span={3}>
                            {incident.description}
                        </Descriptions.Item>
                    </Descriptions>
                    <Form
                        className="addincidentfollowup"
                        form={formUpdate}
                        layout="horizontal"
                        onFinish={onFinishIncidentResponse}
                        validateMessages={validateMessages}
                        requiredMark={false}
                    >
                        <Form.Item
                            className="hidden"
                            label="id"
                            name="id"
                            hidden={true}
                        >
                            <TextArea disabled rows={3}>
                                {incident.id}
                            </TextArea>
                        </Form.Item>
                        <Form.Item
                            className="row2"
                            label="Probable Cause"
                            name="probableCause"
                            rules={[
                                {
                                    required: false
                                }
                            ]}
                        >
                            <TextArea rows={3} />
                        </Form.Item>
                        <Form.Item
                            className="row2"
                            label="Action Taken [dated]"
                            name="actionTaken"
                            rules={[
                                {
                                    required: false
                                }
                            ]}
                        >
                            <TextArea rows={3} />
                        </Form.Item>
                        <Form.Item
                            className="row2"
                            label="Outcome"
                            name="eventOutcome"
                            rules={[
                                {
                                    required: false
                                }
                            ]}
                        >
                            <TextArea rows={3} />
                        </Form.Item>
                        <Form.Item
                            className="row2"
                            label="Further Action Planned"
                            name="furtherActionPlanned"
                            rules={[
                                {
                                    required: false
                                }
                            ]}
                        >
                            <TextArea rows={3} />
                        </Form.Item>
                        {appState?.loginType === "MedicalDirector" ? (
                            <Form.Item
                                className="row2"
                                label="Additional Orders (Medical Director)"
                                name="addedOrders"
                                rules={[
                                    {
                                        required: false
                                    }
                                ]}
                            >
                                <TextArea rows={3} />
                            </Form.Item>
                        ) : (
                            ""
                        )}
                    </Form>
                </div>
            </Modal>
            <Modal
                title={
                    <div className="modal-title">Incident Report - View</div>
                }
                visible={visibilityViewIncident}
                onCancel={() => handleModalVisibility("viewIncident")}
                footer={[
                    <Button
                        key="back"
                        onClick={() => handleModalVisibility("viewIncident")}
                    >
                        Cancel
                    </Button>
                ]}
                className="modal-patient-incidentreport"
                // width={800}
                width={1000}
            >
                <div className="modal-content">
                    <div className="viewincident">
                        <div className="row1">
                            <div className="label">Submitted:</div>
                            <div className="value">{incident.timestamp}</div>
                        </div>
                        <div className="row1">
                            <div className="label">Centre/Group Reporting:</div>
                            <div className="value">
                                {incident.reportingCentre}
                            </div>
                        </div>
                        <div className="row1">
                            <div className="label">No:</div>
                            <div className="value">{incident.id}</div>
                        </div>
                        <div className="row1">
                            <div className="label">Location:</div>
                            <div className="value">{incident.location}</div>
                        </div>
                        <div className="row1">
                            <div className="label">Date / Time:</div>
                            <div className="value">{incident.datetime}</div>
                        </div>
                        <div className="row1">
                            <div className="label">Type:</div>
                            <div className="value">{incident.eventType}</div>
                        </div>
                        <div className="row1">
                            <div className="label">Reported By:</div>
                            <div className="value">{incident.reportedBy}</div>
                        </div>
                        <div className="row2">
                            <div className="label">Description:</div>
                            <div className="value">{incident.description}</div>
                        </div>
                        <div className="row2">
                            <div className="label">Probably Cause:</div>
                            <div className="value">
                                {incident.probableCause}
                            </div>
                        </div>
                        <div className="row2">
                            <div className="label">Action Taken [dated]:</div>
                            <div className="value">{incident.actionTaken}</div>
                        </div>
                        <div className="row2">
                            <div className="label">Outcome:</div>
                            <div className="value">{incident.eventOutcome}</div>
                        </div>
                        <div className="row2">
                            <div className="label">Further Action Planned:</div>
                            <div className="value">
                                {incident.furtherActionPlanned}
                            </div>
                        </div>
                        <div className="row2">
                            <div className="label">
                                Additional Orders (Medical Director):
                            </div>
                            <div className="value">{incident.addedOrders}</div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default PatientIncidentReport;
