import React, { useContext, useEffect, useState } from "react";
import {
    Button,
    Form,
    Input,
    Modal,
    notification,
    Popconfirm,
    Space,
    Spin,
    Table,
    TreeSelect
} from "antd";
import { SearchOutlined, LockOutlined } from "@ant-design/icons";
import { API } from "aws-amplify";

import { listStaff } from "../backend/graphql/queries";
import {
    changePassword,
    createStaff,
    deleteStaff,
    updateStaff
} from "../backend/graphql/mutations";
import { AppContext } from "../contexts/AppContext";
import renderInputItems from "../utils/renderFormInputItems";
import iconEdit from "../media/Icon-Edit.png";
import iconKey from "../media/Icon-Key.png";
import iconMinus from "../media/Icon-Minus.png";

const ManageStaff = (props) => {
    const { appState } = useContext(AppContext);
    const [formAdd] = Form.useForm();
    const [formUpdate] = Form.useForm();
    const [formSearch] = Form.useForm();
    const [formResetPassword] = Form.useForm();
    const { TreeNode, SHOW_PARENT } = TreeSelect;
    const [filter, setFilter] = useState({
        centre: props?.location?.state?.clinicID
            ? [props?.location?.state?.clinicID]
            : [0],
        search: ""
    });
    const validateMessages = {
        required: "Required.",
        types: {
            email: "This is not a valid email."
        },
        string: {
            min: "This field must be at least ${min} characters."
        }
    };
    const [table, setTable] = useState({
        data: -1,
        pagination: {
            current: 1,
            pageSize: 10,
            offset: 0,
            showSizeChanger: true
        },
        loading: false
    });
    const [display, setDisplay] = useState([]);
    const [clinicList, setClinicList] = useState(-1);
    const [visibilityUpdateStaff, setVisibilityUpdateStaff] = useState(false);
    const [editStaff, setEditStaff] = useState({});
    const [visibilityAddStaff, setVisibilityAddStaff] = useState(false);
    const [visibilityResetPassword, setVisibilityResetPassword] =
        useState(false);
    const [loadingModal, setLoadingModal] = useState(false);
    const [centreAccessInput, setCentreAccessInput] = useState(false);

    useEffect(() => {
        // console.log('props', props);
        getStaffList(table.pagination, filter);
        formSearch.setFieldsValue(filter);
    }, []);

    useEffect(() => {
        if (appState.fixedListLoaded === true) {
            setClinicList(appState.clinicList);
            // console.log("appState login type", appState.loginType);
        }
    }, [appState.fixedListLoaded]);

    useEffect(() => {
        if (table.data !== -1 && clinicList !== -1) {
            setDisplay(table.data);
            // let array = []
            // for (let v of table.data) {
            //     v.centreAccess = v.clinicIDs ? JSON.parse(v.clinicIDs) : [0];
            //     console.log(v);
            //     array.push(v);
            // }
            // setDisplay(array);
            // for (let i = 0; i < table.data.length; i++) {
            //     let object = {
            //         ...table.data[i],
            //     }
            //     for (let j = 0; j < clinicList.length; j++) {
            //         if (table.data[i].clinicID === clinicList[j].id) {
            //             object = {
            //                 ...object,
            //                 centreName: clinicList[j].name
            //             }
            //             break
            //         }
            //     }
            //     array.push(object)
            // }
            // setDisplay(array)
            setTable({
                ...table,
                loading: false
            });
        }
    }, [table.data, clinicList]);

    useEffect(() => {
        // console.log("editStaff", editStaff);
        if (Object.keys(editStaff).length !== 0) {
            formUpdate.setFieldsValue({
                name: editStaff.name,
                designation: editStaff.designation,
                // centre: editStaff.clinicID,
                contactNo: editStaff.contactNo,
                email: editStaff.email,
                centreAccess: editStaff.clinicIDs
                    ? JSON.parse(editStaff.clinicIDs)
                    : [0]
            });
            if (
                editStaff.designation === "Nurse" ||
                editStaff.designation === "Doctor"
            ) {
                setCentreAccessInput(true);
            } else {
                setCentreAccessInput(false);
            }
        } else {
            formUpdate.resetFields();
        }
    }, [editStaff]);

    const getStaffList = async (props, values) => {
        setTable({
            ...table,
            loading: true
        });
        try {
            let filter = {
                deleted: {
                    eq: 0
                }
                // and: []
            };

            if (values.search != "") {
                if (!filter.and) filter.and = [];

                filter.and.push({
                    or: [
                        { name: { contains: values.search } },
                        { designation: { contains: values.search } },
                        { email: { contains: values.search } },
                        { contactNo: { contains: values.search } }
                    ]
                });
            }

            // console.log("values.centre[0]", values.centre[0]);
            if (
                values.centre &&
                values.centre.length >= 1 &&
                values.centre[0] !== 0
            ) {
                if (!filter.and) filter.and = [];

                let array = [];
                for (let i = 0; i < values.centre.length; i++) {
                    array.push({
                        clinicIDs: { contains: `[${values.centre[i]},` }
                    });
                    array.push({
                        clinicIDs: { contains: `,${values.centre[i]},` }
                    });
                    array.push({
                        clinicIDs: { contains: ` ${values.centre[i]}]` }
                    });
                    array.push({
                        clinicIDs: { contains: `[${values.centre[i]}]` }
                    });
                    array.push({
                        clinicIDs: { eq: "" }
                    });
                    array.push({
                        clinicIDs: { attributeExists: false }
                    });
                }
                // console.log("array", array);
                filter.and.push({
                    or: array
                });
            }

            const staffDetails = {
                pagination: {
                    limit: props.pageSize,
                    offset: props.offset,
                    orderby: "name ASC"
                },
                filter: filter
            };
            // console.log("staffDetails", staffDetails);
            const result = await API.graphql({
                query: listStaff,
                variables: staffDetails,
                authMode: "AMAZON_COGNITO_USER_POOLS"
            });
            // console.log(result);
            const data = result.data.result;
            // console.log("data", data);
            setTable({
                ...table,
                data: data.result,
                loading: data.result?.length === 0 ? false : true,
                pagination: {
                    ...table.pagination,
                    current: props.current,
                    offset: props.offset,
                    pageSize: props.pageSize,
                    total:
                        props.offset >= data.count ? data.count + 1 : data.count // keeps the last pagination if it is the last record
                }
            });
        } catch (error) {
            console.log("error", error);
            notification.error({
                message: "Unable to retrieve records"
            });
            setTable({
                ...table,
                loading: false
            });
        }
    };

    const createStaffRecord = async (values) => {
        setLoadingModal(true);
        let create = {
            contactNo: values.contactNo,
            designation: values.designation,
            email: values.email,
            name: values.name
        };
        if (
            values.centreAccess &&
            values.centreAccess.length === 1 &&
            values.centreAccess[0] === 0
        ) {
            create = {
                ...create,
                clinicIDs: ""
            };
        } else {
            create = {
                ...create,
                clinicIDs: JSON.stringify(values.centreAccess)
            };
        }
        const staffDetails = {
            create: create,
            password: values?.password
        };
        // console.log("staffDetails", staffDetails);
        try {
            const result = await API.graphql({
                query: createStaff,
                variables: staffDetails,
                authMode: "AMAZON_COGNITO_USER_POOLS"
            });
            // console.log("result", result);
            if (result) {
                notification.success({
                    message: "Updated successfully"
                });
                handleModalVisibility("addStaff");
                getStaffList(
                    {
                        ...table.pagination,
                        current: 1,
                        pageSize: 10,
                        offset: 0
                    },
                    filter
                );
                formAdd.resetFields();
            }
        } catch (error) {
            console.log("error", error);
            if (
                error.errors[0].message ==
                "An account with the email already exists."
            ) {
                notification.error({
                    message: "An account with the email already exists."
                });
            } else {
                notification.error({
                    message: "Unable to create record"
                });
            }
        } finally {
            setLoadingModal(false);
        }
    };

    const updateStaffRecord = async (values) => {
        setLoadingModal(true);
        // console.log("editStaff", editStaff);
        let update = {
            accountID: editStaff.accountID,
            contactNo: values.contactNo,
            designation: values.designation,
            email: values.email,
            name: values.name
        };
        if (
            values.centreAccess &&
            values.centreAccess.length === 1 &&
            values.centreAccess[0] === 0
        ) {
            update = {
                ...update,
                clinicIDs: ""
            };
        } else {
            update = {
                ...update,
                clinicIDs: JSON.stringify(values.centreAccess)
            };
        }
        const staffDetails = {
            update: update
        };
        // console.log("staffDetails", staffDetails);
        try {
            const result = await API.graphql({
                query: updateStaff,
                variables: staffDetails,
                authMode: "AMAZON_COGNITO_USER_POOLS"
            });
            // console.log("result", result);
            notification.success({
                message: "Updated successfully"
            });
            handleModalVisibility("updateStaff");
            getStaffList(table.pagination, filter);
        } catch (error) {
            console.log("error", error);
            notification.error({
                message: "Unable to update record"
            });
        } finally {
            setLoadingModal(false);
        }
    };

    const _resetPassword = async (values) => {
        setLoadingModal(true);
        // console.log("editStaff", editStaff);
        let variables = {
            username: editStaff.accountID,
            password: values.newPassword,
            forceSignOut: 1
        };

        // console.log("staffDetails", staffDetails);
        try {
            const result = await API.graphql({
                query: changePassword,
                variables: variables,
                authMode: "AMAZON_COGNITO_USER_POOLS"
            });
            // console.log("result", result);
            notification.success({
                message: "Password Resetted"
            });
            formResetPassword.resetFields();
            handleModalVisibility("resetPassword");
            // getStaffList(table.pagination, filter)
        } catch (error) {
            console.log("error", error);
            notification.error({
                message: "Unable to reset password"
            });
        } finally {
            setLoadingModal(false);
        }
    };

    const _deleteStaff = async (accountID) => {
        // console.log("handle delete", id);
        setTable({
            ...table,
            loading: true
        });

        // console.log("editStaff", editStaff);
        let variables = {
            accountID
        };

        // console.log("staffDetails", staffDetails);
        try {
            const result = await API.graphql({
                query: deleteStaff,
                variables: variables,
                authMode: "AMAZON_COGNITO_USER_POOLS"
            });
            // console.log("result", result);
            notification.success({
                message: "Deleted successfully"
            });
            getStaffList(
                {
                    ...table.pagination,
                    current: 1,
                    pageSize: 10,
                    offset: 0
                },
                filter
            );
        } catch (error) {
            console.log("error", error);
            notification.error({
                message: "Unable to delete record"
            });
        } finally {
            setTable({
                ...table,
                loading: false
            });
        }
    };

    const onFinishSearch = (values) => {
        // console.log("submit formSearch", values);
        setFilter(values);
        getStaffList(table.pagination, values);
    };

    const onFinishAddStaff = async (values) => {
        // console.log("onFinishAddStaff", values);
        await formAdd.validateFields();
        createStaffRecord(values);
        // setLoadingModal(true)
        // const timer = setTimeout(() => {
        //     setLoadingModal(false)
        //     clearTimeout(timer)
        //     handleModalVisibility("addStaff")
        //     // formAdd.resetFields()
        // }, 1000)
    };

    const onFinishUpdateStaff = async (values) => {
        // console.log("onFinishUpdateStaff", values);
        await formUpdate.validateFields();
        updateStaffRecord(values);
        // setLoadingModal(true)
        // const timer = setTimeout(() => {
        //     setLoadingModal(false)
        //     clearTimeout(timer)
        //     handleModalVisibility("updateStaff")
        // }, 1000)
    };

    const onFinishResetPassword = async (values) => {
        // console.log("onFinishUpdateStaff", values);
        await formResetPassword.validateFields();
        _resetPassword(values);
    };

    const handleModalVisibility = (modal, record) => {
        // console.log("modal", modal);
        switch (modal) {
            case "resetPassword":
                setEditStaff(record ? record : {});
                setVisibilityResetPassword(!visibilityResetPassword);
                break;
            case "addStaff":
                setVisibilityAddStaff(!visibilityAddStaff);
                break;
            case "updateStaff":
                setEditStaff(record ? record : {});
                setVisibilityUpdateStaff(!visibilityUpdateStaff);
                break;
            default:
        }
    };

    const handleTreeSelectChange = (value, label, extra) => {
        // console.log("value", value);
        // console.log("label", label);
        // console.log("extra", extra);
    };

    const handleSelectDesignation = (value) => {
        if (value === "Nurse" || value === "Doctor") {
            // Nurse or Doctor selected
            setCentreAccessInput(true);
        } else {
            setCentreAccessInput(false);
        }
    };

    const handleTableChange = (paginate) => {
        getStaffList(
            {
                ...table.pagination,
                pageSize: paginate.pageSize,
                current: paginate.current,
                pageSize: paginate.pageSize,
                offset: paginate.current * paginate.pageSize - paginate.pageSize
            },
            filter
        );
    };

    const renderTreeSelectOptions = (array) => {
        if (typeof array == "object" && array.length !== 0) {
            const listOptions = array.map((item, index) => {
                return (
                    <TreeNode title={item.name} value={item.id} key={item.id} />
                );
            });

            return listOptions;
        } else {
            return <div />;
        }
    };

    const columns = [
        {
            title: "Name",
            dataIndex: "name"
        },
        {
            title: "Designation",
            dataIndex: "designation"
        },
        {
            title: "Phone No.",
            dataIndex: "contactNo"
        },
        {
            title: "Email",
            dataIndex: "email"
        },
        {
            title: "Actions",
            dataIndex: "actions",
            render: (_, record) => {
                return ["ManagingNurse", "MedicalDirector"].includes(
                    appState.loginType
                ) ? (
                    <Space size="large">
                        <div
                            className="table-row-action clickable"
                            onClick={() =>
                                handleModalVisibility("updateStaff", record)
                            }
                        >
                            <div className="icon">
                                <img src={iconEdit} />
                            </div>
                            <div className="text">Edit</div>
                        </div>
                        <div
                            className="table-row-action clickable"
                            onClick={() =>
                                handleModalVisibility("resetPassword", record)
                            }
                        >
                            <div className="icon">
                                <img src={iconKey} />
                            </div>
                            <div className="text">Reset Password</div>
                        </div>
                        <Popconfirm
                            title="Are you sure?"
                            onConfirm={() => _deleteStaff(record.accountID)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <div className="table-row-action clickable red">
                                <div className="icon">
                                    <img src={iconMinus} />
                                </div>
                                <div className="text">Delete</div>
                            </div>
                        </Popconfirm>
                    </Space>
                ) : (
                    <></>
                );
            }
        }
    ];

    return (
        <div className="content-content">
            <div className="manage-header">
                <div className="container-rows">
                    <div className="row1">
                        <div className="title">Manage Staff</div>
                        <Button
                            type="primary"
                            onClick={() => handleModalVisibility("addStaff")}
                        >
                            Add Staff
                        </Button>
                    </div>
                    <Form
                        form={formSearch}
                        layout="horizontal"
                        onFinish={onFinishSearch}
                        validateMessages={validateMessages}
                        requiredMark={false}
                    >
                        <div className="container-row">
                            <div className="row2">
                                <div className="row">
                                    <Form.Item
                                        key="centre"
                                        name="centre"
                                        rules={[
                                            {
                                                required: true
                                            }
                                        ]}
                                        style={{ minWidth: 430, width: "50%" }}
                                    >
                                        <TreeSelect
                                            showSearch
                                            allowClear
                                            multiple
                                            treeDefaultExpandAll
                                            treeCheckable
                                            placeholder="Select clinics"
                                            showCheckedStrategy={SHOW_PARENT}
                                            filterTreeNode={(input, treenode) =>
                                                treenode.title
                                                    .toLowerCase()
                                                    .indexOf(
                                                        input.toLowerCase()
                                                    ) >= 0
                                            }
                                            // defaultValue={0}
                                            onChange={handleTreeSelectChange}
                                        >
                                            <TreeNode value={0} title="All">
                                                {clinicList !== -1
                                                    ? renderTreeSelectOptions(
                                                          clinicList
                                                      )
                                                    : ""}
                                                {/* {renderTreeSelectOptions([
                                                    {
                                                        id: 1,
                                                        name: "AMC Centre (AMC)"
                                                    },
                                                    {
                                                        id: 2,
                                                        name: "Thomson Clinic (TMC)"
                                                    },
                                                    {
                                                        id: 3,
                                                        name: "Yishun Clinic (YSC)"
                                                    },
                                                    {
                                                        id: 4,
                                                        name: "Hougang Clinic (HGC)"
                                                    },
                                                    {
                                                        id: 5,
                                                        name: "Mayflower Clinic (MFC)"
                                                    },
                                                ])} */}
                                            </TreeNode>
                                        </TreeSelect>
                                    </Form.Item>
                                </div>
                                <div className="row">
                                    <Form.Item className="search" name="search">
                                        <Input
                                            autoComplete="off"
                                            prefix={<SearchOutlined />}
                                            placeholder="Search staff name / designation / email / phoneNo"
                                            value={filter.search}
                                            allowClear
                                        />
                                    </Form.Item>
                                    <Button type="primary" htmlType="submit">
                                        Search
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
            <div className="manage-content">
                <Table
                    columns={columns}
                    rowKey={(record) => record.id}
                    rowClassName={(record, index) => {
                        // console.log("index", index);
                        return index % 2 === 0 ? "highlight" : "";
                    }}
                    loading={table.loading}
                    dataSource={display}
                    onChange={handleTableChange}
                    pagination={table.pagination}
                    // dataSource={[
                    //     {
                    //         id: "1",
                    //         staffName: "Name of User",
                    //         designationID: 3,
                    //         designation: "Doctor",
                    //         centreID: 1,
                    //         centreName: "Centre ABC",
                    //         phoneNo: "+6598765432",
                    //         email: "123@email.com",
                    //     },
                    //     {
                    //         id: "2",
                    //         staffName: "Name of User",
                    //         designationID: 3,
                    //         designation: "Doctor",
                    //         centreID: 2,
                    //         centreName: "Centre DEF",
                    //         phoneNo: "+6598765432",
                    //         email: "123@email.com",
                    //     },
                    //     {
                    //         id: "3",
                    //         staffName: "Name of User",
                    //         designationID: 3,
                    //         designation: "Doctor",
                    //         centreID: 3,
                    //         centreName: "Centre GHI",
                    //         phoneNo: "+6598765432",
                    //         email: "123@email.com",
                    //     },
                    // ]}
                />
            </div>
            <Modal
                title={<div className="modal-title">Edit Staff</div>}
                visible={visibilityUpdateStaff}
                onCancel={() => handleModalVisibility("updateStaff")}
                footer={[
                    <Button
                        key="back"
                        onClick={() => handleModalVisibility("updateStaff")}
                    >
                        Cancel
                    </Button>,
                    <Popconfirm
                        title="Are you sure?"
                        onConfirm={() =>
                            onFinishUpdateStaff(formUpdate.getFieldsValue())
                        }
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button
                            key="submit"
                            type="primary"
                            loading={loadingModal}
                        >
                            Update
                        </Button>
                    </Popconfirm>
                ]}
                className="modal-manage"
                width={800}
            >
                <div className="modal-content">
                    <Spin spinning={loadingModal}>
                        <Form
                            form={formUpdate}
                            layout="horizontal"
                            onFinish={onFinishUpdateStaff}
                            validateMessages={validateMessages}
                            requiredMark={false}
                        >
                            {renderInputItems([
                                {
                                    label: "Staff Name",
                                    name: "name",
                                    type: "input",
                                    placeholder: "Enter staff name",
                                    required: true
                                },
                                {
                                    label: "Designation",
                                    name: "designation",
                                    type: "select",
                                    placeholder: "Select designation",
                                    required: true,
                                    optionList:
                                        appState.loginType === "MedicalDirector"
                                            ? [
                                                  {
                                                      id: "Nurse",
                                                      name: "Nurse"
                                                  },
                                                  {
                                                      id: "ManagingNurse",
                                                      name: "Nurse Manager"
                                                  },
                                                  {
                                                      id: "Doctor",
                                                      name: "Doctor"
                                                  },
                                                  {
                                                      id: "MedicalDirector",
                                                      name: "Medical Director"
                                                  }
                                              ]
                                            : [
                                                  {
                                                      id: "Nurse",
                                                      name: "Nurse"
                                                  },
                                                  {
                                                      id: "ManagingNurse",
                                                      name: "Nurse Manager"
                                                  }
                                              ]
                                },
                                // {
                                //     label: "Centre",
                                //     name: "centre",
                                //     type: "select",
                                //     placeholder: "Select centre",
                                //     required: true,
                                //     optionList: clinicList !== -1 ? clinicList : []
                                // },
                                {
                                    label: "Phone No.",
                                    name: "contactNo",
                                    type: "input",
                                    placeholder: "Enter phone no",
                                    required: true,
                                    regex: new RegExp("[0-9 .+-,]+$"),
                                    regexMessage: "Invalid number"
                                },
                                {
                                    label: "Email",
                                    name: "email",
                                    type: "email",
                                    placeholder: "Enter email",
                                    required: true
                                }
                            ])}
                            {centreAccessInput === true ? (
                                <Form.Item
                                    label="Centre Access"
                                    name="centreAccess"
                                    key="centreACcess"
                                    rules={[
                                        {
                                            required: centreAccessInput
                                        }
                                    ]}
                                >
                                    <TreeSelect
                                        showSearch
                                        allowClear
                                        multiple
                                        treeDefaultExpandAll
                                        treeCheckable
                                        placeholder="Select clinics"
                                        showCheckedStrategy={SHOW_PARENT}
                                        filterTreeNode={(input, treenode) =>
                                            treenode.title
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >=
                                            0
                                        }
                                        // defaultValue={0}
                                        onChange={handleTreeSelectChange}
                                    >
                                        <TreeNode value={0} title="All">
                                            {renderTreeSelectOptions(
                                                clinicList !== -1
                                                    ? clinicList
                                                    : []
                                            )}
                                        </TreeNode>
                                    </TreeSelect>
                                </Form.Item>
                            ) : (
                                ""
                            )}
                        </Form>
                    </Spin>
                </div>
            </Modal>
            <Modal
                title={<div className="modal-title">Add Staff</div>}
                visible={visibilityAddStaff}
                onCancel={() => handleModalVisibility("addStaff")}
                footer={[
                    <Button
                        key="back"
                        onClick={() => handleModalVisibility("addStaff")}
                    >
                        Cancel
                    </Button>,
                    <Popconfirm
                        title="Are you sure"
                        onConfirm={() =>
                            onFinishAddStaff(formAdd.getFieldsValue())
                        }
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button
                            key="submit"
                            type="primary"
                            loading={loadingModal}
                        >
                            Add
                        </Button>
                    </Popconfirm>
                ]}
                className="modal-manage"
                width={800}
            >
                <div className="modal-content">
                    <Spin spinning={loadingModal}>
                        <Form
                            form={formAdd}
                            layout="horizontal"
                            onFinish={onFinishUpdateStaff}
                            validateMessages={validateMessages}
                            requiredMark={false}
                        >
                            {renderInputItems([
                                {
                                    label: "Staff Name",
                                    name: "name",
                                    type: "input",
                                    placeholder: "Enter staff name",
                                    required: true
                                },
                                {
                                    label: "Designation",
                                    name: "designation",
                                    type: "select",
                                    placeholder: "Select designation",
                                    required: true,
                                    optionList:
                                        appState.loginType === "MedicalDirector"
                                            ? [
                                                  {
                                                      id: "Nurse",
                                                      name: "Nurse"
                                                  },
                                                  {
                                                      id: "ManagingNurse",
                                                      name: "Nurse Manager"
                                                  },
                                                  {
                                                      id: "Doctor",
                                                      name: "Doctor"
                                                  },
                                                  {
                                                      id: "MedicalDirector",
                                                      name: "Medical Director"
                                                  }
                                              ]
                                            : [
                                                  {
                                                      id: "Nurse",
                                                      name: "Nurse"
                                                  },
                                                  {
                                                      id: "ManagingNurse",
                                                      name: "Nurse Manager"
                                                  }
                                              ],
                                    handleSelect: handleSelectDesignation
                                },
                                // {
                                //     label: "Centre",
                                //     name: "centre",
                                //     type: "select",
                                //     placeholder: "Select centre",
                                //     required: true,
                                //     optionList: clinicList !== -1 ? clinicList : []
                                // },
                                {
                                    label: "Phone No.",
                                    name: "contactNo",
                                    type: "input",
                                    placeholder: "Enter phone no",
                                    // required: true,
                                    regex: new RegExp("[0-9 .+-,]+$"),
                                    regexMessage: "Invalid number"
                                },
                                {
                                    label: "Email",
                                    name: "email",
                                    type: "email",
                                    placeholder: "Enter email",
                                    required: true
                                }
                            ])}
                            {centreAccessInput === true ? (
                                <Form.Item
                                    label="Centre Access"
                                    name="centreAccess"
                                    key="centreACcess"
                                    rules={[
                                        {
                                            required: centreAccessInput
                                        }
                                    ]}
                                >
                                    <TreeSelect
                                        showSearch
                                        allowClear
                                        multiple
                                        treeDefaultExpandAll
                                        treeCheckable
                                        placeholder="Select clinics"
                                        showCheckedStrategy={SHOW_PARENT}
                                        filterTreeNode={(input, treenode) =>
                                            treenode.title
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >=
                                            0
                                        }
                                        // defaultValue={0}
                                        onChange={handleTreeSelectChange}
                                    >
                                        <TreeNode value={0} title="All">
                                            {renderTreeSelectOptions(
                                                clinicList !== -1
                                                    ? clinicList
                                                    : []
                                            )}
                                        </TreeNode>
                                    </TreeSelect>
                                </Form.Item>
                            ) : (
                                ""
                            )}
                            {/* {renderInputItems([
                                {
                                    label: "Password",
                                    name: "password",
                                    type: "input",
                                    placeholder: "Enter one time password for first login",
                                    required: true,
                                }
                            ])} */}
                            <Form.Item
                                label="Password"
                                name="password"
                                rules={[
                                    {
                                        required: true
                                    },
                                    {
                                        min: 8
                                    }
                                ]}
                            >
                                <Input.Password
                                    prefix={
                                        <LockOutlined className="site-form-item-icon" />
                                    }
                                    placeholder="Enter one time password for first login"
                                />
                            </Form.Item>
                        </Form>
                    </Spin>
                </div>
            </Modal>
            <Modal
                title={<div className="modal-title">Reset Password</div>}
                visible={visibilityResetPassword}
                onCancel={() => handleModalVisibility("resetPassword")}
                footer={[
                    <Button
                        key="back"
                        onClick={() => handleModalVisibility("resetPassword")}
                    >
                        Cancel
                    </Button>,
                    <Button
                        type="primary"
                        onClick={() => {
                            onFinishResetPassword(
                                formResetPassword.getFieldsValue()
                            );
                        }}
                    >
                        ResetPassword
                    </Button>
                ]}
                className="modal-manageaccount-changepassword"
            >
                <Spin spinning={loadingModal}>
                    <Form
                        form={formResetPassword}
                        layout="vertical"
                        validateMessages={validateMessages}
                        requiredMark={false}
                    >
                        <div className="">
                            <div className="container-accountdetails">
                                <Form.Item
                                    label="New Password"
                                    name="newPassword"
                                    rules={[
                                        {
                                            required: true
                                        },
                                        {
                                            min: 8
                                        }
                                    ]}
                                >
                                    <Input.Password
                                        prefix={
                                            <LockOutlined className="site-form-item-icon" />
                                        }
                                        placeholder="New Password"
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Confirm New Password"
                                    name="confirmNewPassword"
                                    rules={[
                                        {
                                            required: true
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (
                                                    !value ||
                                                    getFieldValue(
                                                        "newPassword"
                                                    ) === value
                                                ) {
                                                    return Promise.resolve();
                                                }

                                                return Promise.reject(
                                                    new Error(
                                                        "The two passwords that you entered do not match."
                                                    )
                                                );
                                            }
                                        })
                                    ]}
                                >
                                    <Input.Password
                                        prefix={
                                            <LockOutlined className="site-form-item-icon" />
                                        }
                                        placeholder="Confirm New Password"
                                    />
                                </Form.Item>
                            </div>
                        </div>
                    </Form>
                </Spin>
            </Modal>
        </div>
    );
};

export default ManageStaff;
